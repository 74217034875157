import React from 'react';
import {
  Box,
  Typography,
  TableCell,
  TableRow,
  Button,
  Tooltip,
  Zoom,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MUIDataTable from 'mui-datatables';

const SpiritualCenterForUserList = ({
  coursesCompatible,
  showSelectedButton = false,
  showRemoveButton = false,
  modalAlertDeleteSpiritualCenter = undefined,
  spiritualCenterSelected,
  setSpiritualCenterSelected,
}) => {
  if (coursesCompatible?.length > 0) {
    const hasCompatibleCourses = coursesCompatible.some(
      (el) => el.curso_compativel_id !== null || el?.Turma?.local === 'Externo'
    );

    const columns = [
      {
        name: 'nome_fantasia',
        label: 'Entidades',
        options: {
          filter: false,
          sort: false,
          setCellProps: (value) => {
            return {
              style: {
                width: '80%',
              },
            };
          },
          customBodyRender: (value) => (
            <Typography variant='body1'>{value ?? '-'}</Typography>
          ),
        },
      },

      {
        name: 'centro_espirita_id',
        label: 'Selecionar',
        options: {
          filter: false,
          sort: false,
          display: showSelectedButton ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            <>
              <Button
                disableElevation
                variant={'contained'}
                size={'small'}
                color={'info'}
                //disabled={!hasPermission('Pessoas', 'deletar') ? true : false}
                onClick={() => setSpiritualCenterSelected(value)}
              >
                Selecionar
              </Button>
            </>
          ),
        },
      },
      {
        name: 'centro_espirita_id',
        label: 'Deletar',
        options: {
          filter: false,
          sort: false,
          display: showSelectedButton ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            <>
              <Button
                disableElevation
                variant={'contained'}
                size={'small'}
                color={'secondary'}
                //disabled={!hasPermission('Pessoas', 'deletar') ? true : false}
                onClick={() => modalAlertDeleteSpiritualCenter(value)}
              >
                Deletar
              </Button>
            </>
          ),
        },
      },
    ];

    if (hasCompatibleCourses) {
      return (
        <Box>
          <MUIDataTable
            data={coursesCompatible}
            columns={columns}
            options={{
              filterType: 'checkbox',
              selectableRows: 'none',
              search: false,
              download: false,
              print: false,
              sortFilterList: false,
              viewColumns: false,
              filter: false,
              footer: true,
              textLabels: {
                pagination: {
                  next: 'Próxima Página',
                  previous: 'Página Anterior',
                  rowsPerPage: 'Itens por Página:',
                  displayRows: 'de',
                },
              },
              setTableProps: () => {
                return {
                  size: 'small',
                };
              },
            }}
          />
        </Box>
      );
    } else {
      return 'Nenhum curso compatibilizado encontrado';
    }
  } else {
    return 'Nenhum curso compatibilizado encontrado';
  }
};

export default SpiritualCenterForUserList;
