import React, { useContext } from 'react';
import {
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Grid,
    Tooltip,
    Zoom,
} from '@mui/material';
import Button from '@mui/material/Button';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { TextService } from 'app/servicesTwo/TextService';
import { EllipsisTooltip } from './EllipsisTooltip/EllipsisTooltip';
import { LinkStyle } from 'app/pages/app/users/UserItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useLocation } from 'react-router-dom';

const ListItemComponent = ({ item, includeStudentToClass, isButtonDisabled }) => {
    const { hasPermission } = useContext(PermissionContext);
    const maxLengthText = 20;
    const location = useLocation();

    return (
        <ListItem sx={{ p: (theme) => theme.spacing(1, 3) }}>
            <Grid container spacing={2} alignContent={'center'}>
                <Grid item lg={4} md={5} xs={4}>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Nome
                    </Typography>
                    <EllipsisTooltip text={item?.nome} />
                </Grid>
                <Grid item lg={2} md={5} xs={3}>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        CPF
                    </Typography>
                    <EllipsisTooltip text={item?.cpf} />
                </Grid>
                <Grid
                    item
                    lg={2}
                    sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }}
                >
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Email
                    </Typography>
                    <EllipsisTooltip text={item?.email} />
                </Grid>
                <Grid item lg={2} md={5} xs={3}>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Celular
                    </Typography>
                    <EllipsisTooltip text={item?.celular} />
                   {/*  <Typography variant={'body1'}>{item.celular}</Typography> */}

                </Grid>
                <Grid item lg={1} md={1} xs={2}>
                    <Tooltip
                        title='Visualizar'
                        TransitionComponent={Zoom}
                        arrow
                    >
                        <LinkStyle
                            to={`/app/editar-aluno/${item.pessoa_id}`}
                            state={{
                                backUrl: location.pathname,
                                disable: true,
                            }}
                        >
                            <VisibilityIcon />
                        </LinkStyle>
                    </Tooltip>
                </Grid>
                <Grid item lg={1} md={1} xs={1}>
                    <Button
                        size={'small'}
                        variant={'contained'}
                        color={'success'}
                        disableElevation
                        onClick={() => includeStudentToClass(item)}
                        // {...(item.follow) ? {color: "inherit"} : {}}
                        disabled={
                            !hasPermission(
                                'Inclusão de Alunos na Turma',
                                'editar'
                            ) || isButtonDisabled
                        }
                        sx={{
                            minWidth: 78,
                            textTransform: 'none',
                            p: (theme) => theme.spacing(0.5, 1.5),
                        }}
                    >
                        Incluir
                    </Button>
                    {/* {
                        item.possui_requisitos
                        ?
                            <Button
                                size={"small"}
                                variant={"contained"}
                                color={"success"}
                                disableElevation
                                onClick={() => includeStudentToClass(item)}
                                // {...(item.follow) ? {color: "inherit"} : {}}
                                disabled={!hasPermission('Turmas', 'editar')}
                                sx={{
                                    minWidth: 78,
                                    textTransform: 'none',
                                    p: theme => theme.spacing(.5, 1.5)
                                }}
                            >
                                Incluir
                            </Button>
                        :
                        <Tooltip 
                            title={'Aluno não atende pré-requisito'} 
                            arrow 
                            TransitionComponent={Zoom}
                        >
                            <span>
                            
                                <Button
                                    size={"small"}
                                    variant={"contained"}
                                    color={"success"}
                                    disableElevation
                                    disabled
                                    sx={{
                                        minWidth: 78,
                                        textTransform: 'none',
                                        p: theme => theme.spacing(.5, 1.5)
                                    }}
                                >
                                    Incluir
                                </Button>
                            </span>
                                
                        </Tooltip>
                    } */}
                </Grid>
            </Grid>
        </ListItem>
    );
};
/* Todo item, includeStudentToClass prop define */
export default ListItemComponent;
