import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  useContext,
} from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import PageHeader from '../../../layouts/shared/headers/PageHeader/PageHeader';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { Grid, Paper, Stack, useMediaQuery, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { ApiService } from 'app/servicesTwo/ApiService';
import JumboSearch from '@jumbo/components/JumboSearch';
import { Link, useLocation } from 'react-router-dom';
import { PermissionContext } from 'app/contexts/PermissionContext';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import SpiritualCenterItem from './SpiritualCenterItem';

const validationSchema = yup.object({
  email: yup.string('Insira seu email').email('Insira um email válido'),
  senha: yup.string('Insira sua senha'),
  nome: yup.string('Insira seu nome').required('Preenchimento obrigatório'),
  cpf: yup.string(),
  rg: yup.string().max(11, 'Deve ter no máximo 11 digitos'),
  data_nascimento: yup
    .date()
    .max(new Date(), 'Não é possivel incluir uma data futura')
    .required('Preenchimento obrigatório'),
  celular: yup.string(),
});

const SpiritualCenterList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const Swal = useSwalWrapper();
  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(false);

  const { hasPermission } = useContext(PermissionContext);

  useEffect(() => {
    if (searchTerm == undefined) return;

    if (searchTerm == '') {
      setLoading(true);
      searchData(searchTerm);
      setLoading(false);
    }

    const delayDebounceFn = setTimeout(() => {
      setLoading(true);
      searchData(searchTerm);
      setLoading(false);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const getData = useCallback(async () => {
    try {
      ApiService.get('/centro-espirita')
        .then((response) => {
            setData(response.data);
          console.log('response.data', response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const searchData = useCallback(async (e) => {
    console.log('pesquisar', e);
    try {
      ApiService.get('/centro-espirita', { params: { search: e } })
        .then((response) => {
            setData(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    Swal.close();
  }, [location.pathname, Swal]);

  useEffect(() => {
    console.log('atualizou', data);
  }, [setData, data]);

  return (
    <JumboContentLayout
      header={<PageHeader title={'Centros Espíritas'} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '40px' }}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Link
            to='/app/novo-centro-espirita'
            style={{
              textDecoration: 'none',
              pointerEvents: !hasPermission('Centros Espíritas', 'criar')
                ? 'none'
                : '',
            }}
            state={{ backUrl: location.pathname }}
          >
            <LoadingButton
              color='success'
              type='submit'
              variant='contained'
              size='large'
              disabled={!hasPermission('Centros Espíritas', 'criar')}
              sx={{
                maxWidth: { md: '250px', mt: '1rem' },
                marginBottom: '2rem',
              }}
            >
              Novo centro espírita
            </LoadingButton>
          </Link>
        </Grid>
        <Typography
          variant='h6'
          sx={{ fontWeight: '500', marginBottom: '1rem', marginLeft: '1rem' }}
        >
          Busque por nome do centro espírita.
        </Typography>
        <Grid item xs={1} sx={{ textAlign: 'center' }}>
          <JumboSearch
            onChange={(e) => setSearchTerm(e)}
            placeholder='Digite o nome do centro espírita aqui...'
            sx={{
              width: '440px',
              marginBottom: '1rem',
            }}
          />
        </Grid>

        <Fragment>
          {data?.map((user, key) => (
            <SpiritualCenterItem
              item={user}
              setDepartments={(data) => setData([...data])}
              data={data}
              key={key}
            />
          ))}
          {data.length == 0 && (
            <Typography>Sem resultados correspondentes</Typography>
          )}
        </Fragment>
      </Paper>
    </JumboContentLayout>
  );
};

export default SpiritualCenterList;
