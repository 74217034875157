import axios from 'axios'
const token = localStorage.getItem("token")
console.log('ls',token)
export const ApiService = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token,
  }
})

ApiService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

ApiService.interceptors.response.use(
  res => res,
  err => {
    console.log(err.response.data.error)
    if (err.response.status === 401 && err.response.data.error === 'Token inválido') {
      localStorage.removeItem('token');
      localStorage.removeItem('storedUserImage');
      localStorage.removeItem('storedUserId');
      localStorage.removeItem('storedUsername');
      localStorage.removeItem('storedUserEmail');
      localStorage.removeItem('storedPermissions');
      window.location.href = '/login';
    }
     
    return Promise.reject(err);
  }
);