import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { Avatar, Box, TextField } from "@mui/material";
import html2pdf from 'html2pdf.js'; // Importe a biblioteca

const useStyles = makeStyles((theme) => ({
    card: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "16px",
        position: "relative",
        width: "360px",
        maxHeight: "550px",
        overflow: "hidden",
    },
    media: {
        width: "200px",
        height: "200px",
        borderRadius: "16px",
        objectFit: "cover",
    },
    mediaLogo: {
        width: "170px",
        height: "170px",
        borderRadius: "16px",
        objectFit: "cover",
    },
    actionIcons: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "16px",
        position: "absolute",
        top: "12px",
        right: "12px",
    },
}));

const ProfileCard = ({ username, photo, number, volunteerSince }) => {
    const classes = useStyles();
    const foto = `${process.env.REACT_APP_API_KEY}/images/${photo}`;
    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState(username);

    const crachaInfo = {
        numero: "12345",
        dataInicioVoluntariado: "01/01/2023",
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
    };

    const handleDownloadPDF = () => {
        const element = document.getElementById('profile-card');
        element.style.border = '2px solid #000'; 

        const opt = {
          margin: 0.5, 
          filename: 'cracha.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait'},
          
        };

        const icons = document.querySelectorAll('.no-pdf');
    
        if (icons) {
            icons[0].style.display = 'none';
            icons[1].style.display = 'none';
        }
    
        setTimeout(() => {
            html2pdf().from(element).set(opt).save();
        }, 2000)

        setTimeout(() => {
            icons[0].style.display = 'block';
            icons[1].style.display = 'block';
            element.style.border = '0'; 
        }, 2000)

        
    };

    return (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Card className={classes.card} id="profile-card">
                <CardMedia
                    component="img"
                    alt="Logo da FEESP"
                    className={classes.mediaLogo}
                    image={"/images/logos/logo.png"}
                />
                <Avatar
                    alt="Foto do voluntário"
                    className={classes.media}
                    src={foto ?? '#'}
                />
                <CardContent>
                    {isEditing ? (
                        <Box>
                            <TextField
                                size="small"
                                type="text"
                                value={editedName}
                                label="Nome para impressão no crachá"
                                onChange={(e) => setEditedName(e.target.value)}
                            />
                            <IconButton
                                onClick={handleSaveClick}
                                aria-label="Salvar"
                            >
                                <SaveIcon />
                            </IconButton>
                        </Box>
                    ) : (
                        <Typography textAlign={"center"} variant="h3">
                            {editedName}
                        </Typography>
                    )}
                    <Typography variant="h5">
                        Número: {number}
                    </Typography>
                    <Typography variant="h5">
                        Voluntário desde: {volunteerSince}
                    </Typography>
                </CardContent>
                <div className={classes.actionIcons}>
                    {isEditing ? (
                        <Tooltip title="Salvar alterações">
                            <IconButton
                                onClick={handleSaveClick}
                                aria-label="Salvar"
                               
                            >
                                <SaveIcon  className="no-pdf"/>
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Editar nome do crachá">
                            <IconButton
                                onClick={handleEditClick}
                                aria-label="Editar"
                            >
                                <EditIcon  className="no-pdf"/>
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title="Baixar PDF">
                        <IconButton 
                            onClick={handleDownloadPDF} 
                            aria-label="Download PDF"
                        >
                            <DownloadIcon  className="no-pdf" />
                        </IconButton>
                    </Tooltip>
                </div>
            </Card>
        </Box>
    );
};

export default ProfileCard;
