import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import PageHeader from '../../../layouts/shared/headers/PageHeader/PageHeader';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
    Box,
    CircularProgress,
    Paper,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import * as yup from 'yup';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useLocation } from 'react-router-dom';
import Div from '@jumbo/shared/Div';
import JumboSearch from '@jumbo/components/JumboSearch';
import { PermissionContext } from 'app/contexts/PermissionContext';

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import UserCourseHistoryItem from './UserCourseHistoryItem';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';


const UserCourseHistoryList = () => {
    const { theme } = useJumboTheme();
    const location = useLocation();
    const Swal = useSwalWrapper();

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(
        () => ({
            sidebar: {
                sx: {
                    [theme.breakpoints.up('lg')]: {
                        position: 'sticky',
                        zIndex: 5,
                        top: 96,
                        minHeight: 'auto',
                    },
                    [theme.breakpoints.down('lg')]: {
                        display: 'none',
                    },
                },
            },
            wrapper: {
                sx: {
                    alignItems: 'flex-start',
                },
            },
        }),
        [theme]
    );

    const [users, setUsers] = useState(null);
    const { hasPermission } = useContext(PermissionContext);

    const [searchTerm, setSearchTerm] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (searchTerm == undefined) return;

        if (searchTerm == '') {
            setLoading(true);
            searchUser(searchTerm);
            setLoading(false);
        }

        const delayDebounceFn = setTimeout(() => {
            setLoading(true);
            searchUser(searchTerm);
            setLoading(false);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const searchUser = useCallback(async (e) => {
        try {
            ApiService.get('/pessoa', { params: { search: e } })
                .then((response) => {
                    setUsers(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        Swal.close();
    }, [location.pathname, Swal]);

    return (
        <JumboContentLayout
            header={<PageHeader title={'Consultar Histórico de Cursos'} />}
            layoutOptions={layoutOptions}
        >
            {lg && (
                <Stack
                    spacing={2}
                    direction={'row'}
                    sx={{ mb: 3, mt: -2 }}
                ></Stack>
            )}
            <Paper sx={{ p: '40px' }}>
                <Typography
                    variant='h6'
                    sx={{
                        fontWeight: '500',
                        marginBottom: '1rem',
                        marginLeft: '1rem',
                    }}
                >
                    Busque por nome, email, celular e cpf.
                </Typography>

                <Div
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <JumboSearch
                        onChange={(e) => setSearchTerm(e)}
                        placeholder='Digite o nome, email, celular ou cpf aqui...'
                        sx={{
                            width: '440px',
                            marginBottom: '1rem',
                        }}
                    />
                </Div>
                <>
                    {loading && (
                        <Box display='flex' justifyContent='center'>
                            <CircularProgress />
                        </Box>
                    )}
                    {users != null && users.length == 0 && (
                        <Typography>Sem resultados correspondentes</Typography>
                    )}

                    {users &&
                        users.map((user, key) => (
                            <UserCourseHistoryItem
                                item={user}
                                setUsers={(data) => setUsers([...data])}
                                users={users}
                                key={key}
                            />
                        ))}
                </>
            </Paper>
        </JumboContentLayout>
    );
};

export default UserCourseHistoryList;
