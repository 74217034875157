import React, {
    useState,
    useCallback,
    useEffect,
    Fragment,
    useContext,
} from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import PageHeader from '../../../layouts/shared/headers/PageHeader/PageHeader';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
    Grid,
    Paper,
    Stack,
    useMediaQuery,
    Typography,
    Button,
    Box,
    Pagination,
    CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { ApiService } from 'app/servicesTwo/ApiService';
import ClassItem from './ClassItem';
import JumboSearch from '@jumbo/components/JumboSearch';
import { Link, useLocation } from 'react-router-dom';
import { PermissionContext } from 'app/contexts/PermissionContext';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

const validationSchema = yup.object({
    email: yup.string('Insira seu email').email('Insira um email válido'),
    senha: yup.string('Insira sua senha'),
    nome: yup.string('Insira seu nome').required('Preenchimento obrigatório'),
    cpf: yup.string(),
    rg: yup.string().max(11, 'Deve ter no máximo 11 digitos'),
    data_nascimento: yup
        .date()
        .max(new Date(), 'Não é possivel incluir uma data futura')
        .required('Preenchimento obrigatório'),
    celular: yup.string(),
});

const UserList = () => {
    const { theme } = useJumboTheme();
    const animatedComponents = makeAnimated();
    const location = useLocation();
    const Swal = useSwalWrapper();
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const { hasPermission } = useContext(PermissionContext);

    const [classes, setClasses] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [select1Value, setSelect1Value] = useState(null);
    const [page, setPage] = useState(1);
    const [per_page, setPerPage] = useState(10);
    const [count, setCount] = useState(0);

    const layoutOptions = React.useMemo(
        () => ({
            sidebar: {
                sx: {
                    [theme.breakpoints.up('lg')]: {
                        position: 'sticky',
                        zIndex: 5,
                        top: 96,
                        minHeight: 'auto',
                    },
                    [theme.breakpoints.down('lg')]: {
                        display: 'none',
                    },
                },
            },
            wrapper: {
                sx: {
                    alignItems: 'flex-start',
                },
            },
        }),
        [theme]
    );

    const getClasses = useCallback(async (searchData = {}) => {
        setLoading(true);
        try {
            const response = await ApiService.get('/turmas', { 
                params: { 
                    ...searchData, 
                  /*   page, 
                    per_page  */
                } 
            });
            console.log('resoinse', response.data)
            setClasses(response.data.classes);
            setCount(response.data.total_pages);
        } catch (error) {
            console.error('Error fetching classes:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (searchTerm === undefined) return;

        const selectedValueToString = JSON.stringify(select1Value)
        if (searchTerm === '') {
            getClasses({ search: searchTerm, page, per_page, selectedValueToString });
            return;
        }

        const delayDebounceFn = setTimeout(() => {
            getClasses({ search: searchTerm, page, per_page, selectedValueToString });
        }, 1500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, getClasses, page, per_page]);

    useEffect(() => {
        const selectedValueToString = JSON.stringify(select1Value)
        getClasses({ search: searchTerm, page, per_page, selectedValueToString });
    }, [page, getClasses]);

    useEffect(() => {
        Swal.close();
    }, [location.pathname, Swal]);

    const handleSelect1Change = (selected) => {
        setSelect1Value(selected.length === 0 ? null : selected);
    };

    const optionsYears = () => {
        const startYear = 1980;
        const endYear = new Date().getFullYear();
        return Array.from({ length: endYear - startYear + 3 }, (_, i) => ({
            value: endYear + 2 - i,
            label: (endYear + 2 - i).toString(),
        }));
    };

    const handleSubmit = () => {
        const selectedValueToString = JSON.stringify(select1Value)
        console.log(selectedValueToString)
        const searchData = { search: searchTerm, page, per_page, selectedValueToString };
        setLoading(true);
        getClasses(searchData);
    };

    return (
        <JumboContentLayout
            header={
                <PageHeader
                    title={
                        location.pathname.includes('inserir')
                            ? 'Inclusão de Aluno na Turma'
                            : 'Turmas'
                    }
                />
            }
            layoutOptions={layoutOptions}
        >
            {lg && (
                <Stack
                    spacing={2}
                    direction={'row'}
                    sx={{ mb: 3, mt: -2 }}
                ></Stack>
            )}
            <Paper sx={{ p: '40px' }}>
                {!location.pathname.includes('inserir') && (
                    <Box display='flex' justifyContent='center'>
                        <Link
                            to='/app/nova-turma'
                            style={{
                                textDecoration: 'none',
                                pointerEvents: !hasPermission('Turmas', 'criar')
                                    ? 'none'
                                    : '',
                            }}
                            state={{ backUrl: location.pathname }}
                        >
                            <LoadingButton
                                color='success'
                                type='submit'
                                variant='contained'
                                size='large'
                                disabled={!hasPermission('Turmas', 'criar')}
                                sx={{
                                    maxWidth: { md: '200px', mt: '1rem' },
                                    marginBottom: '2rem',
                                }}
                            >
                                Nova Turma
                            </LoadingButton>
                        </Link>
                    </Box>
                )}

                <Box
                    display='flex'
                    alignItems='center'
                    gap='1rem'
                    marginBottom='1rem'
                    flexWrap='wrap'
                >
                    <Box display='flex' alignItems='center' gap='1rem'>
                        <Select
                            id='select-one'
                            isMulti
                            options={optionsYears()}
                            value={select1Value}
                            onChange={handleSelect1Change}
                            components={animatedComponents}
                            placeholder='Selecione ano'
                            styles={{
                                menu: (list) => ({
                                    ...list,
                                    zIndex: 3,
                                }),
                                control: (base) => ({
                                    ...base,
                                    width: '100%',
                                }),
                            }}
                        />
                        <Button variant={'contained'} onClick={handleSubmit}>
                            Filtrar
                        </Button>
                    </Box>
                    <Box display='flex' alignItems='center' width='55%'>
                        <JumboSearch
                            onChange={(e) => setSearchTerm(e)}
                            placeholder='Digite o nome do curso, dia da semana, horário, modo ou local aqui...'
                        />
                    </Box>
                </Box>

                <Fragment>
                    {loading ? (
                        <Box display='flex' justifyContent='center'>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {classes && classes.map((user, key) => (
                                <ClassItem
                                    item={user}
                                    setClasses={(data) => setClasses([...data])}
                                    classes={classes}
                                    key={key}
                                />
                            ))}
                            {classes && classes.length === 0 && (
                                <Typography>Sem resultados correspondentes</Typography>
                            )}
                        </>
                    )}
                </Fragment>
                <Pagination
                    sx={{ mt: 2 }}
                    color="primary"
                    count={count}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                />
            </Paper>
        </JumboContentLayout>
    );
};

export default UserList;
