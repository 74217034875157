import React, {useCallback, useState} from 'react';
import {List} from "@mui/material";
import { useParams } from "react-router-dom";
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ListItemAssociate from './ListItemAssociate';

const ListRowAssociate = ({ data , setAddStudents, updateStudentList, classStudents, status,setStatus, setUsername, setSearchTerm}) => {
    const { id } = useParams()
    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });
    };

    const [itemsList, setItemsList] = useState(data);
    const includeUserAssociate = useCallback((record) => {
        try {
            ApiService.get(`/pessoa-associada/${record.pessoa_id}`)
            .then((response) => {
                console.log(response.data)
                setStatus(true)
                setUsername(response.data.user)
                setSearchTerm('')
                //toast('success', 'Aluno adicionado com sucesso');
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }

    }, [itemsList, status]);


    return (
            <List disablePadding>
                {
                    data.map((item, index) => (
                        <ListItemAssociate item={item} key={index} includeUserAssociate={includeUserAssociate} />
                    ))
                }
            </List>
    );
};

export default ListRowAssociate;
