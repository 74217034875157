import React, {useEffect, useState} from 'react';
import {convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import code from "./textEditor.txt";

let Editor = () => <React.Fragment/>;

const TextEditor = ({editorState, setEditorState, title}) => {
    //const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        Editor = require('react-draft-wysiwyg').Editor;
        setEditorState(EditorState.createEmpty());
    }, []);
    

    return (
        <JumboDemoCard title={title}>
            <Editor
                editorStyle={{
                    width: '100%',
                    minHeight: 100,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'lightgray',
                }}
                editorState={editorState}
                onEditorStateChange={editorState => setEditorState(editorState)}
            />
            {/* <textarea
                style={{width: '100%', height: 150}}
                disabled
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            /> */}
        </JumboDemoCard>
    );
};

export default TextEditor;