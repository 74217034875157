import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
  Zoom,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { updateInputValue } from '../../../utils/appHelpers';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
/* import ClassStudentsList from "./ClassStudentsList"; */
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';
import UploadIcon from '@mui/icons-material/Upload';
import ListRowVolunteerInterested from 'app/components/ListRowVolunteerInterested/ListRowVolunteerInterested';
import makeAnimated from 'react-select/animated';
import ProfileCard from 'app/components/ProfileCard/ProfileCard';
import JumboSearchClasses from '@jumbo/components/JumboSearchClasses';
import { useDebouncedCallback } from 'beautiful-react-hooks';
import styled from '@emotion/styled';
import FileListSlim from 'app/components/FileUploadSlim/FileListSlim';
import DeleteIcon from '@mui/icons-material/Delete';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const FileListItem = styled.li`
  list-style: none;
  /* margin: 1rem 0; */
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.9rem;
`;

const VolunteerFormResume = () => {
  const { theme } = useJumboTheme();
  const navigate = useNavigate();
  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: 'Fechar',
    });
  };

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const initialValues = {
    pessoa_id: '',
    voluntario_id: '',
    codigo_antigo_voluntariado: '',
    status: '',
    data_inicio: '',
    data_fim: '',
    departamento_id: '',
    area_id: '',
    local: '',
    ficha: '',
    atividade: '',
    observacao: '',
    voluntario_desde: '',
    motivo_encerramento: null,
    obs_encerramento: null,
    teve_vivencia_pratica: false,
    quais_trabalhos: false,
    confirmar_voluntariado: false,
    encerrar_voluntariado: false,
  };

  const validationSchema = yup.object().shape({
    departamento_id: yup.number().nullable(),
    area_id: yup.number().required('Preenchimento obrigatório'),
    local: yup.string().required('Preenchimento obrigatório'),
  });

  const { id } = useParams();

  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState(initialValues);
  const [addStudents, setAddStudents] = useState(null);
  const [classStudents, setClassStudents] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [userFile, setUserFile] = useState(null);
  const [status, setStatus] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userVolunteerCourse, setUserVolunteerCourse] = useState(false);
  const [verifyVolunteerCourse, setVerifyVolunteerCourse] = useState({});
  const [username, setUsername] = useState('');
  const [departments, setDepartments] = useState([]);
  const [areas, setAreas] = useState([]);
  const [activities, setActivities] = useState([]);
  const [activitiesSelected, setActivitiesSelected] = useState(null);
  const [editInput, setEditInput] = useState(true);

  const [userPreferences, setUserPreferences] = useState([
    { dia_da_semana: '', horario_inicio: '', horario_fim: '' },
  ]);
  const [deletedPreferences, setDeletedPreferences] = useState([]);

  const [userVolunteerOut, setUserVolunteerOut] = useState([
    {
      nome_trabalho_voluntario_fora: '',
      local_fora: '',
      data_inicio_voluntario_fora: '',
      obs_fora: '',
    },
  ]);
  const [deletedVolunteerOut, setDeletedVolunteerOut] = useState([]);
  const [open, setOpen] = useState(false);

  const [files, setFiles] = useState([]);
  const [filesSelected, setFilesSelected] = useState([]);
  const [isEditingFile, setIsEditingFile] = useState(false);
  const [textFile, setTextFile] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { hasPermission } = useContext(PermissionContext);
  let { state } = useLocation();

  const canCreate = hasPermission('Voluntariado', 'criar');
  const canEdit = hasPermission('Voluntariado', 'editar');
  const isDisabled = id ? !canEdit : !canCreate;

  const isInputEditable = editInput === true;
  const isStatusDisabled = status === false;

  const storedUserId = localStorage.getItem('storedUserId');

  const getCourseById = useCallback(
    async (voluntario_id) => {
      console.log(selectedOption);
      console.log(users)
      try {
        ApiService.get(`/voluntarios/${id || voluntario_id}`)
          .then((response) => {
            console.log('iii', response.data);
            setUsers({ ...response.data, quais_trabalhos: false, is_codigo_voluntario_feesp_exists: response.data?.codigo_voluntario_feesp ? true : false, });
            setSelectedOption(response.data.User);
            setUserPreferences(response.data.Horario_voluntariado);
            setUserVolunteerOut(response.data.Trabalho_Voluntario_Fora_Feesp);
            setActivitiesSelected(response.data.atividade_selecionada_id);

            if (response.data.status == 'ativo') {
              setStatus(true);
            }
            //setVerifyVolunteerCourse(response.data.verifyVolunteerCourse)
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  const getDepartments = useCallback(async () => {
    try {
      ApiService.get(`/departamentos`)
        .then((response) => {
          setDepartments(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getAreas = useCallback(async () => {
    try {
      ApiService.get(`/areas`)
        .then((response) => {
          setAreas(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getAtividades = useCallback(async () => {
    try {
      ApiService.get(`/atividades-voluntarios`)
        .then((response) => {
          console.log(response.data);
          setActivities(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (id) {
      getCourseById(selectedOption, userVolunteerCourse);
      getAtividades();
    }
    getDepartments();
    getAreas();
    getAtividades();
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    //return console.log('Submit', teste)
    let volunteerId;
    console.log(users);
    let formData = new FormData();
    filesSelected.forEach((file, index) => {
      if (file instanceof File) {
        formData.append('docs', file);
        formData.append(`nome_label_${index}`, file.nome_label);
      }
    });
    if (id || users.is_codigo_voluntario_feesp_exists) {
      if (values.encerrar_voluntariado == 'on') {
        //encerrar voluntariado
        const result = await Swal.fire({
          title: 'Tem certeza que deseja encerrar voluntariado?',
          text: 'Não será possível reverter a ação!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          reverseButtons: true,
        });

        /* const allValuesEdit = {...values, horario_voluntariado: userPreferences, trabalho_voluntario_fora_feesp: userVolunteerOut } */

        if (result.value) {
          await ApiService.put(`/encerrar-voluntariado/${id}`, { values })
            .then((response) => {
              toast('success', 'Voluntario encerrado com sucesso');
            })
            .catch((error) => {
              toast('error', 'Ocorreu um erro');
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('error', error.message);
              }
            });
        }
      } else {
        //atualiza form sem encerrar o voluntariado
        const allValuesEdit = {
          ...values,
          horario_voluntariado: userPreferences,
          trabalho_voluntario_fora_feesp: userVolunteerOut,
          atividade_selecionada_id: activitiesSelected,
        };

        await ApiService.put(
          `/voluntarios/${id || users.voluntario_id}?page=voluntario`,
          { values: allValuesEdit }
        )
          .then((response) => {
            if (!userFile)
              toast('success', 'Voluntário atualizado com sucesso');
            /* setSubmitClicked(true); */

            if (userFile) {
              let data = new FormData();
              data.append('ficha', userFile);
              console.log(data);
              ApiService.put(
                `/voluntarios/atualizar-ficha/${id || users.voluntario_id}`,
                data
              )
                .then(() => {
                  toast('success', 'Voluntário atualizado com sucesso', true);
                })
                .catch((error) => {
                  console.log(error);
                  toast('error', 'Ocorreu um erro ao enviar a ficha', true);

                  if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('error', error.message);
                  }
                });
            }
          })
          .catch((error) => {
            toast('error', error.response.data.error);
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
          });
      }
    } else {
      /* if(username && (username.ficha == null  || username.ficha == undefined)) {

                if (!userFile) return toast('error', 'Adicione uma ficha.', true);
            } */

      //horario_voluntariado: userPreferences, trabalho_voluntario_fora_feesp: userVolunteerOut,
      const allValues = {
        ...values,
        pessoa_id: username.pessoa_id,
        id_quem_aceitou: storedUserId,
        atividade_selecionada_id: activitiesSelected,
      };
      console.log('submenteu');
      await ApiService.post('/voluntarios?page=voluntario', {
        values: allValues,
      })
        .then((response) => {
          console.log('criou', response.data);
          volunteerId = response.data[0].voluntario_id;
          console.log('volunteer', volunteerId);
          setUsers(response.data[0]);

          toast('success', 'Criado com sucesso');
          setSubmitClicked(true);

          if (files.length > 0) {
            formData.append('voluntario_id', volunteerId); // Adiciona o pessoa_id

            ApiService.post(`/fichas-do-voluntario`, formData)
              .then(() => {})
              .catch((error) => {
                if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
              });
          }
          /*  if (userFile) {
            console.log(data);
            ApiService.put(`/voluntarios/atualizar-ficha/${volunteerId}`, data)
              .then(() => {
               
              })
              .catch((error) => {
                console.log(error);
                toast('error', 'Ocorreu um erro ao enviar a ficha', true);

                if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
              });
          } */
          /* resetForm();
          setUsername('');
          setActivitiesSelected(null);
          setPreviewFile(null);
          setUserFile(null);
          setUsers({}); */
        })
        .catch((error) => {
          toast('error', error.response.data.error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    }

    setSubmitting(false);
  };

  const uploadHandler = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    setFiles([...files, file]);
    setFilesSelected([...filesSelected, file]);
  };

  const removeFile = (index) => {
    setFiles(files.filter((file, i) => i !== index));
  };

  const handleSaveClick = (index) => {
    setIsEditingFile(false);
    files.map((file, i) =>
      i == index ? (file.nome_label = textFile) : file.nome_label
    );
    setTextFile('');
  };

  const handleEditClick = (index) => {
    setIsEditingFile({ active: true, index: index });
    setTextFile(
      files
        .filter(
          (file, i) =>
            i === index &&
            (file.nome_label || file.nome || file instanceof File)
        )
        .map((file) => {
          if (file instanceof File && file.nome_label) {
            return file.nome_label;
          }
          return file.nome_label || file.nome || file.name;
        })
    );
  };

  const handleInputChangeFile = (e) => {
    setTextFile(e.target.value);
  };

  const uploadFile = async (voluntario_id) => {
    let formData = new FormData();
    filesSelected.forEach((file, index) => {
      if (file instanceof File) {
        formData.append('docs', file);
        formData.append(`nome_label_${index}`, file.nome_label);
      }
    });

    if (files.length > 0) {
      formData.append('voluntario_id', users.voluntario_id); // Adiciona o pessoa_id

      ApiService.post(`/fichas-do-voluntario`, formData)
        .then(() => {
          setFiles([]);
          setFilesSelected([]);
          setTextFile(null);
          getCourseById(voluntario_id);
          toast('success', 'Adicionado com sucesso');
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    }
  };

  const modalAlertDeleteFile = (id, voluntario_id) => {
    console.log(id);
    Swal.fire({
      title: 'Tem certeza que deseja apagar?',
      text: 'Não será póssível reverter a ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        removeFileFromBd(id, voluntario_id);
      }
    });
  };

  const removeFileFromBd = async (id, voluntario_id) => {
    ApiService.delete(`/fichas-do-voluntario/${id}`)
      .then(() => {
        /* setFiles([]);
        setFilesSelected([]);
        setTextFile(null); */
        getCourseById(voluntario_id)
        toast('success', 'Removido com sucesso');
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('error', error.message);
        }
      });
  };

  useEffect(() => {
    if (searchTerm == undefined || searchTerm == '') {
      setAddStudents(null);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      searchStudent(searchTerm);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const searchStudent = useCallback(async (e) => {
    setLoading(true);
    try {
      ApiService.get(`/pessoa`, { params: { search: e, turma_id: id } })
        .then((response) => {
          setAddStudents(response.data);
          // toast('success', 'Aluno adicionado com sucesso');
          setLoading(false);
        })
        .catch((error) => {
          // toast('error', 'Ocorreu um erro');

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleChangeSearch = useDebouncedCallback((event) => {
    setSearchTerm(event.target.value);
  });

  React.useEffect(() => {
    setSearchTerm(searchTerm);
  }, [searchTerm]);

  React.useEffect(() => {
    return () => handleChangeSearch.cancel();
  });

  const updateStudentList = useCallback(async (data) => {
    setClassStudents([...data]);
    setAddStudents(null);
  }, []);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const [submitClicked, setSubmitClicked] = useState(false);

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={'Voluntários'}
          subtitle={id ? 'Editar' : 'Cadastro de Voluntariado'}
          titleUrl={'/app/listar-voluntariado'}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}

      <Paper sx={{ p: '40px' }}>
        {state && (
          <Button
            color='success'
            type='submit'
            variant='contained'
            size='large'
            sx={{ maxWidth: { md: '200px' }, mb: '2rem' }}
            onClick={() => navigate(state.backUrl)}
          >
            Voltar
          </Button>
        )}
        <Formik
          initialValues={users}
          //validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange }) => {
            const selectedArea = areas.find(
              (area) => area.area_id === values.area_id
            );

            /* if(username && (username.ficha !== null  || username.ficha !== undefined)) {
                        values.ficha = username.ficha
                    } */
            return (
              <Form style={{ width: '100%' }} noValidate autoComplete='off'>
                <Grid container spacing={3} alignContent={'center'}>
                  {id ? null : (
                    <>
                      <Grid item xs={12}>
                        <JumboSearchClasses
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          placeholder='Busque por Nome, CPF ou E-mail.'
                          sx={{
                            width: '440px',
                            marginBottom: '1rem',
                          }}
                          onFocus={handleInputFocus}
                          onBlur={handleInputBlur}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {loading && (
                          <Box display='flex' justifyContent='center'>
                            <CircularProgress />
                          </Box>
                        )}
                        {addStudents != null && addStudents.length > 0 && (
                          <ListRowVolunteerInterested
                            data={addStudents}
                            updateStudentList={(data) =>
                              updateStudentList(data)
                            }
                            classStudents={classStudents}
                            status={status}
                            setStatus={setStatus}
                            setUsername={setUsername}
                            setSearchTerm={setSearchTerm}
                            setUsers={setUsers}
                            setPreviewFile={setPreviewFile}
                            setSubmitClicked={setSubmitClicked}
                          />
                        )}
                        {addStudents != null && addStudents.length == 0 && (
                          <Typography>
                            Sem resultados correspondentes
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <JumboTextField
                      fullWidth
                      name={id ? 'voluntario_id' : 'pessoa_id'}
                      label='Nome'
                      value={
                        id
                          ? selectedOption && selectedOption.nome
                          : username && username.nome
                      }
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputMask
                      mask='99/9999'
                      value={updateInputValue(
                        users,
                        values,
                        'voluntario_desde'
                      )}
                      onChange={handleChange}
                      disabled={isDisabled || status == false}
                      disableUnderline={isDisabled || status == false}
                      placeholder='mm/aaaa'
                    >
                      {(inputProps) => (
                        <JumboTextField
                          {...inputProps}
                          id='voluntario_desde'
                          fullWidth
                          name='voluntario_desde'
                          label='Voluntário desde'
                          type='text'
                          sx={{ background: '#ffffff' }}
                          disabled={inputProps.disableUnderline}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={4}>
                    <JumboTextField
                      fullWidth
                      name='codigo_antigo_voluntariado'
                      label='Código do Voluntário (Sistema Antigo)'
                      value={updateInputValue(
                        users,
                        values,
                        'codigo_antigo_voluntariado'
                      )}
                      disabled={isDisabled || status == false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <JumboTextField
                      fullWidth
                      name='codigo_voluntario_feesp'
                      label='Código do Voluntário (Sistema Novo)'
                      value={updateInputValue(
                        users,
                        values,
                        'codigo_voluntario_feesp'
                      )}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display={'flex'}
                      flexWrap={'wrap'}
                      gap={3}
                      flexDirection={'column'}
                    >
                      <Typography>
                        Declaração(ões)
                      </Typography>
                      {users?.Ficha_Do_Voluntario?.map((item) => (
                        <FileListItem className='file-item' key={item.nome}>
                          <p>
                            <a
                              href={`${process.env.REACT_APP_API_KEY}/images/${item.nome}`}
                              target='_blank'
                              rel='noreferrer'
                            >
                              {item?.nome_label ||
                                item?.nome.replace(/^\d+_/, '')}
                            </a>
                          </p>

                          <Button
                            variant='contained'
                            size='small'
                            color='secondary'
                            onClick={() =>
                              modalAlertDeleteFile(
                                item.ficha_do_voluntario_id,
                                item.voluntario_id
                              )
                            }
                          >
                            <DeleteIcon />
                          </Button>
                        </FileListItem>
                      ))}
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      variant='contained'
                      component='label'
                      color='info'
                      disabled={isDisabled || status == false}
                      sx={{
                        height: '53.13px',
                        fontSize: {
                          xs: '0.7rem',
                        },
                        textAlign: 'center',
                      }}
                    >
                      Adicionar Declarações/Comprovantes
                      <input
                        type='file'
                        hidden
                        name='imagem_area'
                        onChange={uploadHandler}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <FileListSlim
                      files={files}
                      removeFile={removeFile}
                      isEditingFile={isEditingFile}
                      textFile={textFile}
                      handleInputChangeFile={handleInputChangeFile}
                      handleSaveClick={handleSaveClick}
                      handleEditClick={handleEditClick}
                      setIsEditingFile={setIsEditingFile}
                    />

                    {users.is_codigo_voluntario_feesp_exists && files.length > 0 && (
                      <Grid item xs={12}>
                        <Button
                          variant='contained'
                          onClick={() => uploadFile(users.voluntario_id)}
                        >
                          Salvar Declaração(ões)/Comprovante(s)
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      name={updateInputValue(
                        users,
                        values,
                        'teve_vivencia_pratica'
                      )}
                      control={
                        <Checkbox
                          name='teve_vivencia_pratica'
                          checked={updateInputValue(
                            users,
                            values,
                            'teve_vivencia_pratica'
                          )}
                          disabled={isDisabled || status == false}
                        />
                      }
                      label='Realizou curso de Capacitação de Voluntários?'
                      labelPlacement='end'
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: 'center', mt: 3 }}>
                    <LoadingButton
                      color='success'
                      type='submit'
                      variant='contained'
                      size='large'
                      sx={{ maxWidth: { md: '200px', mt: '1rem' } }}
                      loading={isSubmitting}
                      disabled={
                        isDisabled ||
                        isInputFocused ||
                        (isInputEditable ? isStatusDisabled : false) ||
                        submitClicked
                      }
                    >
                      {id ? 'Atualizar' : 'Cadastrar'}
                    </LoadingButton>
                  </Grid>

                  {/* <>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel shrink id='area_id'>
                          Área
                        </InputLabel>
                        <JumboSelectField
                          labelId='area_id'
                          id='area_id'
                          name='area_id'
                          value={updateInputValue(users, values, 'area_id')}
                          label='Área'
                          disabled={isDisabled || status == false}
                          notched
                        >
                          <MenuItem value={''}>Sem seleção</MenuItem>
                          {areas.length > 0 &&
                            areas.map((x) => (
                              <MenuItem value={x.area_id}>{x.nome}</MenuItem>
                            ))}
                        </JumboSelectField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel shrink id='departamento_id'>
                          Departamento
                        </InputLabel>
                        <JumboSelectField
                          labelId='departamento_id'
                          id='departamento_id'
                          name='departamento_id'
                          value={updateInputValue(
                            users,
                            values,
                            'departamento_id'
                          )}
                          label='Departamento'
                          disabled={isDisabled || status == false}
                          sx={{ background: '#ffffff' }}
                          notched
                        >
                          <MenuItem value={''}>Sem seleção</MenuItem>
                          {(selectedArea?.departamento || []).map((x) => (
                            <MenuItem value={x.departamento_id}>
                              {x.nome}
                            </MenuItem>
                          ))}
                        </JumboSelectField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel shrink id='local'>
                          Local
                        </InputLabel>
                        <JumboSelectField
                          labelId='local'
                          id='local'
                          name='local'
                          value={updateInputValue(users, values, 'local')}
                          label='Local'
                          disabled={isDisabled || status == false}
                          notched
                        >
                          <MenuItem value={'Casa Transitória'}>
                            Casa Transitória
                          </MenuItem>
                          <MenuItem value={'Sede'}>Sede</MenuItem>
                          <MenuItem value={'Outro'}>Outro</MenuItem>
                        </JumboSelectField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <JumboTextField
                        fullWidth
                        name='data_inicio'
                        type='date'
                        label='Data de início'
                        sx={{ background: '#ffffff' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={isDisabled || status == false}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <div style={{ position: 'relative' }}>
                        <Select
                          id='select-two'
                          styles={customStyles}
                          options={optionsVolunteerActivities}
                          components={animatedComponents}
                          onChange={(newValue) =>
                            setActivitiesSelected(newValue.value)
                          }
                          value={optionsVolunteerActivities.filter(
                            (option) => option.value === activitiesSelected
                          )}
                          placeholder='Selecione Atividade'
                        />

                        <Typography
                          style={{
                            position: 'absolute',
                            top: '-9px',
                            left: '10px',
                            background: '#ffffff',
                            padding: '0 5px',
                            color: '#8595A6',
                            fontSize: '0.7rem',
                            fontFamily: 'NoirPro,Arial',
                          }}
                        >
                          Selecione atividade
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      {id ? (
                        <>
                          <Grid item xs={12}>
                            <Accordion
                              expanded={
                                users.status === 'ativo'
                                  ? updateInputValue(
                                      users,
                                      values,
                                      'encerrar_voluntariado'
                                    ) == 'on'
                                    ? true
                                    : false
                                  : true
                              }
                              elevation={0}
                            >
                              <AccordionSummary
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                              >
                                <Stack
                                  sx={{
                                    width: '100%',
                                    textAlign: { xs: 'center', lg: 'left' },
                                  }}
                                  direction={{ xs: 'column', lg: 'row' }}
                                  justifyContent='space-between'
                                  alignItems='center'
                                  spacing={2}
                                >
                                  <Typography sx={{ fontSize: '1.05rem' }}>
                                    {users.status === 'ativo'
                                      ? 'Encerrar voluntariado?'
                                      : 'Voluntariado Encerrado'}
                                  </Typography>
                                  {users.status === 'inativo' ? (
                                    <Tooltip
                                      title={'Editar data final e/ou motivo'}
                                      arrow
                                      TransitionComponent={Zoom}
                                    >
                                      <EditIcon
                                        onClick={() => setEditInput(!editInput)}
                                      />
                                    </Tooltip>
                                  ) : undefined}

                                  {users.status === 'ativo' ? (
                                    <Stack
                                      direction='row'
                                      alignItems='center'
                                      spacing={1}
                                    >
                                      <Typography>Não</Typography>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={
                                              updateInputValue(
                                                users,
                                                values,
                                                'encerrar_voluntariado'
                                              ) == 'on'
                                                ? true
                                                : false
                                            }
                                            onChange={handleChange}
                                            name='encerrar_voluntariado'
                                            disabled={isDisabled}
                                          />
                                        }
                                        labelPlacement='start'
                                      />
                                      <Typography>Sim</Typography>
                                    </Stack>
                                  ) : undefined}
                                </Stack>
                              </AccordionSummary>
                              <AccordionDetails
                                sx={{ display: 'flex', gap: '1.5rem' }}
                              >
                                <Grid
                                  container
                                  spacing={3}
                                  alignContent={'center'}
                                >
                                  <Grid item xs={6}>
                                    <JumboTextField
                                      fullWidth
                                      name='data_fim'
                                      type='date'
                                      label='Data Final'
                                      value={updateInputValue(
                                        users,
                                        values,
                                        'data_fim'
                                      )}
                                      sx={{ background: '#ffffff' }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={
                                        isDisabled ||
                                        (isInputEditable
                                          ? isStatusDisabled
                                          : false)
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={6}>
                                    <JumboTextField
                                      fullWidth
                                      name='motivo_encerramento'
                                      label='Motivo'
                                      value={updateInputValue(
                                        users,
                                        values,
                                        'motivo_encerramento'
                                      )}
                                      disabled={
                                        isDisabled ||
                                        (isInputEditable
                                          ? isStatusDisabled
                                          : false)
                                      }
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                            {values.encerrar_voluntariado == 'on' ? null : (
                              <Divider variant='middle' />
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Accordion
                              expanded={
                                updateInputValue(
                                  users,
                                  values,
                                  'infos_usuario'
                                ) == 'on'
                                  ? true
                                  : false
                              }
                              elevation={0}
                            >
                              <AccordionSummary>
                                <Stack
                                  sx={{
                                    width: '100%',
                                    textAlign: { xs: 'center', lg: 'left' },
                                  }}
                                  direction={{ xs: 'column', lg: 'row' }}
                                  justifyContent='space-between'
                                  alignItems='center'
                                  spacing={2}
                                >
                                  <Typography sx={{ fontSize: '1.05rem' }}>
                                    Informações sobre criação e atualização do
                                    usuário
                                  </Typography>

                                  <Stack
                                    direction='row'
                                    spacing={1}
                                    alignItems='center'
                                  >
                                    <Typography>Ocultar</Typography>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={
                                            updateInputValue(
                                              users,
                                              values,
                                              'infos_usuario'
                                            ) == 'on'
                                              ? true
                                              : false
                                          }
                                          onChange={handleChange}
                                          name='infos_usuario'
                                          disabled={isDisabled}
                                        />
                                      }
                                      labelPlacement='start'
                                    />
                                    <Typography>Visualizar</Typography>
                                  </Stack>
                                </Stack>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid item xs={12}>
                                  <InfosAboutCreateAndUpdate
                                    data={users}
                                    quem_criou={
                                      users?.quem_aceitou?.nome ?? '-'
                                    }
                                    quem_atualizou={
                                      users?.quem_atualizou?.nome ?? '-'
                                    }
                                  />
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        </>
                      ) : null}

                      {values.encerrar_voluntariado == 'on' ? ( // verifica se o switch é true
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                          <LoadingButton
                            color='error'
                            type='submit'
                            variant='contained'
                            size='large'
                            loading={isSubmitting}
                            disabled={
                              isDisabled ||
                              isInputFocused ||
                              status == false ||
                              values.data_fim == '' ||
                              values.data_fim == null ||
                              values.motivo_encerramento == null ||
                              values.motivo_encerramento == ''
                            }
                          >
                            Encerrar voluntariado
                          </LoadingButton>
                        </Grid>
                      ) : (
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 3 }}>
                          <LoadingButton
                            color='success'
                            type='submit'
                            variant='contained'
                            size='large'
                            sx={{ maxWidth: { md: '200px', mt: '1rem' } }}
                            loading={isSubmitting}
                            disabled={
                              isDisabled ||
                              isInputFocused ||
                              values.data_inicio == '' ||
                              values.data_inicio == null ||
                              (isInputEditable ? isStatusDisabled : false)
                            }
                          >
                            {id ? 'Atualizar' : 'Cadastrar'}
                          </LoadingButton>
                        </Grid>
                      )}
                    </Grid>
                  </> */}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Paper>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Div sx={style}>
            <ProfileCard
              username={selectedOption?.nome}
              photo={selectedOption?.imagem_perfil}
              number={users?.codigo_voluntario_feesp}
              volunteerSince={users?.voluntario_desde}
              handleClose={handleClose}
            />
          </Div>
        </Fade>
      </Modal>
    </JumboContentLayout>
  );
};

export default VolunteerFormResume;
