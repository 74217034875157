import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  Switch,
  Tooltip,
  Typography,
  Zoom,
  useMediaQuery,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { updateInputValue } from '../../../utils/appHelpers';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
/* import ClassStudentsList from "./ClassStudentsList"; */
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';
import UploadIcon from '@mui/icons-material/Upload';
import ListRowVolunteerInterested from 'app/components/ListRowVolunteerInterested/ListRowVolunteerInterested';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import InfosAboutCreateAndUpdate from 'app/components/InfosAboutCreateAndUpdate/InfosAboutCreateAndUpdate';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import ProfileCard from 'app/components/ProfileCard/ProfileCard';
import JumboSearchClasses from '@jumbo/components/JumboSearchClasses';
import { useDebouncedCallback } from 'beautiful-react-hooks';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const VolunteerAlocationFormResume = () => {
  const { theme } = useJumboTheme();
  const animatedComponents = makeAnimated();
  const { showDialog, hideDialog } = useJumboDialog();

  const navigate = useNavigate();
  const moment = require('moment-timezone');

  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: 'Fechar',
    });

    //  Redireciona se mensagem de retorno for de sucesso

    /* if(id) {
            if(variant == 'success' && (!userFile || (userFile && type))){
                setTimeout(function() {
                    navigate("/app/listar-voluntariado")
                }, 2000)
            }
        } */
  };

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const initialValues = {
    pessoa_id: '',
    voluntario_id: '',
    codigo_antigo_voluntariado: '',
    status: '',
    data_inicio: '',
    data_fim: '',
    departamento_id: '',
    area_id: '',
    local: '',
    ficha: '',
    atividade: '',
    observacao: '',
    voluntario_desde: '',
    motivo_encerramento: null,
    obs_encerramento: null,
    teve_vivencia_pratica: false,
    quais_trabalhos: false,
    confirmar_voluntariado: false,
    encerrar_voluntariado: false,
  };

  const validationSchema = yup.object().shape({
    departamento_id: yup.number().nullable(),
    area_id: yup.number().required('Preenchimento obrigatório'),
    local: yup.string().required('Preenchimento obrigatório'),
  });

  const { id } = useParams();

  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState(initialValues);
  const [addStudents, setAddStudents] = useState(null);
  const [classStudents, setClassStudents] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [userFile, setUserFile] = useState(null);
  const [status, setStatus] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userVolunteerCourse, setUserVolunteerCourse] = useState(false);
  const [verifyVolunteerCourse, setVerifyVolunteerCourse] = useState({});
  const [username, setUsername] = useState('');
  const [departments, setDepartments] = useState([]);
  const [areas, setAreas] = useState([]);
  const [activities, setActivities] = useState([]);
  const [activitiesSelected, setActivitiesSelected] = useState(null);
  const [editInput, setEditInput] = useState(true);

  const [userPreferences, setUserPreferences] = useState([
    { dia_da_semana: '', horario_inicio: '', horario_fim: '' },
  ]);
  const [deletedPreferences, setDeletedPreferences] = useState([]);

  const [userVolunteerOut, setUserVolunteerOut] = useState([
    {
      nome_trabalho_voluntario_fora: '',
      local_fora: '',
      data_inicio_voluntario_fora: '',
      obs_fora: '',
    },
  ]);
  const [deletedVolunteerOut, setDeletedVolunteerOut] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { hasPermission } = useContext(PermissionContext);
  let { state } = useLocation();

  const canCreate = hasPermission('Voluntariado', 'criar');
  const canEdit = hasPermission('Voluntariado', 'editar');
  const isDisabled = id ? !canEdit : !canCreate;

  const isInputEditable = editInput === true;
  const isStatusDisabled = status === false;

  const storedUserId = localStorage.getItem('storedUserId');

  const getCourseById = useCallback(
    async (selectedOption, userVolunteerCourse) => {
      try {
        ApiService.get(`/voluntarios/${id}`)
          .then((response) => {
            console.log('iii', response.data);
            setUsers({ ...response.data, quais_trabalhos: false });
            setSelectedOption(response.data.User);
            setUserPreferences(response.data.Horario_voluntariado);
            setUserVolunteerOut(response.data.Trabalho_Voluntario_Fora_Feesp);
            setActivitiesSelected(response.data.atividade_selecionada_id);

            if (response.data.status == 'ativo') {
              setStatus(true);
            }
            //setVerifyVolunteerCourse(response.data.verifyVolunteerCourse)
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  const getDepartments = useCallback(async () => {
    try {
      ApiService.get(`/departamentos`)
        .then((response) => {
          setDepartments(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getAreas = useCallback(async () => {
    try {
      ApiService.get(`/areas`)
        .then((response) => {
          setAreas(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getAtividades = useCallback(async () => {
    try {
      ApiService.get(`/atividades-voluntarios`)
        .then((response) => {
          console.log(response.data);
          setActivities(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (id) {
      getCourseById(selectedOption, userVolunteerCourse);
      getAtividades();
    }
    getDepartments();
    getAreas();
    getAtividades();
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    //return console.log('Submit', teste)
    let volunteerId;

    let data = new FormData();
    data.append('ficha', userFile);

    if (id) {
      if (values.encerrar_voluntariado == 'on') {
        //encerrar voluntariado
        const result = await Swal.fire({
          title: 'Tem certeza que deseja encerrar voluntariado?',
          text: 'Não será possível reverter a ação!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          reverseButtons: true,
        });

        /* const allValuesEdit = {...values, horario_voluntariado: userPreferences, trabalho_voluntario_fora_feesp: userVolunteerOut } */

        if (result.value) {
          await ApiService.put(`/encerrar-voluntariado/${id}`, { values })
            .then((response) => {
              toast('success', 'Voluntario encerrado com sucesso');
            })
            .catch((error) => {
              toast('error', 'Ocorreu um erro');
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('error', error.message);
              }
            });
        }
      } else {
        //atualiza form sem encerrar o voluntariado
        const allValuesEdit = {
          ...values,
          horario_voluntariado: userPreferences,
          trabalho_voluntario_fora_feesp: userVolunteerOut,
          atividade_selecionada_id: activitiesSelected,
        };

        await ApiService.put(`/voluntarios/${id}`, { values: allValuesEdit })
          .then((response) => {
            if (!userFile)
              toast('success', 'Voluntario atualizado com sucesso');
            setSubmitClicked(true);
            if (userFile) {
              let data = new FormData();
              data.append('ficha', userFile);
              console.log(data);
              ApiService.put(`/voluntarios/atualizar-ficha/${id}`, data)
                .then(() => {
                  toast('success', 'Voluntario atualizado com sucesso', true);
                })
                .catch((error) => {
                  console.log(error);
                  toast('error', 'Ocorreu um erro ao enviar a ficha', true);

                  if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('error', error.message);
                  }
                });
            }
          })
          .catch((error) => {
            toast('error', error.response.data.error);
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
          });
      }
    } 

    setSubmitting(false);
  };

  const handleChangeFile = (e) => {
    setPreviewFile(URL.createObjectURL(e.target.files[0]));
    setUserFile(e.target.files[0]);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...userPreferences];
    list[index][name] = value;
    setUserPreferences(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...userPreferences];

    const preferences = list[index];
    setDeletedPreferences([...deletedPreferences, preferences]);

    list.splice(index, 1);
    setUserPreferences(list);
  };

  const handleAddClick = () => {
    setUserPreferences([
      ...userPreferences,
      { dia_da_semana: '', horario_inicio: '', horario_fim: '' },
    ]);
  };

  const handleInputChangeVolunteerOut = (e, index) => {
    const { name, value } = e.target;
    const list = [...userVolunteerOut];
    list[index][name] = value;
    setUserVolunteerOut(list);
  };

  const handleRemoveClickVolunteerOut = (index) => {
    const list = [...userVolunteerOut];

    const volunteerOut = list[index];
    setDeletedVolunteerOut([...deletedVolunteerOut, volunteerOut]);

    list.splice(index, 1);
    setUserVolunteerOut(list);
  };
  const handleAddClickVolunteerOut = () => {
    setUserVolunteerOut([
      ...userVolunteerOut,
      {
        nome_trabalho_voluntario_fora: '',
        local_fora: '',
        data_inicio_voluntario_fora: '',
        obs: '',
      },
    ]);
  };

  useEffect(() => {
    if (searchTerm == undefined || searchTerm == '') {
      setAddStudents(null);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      searchStudent(searchTerm);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const searchStudent = useCallback(async (e) => {
    setLoading(true);
    try {
      ApiService.get(`/pessoa`, { params: { search: e, turma_id: id } })
        .then((response) => {
          setAddStudents(response.data);
          // toast('success', 'Aluno adicionado com sucesso');
          setLoading(false);
        })
        .catch((error) => {
          // toast('error', 'Ocorreu um erro');

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleChangeSearch = useDebouncedCallback((event) => {
    setSearchTerm(event.target.value);
  });

  React.useEffect(() => {
    setSearchTerm(searchTerm);
  }, [searchTerm]);

  React.useEffect(() => {
    return () => handleChangeSearch.cancel();
  });

  const updateStudentList = useCallback(async (data) => {
    setClassStudents([...data]);
    setAddStudents(null);
  }, []);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  console.log(activitiesSelected);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'transparent' : 'white',
      border: state.isFocused
        ? '1px solid #7352C7'
        : '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      padding: '8px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'black',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#7352C7' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: 'lightblue',
        color: 'white',
      },
    }),
  };

  const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
  });

  const handleCreate = (inputValue) => {
    const newOption = createOption(inputValue);

    setActivities((prev) => [...prev, newOption]);
    setActivitiesSelected(newOption);
  };

  const optionsVolunteerActivities = activities?.map((result) => {
    return {
      value: result.atividade_voluntario_id,
      label: result.nome,
    };
  });

  const [submitClicked, setSubmitClicked] = useState(false);

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={'Alocação de Voluntários'}
          subtitle={id ? 'Editar' : 'Cadastro de Voluntariado'}
          titleUrl={'/app/listar-voluntariado'}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}

      <Paper sx={{ p: '40px' }}>
        {state && (
          <Button
            color='success'
            type='submit'
            variant='contained'
            size='large'
            sx={{ maxWidth: { md: '200px' }, mb: '2rem' }}
            onClick={() => navigate(state.backUrl)}
          >
            Voltar
          </Button>
        )}
        <Formik
          initialValues={users}
          //validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange }) => {
            const selectedArea = areas.find(
              (area) => area.area_id === values.area_id
            );

            /* if(username && (username.ficha !== null  || username.ficha !== undefined)) {
                        values.ficha = username.ficha
                    } */
            return (
              <Form style={{ width: '100%' }} noValidate autoComplete='off'>
                <Grid container spacing={3} alignContent={'center'}>
                  {id ? null : (
                    <>
                      <Grid item xs={12}>
                        <JumboSearchClasses
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          placeholder='Busque por Nome, CPF ou E-mail.'
                          sx={{
                            width: '440px',
                            marginBottom: '1rem',
                          }}
                          onFocus={handleInputFocus}
                          onBlur={handleInputBlur}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {loading && (
                          <Box display='flex' justifyContent='center'>
                            <CircularProgress />
                          </Box>
                        )}
                        {addStudents != null && addStudents.length > 0 && (
                          <ListRowVolunteerInterested
                            data={addStudents}
                            updateStudentList={(data) =>
                              updateStudentList(data)
                            }
                            classStudents={classStudents}
                            status={status}
                            setStatus={setStatus}
                            setUsername={setUsername}
                            setSearchTerm={setSearchTerm}
                            setUsers={setUsers}
                          />
                        )}
                        {addStudents != null && addStudents.length == 0 && (
                          <Typography>
                            Sem resultados correspondentes
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <JumboTextField
                      fullWidth
                      name={id ? 'voluntario_id' : 'pessoa_id'}
                      label='Nome'
                      value={
                        id
                          ? selectedOption && selectedOption.nome
                          : username && username.nome
                      }
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputMask
                      mask='99/9999'
                      value={updateInputValue(
                        users,
                        values,
                        'voluntario_desde'
                      )}
                      onChange={handleChange}
                      disabled={true}
                      disableUnderline={true}
                      placeholder='mm/aaaa'
                    >
                      {(inputProps) => (
                        <JumboTextField
                          {...inputProps}
                          id='voluntario_desde'
                          fullWidth
                          name='voluntario_desde'
                          label='Voluntário desde'
                          type='text'
                          sx={{ background: '#ffffff' }}
                          disabled={true}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={4}>
                    <JumboTextField
                      fullWidth
                      name='codigo_antigo_voluntariado'
                      label='Código do Voluntário (Sistema Antigo)'
                      value={updateInputValue(
                        users,
                        values,
                        'codigo_antigo_voluntariado'
                      )}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <JumboTextField
                      fullWidth
                      name='codigo_voluntario_feesp'
                      label='Código do Voluntário (Sistema Novo)'
                      value={updateInputValue(
                        users,
                        values,
                        'codigo_voluntario_feesp'
                      )}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item lg={12} xs={2}>
                    <Div
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1rem',
                      }}
                    >
                      {previewFile && (
                        <Avatar
                          sx={{ width: 46, height: 46 }}
                          src={previewFile}
                        />
                      )}
                      {values.ficha && !previewFile && (
                        <>
                          <Avatar
                            sx={{ width: 46, height: 46 }}
                            src={`${process.env.REACT_APP_API_KEY}/images/${users.ficha}`}
                          />
                          <Button
                            variant='contained'
                            href={`${process.env.REACT_APP_API_KEY}/images/${values.ficha}`}
                            target='_blank'
                          >
                            Ver ficha atual
                          </Button>
                        </>
                      )}
                      {/* <Tooltip
                        title='Adicionar nova ficha com a assinatura do voluntário'
                        TransitionComponent={Zoom}
                        arrow
                      >
                        <Button
                          variant='contained'
                          component='label'
                          color='info'
                          disabled={isDisabled || status == false}
                          endIcon={<UploadIcon />}
                        >
                          Nova ficha
                          <input
                            type='file'
                            hidden
                            onChange={handleChangeFile}
                          />
                        </Button>
                      </Tooltip> */}

                      {id ? (
                        <Tooltip
                          title=' Gerar crachá'
                          TransitionComponent={Zoom}
                          arrow
                        >
                          <Button
                            variant='contained'
                            component='label'
                            color='secondary'
                            disabled={isDisabled || status == false}
                            endIcon={<OpenInBrowserIcon />}
                            onClick={handleOpen}
                          >
                            Gerar crachá
                          </Button>
                        </Tooltip>
                      ) : null}
                    </Div>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      name={updateInputValue(
                        users,
                        values,
                        'teve_vivencia_pratica'
                      )}
                      control={
                        <Checkbox
                          name='teve_vivencia_pratica'
                          checked={updateInputValue(
                            users,
                            values,
                            'teve_vivencia_pratica'
                          )}
                          disabled={isDisabled || status == false}
                        />
                      }
                      label='Realizou curso de Capacitação de Voluntários?'
                      labelPlacement='end'
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>
                  <>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel shrink id='area_id'>
                          Área
                        </InputLabel>
                        <JumboSelectField
                          labelId='area_id'
                          id='area_id'
                          name='area_id'
                          value={updateInputValue(users, values, 'area_id')}
                          label='Área'
                          disabled={isDisabled || status == false}
                          notched
                        >
                          <MenuItem value={''}>Sem seleção</MenuItem>
                          {areas.length > 0 &&
                            areas.map((x) => (
                              <MenuItem value={x.area_id}>{x.nome}</MenuItem>
                            ))}
                        </JumboSelectField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel shrink id='departamento_id'>
                          Departamento
                        </InputLabel>
                        <JumboSelectField
                          labelId='departamento_id'
                          id='departamento_id'
                          name='departamento_id'
                          value={updateInputValue(
                            users,
                            values,
                            'departamento_id'
                          )}
                          label='Departamento'
                          disabled={isDisabled || status == false}
                          sx={{ background: '#ffffff' }}
                          notched
                        >
                          <MenuItem value={''}>Sem seleção</MenuItem>
                          {(selectedArea?.departamento || []).map((x) => (
                            <MenuItem value={x.departamento_id}>
                              {x.nome}
                            </MenuItem>
                          ))}
                        </JumboSelectField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel shrink id='local'>
                          Local
                        </InputLabel>
                        <JumboSelectField
                          labelId='local'
                          id='local'
                          name='local'
                          value={updateInputValue(users, values, 'local')}
                          label='Local'
                          disabled={isDisabled || status == false}
                          notched
                        >
                          <MenuItem value={'Casa Transitória'}>
                            Casa Transitória
                          </MenuItem>
                          <MenuItem value={'Sede'}>Sede</MenuItem>
                          <MenuItem value={'Outro'}>Outro</MenuItem>
                        </JumboSelectField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <JumboTextField
                        fullWidth
                        name='data_inicio'
                        type='date'
                        label='Data de início'
                        sx={{ background: '#ffffff' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={isDisabled || status == false}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <div style={{ position: 'relative' }}>
                        <Select
                          id='select-two'
                          styles={customStyles}
                          options={optionsVolunteerActivities}
                          components={animatedComponents}
                          onChange={(newValue) =>
                            setActivitiesSelected(newValue.value)
                          }
                          value={optionsVolunteerActivities.filter(
                            (option) => option.value === activitiesSelected
                          )}
                          placeholder='Selecione Atividade'
                          isDisabled={isDisabled || status == false}
                        />

                        <Typography
                          style={{
                            position: 'absolute',
                            top: '-9px',
                            left: '10px',
                            background: '#ffffff',
                            padding: '0 5px',
                            color: '#8595A6',
                            fontSize: '0.7rem',
                            fontFamily: 'NoirPro,Arial',
                          }}
                        >
                          Selecione atividade
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      {id && users?.data_inicio ? (
                        <>
                          <Grid item xs={12}>
                            <Accordion
                              expanded={
                                users.status === 'ativo'
                                  ? updateInputValue(
                                      users,
                                      values,
                                      'encerrar_voluntariado'
                                    ) == 'on'
                                    ? true
                                    : false
                                  : true
                              }
                              elevation={0}
                            >
                              <AccordionSummary
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                              >
                                <Stack
                                  sx={{
                                    width: '100%',
                                    textAlign: { xs: 'center', lg: 'left' },
                                  }}
                                  direction={{ xs: 'column', lg: 'row' }}
                                  justifyContent='space-between'
                                  alignItems='center'
                                  spacing={2}
                                >
                                  <Typography sx={{ fontSize: '1.05rem' }}>
                                    {users.status === 'ativo'
                                      ? 'Encerrar voluntariado?'
                                      : 'Voluntariado Encerrado'}
                                  </Typography>
                                  {users.status === 'inativo' ? (
                                    <Tooltip
                                      title={'Editar data final e/ou motivo'}
                                      arrow
                                      TransitionComponent={Zoom}
                                    >
                                      <EditIcon
                                        onClick={() => setEditInput(!editInput)}
                                      />
                                    </Tooltip>
                                  ) : undefined}

                                  {users.status === 'ativo' ? (
                                    <Stack
                                      direction='row'
                                      alignItems='center'
                                      spacing={1}
                                    >
                                      <Typography>Não</Typography>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={
                                              updateInputValue(
                                                users,
                                                values,
                                                'encerrar_voluntariado'
                                              ) == 'on'
                                                ? true
                                                : false
                                            }
                                            onChange={handleChange}
                                            name='encerrar_voluntariado'
                                            disabled={isDisabled}
                                          />
                                        }
                                        labelPlacement='start'
                                      />
                                      <Typography>Sim</Typography>
                                    </Stack>
                                  ) : undefined}
                                </Stack>
                              </AccordionSummary>
                              <AccordionDetails
                                sx={{ display: 'flex', gap: '1.5rem' }}
                              >
                                <Grid
                                  container
                                  spacing={3}
                                  alignContent={'center'}
                                >
                                  <Grid item xs={6}>
                                    <JumboTextField
                                      fullWidth
                                      name='data_fim'
                                      type='date'
                                      label='Data Final'
                                      value={updateInputValue(
                                        users,
                                        values,
                                        'data_fim'
                                      )}
                                      sx={{ background: '#ffffff' }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      disabled={
                                        isDisabled ||
                                        (isInputEditable
                                          ? isStatusDisabled
                                          : false)
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={6}>
                                    <JumboTextField
                                      fullWidth
                                      name='motivo_encerramento'
                                      label='Motivo'
                                      value={updateInputValue(
                                        users,
                                        values,
                                        'motivo_encerramento'
                                      )}
                                      disabled={
                                        isDisabled ||
                                        (isInputEditable
                                          ? isStatusDisabled
                                          : false)
                                      }
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                            {values.encerrar_voluntariado == 'on' ? null : (
                              <Divider variant='middle' />
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Accordion
                              expanded={
                                updateInputValue(
                                  users,
                                  values,
                                  'infos_usuario'
                                ) == 'on'
                                  ? true
                                  : false
                              }
                              elevation={0}
                            >
                              <AccordionSummary>
                                <Stack
                                  sx={{
                                    width: '100%',
                                    textAlign: { xs: 'center', lg: 'left' },
                                  }}
                                  direction={{ xs: 'column', lg: 'row' }}
                                  justifyContent='space-between'
                                  alignItems='center'
                                  spacing={2}
                                >
                                  <Typography sx={{ fontSize: '1.05rem' }}>
                                    Informações sobre criação e atualização do
                                    usuário
                                  </Typography>

                                  <Stack
                                    direction='row'
                                    spacing={1}
                                    alignItems='center'
                                  >
                                    <Typography>Ocultar</Typography>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={
                                            updateInputValue(
                                              users,
                                              values,
                                              'infos_usuario'
                                            ) == 'on'
                                              ? true
                                              : false
                                          }
                                          onChange={handleChange}
                                          name='infos_usuario'
                                          disabled={isDisabled}
                                        />
                                      }
                                      labelPlacement='start'
                                    />
                                    <Typography>Visualizar</Typography>
                                  </Stack>
                                </Stack>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid item xs={12}>
                                  <InfosAboutCreateAndUpdate
                                    data={users}
                                    quem_criou={
                                      users?.quem_aceitou?.nome ?? '-'
                                    }
                                    quem_atualizou={
                                      users?.quem_atualizou?.nome ?? '-'
                                    }
                                  />
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        </>
                      ) : null}

                      {values.encerrar_voluntariado == 'on' ? ( // verifica se o switch é true
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                          <LoadingButton
                            color='error'
                            type='submit'
                            variant='contained'
                            size='large'
                            loading={isSubmitting}
                            disabled={
                              isDisabled ||
                              isInputFocused ||
                              status == false ||
                              values.data_fim == '' ||
                              values.data_fim == null ||
                              values.motivo_encerramento == null ||
                              values.motivo_encerramento == ''
                            }
                          >
                            Encerrar voluntariado
                          </LoadingButton>
                        </Grid>
                      ) : (
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 3 }}>
                          <LoadingButton
                            color='success'
                            type='submit'
                            variant='contained'
                            size='large'
                            sx={{ maxWidth: { md: '200px', mt: '1rem' } }}
                            loading={isSubmitting}
                            disabled={
                              isDisabled ||
                              isInputFocused ||
                              values.data_inicio == '' ||
                              values.data_inicio == null ||
                              (isInputEditable ? isStatusDisabled : false)
                            }
                          >
                            {id ? 'Atualizar' : 'Cadastrar'}
                          </LoadingButton>
                        </Grid>
                      )}
                    </Grid>
                  </>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Paper>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Div sx={style}>
            <ProfileCard
              username={selectedOption?.nome}
              photo={selectedOption?.imagem_perfil}
              number={users?.codigo_voluntario_feesp}
              volunteerSince={users?.voluntario_desde}
              handleClose={handleClose}
            />
          </Div>
        </Fade>
      </Modal>
    </JumboContentLayout>
  );
};

export default VolunteerAlocationFormResume;
