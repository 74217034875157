import React, { useCallback, useState, useContext } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import { Accordion, Card, Tooltip, Typography, Zoom } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import Span from '@jumbo/shared/Span';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import capitalize from '../../../utils/capitalize';
import formatDate from '../../../utils/formatDate';
import { DAYS_OF_WEEK } from '../../../utils/constants/daysOfWeek';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { LinkStyle } from '../users/UserItem';
import { useLocation } from 'react-router-dom';

const Item = styled(Span)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

const SpiritualCenterItem = ({ item, setDepartments, data }) => {
  const { hasPermission } = useContext(PermissionContext);
  const location = useLocation();

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });
  };

  const modalAlert = (id) => {
    Swal.fire({
      title: 'Tem certeza que deseja apagar?',
      html: 'Não será póssível reverter a ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteDepartment(id);
      }
    });
  };

  function removeById(arr, id) {
    const objWithIdIndex = arr.findIndex(
      (obj) => obj.centro_espirita_id === id
    );

    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }

    return arr;
  }

  const deleteDepartment = useCallback(async (id) => {
    try {
      ApiService.delete(`/centro-espirita/${id}`)
        .then((response) => {
          toast('success', 'Removido com sucesso');
          const updatedDepartmentsArray = removeById(data, id);
          setDepartments(updatedDepartmentsArray);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
      toast('error', 'Ocorreu um erro');
    }
  }, []);

  return (
    <Card sx={{ mb: 1 }}>
      <Accordion square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{
            px: 3,
            flexDirection: 'row-reverse',

            '& .MuiAccordionSummary-content': {
              alignItems: 'center',

              '&.Mui-expanded': {
                margin: '12px 0',
              },
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              borderRadius: 1,
              border: 1,
              color: 'text.secondary',
              borderColor: 'divider',
              transform: 'none',
              height: 28,
              width: 28,
              alignItems: 'center',
              justifyContent: 'center',
              mr: 1,

              '&.Mui-expanded': {
                transform: 'none',
                color: 'primary.main',
                borderColor: 'primary.main',
              },

              '& svg': {
                fontSize: '1.25rem',
              },
            },
          }}
        >
          <Div
            sx={{
              display: { xs: 'none', lg: 'block' },
              width: '40%',
              flexShrink: 0,
              px: 1,
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              Nome
            </Typography>
            <Typography variant={'body1'}>{item.nome}</Typography>
          </Div>
          <Div
            sx={{
              display: { xs: 'none', lg: 'block' },
              width: '40%',
              flexShrink: 0,
              px: 1,
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              Nome Fantasia
            </Typography>
            <Typography variant={'body1'}>{item?.nome_fantasia.length === 0 ? '-' : item.nome_fantasia}</Typography>
          </Div>
          <Div
            sx={{
              ml: 'auto',
              flexDirection: 'row',
              display: 'flex',
            }}
          >
            <Tooltip title='Visualizar' TransitionComponent={Zoom} arrow>
              <Item sx={{ ml: 'auto', display: { sm: 'block' } }}>
                <LinkStyle
                  to={`/app/editar-centro-espirita/${item.centro_espirita_id}`}
                  state={{ backUrl: location.pathname }}
                >
                  <VisibilityIcon />
                </LinkStyle>
              </Item>
            </Tooltip>

            <Tooltip title='Excluir' TransitionComponent={Zoom} arrow>
              <Item sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Button
                  sx={{ minWidth: 1 }}
                  disableElevation
                  variant={'contained'}
                  size={'small'}
                  color={'secondary'}
                  disabled={!hasPermission('Centros Espíritas', 'deletar')}
                  onClick={() => modalAlert(item.centro_espirita_id)}
                >
                  <DeleteIcon />
                </Button>
              </Item>
            </Tooltip>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  );
};
/* Todo item prop define */
export default SpiritualCenterItem;
