/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { updateInputValue } from '../../../utils/appHelpers';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';
import styled from '@emotion/styled';
import Span from '@jumbo/shared/Span';
import { useDebouncedCallback } from 'beautiful-react-hooks';
import JumboSearchClasses from '@jumbo/components/JumboSearchClasses';
import ListRowBookTeam from 'app/components/ListRowBookTeam/ListRowBookTeam';
import VolunteerTeamList from './VolunteerTeamList';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

export const Item = styled(Span)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

export const CSVStyled = styled(Item)`
  padding: 4px 10px;
  background: #0092d0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 0.5rem;
  cursor: pointer;

  svg {
    color: white;
  }
`;

export const generateYearOptions = () => {
  const arr = [];

  const startYear = 1980;
  const endYear = new Date().getFullYear();

  for (let i = endYear + 2; i >= startYear; i--) {
    arr.push(
      <MenuItem key={i} value={i.toString()}>
        {i}
      </MenuItem>
    );
  }

  return arr;
};

const BookTeamForm = () => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const generatePdf = (data) => {
    const getStatus = (voluntario) => {
      if (voluntario.data_fim) return 'Inativo';
      return 'Ativo';
    };

    const atividades = {
      ativo: [],
      inativo: [],
    };

    data.Equipe.forEach((equipe) => {
      equipe.Voluntario.Atividade_Do_Voluntario.forEach((atividade) => {
        const status = getStatus(atividade);
        const statusKey = status.toLowerCase();
        const atividadeNome = atividade?.Atividades_Voluntario?.nome;

        if (!atividades[statusKey][atividadeNome]) {
          atividades[statusKey][atividadeNome] = [];
        }

        atividades[statusKey][atividadeNome].push(equipe.Voluntario.User.nome);
      });
    });

    const totalAtivos = Object.keys(atividades.ativo).reduce(
      (total, atividadeNome) => total + atividades.ativo[atividadeNome].length,
      0
    );

    const totalAfastados = Object.keys(atividades.inativo).reduce(
      (total, atividadeNome) =>
        total + atividades.inativo[atividadeNome].length,
      0
    );

    const totalGeral = totalAtivos + totalAfastados;

    const docDefinition = {
      content: [
        {
          text: `ÁREA DE ${data.Areas.nome.toUpperCase()}`,
          bold: true,
          alignment: 'center',
          fontSize: 16,
        },
        {
          text: `COLABORADORES POR ASSISTÊNCIA`,
          alignment: 'center',
          fontSize: 14,
          margin: [0, 0, 0, 10],
        },
        {
          text: `LIVRO: ${data.codigo_do_livro}`,
          alignment: 'center',
          fontSize: 12,
          margin: [0, 0, 0, 10],
        },
        {
          text: `DIA: ${data.dia_da_semana} - INÍCIO: ${data.horario_inicio} - TÉRMINO: ${data.horario_termino} - SALA: ${data.sala}`,
          alignment: 'center',
          fontSize: 12,
        },
        {
          text: 'ATIVO',
          alignment: 'center',
          bold: true,
          fontSize: 14,
          margin: [0, 10, 0, 0],
        },
        ...Object.keys(atividades.ativo).map((atividadeNome) => [
          { text: `${atividadeNome}`, bold: true },
          { ul: atividades.ativo[atividadeNome], margin: [20, 0, 0, 0] },
        ]),
        {
          text: `TOTAL DE ATIVOS: ${totalAtivos}`,
          alignment: 'left',
          bold: true,
          margin: [0, 5, 0, 10],
        },
        {
          text: 'AFASTADO',
          alignment: 'center',
          bold: true,
          fontSize: 14,
          margin: [0, 10, 0, 5],
        },
        ...Object.keys(atividades.inativo).map((atividadeNome) => [
          { text: `${atividadeNome}`, bold: true },
          { ul: atividades.inativo[atividadeNome], margin: [20, 0, 0, 0] },
        ]),
        {
          text: `TOTAL DE AFASTADOS: ${totalAfastados}`,
          alignment: 'left',
          bold: true,
          margin: [0, 5, 0, 10],
        },
        {
          text: `TOTAL GERAL (ATIVOS + AFASTADOS): ${totalGeral}`,
          alignment: 'left',
          bold: true,
          margin: [0, 10, 0, 0],
        },
      ],
    };

    pdfMake.createPdf(docDefinition).open();
  };
  const { theme } = useJumboTheme();
  const { showDialog, hideDialog } = useJumboDialog();

  const navigate = useNavigate();
  let { state, pathname } = useLocation();

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });
  };

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const initialValues = {
    area_id: '',
    departamento_id: '',
    codigo_do_livro: '',
    dia_da_semana: '',
    horario_inicio: '',
    horario_termino: '',
    sala_id: '',
  };

  const { id } = useParams();
  const [areas, setAreas] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [books, setBooks] = useState(initialValues);
  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(false);

  const [addVolunteers, setAddVolunteers] = useState(null);
  const [volunteersTeam, setVolunteersTeam] = useState([]);

  const [isInputFocused, setIsInputFocused] = useState(false);

  const { hasPermission } = useContext(PermissionContext);

  const canCreate = hasPermission('Voluntariado', 'criar');
  const canEdit = hasPermission('Voluntariado', 'editar');
  const isDisabled = id ? !canEdit : !canCreate;

  useEffect(() => {
    if (searchTerm == undefined || searchTerm == '') {
      setAddVolunteers(null);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      searchVolunteer(searchTerm);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const searchVolunteer = useCallback(
    async (search) => {
      try {
        ApiService.get(`/voluntarios`, {
          params: {
            search: search,
            area_id: books?.area_id,
            departamento_id: books?.departamento_id,
            livro_id: id,
            searchVolunteerInArea: true,
          },
        })
          .then((response) => {
            console.log(response.data);
            setAddVolunteers(response.data);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    [books]
  );

  const getAreas = useCallback(async () => {
    try {
      ApiService.get(`/areas`)
        .then((response) => {
          setAreas(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getBookTeamsById = useCallback(async () => {
    try {
      ApiService.get(`/livros/${id}`)
        .then((response) => {
          console.log(response.data);

          setBooks(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (id) getBookTeamsById();
    getAreas();
  }, []);

  useEffect(() => {
    Swal.close();
  }, [pathname]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (id) {
      console.log('enviou', values);
      await ApiService.put(`/livros/${id}`, { values })
        .then((response) => {
          toast('success', 'Turma atualizada com sucesso');
        })
        .catch((error) => {
          toast('error', error.response.data.error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } else {
      await ApiService.post('/livros', { values })
        .then((response) => {
          toast('success', 'Criado com sucesso');
          resetForm();
        })
        .catch((error) => {
          toast('error', error.response.data.error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    }

    setSubmitting(false);
  };

  const handleChangeSearch = useDebouncedCallback((event) => {
    setSearchTerm(event.target.value);
  });

  React.useEffect(() => {
    setSearchTerm(searchTerm);
  }, [searchTerm]);

  React.useEffect(() => {
    return () => handleChangeSearch.cancel();
  });

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const updateVolunteerList = useCallback(async (data) => {
    setVolunteersTeam([...data]);
    setAddVolunteers(null);
    getBookTeamsById();
  }, []);

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={'Equipe'}
          subtitle={'Inclusão de Voluntário na Equipe'}
          titleUrl={'/app/listar-livros-inserir-voluntario'}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '40px' }}>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          mb={3}
        >
          {state && (
            <Button
              color='success'
              type='submit'
              variant='contained'
              size='large'
              sx={{ maxWidth: { md: '200px' } }}
              onClick={() => navigate(state.backUrl)}
            >
              Voltar
            </Button>
          )}
          <Button
            variant='contained'
            onClick={() => {
              generatePdf(books);
            }}
          >
            Gerar PDF
          </Button>
        </Box>
        <Formik
          initialValues={books}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange, setFieldValue }) => {
            const selectedArea = areas.find(
              (area) => area.area_id === values.area_id
            );
            return (
              <Form style={{ width: '100%' }} noValidate autoComplete='off'>
                <Grid container spacing={3} alignContent={'center'}>
                  <Grid item xs={3}>
                    <JumboTextField
                      fullWidth
                      name='codigo_do_livro'
                      label='Código do Livro'
                      value={updateInputValue(books, values, 'codigo_do_livro')}
                      sx={{ background: '#ffffff' }}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='area_id'>
                        Área
                      </InputLabel>
                      <JumboSelectField
                        labelId='area_id'
                        id='area_id'
                        name='area_id'
                        value={updateInputValue(books, values, 'area_id')}
                        label='Área'
                        disabled={true}
                        notched
                      >
                        <MenuItem value={''}>Sem seleção</MenuItem>
                        {areas.length > 0 &&
                          areas.map((x) => (
                            <MenuItem value={x.area_id}>{x.nome}</MenuItem>
                          ))}
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='departamento_id'>
                        Departamento
                      </InputLabel>
                      <JumboSelectField
                        labelId='departamento_id'
                        id='departamento_id'
                        name='departamento_id'
                        value={updateInputValue(
                          books,
                          values,
                          'departamento_id'
                        )}
                        label='Departamento'
                        disabled={true}
                        sx={{ background: '#ffffff' }}
                        notched
                      >
                        <MenuItem value={''}>Sem seleção</MenuItem>
                        {(selectedArea?.departamento || []).map((x) => (
                          <MenuItem value={x.departamento_id}>
                            {x.nome}
                          </MenuItem>
                        ))}
                      </JumboSelectField>
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='sala_id'>
                        Sala
                      </InputLabel>
                      <JumboSelectField
                        labelId='sala_id'
                        id='sala_id'
                        name='sala_id'
                        value={books.sala_id}
                        label='Sala'
                        disabled={true}
                        notched
                      >
                        <MenuItem value={''}>Sem seleção</MenuItem>
                        <MenuItem value={books.sala_id}>{books?.Sala?.nome}</MenuItem>
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='local'>
                        Local
                      </InputLabel>
                      <JumboSelectField
                        labelId='local'
                        id='local'
                        name='local'
                        value={books?.Sala?.local || ''}
                        label='Local'
                        disabled={true}
                        sx={{ background: '#ffffff' }}
                        notched
                      >
                        <MenuItem value={''}>Sem seleção</MenuItem>

                        <MenuItem value={books?.Sala?.local}>
                          {books?.Sala?.local}
                        </MenuItem>
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='andar'>
                        Andar
                      </InputLabel>
                      <JumboSelectField
                        labelId='andar'
                        id='andar'
                        name='andar'
                        value={books?.Sala?.andar || ''}
                        label='andar'
                        disabled={true}
                        sx={{ background: '#ffffff' }}
                        notched
                      >
                        <MenuItem value={''}>Sem seleção</MenuItem>

                        <MenuItem value={books?.Sala?.andar}>
                          {books?.Sala?.andar}
                        </MenuItem>
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='dia_da_semana'>
                        Dia da semana
                      </InputLabel>
                      <JumboSelectField
                        labelId='dia_da_semana'
                        id='dia_da_semana'
                        name='dia_da_semana'
                        value={updateInputValue(books, values, 'dia_da_semana')}
                        label='Dia da semana'
                        disabled={true}
                        notched
                      >
                        <MenuItem value={'Domingo'}>Domingo</MenuItem>
                        <MenuItem value={'Segunda'}>Segunda</MenuItem>
                        <MenuItem value={'Terça'}>Terça</MenuItem>
                        <MenuItem value={'Quarta'}>Quarta</MenuItem>
                        <MenuItem value={'Quinta'}>Quinta</MenuItem>
                        <MenuItem value={'Sexta'}>Sexta</MenuItem>
                        <MenuItem value={'Sábado'}>Sábado</MenuItem>
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputMask
                        mask='99:99'
                        value={updateInputValue(
                          books,
                          values,
                          'horario_inicio'
                        )}
                        onChange={handleChange}
                        disabled={true}
                      >
                        {() => (
                          <JumboTextField
                            fullWidth
                            name='horario_inicio'
                            label='Início'
                            disabled={true}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      </InputMask>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputMask
                        mask='99:99'
                        value={updateInputValue(
                          books,
                          values,
                          'horario_termino'
                        )}
                        onChange={handleChange}
                        disabled={true}
                      >
                        {() => (
                          <JumboTextField
                            fullWidth
                            name='horario_termino'
                            label='Término'
                            disabled={true}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      </InputMask>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant={'h4'} mb={1}>
                      Adicionar voluntário
                    </Typography>

                    <Grid item xs={1} sx={{ textAlign: 'center' }}>
                      <JumboSearchClasses
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder='Busque por Nome, CPF, Celular ou E-mail.'
                        sx={{
                          width: '440px',
                          marginBottom: '1rem',
                        }}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        disabled={isDisabled}
                      />
                    </Grid>

                    {addVolunteers != null && addVolunteers.length > 0 && (
                      <ListRowBookTeam
                        data={addVolunteers}
                        updateVolunteerList={(data) =>
                          updateVolunteerList(data)
                        }
                        volunteersTeam={volunteersTeam}
                        setSearchTerm={setSearchTerm}
                      />
                    )}
                    {addVolunteers != null && addVolunteers.length == 0 && (
                      <Typography>Sem resultados correspondentes</Typography>
                    )}

                    {addVolunteers != null &&
                      addVolunteers.length == 1 &&
                      addVolunteers[0]?.naTurma && (
                        <Typography>Aluno já está turma</Typography>
                      )}

                    <Typography
                      variant={'h4'}
                      mb={1}
                      sx={{ marginTop: '2rem' }}
                    >
                      Lista de voluntários na equipe
                    </Typography>

                    <VolunteerTeamList
                      volunteersTeam={volunteersTeam}
                      setVolunteersTeam={setVolunteersTeam}
                      handleInputFocus={handleInputFocus}
                      handleInputBlur={handleInputBlur}
                      getBookTeamsById={() => getBookTeamsById()}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default BookTeamForm;
