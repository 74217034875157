import React, { useState } from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import {Accordion, Card, Typography, Tooltip, Button, Modal, Backdrop, Fade} from "@mui/material";
import Div from "@jumbo/shared/Div";
import formatDate from "../../../utils/formatDate";
import { TextService } from 'app/servicesTwo/TextService';
import Zoom from '@mui/material/Zoom';
import ModalLogs from 'app/components/Logs/Modals/ModalLogs';

const LogItem = ({ item, getActionLabel, distinctsModel }) => {
    const maxLengthText = 20;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getDadosGravados = (atividade) => {
        const modelos = distinctsModel.map(opcao => opcao.label);
        if (atividade.acao === "findMany" && modelos.includes(atividade.modelo)) {
            return 'Lista de exibição';
        }
        //return TextService.limitText(atividade.dados_gravados, maxLengthText);
        return atividade.dados_gravados
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80em',
        p: 4,
    };

    return (
        <Card sx={{mb: 1}}>
            <Accordion square sx={{borderRadius: 2}}>
                <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        px: 3,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',

                            '&.Mui-expanded': {
                               margin: '12px 0',
                            }
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            }
                        }
                    }}
                >
                    <Div sx={{display: { lg: 'block'}, width: '20%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Usuário
                        </Typography>
                        <Typography variant={"body1"}>{item.User?.nome ?? '-'}</Typography>
                    </Div>
                    <Div sx={{display: { lg: 'block'}, width: '20%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Ação
                        </Typography>
                        <Tooltip title={item.acao} arrow TransitionComponent={Zoom}>
                            <Typography variant={"body1"}>{getActionLabel(item.acao) ?? '-'}</Typography>

                        </Tooltip>
                    </Div>
                    <Div sx={{display: { lg: 'block'}, width: '20%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Modelo
                        </Typography>
                        <Typography variant={"body1"}>{item.modelo ?? '-'}</Typography>
                    </Div>
                    <Div sx={{display: { lg: 'block'}, width: '20%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                            mt={.75}
                        >
                            Dados enviados/Resposta
                        </Typography>
                        {/* {!item.dados_antigo || item.dados_antigo.length < maxLengthText
                            ? 
                            (
                                <Typography variant={"body1"}>
                                    {item.dados_antigo ?? '-'}
                                </Typography>
                            )
                            :
                            (
                                <Tooltip title={item.dados_antigo} arrow TransitionComponent={Zoom}>
                                    <Typography variant={"body1"}>
                                        {TextService.limitText(item.dados_antigo, maxLengthText)}
                                    </Typography>
                                </Tooltip>
                            )

                        } */}
                        <Button
                            color="info"
                            variant="contained"
                            size="small"
                            sx={{maxWidth: {md: '400px', mt: '1rem'}}}
                            onClick={handleOpen}
                        >
                            Ver mais
                        </Button>
                    </Div>
                    {/* <Div sx={{display: { lg: 'block'}, width: '16.6%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Resposta recebida
                        </Typography>
                        {!item.dados_gravados || item.dados_gravados.length < maxLengthText
                            ? 
                            (
                                <Typography variant={"body1"}>
                                    {item.dados_gravados ?? '-'}
                                </Typography>
                            )
                            :
                            (
                                <Tooltip title={item.dados_gravados} arrow TransitionComponent={Zoom}>
                                    <Typography variant={"body1"}>
                                       { getDadosGravados(item)}
                                    </Typography>
                                </Tooltip>
                            )

                        }
                    </Div> */}
                    <Div sx={{display: { lg: 'block'}, width: '20%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Data de criação
                        </Typography>
                        <Typography variant={"body1"}>{formatDate(item.createdAt, true)}</Typography>
                    </Div>
                </AccordionSummary>
            </Accordion>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Div sx={style}>
                        <ModalLogs item={item} getDadosGravados={getDadosGravados}/>
                    </Div>
                </Fade>
            </Modal>
        </Card>
    );
};
/* Todo item prop define */
export default LogItem;
