import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  useContext,
} from 'react';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  TableCell,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { PermissionContext } from 'app/contexts/PermissionContext';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { generateYearOptions } from '../classes/ClassForm';
import MUIDataTable from 'mui-datatables';
import { useLocation } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

const BatchAuthorizeLaunch = ({ username, userId, handleClose }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
  };
  let { pathname } = useLocation();
  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
    });
  };

  const [courses, setCourses] = useState([]);
  const [spiritualCenter, setSpiritualCenter] = useState([]);
  const [courseHistoryOnlyApproval, setCourseHistoryOnlyApproval] = useState(
    []
  );
  const [coursesContinued, setCoursesContinued] = useState([]);
  const [forceCreate, setForceCreate] = useState(false);

  const [userCourses, setUserCourses] = useState({
    curso_id: '',
    local: '',
    ano_de_conclusao: '',
    centro_espirita_id: '',
  });
  const [deleteCourses, setDeleteCourse] = useState([]);
  const [confirmExclusion, setConfirmExclusion] = useState(false);
  const [open, setOpen] = useState(false);

  const { hasPermission } = useContext(PermissionContext);

  const handleCloseHistory = () => setOpen(false);

  const modalAlert = (info) => {
    Swal.fire({
      title: 'Tem certeza que deseja incluir?',
      text: 'Aluno ja realizou este curso anteriormente',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        forceCreateCourse(true);
      }
    });
  };

  const modalAlertDelete = (id) => {
    Swal.fire({
      title: 'Tem certeza que deseja apagar?',
      text: 'Não será póssível reverter a ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteHistory(id);
      }
    });
  };

  const deleteHistory = useCallback(async (id) => {
    try {
      ApiService.delete(`/historico-cursos/${id}`)
        .then((response) => {
          toast('success', 'Removido com sucesso');
          getCoursesRegular();
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
      toast('error', 'Ocorreu um erro');
    }
  }, []);

  const forceCreateCourse = useCallback(
    async (info) => {
      const pessoa_que_autorizou_id = localStorage.getItem('storedUserId');
      try {
        ApiService.post(
          `/lancamento-curso-lote/aluno/${userId}/autorizou/${pessoa_que_autorizou_id}`,
          {
            userCourses,
            pessoa_que_autorizou_id,
            forceCreate: info,
          }
        )
          .then((response) => {
            toast('success', 'Adicionado com sucesso');
            setUserCourses({
              curso_id: '',
              local: '',
              ano_de_conclusao: '',
              centro_espirita_id: '',
            });
            getCoursesRegular();
          })
          .catch((error) => {
            toast('error', error?.response?.data?.error);
            console.log(error?.response?.data?.error);
          });
      } catch (err) {
        console.log(err);
        toast('error', 'Ocorreu um erro');
      }
    },
    [forceCreate, toast, userCourses, userId]
  );

  const optionsAllCourses = courses
    .filter((course) => course.tipo_curso !== 'antigo')
    .map((course) => {
      // Verifica se curso_id e nome estão presentes antes de atribuir
      const value =
        course.curso_id !== undefined ? course.curso_id : course.value;
      const label = course.nome !== undefined ? course.nome : course.label;

      return { value, label };
    });

  const generateYearOptionsForAutoComplete = () => {
    const startYear = 1980;
    const endYear = new Date().getFullYear();
    const years = [];

    for (let i = endYear + 2; i >= startYear; i--) {
      years.push(i.toString());
    }

    return years;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const pessoa_que_autorizou_id = localStorage.getItem('storedUserId');
    console.log(userCourses);

    try {
      ApiService.post(
        `/lancamento-curso-lote/aluno/${userId}/autorizou/${pessoa_que_autorizou_id}`,
        {
          userCourses,
          pessoa_que_autorizou_id,
          forceCreate,
        }
      )
        .then((response) => {
          toast('success', 'Adicionado com sucesso');
          setUserCourses({
            curso_id: '',
            local: '',
            ano_de_conclusao: '',
            centro_espirita_id: '',
          });
          getCoursesRegular();
        })
        .catch((error) => {
          const info = error?.response?.data?.info;
          if (info) {
            modalAlert();
          } else {
            toast('error', error?.response?.data?.error);
            console.log(error?.response?.data?.error);
          }
        });
    } catch (err) {
      console.log(err);
      toast('error', 'Ocorreu um erro');
    }

    setSubmitting(false);
  };

  const getCourse = useCallback(async () => {
    try {
      ApiService.get(`/cursos`)
        .then((response) => {
          setCourses(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getCoursesRegular = useCallback(async () => {
    try {
      ApiService.get(`/cursos-regulares-continuados/${userId}`)
        .then((response) => {
          console.log(
            'Response',
            response.data?.historicoCursosRegulares?.historicoCursos
          );
          setCourseHistoryOnlyApproval(
            response.data?.historicoCursosRegulares?.historicoCursos
          );
          setCoursesContinued(
            response.data?.historicoCursosContinuados?.historicoCursos
          );
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getSpiritualCenter = useCallback(async () => {
    try {
      ApiService.get(`/centro-espirita`)
        .then((response) => {
          setSpiritualCenter(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getCourse();
    getCoursesRegular();
    getSpiritualCenter();
  }, []);

  useEffect(() => {
    Swal.close();
  }, [pathname]);

  const renderContinuingEducationCoursesItems = () => {
    const coursesContinuingFiltered = coursesContinued.filter(
      (item) => !item.curso_compativel_id
    );

    const columnsContinuing = [
      {
        name: 'Turma.Curso.nome',
        label: 'Nome',
        options: {
          filter: false,
          sort: false,
          setCellHeaderProps: (value) => ({
            align: 'left',
            width: '40%',
          }),
          setCellProps: () => ({
            align: 'left',
            width: '40%',
          }),
          customHeadRender: (
            columnMeta,
            handleToggleColumn,
            sortOrder,
            sortColumn
          ) => {
            return (
              <TableCell width={'40%'} align='left'>
                Nome
              </TableCell>
            );
          },
        },
      },

      {
        name: 'Turma.ano',
        label: 'Conclusão',
        options: {
          filter: false,
          sort: false,
          setCellHeaderProps: (value) => ({
            align: 'center',
          }),
          setCellProps: () => ({
            align: 'center',
          }),
          customBodyRender: (value, tableMeta, updateValue) => (
            <>
              <Typography variant={'body1'}>{value ?? '-'}</Typography>
            </>
          ),
        },
      },

      /* {
        name: 'Turma.local',
        label: 'Local (FEESP ou Outro centro)',
        options: {
          filter: false,
          sort: false,
          setCellHeaderProps: (value) => ({
            align: 'center',
          }),
          setCellProps: () => ({
            align: 'center',
          }),
          customBodyRender: (value, tableMeta, updateValue) => (
            <>
              <Typography variant={'body1'}>{value ?? '-'}</Typography>
            </>
          ),
        },
      }, */
      {
        name: 'Turma',
        label: 'Local',
        options: {
          filter: false,
          sort: false,
          setCellHeaderProps: (value) => ({
            align: 'center',
            width: '25%',
          }),
          setCellProps: () => ({
            align: 'center',
            width: '25%',
          }),
          customBodyRender: (value, tableMeta, updateValue) => (
            <>
              <Typography variant={'body1'}>
                {value?.Centro_Espirita?.nome_fantasia || value?.local}
              </Typography>
            </>
          ),
        },
      },
      {
        name: 'curso_historico_id',
        label: 'Ação',
        options: {
          filter: false,
          sort: false,
          setCellHeaderProps: (value) => ({
            align: 'center',
          }),
          setCellProps: () => ({
            align: 'center',
          }),
          customBodyRender: (value, tableMeta, updateValue) => (
            <>
              <Button
                disableElevation
                variant={'contained'}
                size={'small'}
                color={'secondary'}
                disabled={!hasPermission('Pessoas', 'deletar') ? true : false}
                onClick={() => modalAlertDelete(value)}
              >
                <Tooltip title='Excluir' TransitionComponent={Zoom} arrow>
                  <DeleteIcon />
                </Tooltip>
              </Button>
            </>
          ),
        },
      },
    ];

    const optionsContinuing = {
      filterType: 'checkbox',
      selectableRows: 'none',
      search: false,
      download: false,
      print: false,
      sortFilterList: false,
      viewColumns: false,
      filter: false,
      enableNestedDataAccess: '.',
      textLabels: {
        pagination: {
          next: 'Próxima Página',
          previous: 'Página Anterior',
          rowsPerPage: 'Itens por Página:',
          displayRows: 'de',
        },
      },
      setTableProps: () => {
        return {
          size: 'small',
        };
      },
    };

    const componentsContinuing = {
      ExpandButton: function (props) {
        return <div style={{ width: '24px' }} />;
      },
    };

    if (coursesContinued?.length > 0) {
      return (
        <MUIDataTable
          data={coursesContinuingFiltered}
          columns={columnsContinuing}
          options={optionsContinuing}
          components={componentsContinuing}
        />
      );
    } else {
      return 'Nenhum curso encontrado';
    }
  };

  console.log(userCourses);

  const columnsRegular = [
    {
      name: 'Turma',
      label: 'Nome',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({
          align: 'left',
          width: '40%',
        }),
        setCellProps: () => ({
          align: 'left',
          width: '40%',
        }),
        customHeadRender: (
          columnMeta,
          handleToggleColumn,
          sortOrder,
          sortColumn
        ) => {
          return (
            <TableCell width={'40%'} align='left'>
              Nome
            </TableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          const index = tableMeta.rowIndex;
          const item = courseHistoryOnlyApproval[index].Turma
            ? courseHistoryOnlyApproval[index].Turma?.Curso.nome
            : courseHistoryOnlyApproval[index].curso_compativel_nome;
          return <Typography variant={'body1'}>{item}</Typography>;
        },
      },
    },

    {
      name: 'Turma.ano',
      label: 'Conclusão',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({
          align: 'center',
        }),
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Typography variant={'body1'}>{value ?? '-'}</Typography>
          </>
        ),
      },
    },

    /* {
      name: 'Turma',
      label: 'Local (FEESP ou Outro centro)',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({
          align: 'center',
        }),
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Typography variant={'body1'}>{value?.local ?? '-'}</Typography>
          </>
        ),
      },
    }, */
    {
      name: 'Turma',
      label: 'Local',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({
          align: 'center',
          width: '25%',
        }),
        setCellProps: () => ({
          align: 'center',
          width: '25%',
        }),
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Typography variant={'body1'}>
              {value?.Centro_Espirita?.nome_fantasia || value?.local}
            </Typography>
          </>
        ),
      },
    },
    {
      name: 'curso_historico_id',
      label: 'Ação',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({
          align: 'center',
        }),
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {courseHistoryOnlyApproval[tableMeta?.rowIndex]?.Turma
              ?.tipo_turma === 'antigo' && (
              <Button
                disableElevation
                variant={'contained'}
                size={'small'}
                color={'secondary'}
                disabled={!hasPermission('Pessoas', 'deletar') ? true : false}
                onClick={() => modalAlertDelete(value)}
              >
                <Tooltip title='Excluir' TransitionComponent={Zoom} arrow>
                  <DeleteIcon />
                </Tooltip>
              </Button>
            )}
          </>
        ),
      },
    },
  ];

  const optionsRegular = {
    filterType: 'checkbox',
    selectableRows: 'none',
    search: false,
    download: false,
    print: false,
    sortFilterList: false,
    viewColumns: false,
    filter: false,
    enableNestedDataAccess: '.',
    textLabels: {
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Itens por Página:',
        displayRows: 'de',
      },
    },
    setTableProps: () => {
      return {
        size: 'small',
      };
    },
  };

  const componentsRegular = {
    ExpandButton: function (props) {
      return <div style={{ width: '24px' }} />;
    },
  };

  /* Cursos Regulares */

  return (
    <Paper sx={{ p: '40px', height: '500px' }}>
      <Box sx={{ height: '100%', overflowY: 'auto' }}>
        <Box
          display='flex'
          justifyContent='flex-end'
          alignItems='center'
          marginRight='1rem'
        >
          <Button size='small' variant='contained' onClick={handleClose}>
            Fechar
          </Button>
        </Box>
        <Typography
          variant={'h1'}
          style={{ textAlign: 'center', marginBottom: 30 }}
        >
          Lançamento de Curso em Lote
        </Typography>
        <Fragment>
          <Formik initialValues={{}} enableReinitialize onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form style={{ width: '100%' }} noValidate autoComplete='off'>
                <Grid container spacing={3} alignContent={'center'}>
                  <Grid item xs={12}>
                    <Box display={'flex'} gap={1} alignItems={'center'}>
                      <Typography variant='h4' fontWeight={'300'}>
                        Aluno selecionado:
                      </Typography>

                      <Typography
                        variant='h4'
                        textTransform={'capitalize'}
                        fontWeight={'500'}
                      >
                        {username}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    sx={{
                      marginBottom: {
                        xs: '0.5rem',
                      },
                    }}
                  >
                    <Autocomplete
                      value={userCourses.curso_id}
                      disablePortal
                      id='combo-box-demo'
                      options={optionsAllCourses}
                      autoHighlight={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Cursos'
                          name='curso_id'
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                      onChange={(e, newValue) =>
                        setUserCourses({
                          ...userCourses,
                          curso_id: newValue,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={2}>
                    {/* <FormControl fullWidth>
                      <InputLabel shrink id='ano_de_conclusao'>
                        Ano de Conclusão
                      </InputLabel>
                      <JumboSelectField
                        labelId='ano_de_conclusao'
                        id='ano_de_conclusao'
                        name='ano_de_conclusao'
                        label='Ano de Conclusão'
                        value={userCourses.ano_de_conclusao || ''}
                        onChange={(e) =>
                          setUserCourses({
                            ...userCourses,
                            ano_de_conclusao: e.target.value,
                          })
                        }
                        sx={{
                          background: '#ffffff',
                        }}
                        notched
                      >
                        {generateYearOptions()}
                      </JumboSelectField>
                    </FormControl> */}
                    <Autocomplete
                      fullWidth
                      id='ano_de_conclusao'
                      options={generateYearOptionsForAutoComplete()}
                      value={userCourses.ano_de_conclusao || ''}
                      onChange={(event, newValue) =>
                        setUserCourses({
                          ...userCourses,
                          ano_de_conclusao: newValue,
                        })
                      }
                      autoHighlight={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Ano de Conclusão'
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          sx={{ background: '#ffffff' }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='local'>
                        Local
                      </InputLabel>
                      <JumboSelectField
                        labelId='local'
                        id='local'
                        name='local'
                        label='Local'
                        value={userCourses.local}
                        onChange={(e) =>
                          setUserCourses({
                            ...userCourses,
                            local: e.target.value,
                          })
                        }
                        sx={{
                          background: '#ffffff',
                        }}
                        notched
                      >
                        <MenuItem value={'Casa Transitória'}>
                          Casa Transitória
                        </MenuItem>
                        <MenuItem value={'Externo'}>Externo</MenuItem>
                        <MenuItem value={'Outros'}>Outros</MenuItem>
                        <MenuItem value={'Sede'}>Sede</MenuItem>
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  {userCourses.local === 'Externo' && (
                    <Grid item xs={2}>
                      <FormControl fullWidth>
                        <InputLabel shrink id='Entidade de Origem'>
                          Entidade de Origem
                        </InputLabel>
                        <JumboSelectField
                          labelId='Entidade de Origem'
                          id='Entidade de Origem'
                          name='centro_espirita_id'
                          label='Entidade de Origem'
                          value={userCourses.centro_espirita_id}
                          onChange={(e) =>
                            setUserCourses({
                              ...userCourses,
                              centro_espirita_id: e.target.value,
                            })
                          }
                          sx={{
                            background: '#ffffff',
                          }}
                          notched
                        >
                          {spiritualCenter.map((item) => (
                            <MenuItem
                              key={item.centro_espirita_id}
                              value={item.centro_espirita_id}
                            >
                              {item.nome_fantasia}
                            </MenuItem>
                          ))}
                        </JumboSelectField>
                      </FormControl>
                    </Grid>
                  )}
                  {userCourses.local !== 'Externo'
                    ? (userCourses.centro_espirita_id = null)
                    : undefined}

                  <Grid item xs={3}>
                    <LoadingButton
                      color='success'
                      type='submit'
                      variant='contained'
                      size='large'
                      loading={isSubmitting}
                      disabled={
                        !hasPermission('Turmas', 'editar') ||
                        !userCourses.curso_id
                      }
                    >
                      Adicionar Curso
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display='flex'
                      flexDirection='column'
                      gap={1}
                      marginTop='2rem'
                      marginBottom='1rem'
                    >
                      <Typography variant={'h4'} textAlign={'center'}>
                        Cursos Realizados (Somente Aprovados)
                      </Typography>
                      <Typography variant={'h4'} margin={0}>
                        Cursos Regulares
                      </Typography>
                      {courseHistoryOnlyApproval.length > 0 ? (
                        <MUIDataTable
                          data={courseHistoryOnlyApproval}
                          columns={columnsRegular}
                          options={optionsRegular}
                          components={componentsRegular}
                        />
                      ) : (
                        <Typography>Nenhum curso encontrado</Typography>
                      )}
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='column'
                      gap={1}
                      marginTop='2rem'
                      marginBottom='1rem'
                    >
                      <Typography variant={'h4'} margin={0}>
                        Cursos de Educação Continuada
                      </Typography>
                      {renderContinuingEducationCoursesItems()}
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Fragment>
      </Box>
    </Paper>
  );
};

export default BatchAuthorizeLaunch;
