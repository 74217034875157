import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, Paper, Radio, RadioGroup, Stack,Switch,Tooltip,Typography,Zoom,useMediaQuery} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { updateInputValue, disableInputValue } from "../../../utils/appHelpers";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import TextField from '@mui/material/TextField';
import JumboSearch from "@jumbo/components/JumboSearch";
/* import ClassStudentsList from "./ClassStudentsList"; */
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';
import ListRowCouncil from 'app/components/ListRowCouncil/ListRowCouncil';
import JumboSearchClasses from '@jumbo/components/JumboSearchClasses';
import { useDebouncedCallback } from 'beautiful-react-hooks';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
};

const CouncilFormResume = () => {

    const {theme} = useJumboTheme();
    const {showDialog, hideDialog} = useJumboDialog();

    const navigate = useNavigate();
    const moment = require('moment-timezone');

    const Swal = useSwalWrapper();
    const toast = (variant, message, type = false) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });

        //  Redireciona se mensagem de retorno for de sucesso
        
        /* if(id) {

            if(variant == 'success' && (!userFile || (userFile && type) || !files || (files && type))){
                setTimeout(function() {
                    navigate("/app/listar-conselhos")
                }, 2000)
            }
        } */
        
    };

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const initialValues = {
        conselho_id: "",
        associado_id: "",
        status: "",
        data_inicio_conselho: "",
        data_inicio_conselho_efetivo: "",
        data_inicio_conselho_fiscal: "",
        data_fim_conselho: "",
        tipo_conselho: "",
        //encerrar_associacao: false,
        //seguir_associacao_efetiva: false
    }

    const validationSchema = yup.object().shape({
        //motivo_encerramento: yup.string().required('Preenchimento obrigatório'),
        departamento_id: yup.number().nullable(),
        area_id: yup.number().nullable(),
        local: yup.string().required('Preenchimento obrigatório'),
        }).test('departamento-ou-area', 'Escolha apenas departamento ou área', function (value) {
        if ((!value.departamento_id && !value.area_id) || (value.departamento_id && value.area_id)) {
            return this.createError({ message: 'Escolha apenas departamento ou área', path: 'departamento_id' });
        }
        return true;
    }, []);

    const { id } = useParams()
    
    const [searchTerm, setSearchTerm] = useState(null);
    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState(initialValues);
    const [addStudents, setAddStudents] = useState(null);
    const [classStudents, setClassStudents] = useState([]);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [userFile, setUserFile] = useState(null);
    const [status, setStatus] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [username, setUsername] = useState('');
    const [departments, setDepartments] = useState([]);
    const [areas, setAreas] = useState([]);
    const [activities, setActivities] = useState([]);
    const [activitiesSelected, setActivitiesSelected] = useState(null);
    const [files, setFiles] = useState([])
    
    const { hasPermission } = useContext(PermissionContext);
    let { state, pathname } = useLocation();

    const canCreate = hasPermission('Conselho Deliberativo', 'criar');
    const canEdit = hasPermission('Conselho Deliberativo', 'editar');
    const isDisabled = id ? !canEdit : !canCreate
    
    const getAssociateById = useCallback(async () => {
        try {
            ApiService.get(`/conselhos/${id}`)
            .then((response) => {
                console.log('iii', response.data)
                setUsers(response.data);
                setSelectedOption(response.data.Associado?.User)
                setFiles(response.data.Documentos_De_Associados)
                
                if(response.data.status == 'ativo') {
                    setStatus(true)
                }
    
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const getDepartments = useCallback(async () => {
        try {
            ApiService.get(`/departamentos`)
            .then((response) => {
              setDepartments(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const getAreas = useCallback(async () => {
        try {
            ApiService.get(`/areas`)
            .then((response) => {
              setAreas(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const getAtividades = useCallback(async () => {
        try {
            ApiService.get(`/atividades`)
            .then((response) => {
                console.log(response.data)
              setActivities(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    useEffect(() => {
        if(id) getAssociateById();
        getDepartments();
        getAreas();
        getAtividades();
    }, []);

    useEffect(() => {
        Swal.close();
    }, [pathname]);
    
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
       
        
        if(id) {
            
            if(values.encerrar_conselho == 'on') {
                //encerrar voluntariado
                const result = await Swal.fire({
                    title: 'Tem certeza que deseja encerrar?',
                    text: 'Não será possível reverter a ação!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim!',
                    cancelButtonText: 'Não!',
                    reverseButtons: true,
                });

                /* const allValuesEdit = {...values, horario_voluntariado: userPreferences, trabalho_voluntario_fora_feesp: userVolunteerOut } */
               
                if(result.value) {
                    await ApiService.put(`/encerrar-conselho/${id}`, { values })
                    .then((response) => {
                    toast('success', 'Encerrado com sucesso')
                    })
                    .catch((error) => {
                    toast('error', 'Ocorreu um erro')
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        } else if (error.request) {
                        console.log(error.request);
                        } else {
                        console.log('error', error.message);
                        }
                    })
                }
            } else if(values.seguir_conselho_suplente == 'on') {
                //encerrar voluntariado
                console.log('aqui')
                const result = await Swal.fire({
                    title: 'Tem certeza que deseja seguir com conselheiro suplente?',
                    text: 'Não será possível reverter a ação!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim!',
                    cancelButtonText: 'Não!',
                    reverseButtons: true,
                });

                /* const allValuesEdit = {...values, horario_voluntariado: userPreferences, trabalho_voluntario_fora_feesp: userVolunteerOut } */
                const allValuesEdit = {...values, tipo_conselho: 'Conselheiro Suplente'}
               
                if(result.value) {
                    await ApiService.post(`/seguir-conselho-suplente/${id}`, { values: allValuesEdit })
                    .then((response) => {
                    
                        toast('success', 'Transferido com sucesso')
                    })
                    .catch((error) => {
                    toast('error', error.response.data.error)
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        } else if (error.request) {
                        console.log(error.request);
                        } else {
                        console.log('error', error.message);
                        }
                    })
                }
            }else if(values.seguir_conselho_efetivo == 'on') {
                const result = await Swal.fire({
                    title: 'Tem certeza que deseja seguir com conselheiro efetivo?',
                    text: 'Não será possível reverter a ação!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim!',
                    cancelButtonText: 'Não!',
                    reverseButtons: true,
                });

                /* const allValuesEdit = {...values, horario_voluntariado: userPreferences, trabalho_voluntario_fora_feesp: userVolunteerOut } */
                const allValuesEdit = {...values, tipo_conselho: 'Conselheiro Efetivo'}
               
                if(result.value) {
                    await ApiService.post(`/seguir-conselho-efetivo/${id}`, { values: allValuesEdit })
                    .then((response) => {
                    
                        toast('success', 'Transferido com sucesso')
                    })
                    .catch((error) => {
                    toast('error', error.response.data.error)
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        } else if (error.request) {
                        console.log(error.request);
                        } else {
                        console.log('error', error.message);
                        }
                    })
                }
            }else if(values.seguir_conselho_fiscal == 'on') {
                const result = await Swal.fire({
                    title: 'Tem certeza que deseja seguir com conselheiro fiscal?',
                    text: 'Não será possível reverter a ação!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim!',
                    cancelButtonText: 'Não!',
                    reverseButtons: true,
                });

                /* const allValuesEdit = {...values, horario_voluntariado: userPreferences, trabalho_voluntario_fora_feesp: userVolunteerOut } */
                const allValuesEdit = {...values, tipo_conselho: 'Conselheiro Fiscal'}
               
                if(result.value) {
                    await ApiService.post(`/seguir-conselho-fiscal/${id}`, { values: allValuesEdit })
                    .then((response) => {
                    
                        toast('success', 'Transferido com sucesso')
                    })
                    .catch((error) => {
                    toast('error', error.response.data.error)
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        } else if (error.request) {
                        console.log(error.request);
                        } else {
                        console.log('error', error.message);
                        }
                    })
                }
            }
            else {
                //atualiza form sem encerrar associação
                await ApiService.put(`/conselhos/${id}`, { values })
                .then((response) => {
                    if (!userFile) toast('success', 'Atualizado com sucesso')
                    setSubmitClicked(true)
                })
                .catch((error) => {
                    toast('error', error.response.data.error);
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    console.log(error.request);
                    } else {
                    console.log('error', error.message);
                    }
                })
                
            }
        } else {
            //horario_voluntariado: userPreferences, trabalho_voluntario_fora_feesp: userVolunteerOut,
            const allValues = {...values, associado_id: username.associado_id}
            //return console.log(allValues)
            await ApiService.post('/conselhos', { values: allValues })
            .then((response) => {
                console.log(response)
                toast('success', 'Criado com sucesso')
                resetForm()
                setUsername('')

            })
            .catch((error) => {
                toast('error', error.response.data.error);
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('error', error.message);
                }
            })
        }
        
        setSubmitting(false);
    } 

    useEffect(() => {
        if(searchTerm == undefined || searchTerm == ''){
            setAddStudents(null);
            return;
        } 

        const delayDebounceFn = setTimeout(() => {
          searchStudent(searchTerm)
        }, 1500)

        
        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    const searchStudent = useCallback(async (e) => {
        setLoading(true)
        try {
             ApiService.get(`/pessoa`, {params: {search: e}})
            .then((response) => {
                setAddStudents(response.data)
                // toast('success', 'Aluno adicionado com sucesso');
                setLoading(false)
            })
            .catch((error) => {
                // toast('error', 'Ocorreu um erro');

              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
                setLoading(false)
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const handleChangeSearch = useDebouncedCallback((event) => {
        setSearchTerm(event.target.value);
    });

    
    React.useEffect(() => {
        setSearchTerm(searchTerm);
    }, [searchTerm]);

    React.useEffect(() => {
        return () => handleChangeSearch.cancel();
    });


    const updateStudentList = useCallback(async (data) => {
        setClassStudents([...data]);
        setAddStudents(null);
    }, []);

    const handleInputFocus = () => {
        setIsInputFocused(true);
      }
    
    const handleInputBlur = () => {
        setIsInputFocused(false);
    }


    const dateTime = users && users?.updatedAt;
    let dateBr;
    let timeBr;
    if(dateTime) {
        dateBr = moment(dateTime).tz('America/Sao_Paulo').format('DD/MM/YYYY');
        timeBr = moment(dateTime).tz('America/Sao_Paulo').format('HH:mm:ss')
    }

    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? 'transparent' : 'white',
          border: state.isFocused ? '1px solid #7352C7' : '1px solid rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',
          padding: '8px',
          boxShadow: 'none',
          '&:hover': {
            borderColor: 'black',
          },
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? '#7352C7' : 'white',
          color: state.isSelected ? 'white' : 'black',
          '&:hover': {
            backgroundColor: 'lightblue',
            color: 'white',
          },
        }),
    };

    const createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
    });

    const handleCreate = (inputValue) => {
        const newOption = createOption(inputValue);
        
        setActivities((prev) => [...prev, newOption]);
        setActivitiesSelected(newOption);
    };

  const [submitClicked, setSubmitClicked] = useState(false);

  return (
    <JumboContentLayout
        header={
            <HeaderBreadcrumbs
                id={id}
                title={"Conselheiros"}
                subtitle={id ? "Editar" : "Cadastro de Conselheiros"}
                titleUrl={"/app/listar-conselhos"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }

        <Paper sx={{p: '40px'}}> 
            {state &&
                <Button
                    color="success"
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{maxWidth: {md: '200px'}, mb: '2rem'}}
                    onClick={() => navigate(state.backUrl)}
                >
                    Voltar
                </Button>
            }
            <Formik
                initialValues={users}
                //validationSchema={validationSchema}
                enableReinitialize
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={handleSubmit}
            >
                {({values, isSubmitting, handleChange}) => (
                    <Form style={{width: '100%'}} noValidate autoComplete='off'>
                        <Grid container spacing={3} alignContent={'center'}>
                            {id
                                ?
                                null
                                :
                                (
                                    <>
                                        <Grid item xs={12} >
                                            <JumboSearchClasses
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                placeholder="Busque por Nome, CPF ou E-mail."
                                                sx={{
                                                    width: '440px',
                                                    marginBottom: '1rem'
                                                }}
                                                onFocus={handleInputFocus} 
                                                onBlur={handleInputBlur}
                                                disabled={isDisabled}
                                            />
                                        </Grid> 
                                        <Grid item xs={12}>
                                            {loading 
                                                && 
                                                <Box display='flex' justifyContent='center'>
                                                    <CircularProgress  /> 
                                                </Box>
                                            }
                                            {
                                                (addStudents != null && addStudents.length > 0) && <ListRowCouncil data={addStudents} updateStudentList={(data) => updateStudentList(data)} classStudents={classStudents} status={status} setStatus={setStatus} setUsername={setUsername}setSearchTerm={setSearchTerm} />
                                            }
                                            {
                                                (addStudents != null && addStudents.length == 0) && <Typography>Sem resultados correspondentes</Typography>
                                            }  

                                        </Grid>
                                    </>
                                ) 
                            }
                            
                            <Grid item xs={12}>
                                <JumboTextField
                                    fullWidth
                                    name={id ? "conselho_id" : "associado_id"}
                                    label="Nome"
                                    value={id ? selectedOption && selectedOption.nome : username && username?.User.nome}
                                    disabled={true}
                                    InputLabelProps={{ 
                                        shrink: true 
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">Tipo de conselheiro</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="tipo_conselho"
                                        value={updateInputValue(users, values, 'tipo_conselho')}
                                        //onChange={(event) => setSize(event.target.value)}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel 
                                            value="Conselheiro Suplente" 
                                            control={<Radio />} 
                                            label="Conselheiro Suplente" 
                                            disabled={
                                                isDisabled 
                                                || 
                                                status == false
                                                ||
                                                (id &&
                                                    (values.tipo_conselho === 'Conselheiro Efetivo' || values.tipo_conselho === 'Conselheiro Fiscal')
                                                )
                                            }
                                        />
                                        <FormControlLabel 
                                            value="Conselheiro Efetivo" 
                                            control={<Radio />} 
                                            label="Conselheiro Efetivo" 
                                            disabled={
                                                isDisabled 
                                                || 
                                                status == false
                                                ||
                                                (id &&
                                                    (values.tipo_conselho === 'Conselheiro Suplente' 
                                                    || values.tipo_conselho === 'Conselheiro Fiscal')
                                                )
                                            }
                                        />
                                        <FormControlLabel 
                                            value="Conselheiro Fiscal" 
                                            control={<Radio />} 
                                            label="Conselheiro Fiscal" 
                                            disabled={
                                                isDisabled 
                                                || 
                                                status == false
                                                ||
                                                (id &&
                                                    (values.tipo_conselho === 'Conselheiro Suplente'
                                                    || values.tipo_conselho === 'Conselheiro Efetivo')
                                                )
                                            }
                                        />
                                        
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {
                                values.seguir_conselho_efetivo == 'on' 
                                ?
                                null
                                :
                                <>
                                    <Grid item xs={6}>
                                        <JumboTextField
                                            fullWidth
                                            name="data_inicio_conselho"
                                            type="date"
                                            label="Data de início"
                                            sx={{ background: '#ffffff'}}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={isDisabled || status == false}
                                        />
                                    </Grid>  
                                </>
                            }
                        
                            <Grid item xs={12}>
                                {id && users.status === 'ativo'
                                    ?
                                        <>
                                            {
                                                values.tipo_conselho === 'Conselheiro Efetivo' 
                                                || values.tipo_conselho === 'Conselheiro Fiscal'
                                                ?
                                                <Grid item xs={12}>
                                                    <Accordion 
                                                        expanded={updateInputValue(users, values, 'seguir_conselho_suplente') == "on" ? true : false}
                                                        elevation={0}
                                                    >
                                                        <AccordionSummary
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                                <Stack
                                                                    sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                                    direction={{ xs: 'column', lg: 'row' }}
                                                                    justifyContent="space-between"
                                                                    alignItems="center"
                                                                    spacing={2}
                                                                >
                                                                    <Typography
                                                                        sx={{fontSize: '1.05rem'}}
                                                                    >
                                                                        Seguir como conselheiro suplente?
                                                                    </Typography>

                                                                    <Stack
                                                                        direction="row"
                                                                        alignItems="center"
                                                                        spacing={1}
                                                                    >
                                                                        <Typography>Não</Typography>
                                                                            <FormControlLabel
                                                                                control={
                                                                                <Switch 
                                                                                    checked={updateInputValue(users, values, 'seguir_conselho_suplente') == "on" ? true : false}
                                                                                    
                                                                                    onChange={handleChange}
                                                                                    name="seguir_conselho_suplente" 
                                                                                    disabled={isDisabled}
                                                                                />
                                                                                }
                                                                                labelPlacement="start"
                                                                            />
                                                                        <Typography>Sim</Typography>
                                                                    </Stack>

                                                                </Stack>
                                                        </AccordionSummary>
                                                        <AccordionDetails sx={{display: 'flex', gap: '1.5rem'}}>
                                                            <Grid container spacing={3} alignContent={'center'}>
                                                                <Grid item xs={4}>
                                                                    <JumboTextField
                                                                        fullWidth
                                                                        name="data_fim_conselho"
                                                                        type="date"
                                                                        label="Insira a data de encerramento do conselheiro anterior"
                                                                        value={updateInputValue(users, values, 'data_fim_conselho')}
                                                                        sx={{ background: '#ffffff'}}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        disabled={isDisabled || status == false}
                                                                    />
                                                                </Grid> 
                                                                <Grid item xs={4}>
                                                                    <JumboTextField
                                                                        fullWidth
                                                                        name="data_inicio_conselho_suplente"
                                                                        type="date"
                                                                        label="Insira a data inicial do conselheiro suplente"
                                                                        value={updateInputValue(users, values, 'data_inicio_conselho_suplente')}
                                                                        sx={{ background: '#ffffff'}}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        disabled={isDisabled || status == false}
                                                                    />
                                                                </Grid>
                                                                
                                                                {values.seguir_conselho_suplente == 'on' // verifica se o switch é true
                                                                    ?
                                                                    (
                                                                        <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                                                            <LoadingButton 
                                                                                color='error' 
                                                                                type="submit"
                                                                                variant="contained"  
                                                                                size="large"
                                                                                loading={isSubmitting}
                                                                                disabled={isDisabled || isInputFocused || status == false || values.data_fim_conselho == '' || values.data_fim_conselho == null}
                                                                            >
                                                                                Confirmar
                                                                            </LoadingButton> 
                                                                        </Grid>
                                                                    )
                                                                    :
                                                                    null
                                                                } 
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    {values.encerrar_conselho == 'on' ? null : <Divider variant="middle" />}
                                                </Grid>
                                                : null
                                            }
                                            {
                                                values.tipo_conselho === 'Conselheiro Suplente' 
                                                || values.tipo_conselho === 'Conselheiro Fiscal'
                                                ?
                                                    <Grid item xs={12}>
                                                        <Accordion 
                                                            expanded={updateInputValue(users, values, 'seguir_conselho_efetivo') == "on" ? true : false}
                                                            elevation={0}
                                                        >
                                                            <AccordionSummary
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                    <Stack
                                                                        sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                                        direction={{ xs: 'column', lg: 'row' }}
                                                                        justifyContent="space-between"
                                                                        alignItems="center"
                                                                        spacing={2}
                                                                    >
                                                                        <Typography
                                                                            sx={{fontSize: '1.05rem'}}
                                                                        >
                                                                            Seguir como conselheiro efetivo?
                                                                        </Typography>

                                                                        <Stack
                                                                            direction="row"
                                                                            alignItems="center"
                                                                            spacing={1}
                                                                        >
                                                                            <Typography>Não</Typography>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                    <Switch 
                                                                                        checked={updateInputValue(users, values, 'seguir_conselho_efetivo') == "on" ? true : false}
                                                                                        
                                                                                        onChange={handleChange}
                                                                                        name="seguir_conselho_efetivo" 
                                                                                        disabled={isDisabled}
                                                                                    />
                                                                                    }
                                                                                    labelPlacement="start"
                                                                                />
                                                                            <Typography>Sim</Typography>
                                                                        </Stack>

                                                                    </Stack>
                                                            </AccordionSummary>
                                                            <AccordionDetails sx={{display: 'flex', gap: '1.5rem'}}>
                                                                <Grid container spacing={3} alignContent={'center'}>
                                                                    <Grid item xs={4}>
                                                                        <JumboTextField
                                                                            fullWidth
                                                                            name="data_fim_conselho"
                                                                            type="date"
                                                                            label="Insira a data de encerramento do conselheiro anterior"
                                                                            value={updateInputValue(users, values, 'data_fim_conselho')}
                                                                            sx={{ background: '#ffffff'}}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            disabled={isDisabled || status == false}
                                                                        />
                                                                    </Grid> 
                                                                    <Grid item xs={4}>
                                                                        <JumboTextField
                                                                            fullWidth
                                                                            name="data_inicio_conselho_efetivo"
                                                                            type="date"
                                                                            label="Insira a data inicial do conselheiro efetivo"
                                                                            value={updateInputValue(users, values, 'data_inicio_conselho_efetivo')}
                                                                            sx={{ background: '#ffffff'}}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            disabled={isDisabled || status == false}
                                                                        />
                                                                    </Grid>
                                                                    
                                                                    {values.seguir_conselho_efetivo == 'on' // verifica se o switch é true
                                                                        ?
                                                                        (
                                                                            <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                                                                <LoadingButton 
                                                                                    color='error' 
                                                                                    type="submit"
                                                                                    variant="contained"  
                                                                                    size="large"
                                                                                    loading={isSubmitting}
                                                                                    disabled={isDisabled || isInputFocused || status == false || values.data_fim_conselho == '' || values.data_fim_conselho == null}
                                                                                >
                                                                                    Confirmar
                                                                                </LoadingButton> 
                                                                            </Grid>
                                                                        )
                                                                        :
                                                                        null
                                                                    } 
                                                                </Grid>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                        {values.encerrar_conselho == 'on' ? null : <Divider variant="middle" />}
                                                    </Grid>
                                                :
                                                null
                                            }
                                            {
                                                values.tipo_conselho === 'Conselheiro Suplente'
                                                || values.tipo_conselho === 'Conselheiro Efetivo'
                                                ?
                                                    <Grid item xs={12}>
                                                        <Accordion 
                                                            expanded={updateInputValue(users, values, 'seguir_conselho_fiscal') == "on" ? true : false}
                                                            elevation={0}
                                                        >
                                                            <AccordionSummary
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                    <Stack
                                                                        sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                                        direction={{ xs: 'column', lg: 'row' }}
                                                                        justifyContent="space-between"
                                                                        alignItems="center"
                                                                        spacing={2}
                                                                    >
                                                                        <Typography
                                                                            sx={{fontSize: '1.05rem'}}
                                                                        >
                                                                            Seguir como conselheiro fiscal?
                                                                        </Typography>

                                                                        <Stack
                                                                            direction="row"
                                                                            alignItems="center"
                                                                            spacing={1}
                                                                        >
                                                                            <Typography>Não</Typography>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                    <Switch 
                                                                                        checked={updateInputValue(users, values, 'seguir_conselho_fiscal') == "on" ? true : false}
                                                                                        
                                                                                        onChange={handleChange}
                                                                                        name="seguir_conselho_fiscal" 
                                                                                        disabled={isDisabled}
                                                                                    />
                                                                                    }
                                                                                    labelPlacement="start"
                                                                                />
                                                                            <Typography>Sim</Typography>
                                                                        </Stack>

                                                                    </Stack>
                                                            </AccordionSummary>
                                                            <AccordionDetails sx={{display: 'flex', gap: '1.5rem'}}>
                                                                <Grid container spacing={3} alignContent={'center'}>
                                                                    <Grid item xs={4}>
                                                                        <JumboTextField
                                                                            fullWidth
                                                                            name="data_fim_conselho"
                                                                            type="date"
                                                                            label="Insira a data de encerramento do conselheiro anterior"
                                                                            value={updateInputValue(users, values, 'data_fim_conselho')}
                                                                            sx={{ background: '#ffffff'}}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            disabled={isDisabled || status == false}
                                                                        />
                                                                    </Grid> 
                                                                    <Grid item xs={4}>
                                                                        <JumboTextField
                                                                            fullWidth
                                                                            name="data_inicio_conselho_fiscal"
                                                                            type="date"
                                                                            label="Insira a data inicial do conselheiro fiscal"
                                                                            value={updateInputValue(users, values, 'data_inicio_conselho_fiscal')}
                                                                            sx={{ background: '#ffffff'}}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            disabled={isDisabled || status == false}
                                                                        />
                                                                    </Grid>
                                                                    
                                                                    {values.seguir_conselho_fiscal == 'on' // verifica se o switch é true
                                                                        ?
                                                                        (
                                                                            <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                                                                <LoadingButton 
                                                                                    color='error' 
                                                                                    type="submit"
                                                                                    variant="contained"  
                                                                                    size="large"
                                                                                    loading={isSubmitting}
                                                                                    disabled={isDisabled || isInputFocused || status == false || values.data_fim_conselho == '' || values.data_fim_conselho == null}
                                                                                >
                                                                                    Confirmar
                                                                                </LoadingButton> 
                                                                            </Grid>
                                                                        )
                                                                        :
                                                                        null
                                                                    } 
                                                                </Grid>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                        {values.encerrar_conselho == 'on' ? null : <Divider variant="middle" />}
                                                    </Grid>
                                                :
                                                null
                                            }

                                        </>
                                        
                                    :
                                    null
                                }
                                {values.data_fim_associado == 'on' // verifica se o switch é true
                                    ?
                                    (
                                        <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                            <LoadingButton 
                                                color='error' 
                                                type="submit"
                                                variant="contained"  
                                                size="large"
                                                loading={isSubmitting}
                                                disabled={isDisabled || isInputFocused || status == false || values.data_fim_associado == '' || values.data_fim_associado == null}
                                            >
                                                Encerrar associação
                                            </LoadingButton> 
                                        </Grid>
                                    )
                                    :
                                    null
                                } 
                                    
                            </Grid>        
                            <Grid item xs={12}>
                                {id
                                    ?
                                        <Grid item xs={12}>
                                            <Accordion 
                                                expanded={
                                                    users.status === 'ativo' 
                                                    ?
                                                    (
                                                        updateInputValue(users, values, 'encerrar_conselho') == "on" 
                                                        ? true : false
                                                    )
                                                    :
                                                    true
                                                }
                                                elevation={0}
                                            >
                                                <AccordionSummary
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                        <Stack
                                                            sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                            direction={{ xs: 'column', lg: 'row' }}
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            spacing={2}
                                                        >
                                                            <Typography
                                                                sx={{fontSize: '1.05rem'}}
                                                            >
                                                                {users.status === 'ativo' ? 'Encerrar conselho?' : 'Conselho Encerrado'}
                                                            </Typography>
                                                            {
                                                                users.status === 'ativo' 
                                                                ? 
                                                                (
                                                                    <Stack
                                                                        direction="row"
                                                                        alignItems="center"
                                                                        spacing={1}
                                                                    >
                                                                        <Typography>Não</Typography>
                                                                            <FormControlLabel
                                                                                control={
                                                                                <Switch 
                                                                                    checked={updateInputValue(users, values, 'encerrar_conselho') == "on" ? true : false}
                                                                                    
                                                                                    onChange={handleChange}
                                                                                    name="encerrar_conselho" 
                                                                                    disabled={isDisabled}
                                                                                />
                                                                                }
                                                                                labelPlacement="start"
                                                                            />
                                                                        <Typography>Sim</Typography>
                                                                    </Stack>
                                                                )
                                                                :
                                                                undefined
                                                            }

                                                        </Stack>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{display: 'flex', gap: '1.5rem'}}>
                                                    <Grid container spacing={3} alignContent={'center'}>
                                                        <Grid item xs={6}>
                                                            <JumboTextField
                                                                fullWidth
                                                                name="data_fim_conselho"
                                                                type="date"
                                                                label="Data Final"
                                                                value={updateInputValue(users, values, 'data_fim_conselho')}
                                                                sx={{ background: '#ffffff'}}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                disabled={isDisabled || status == false}
                                                            />
                                                        </Grid> 
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            {values.encerrar_conselho == 'on' ? null : <Divider variant="middle" />}
                                        </Grid>
                                    :
                                    null
                                }
                                {values.encerrar_conselho == 'on' // verifica se o switch é true
                                    ?
                                    (
                                        <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                            <LoadingButton 
                                                color='error' 
                                                type="submit"
                                                variant="contained"  
                                                size="large"
                                                loading={isSubmitting}
                                                disabled={isDisabled || isInputFocused || status == false || values.data_fim_conselho == '' || values.data_fim_conselho == null}
                                            >
                                                Encerrar
                                            </LoadingButton> 
                                        </Grid>
                                    )
                                    :
                                    (
                                        values.seguir_conselho_efetivo == 'on' 
                                        ?
                                        null
                                        :
                                        <Grid item xs={12} sx={{textAlign: 'center', mt: 3}}>
                                            <LoadingButton
                                            color="success"
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                            loading={isSubmitting}
                                            disabled={isDisabled || isInputFocused || status == false || values.data_inicio_conselho == '' || values.data_inicio_conselho == null}
                                        >
                                            {id ? 'Atualizar' : 'Cadastrar'}
                                            </LoadingButton>
                                        </Grid>
                                    )
                                } 
                                    
                            </Grid> 
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Paper>
    </JumboContentLayout>
  )
}

export default CouncilFormResume