import React, { useCallback, useState, useContext } from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import {Accordion, Card, Tooltip, Typography, Grid, Chip, Modal, Fade, Backdrop} from "@mui/material";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import WarningIcon from '@mui/icons-material/Warning';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Zoom from '@mui/material/Zoom';
import { TextService } from 'app/servicesTwo/TextService';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { LinkStyle } from '../users/UserItem';
import { useLocation, useNavigate } from 'react-router-dom'
import Div from '@jumbo/shared/Div/Div';
import ModalAuthorizeFile from './ModalAuthorizeFile';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
   
};

const CoursesIntroRegistrationListItem = ({ item, setUsersRegistration, usersRegistration}) => {
    
    const location = useLocation();
    const { hasPermission } = useContext(PermissionContext);
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });
    };

    const modalAlert = (curso_introdutorio_historico_id) => {
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: "Não será póssível reverter a ação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                removeStudentFromCourseIntro(curso_introdutorio_historico_id);
            }
        });
    };

    const modalAlertInsert = (userInfo) => {
        Swal.fire({
            title: 'Tem certeza que deseja incluir na turma?',
            text: "Não será póssível reverter a ação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                transferStudentFromCourseIntro(userInfo);
            }
        });
    };

    function removeById(arr, id) {
        const objWithIdIndex = arr.findIndex((obj) => obj.curso_introdutorio_historico_id === id);
      
        if (objWithIdIndex > -1) {
          arr.splice(objWithIdIndex, 1);
        }
      
        return arr;
    }

    function updateById(arr, id, status) {
        const updatedClass = arr.find((entry) => entry.curso_introdutorio_historico_id === id)
        if(updatedClass) {
            updatedClass.status = status
        }
      
        return arr;
    }

    const removeStudentFromCourseIntro = useCallback(async (curso_introdutorio_historico_id) => {
    try {
        ApiService.delete(`/cursos-introdutorios/pessoa/${curso_introdutorio_historico_id}`,)
        .then((response) => {
            toast('success', 'Removido com sucesso');

            const updatedStudentsArray = removeById(usersRegistration, curso_introdutorio_historico_id)
            setUsersRegistration(updatedStudentsArray);
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('error', error.message);
            }
        })
    } catch (err) {
        console.log(err);
        toast('error', 'Ocorreu um erro');
    }
    }, [usersRegistration, setUsersRegistration]);

    const transferStudentFromCourseIntro = useCallback(async (userInfo) => {
        const turmaId = userInfo.Turma.turmas_id
        const pessoa_id = userInfo.pessoa_id
        const introHistoricoId = userInfo.curso_introdutorio_historico_id
        try {
            ApiService.post(`/turmas-inserir/${turmaId}/aluno/${pessoa_id}/intro/${introHistoricoId}`,)
            .then((response) => {
                toast('success', 'Adicionado com sucesso');
                const status = response.data.status
                const updatedStudentsArray = updateById(usersRegistration, introHistoricoId, status)
                setUsersRegistration(updatedStudentsArray);
            })
            .catch((error) => {
                if (error.response) {
                    toast('error', error.response.data.error);
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('error', error.message);
                }
            })
        } catch (err) {
            console.log(err);
            toast('error', 'Ocorreu um erro');
        }
    }, [usersRegistration, setUsersRegistration]);

    const maxLengthText = 20;

    const getChipColor = (diaDaSemana) => {
        switch (diaDaSemana) {
            case 'Segunda':
                return 'primary';
            case 'Terça':
                return 'secondary';
            case 'Quarta':
                return 'success';
            case 'Quinta':
                return 'success';
            case 'Sexta':
                return 'info';
            case 'Sábado':
                return 'error';
          
          default:
            return 'default';
        }
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const isEligible = (item.status_curso_introdutorio_historico !== "Matriculado" ||
                                    item.status !== "Matriculado") &&
                                    (item.ficha_compativel !== null && item.ficha_compativel !== '' ) && !item.atende_requisitos
    const isEligibleAndCompatible = (item.status_curso_introdutorio_historico === "Matriculado" ||
                                    item.status === "Matriculado") &&
                                    (item.ficha_compativel !== null && item.ficha_compativel !== '' )

    return (
        <>
            <Card sx={{mb: 1}}>
                <Accordion square sx={{borderRadius: 2}}>
                    <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    
                        sx={{
                            px: 3,
                            flexDirection: 'row-reverse',

                            '& .MuiAccordionSummary-content': {
                                alignItems: 'center',

                                '&.Mui-expanded': {
                                margin: '12px 0',
                                }
                            },
                            '.MuiAccordionSummary-expandIconWrapper': {
                                borderRadius: 1,
                                border: 1,
                                color: 'text.secondary',
                                borderColor: 'divider',
                                transform: 'none',
                                height: 28,
                                width: 28,
                                alignItems: 'center',
                                justifyContent: 'center',
                                mr: 1,

                                '&.Mui-expanded': {
                                    transform: 'none',
                                    color: 'primary.main',
                                    borderColor: 'primary.main',
                                },

                                '& svg': {
                                    fontSize: '1.25rem',
                                }
                            }
                        }}
                    >
                        <Grid container spacing={3} alignContent={'center'}>
                            <Grid item lg={1} md={2} xs={2} sx={{display: 'none'}}>
                                <Avatar sx={{width: 52, height: 52}} alt={item.nome} src={`${process.env.REACT_APP_API_KEY}/images/${item.imagem_perfil}`} variant="rounded"/>
                            </Grid>
                            <Grid item lg={5} md={4} xs={6}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    Nome
                                </Typography>
                                <Typography variant={"body1"}>{item.nome}</Typography>
                            </Grid>
                        
                            <Grid item lg={4} md={3} xs={2} sx={{ display: {xs: 'none', md: 'none', lg: 'block'} }}>
                                
                                {item.Turma && (

                                    <Chip 
                                        label={`${item.Turma.horario} - ${item.Turma.dia_da_semana} - ${item.Turma.modo}`} 
                                        color={getChipColor(item.Turma.dia_da_semana)} 
                                    />
                                )}
                            </Grid>
                            {/* {
                                (item.status_curso_introdutorio_historico === "Matriculado" ||
                                item.status === "Matriculado") &&
                                (item.ficha_compativel !== null && item.ficha_compativel !== '' )
                                ?
                                    <Grid item lg={1} md={2} xs={4}>
                                        <Tooltip 
                                            title="Clique para visualizar quem autorizou a compatibilidade"
                                            TransitionComponent={Zoom}
                                            arrow
                                        >
                                            <Button 
                                                sx={{minWidth: 1}} 
                                                disableElevation 
                                                variant={"contained"} 
                                                size={"small"} 
                                                color={"warning"} 
                                                onClick={handleOpen}
                                            >
                                                <FindInPageIcon />
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                :
                                null
                            } */}
                            <Grid item lg={1} md={1} xs={2}>
                                {
                                    item.status_curso_introdutorio_historico === "Matriculado" ||
                                    item.status === "Matriculado"
                                    ?
                                        <Tooltip 
                                            title="Clique visualizar aluno na turma"
                                            TransitionComponent={Zoom}
                                            arrow
                                        >
                                            <Button 
                                                sx={{minWidth: 1}} 
                                                disableElevation 
                                                variant={"contained"} 
                                                size={"small"} 
                                                color={"info"}
                                                onClick={() => navigate(`/app/editar-turma/${item.turmas_id}`)}
                                            >
                                                <ArrowForwardIosIcon />
                                            </Button>
                                        </Tooltip>
                                    :
                                    
                                    isEligible
                                    ?
                                        undefined
                                    :
                                        <Tooltip 
                                            title="Clique para adicionar aluno na turma"
                                            TransitionComponent={Zoom}
                                            arrow
                                        >
                                            <Button 
                                                sx={{minWidth: 1}} 
                                                disableElevation 
                                                variant={"contained"} 
                                                size={"small"} 
                                                color={"primary"}
                                                disabled={!hasPermission('Turmas', 'criar')}
                                                onClick={() => modalAlertInsert(item)}
                                            >
                                                <PersonAddIcon />
                                            </Button>
                                        </Tooltip>
                                }
                            </Grid>
                            <Grid item lg={1} md={2} xs={4}>
                                <Tooltip 
                                    title="Visualizar"
                                    TransitionComponent={Zoom}
                                    arrow
                                >
                                    <LinkStyle to={`/app/editar-aluno/${item.pessoa_id}`} state={{ backUrl: location.pathname,  disable: true }}>
                                        <VisibilityIcon />
                                    </LinkStyle>
                                </Tooltip>
                            </Grid>
                           
                            <Grid item lg={1} md={2} xs={4}>
                                <Tooltip 
                                    /* title={
                                        isEligibleAndCompatible 
                                        ?
                                        "Clique para visualizar quem autorizou a compatibilidade"
                                        :
                                        isEligible
                                        ?
                                        "Clique visualizar documento de compatibilidade de curso"
                                        :
                                        item.atende_requisitos 
                                        ?
                                        "Usuário não enviou documento de compatibilidade, pois atende aos requisitos"
                                        :
                                        "Usuário não enviou documento de compatibilidade"
                                    } */
                                    title = {
                                        item.ficha_compativel === null || item.ficha_compativel === ''
                                        ?
                                        "Usuário não enviou documento de compatibilidade"
                                        :
                                        "Clique para visualizar documento de compatibilidade"
                                        
                                    }
                                    TransitionComponent={Zoom}
                                    arrow
                                >
                                    <span>
                                        <Button 
                                            sx={{minWidth: 1}} 
                                            disableElevation 
                                            variant={"contained"} 
                                            size={"small"} 
                                            color={"warning"} 
                                            onClick={handleOpen}
                                            disabled={
                                                !item.ficha_compativel
                                            }
                                        >
                                            <FindInPageIcon />
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Grid>
                               

                           
                            {/* <Grid item lg={1} md={1} xs={4}>
                                <Tooltip 
                                    title="Excluir"
                                    TransitionComponent={Zoom}
                                    arrow
                                >
                                    <Button 
                                        sx={{minWidth: 1}} 
                                        disableElevation 
                                        variant={"contained"} 
                                        size={"small"} 
                                        color={"secondary"}
                                        disabled={!hasPermission('Turmas', 'deletar')}
                                        onClick={() => modalAlert(item.curso_introdutorio_historico_id)}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </Tooltip>
                            </Grid> */}
                        </Grid>
                    
                    </AccordionSummary>
                </Accordion>
            </Card>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Div sx={style}>
                        <ModalAuthorizeFile data={item} curso_historico_id={item.curso_historico_id} handleClose={handleClose} classStudents={usersRegistration} setClassStudents={usersRegistration}/>
                    </Div>
                </Fade>
            </Modal>
        </>
    );
};
/* Todo item prop define */
export default CoursesIntroRegistrationListItem;
