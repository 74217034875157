import React, { useCallback, useState, useContext } from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import {Accordion, Card, Tooltip, Typography, Zoom, Grid} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import capitalize from "../../../utils/capitalize";
import formatDate from "../../../utils/formatDate";
import {DAYS_OF_WEEK} from "../../../utils/constants/daysOfWeek";
import { PermissionContext } from 'app/contexts/PermissionContext';
import { LinkStyle } from '../users/UserItem';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

export const Item = styled(Span)(({theme}) => ({
    padding: theme.spacing(0, 1),
}));

export const CSVStyled = styled(CSVLink)`
    padding: 4px 10px;
    background: #0092D0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    
    svg {
        color: white;
    }
`

const ModalTraClassItem = ({ item ,setClasses, classes, handleSubmit, getCourse }) => {
    
    const { hasPermission } = useContext(PermissionContext);
    const [confirmExclusion, setConfirmExclusion] = useState(false);

    console.log(item)

    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });
    };

    const modalAlert = (turma_id) => {
        Swal.fire({
            title: 'Tem certeza que deseja transferir o aluno para essa turma?',
           /*  text: "Não será póssível reverter a ação!", */
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                handleSubmit(turma_id);
            }
        });
    };

    return (
        <Card sx={{mb: 1}}>
            <Accordion square sx={{borderRadius: 2}}>
                <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        px: 0.5,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',

                            '&.Mui-expanded': {
                               margin: '12px 0',
                            }
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            }
                        }
                    }}
                >
                    {/* <Grid container spacing={2} alignContent={'center'}> */}
                        <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width: {xs: '7%', lg: '7%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Ano
                            </Typography>
                            <Typography variant={"body1"}>{item.ano}</Typography>
                        </Div>
                        <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width:{xs: '30%', xl: '30%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Nome do curso
                            </Typography>
                            <Typography variant={"body1"}>{getCourse?.Curso?.nome}</Typography>
                        </Div>
                       
                        <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width:{xs: '10%', xl: '10%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Dia
                            </Typography>
                            <Typography variant={"body1"}>{item.dia_da_semana}</Typography>
                        </Div>
                        <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width:{xs: '7%', xl: '7%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Horário
                            </Typography>
                            <Typography variant={"body1"}>{item.horario}</Typography>
                        </Div>
                        <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width:{xs: '12%', xl: '10%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Modo
                            </Typography>
                            <Typography variant={"body1"}>{item.modo}</Typography>
                        </Div>
                        <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width:{xs: '16%', xl: '16%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Local
                            </Typography>
                            <Typography variant={"body1"}>{capitalize(item.local)}</Typography>
                        </Div>

                        <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width:{xs: '7%', xl: '7%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Grupo
                            </Typography>
                            <Typography variant={"body1"}>{item.grupo}</Typography>
                        </Div>

                        <Div sx={{display: 'flex', lineBreak: 'anywhere', flexShrink: 1, px: 1, gap: '1rem'}}>
                           {/*  {confirmExclusion && 
                                <Button disableElevation variant={"contained"} size={"small"} sx={{ width: '120px' }}
                                    onClick={() => setConfirmExclusion(false)}
                                >
                                        Cancelar
                                </Button>
                            } */}
                            <Button 
                                sx={{ width: '120px' }}
                                disableElevation 
                                variant={"contained"} 
                                size={"small"} 
                                color={"success"}
                                onClick={() => modalAlert(item.turmas_id)}
                            >
                                
                                <SwapHorizIcon />
                            </Button>
                        </Div>  
                   {/*  </Grid> */}  
                </AccordionSummary>
            </Accordion>
        </Card>
    );
};
/* Todo item prop define */
export default ModalTraClassItem;
