import React, { useCallback, useState, useContext } from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import {Accordion, AccordionDetails, Card, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import capitalize from "../../../utils/capitalize";
import formatDate from "../../../utils/formatDate";
import {DAYS_OF_WEEK} from "../../../utils/constants/daysOfWeek";
import {TIME} from "../../../utils/constants/time";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from "@mui/material/Stack";
import { PermissionContext } from 'app/contexts/PermissionContext';


const LogModalItem = ({ itemName, itemType }) => {
    const [expanded, setExpanded] = useState(false);
    const toggleAccordion = () => setExpanded(!expanded);

    return (
        <Card sx={{mb: 1}} style={{ display: 'flex', flexDirection: 'row' }}>
            
            <Accordion expanded={expanded} square sx={{borderRadius: 2, width: '100%'}}>
                <AccordionSummary
                    expandIcon={ /* item.status == 'Reprovado' 
                        ?  */
                        <ExpandMoreIcon
                            onClick={toggleAccordion}
                        /> 
                       /*  : 
                        null */
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        px: 3,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',

                            '&.Mui-expanded': {
                               margin: '12px 0',
                            }
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            }
                        }
                    }}
                >

                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '100%', flexShrink: 0, px: 1}}>
                        <Typography
                            variant={"h4"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            {itemName}
                        </Typography>
                        
                    </Div>
                    
                </AccordionSummary>
                
                <AccordionDetails sx={{borderTop: 1, borderColor: 'divider', /* p: theme => theme.spacing(2, 7.5, 2, 7.5) */}}>
                   {/*  <Typography variant={"h5"}>Motivo de reprovação</Typography> */}
                    <Typography variant={"body1"} color={"text.secondary"}sx={{overflowWrap: 'break-word'}}>
                        {itemType}
                    </Typography>
                </AccordionDetails>
            
            </Accordion>
        </Card>
    );
};
/* Todo item prop define */
export default LogModalItem;
