import React, { useEffect } from 'react';
import {Card, CardContent, Checkbox, FormControlLabel, IconButton, InputAdornment, Typography} from "@mui/material";
/* import Link from "@mui/material/Link"; */
import Stack from "@mui/material/Stack";
import {Facebook, Google, Twitter, Visibility, VisibilityOff} from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import {alpha} from "@mui/material/styles";
import {ASSET_LOGOS} from "../../utils/constants/paths";
import {getAssetPath} from "../../utils/appHelpers";
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import authServices from "../../servicesTwo/auth-services";
import {Link, useNavigate} from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import InputMask from 'react-input-mask';
import { useState } from 'react';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";


function formatCPF(cpf) {
    const regex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
    //console.log(cpf.replace(regex, "$1.$2.$3-$4"))
    return cpf.replace(regex, "$1.$2.$3-$4");
}

const validationSchema = yup.object({
    senha: yup
        .string()
        .required('Preenchimento obrigatório'),

    emailOrCpf: yup.string()
    .required('Campo obrigatório')
    .test('valid-email-or-cpf', 'Email ou CPF inválido', (value) => {
        if (!value) return false;
        // Regex para verificar se é um email válido
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;
        // Regex para verificar se é um CPF válido
        const cpfRegex = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;
        if (cpfRegex.test(value)) {
            // Se for um CPF válido, retorna o CPF formatado
            return formatCPF(value);
          }
          return emailRegex.test(value);
    }),
});


const Login = ({disableSmLogin}) => {
    const {setAuthToken} = useJumboAuth();

    const navigate = useNavigate();

    const Swal = useSwalWrapper();

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

    const toast = (variant, message, type = false) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });
      
    };

    const onSignIn = async (emailOrCpf, senha, setSubmitting) => {
        
        if (/\S+@\S+\.\S+/.test(emailOrCpf)) {
           let email = emailOrCpf;
           await authServices.signIn({email, senha})
           .then((data) => {
               console.log(data)
               setAuthToken(data?.token, data?.id, data.nome, data.email, data.imagem_perfil, data?.permissions);
               navigate("/");
           })
           .catch((err) => {
                toast('error', err.response.data.error);
           })
          } else {
            let cpf = emailOrCpf;
            await authServices.signIn({cpf, senha})
            .then((data) => {
                console.log(data)
                setAuthToken(data?.token, data?.id, data.nome, data.email, data.imagem_perfil, data?.permissions);
                navigate("/");
            })
            .catch((err) => {
                if(err.response.data.error) {
                    alert(err.response.data.error);
                } else {
                    alert(err.response.data);
                }
            })
        }
        setSubmitting(false)
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPasswordConfirmation = () => setShowPasswordConfirmation((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseDownPasswordConfirmation = (event) => {
        event.preventDefault();
    };

    return (
        <Div sx={{
            width: 750,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: {xs: 'column', md: 'row'}
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        /* background: `#0267a0 url(/images/logos/logo.png) no-repeat center`, */
                        backgroundImage: 'url(/images/logos/logo.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            /* backgroundColor: alpha('#0267a0', .65) */
                        }
                    }}
                >
                </CardContent>
                <CardContent sx={{flex: 1, p: 4}}
                >
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            emailOrCpf: '',
                            senha: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => {
                            setSubmitting(true);
                            onSignIn(data.emailOrCpf, data.senha, setSubmitting);
                        }}
                    >
                        {({isSubmitting, handleChange, values, setFieldTouched, setFieldValue}) => (
                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                                <Div sx={{mt: 1, mb: 3}}>
                                        <JumboTextField
                                            fullWidth
                                            name="emailOrCpf"
                                            label="Email ou CPF"
                                            value={values.emailOrCpf}
                                            onChange={(event) => {
                                                const {name, value} = event.target;
                                                setFieldValue(name, formatCPF(value));
                                            }}
                                            onBlur={() => setFieldTouched('emailOrCpf', true)} 
                                        />
                                </Div>
                                <Div sx={{mt: 1, mb: 2}}>
                                <JumboTextField
                                    fullWidth
                                    id="senha"
                                    type={showPassword ? 'text' : 'password'}
                                    name="senha"
                                    value={values.senha}
                                    InputProps={{ 
                                        endAdornment: 
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                >
                                        {showPassword ? <VisibilityOff /> : <Visibility/>}
                                        </IconButton>
                                        </InputAdornment>,
                                    }}
                                    sx={{ background: '#ffffff'}}
                                    label="Senha"
                                />
                                </Div>
                                <Div 
                                    sx={{
                                        mb: 2, display: 'flex', 
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                    <FormControlLabel control={<Checkbox/>} label="Lembrar"/>
                                    <Link to="/esqueci-senha">
                                        Esqueceu a senha? Clique aqui
                                    </Link>
                                </Div>
                                <LoadingButton
                                    color="info"
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    loading={isSubmitting}
                                >
                                    Acessar
                                </LoadingButton>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </Div>
    );
};

export default Login;
