import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, Paper, Stack,Switch,Typography,useMediaQuery} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { updateInputValue, disableInputValue } from "../../../utils/appHelpers";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import TextField from '@mui/material/TextField';
import JumboSearch from "@jumbo/components/JumboSearch";
import ProfileUserList from "./ProfileUserList";
import ListRow from "../../../components/ListRow";
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IndeterminateCheckBox } from '@mui/icons-material';
import ListRowProfile from '../../../components/ListRowProfile/ListRowProfile';
import { PermissionContext } from 'app/contexts/PermissionContext';


/* const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
}; */

const ProfileForm = () => {

    const {theme} = useJumboTheme();
    const {showDialog, hideDialog} = useJumboDialog();

    const navigate = useNavigate();
    let { state, pathname } = useLocation();

    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });

        //  Redireciona se mensagem de retorno for de sucesso
        if(!id) {
            if(variant == 'success'){
                setTimeout(function() {
                    navigate("/app/listar-perfis-de-acesso")
                }, 2000)
            }
        }
    };

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);
    
    const initialValues = {
        nome: "",
        
    }

    const validationSchema = yup.object().shape({
        nome: yup
            .string()
            .required('Preenchimento obrigatório'),
    }, []);

    const { id } = useParams()
    const [profiles, setProfiles] = useState(initialValues);
    const [screens, setScreens] = useState([]);
    const [profilesScreens, setProfilesScreens] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null)
    const [rowId, setRowId] = useState([{}]);
    const [addProfiles, setAddProfiles] = useState(null);
    const [profileUser, setProfileUser] = useState([]);
    const [isInputFocused, setIsInputFocused] = useState(false);
   
    const { hasPermission } = useContext(PermissionContext);

    const canCreate = hasPermission('Perfis', 'criar');
    const canEdit = hasPermission('Perfis', 'editar');
    const isDisabled = id ? !canEdit : !canCreate

    const handleInputChange = (e, states, tela_id) => {
 
       const {name, checked} = e.target;
        
       const list = [...states]
       
       const el = list.map((item, index) => {
            if(item.tela_id == tela_id){ 
                item.tela_id = tela_id;
                
                list[index][name] = checked
       
                return item
            } else {
                return item
            
            }
        })

        setRowId(el)
    }


    useEffect(() => {
        if(searchTerm == undefined || searchTerm == ''){
            setAddProfiles(null);
            return;
        } 

        const delayDebounceFn = setTimeout(() => {
          searchStudent(searchTerm)
        }, 1500)

        
        return () => clearTimeout(delayDebounceFn)
        }, [searchTerm])

        const searchStudent = useCallback(async (e) => {
        try {
             ApiService.get(`/usuarios-sem-perfil/${id}`, {params: {search: e}})
            .then((response) => {
                setAddProfiles(response.data)
                // toast('success', 'Aluno adicionado com sucesso');
            })
            .catch((error) => {
                // toast('error', 'Ocorreu um erro');

              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);


    const getScreens = useCallback(async () => {
        try {
            ApiService.get(`/telas`)
            .then((response) => {
              setScreens(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);


    const updateProfileList = useCallback(async (data) => {
        setProfileUser([...data]);
        setAddProfiles(null);
    }, []);
   
    const getProfileById = useCallback(async () => {
        try {
            ApiService.get(`/perfil/${id}`)
            .then((response) => {
                console.log(response.data);
                setProfiles(response.data.perfil);
              if(response.data.userPerfil.length > 0){
                setProfilesScreens(response.data.profileScreenMarked)
            }

            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    useEffect(() => {
        if(id) {
            getProfileById();
        };
        getScreens();
    }, []);

    useEffect(() => {
        Swal.close();
    }, [pathname]);
    
    const handleSubmit = async (values, { setSubmitting }) => {

        if(id) {
            ApiService.put(`/perfis-telas/${id}`, { perfil_nome: values.nome, values: rowId })
            .then((response) => {
                toast('success', 'Perfil atualizado com sucesso')
                })
                .catch((error) => {
                toast('error', 'Ocorreu um erro')
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    console.log(error.request);
                    } else {
                    console.log('error', error.message);
                    }
                })
        }else {
            await ApiService.post('/perfis-telas', { perfil_nome: values.nome, values: rowId })
            .then((response) => {
            toast('success', 'Criado com sucesso')
            })
            .catch((error) => {
            toast('error', 'Ocorreu um erro')
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('error', error.message);
                }
            })
        }


        setSubmitting(false);
    } 

    const handleInputFocus = () => {
        setIsInputFocused(true);
      }
    
    const handleInputBlur = () => {
        setIsInputFocused(false);
    }
    
  return (
    <JumboContentLayout
        header={
            <HeaderBreadcrumbs
                id={id}
                title={"Perfis"}
                subtitle={id ? "Gerenciar Perfis" : "Cadastro de Perfis"}
                titleUrl={"/app/listar-perfis-de-acesso"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px'}}>
            {state &&
                <Button
                    color="success"
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{maxWidth: {md: '200px', mt: '1rem'}, mb: '2rem'}}
                    onClick={() => navigate(state.backUrl)}
                >
                    Voltar
                </Button>
            }
                
            <Formik
                initialValues={profiles}
                validationSchema={validationSchema}
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleSubmit}
            >
                {({values, isSubmitting, handleChange}) => (
                    <Form style={{width: '100%'}} noValidate autoComplete='off'>
                        <Grid container spacing={3} alignContent={'center'}>
                            <Grid item xs={6} >
                                <JumboTextField
                                    fullWidth
                                    name="nome"
                                    label="Nome"
                                    value={updateInputValue(profiles, values, 'nome')}
                                    disabled={!hasPermission('Perfis', 'editar')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                            
                            <Grid item xs={12}>

                                <TableContainer >
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table"
                                
                                    >
                                        <TableHead>
                                        <TableRow>
                                            <TableCell component="th">Nome</TableCell>
                                            <TableCell component="th">Visualizar</TableCell>
                                            <TableCell component="th">Criar</TableCell>
                                            <TableCell component="th">Editar</TableCell>
                                            <TableCell component="th">Deletar</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                    
                                        {
                                            id && profilesScreens.length > 0
                                            ?
                                            (
                                                profilesScreens.map((item, index) => (
                                                <TableRow key={item.tela_id}>
                                                    <TableCell>
                                                        {item.nome}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Checkbox
                                                            name="acessar"
                                                            checked={item.acessar}
                                                            onClick={(e) => handleInputChange(e, profilesScreens, item.tela_id)}
                                                            disabled={isDisabled}
                                                        />
                                                    </TableCell>

                                                    <TableCell>
                                                        <Checkbox
                                                            name="criar"  
                                                            checked={ item.criar }          
                                                            onClick={(e) => handleInputChange(e, profilesScreens, item.tela_id)}
                                                            disabled={isDisabled}
                                                        />
                                                    </TableCell>

                                                    <TableCell>
                                                        <Checkbox
                                                            name="editar"
                                                            checked={ item.editar}
                                                            onClick={(e) => handleInputChange(e, profilesScreens, item.tela_id)}
                                                            disabled={isDisabled}
                                                        />
                                                    </TableCell>

                                                    <TableCell>
                                                        <Checkbox
                                                            name="deletar"
                                                            checked={ item.deletar}
                                                            onClick={(e) => handleInputChange(e, profilesScreens, item.tela_id)}
                                                            disabled={isDisabled}
                                                        />
                                                    </TableCell>

                                                </TableRow>  
                                                ))
                                            )
                                            :
                                            (

                                                screens.map((screen, index) => (
                                                    <>
                                                        <TableRow
                                                            key={screen.tela_id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            id={screen.tela_id}
                                                        >
                                                            <TableCell>
                                                                {screen.nome}
                                                            </TableCell>
                                                                                                    
                                                            <>
                                                                <TableCell>
                                                                    <Checkbox
                                                                        name="acessar"
                                                                        onClick={(e) => handleInputChange(e, screens, screen.tela_id)}
                                                                        disabled={isDisabled}
                                                                    />
                                                                </TableCell>

                                                                <TableCell>
                                                                    <Checkbox
                                                                        name="criar"  
                                                                        onClick={(e) => handleInputChange(e, screens, screen.tela_id)}
                                                                        disabled={isDisabled}
                                                                    />
                                                                </TableCell>

                                                                <TableCell>
                                                                    <Checkbox
                                                                        name="editar"
                                                                        onClick={(e) => handleInputChange(e, screens, screen.tela_id)}
                                                                        disabled={isDisabled}
                                                                    />
                                                                </TableCell>

                                                                <TableCell>
                                                                    <Checkbox
                                                                        name="deletar"
                                                                        onClick={(e) => handleInputChange(e, screens, screen.tela_id)}
                                                                        disabled={isDisabled}
                                                                    />
                                                                </TableCell>

                                                            </>
                                                                                                            
                                                        </TableRow>
                                                    
                                                    </>
                                                ))
                                            )
                                        } 
    
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                
                            </Grid>


                            {!id ? (
                                <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                    <LoadingButton
                                        color="success"
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                        loading={isSubmitting}
                                        disabled={isDisabled}
                                    >
                                        Salvar
                                    </LoadingButton>
                                </Grid>
                            ):(
                                <>
                                    <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                    <LoadingButton
                                        color="success"
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                        loading={isSubmitting}
                                        disabled={isDisabled || isInputFocused}
                                    >
                                       {id ? 'Atualizar' : ''}
                                    </LoadingButton>
                                </Grid>
                                    <Grid item xs={12}>

                                        <Typography variant={"h4"} mb={1}>Adicionar usuário</Typography>

                                        <Grid item xs={1} sx={{textAlign: 'center'}}> 
                                            <JumboSearch
                                                onChange={(e) => setSearchTerm(e)}
                                                placeholder="Busque por Nome, CPF ou E-mail."
                                                sx={{
                                                    width: '440px',
                                                    marginBottom: '1rem'
                                                }}
                                                onFocus={handleInputFocus} 
                                                onBlur={handleInputBlur}
                                            />
                                        </Grid>

                                        {
                                            (addProfiles != null && addProfiles.length > 0) && <ListRowProfile data={addProfiles} updateProfileList={(data) => updateProfileList(data)} profileUser={profileUser}/>
                                        }
                                        {
                                            (addProfiles != null && addProfiles.length == 0) && <Typography>Sem resultados correspondentes</Typography>
                                        }

                                        <Typography variant={"h4"} mb={1} sx={{ marginTop: '2rem' }}>Lista de usuarios com este perfil</Typography>

                                        
                                        <ProfileUserList 
                                            setProfileUser={setProfileUser} 
                                            profileUser={profileUser} 
                                            profiles={profiles}
                                            handleInputFocus={handleInputFocus}
                                            handleInputBlur={handleInputBlur}
                                        />
                                            

                                    </Grid>
                                </>

                            )}
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Paper>
    </JumboContentLayout>
  )
}

export default ProfileForm






/* tenho esse array de objetos screens e tenho esse outro profilesScreens

como posso implementar uma logica para fazer o map em screens e marcar como checked  as permissoes que estao vindo de profilesScreens

profilesScreens= [
    {
    acessar: false,
    criar: false,
    deletar: false,
    editar: false,
    perfil_id: 48,
    perfil_tela_id: 409,
    tela: {nome: 'Pessoas'},
    telas_id: 1,

    },
    {
    acessar: false,
    criar: false,
    deletar: false,
    editar: false,
    perfil_id: 48,
    perfil_tela_id: 409,
    tela: {nome: 'Áreas'},
    telas_id: 2,

    }
]

screens= [
    {
    nome: 'Áreas',
    tela_id: 1
    },
    {
    nome: 'Pessoas',
    tela_id: 1
    },
           
] */
