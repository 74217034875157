import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  useContext,
} from 'react';
import {
  Box,
  Button,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { PermissionContext } from 'app/contexts/PermissionContext';
import makeAnimated from 'react-select/animated';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Select from 'react-select';
import FileListSlim from 'app/components/FileUploadSlim/FileListSlim';
import Div from '@jumbo/shared/Div';
import ModalCourseHistory from '../course_histories/ModalCourseHistory';
import { useLocation } from 'react-router-dom';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import CompatibleCoursesList from 'app/components/CompatibleCoursesList/CompatibleCoursesList';
import SpiritualCenterForUserList from 'app/components/SpiritualCenterForUserList/SpiritualCenterForUserList';
import CompatibleCoursesInfosForEdit from 'app/components/CompatibleCoursesInfosForEdit/CompatibleCoursesInfosForEdit';

const CoursesFromOtherEntitiesAuthorizeLaunch = ({
  username,
  userId,
  handleClose,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'transparent' : 'white',
      border: state.isFocused
        ? '1px solid #7352C7'
        : '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      padding: '8px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'black',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#7352C7' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: 'lightblue',
        color: 'white',
      },
    }),

    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  const animatedComponents = makeAnimated();
  let { pathname } = useLocation();

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      title: message,
      icon: variant,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      showCloseButton: true,
    });
  };

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const [courses, setCourses] = useState([]);

  const [userCourses, setUserCourses] = useState({
    curso_id: '',
    local: '',
    ano_de_conclusao: '',
    curso_compativel_id: '',
    observacao: '',
  });
  const [userCoursesCompatible, setUserCoursesCombatible] = useState(null);
  const [userCoursesFromOtherEntities, setUserCoursesFromOtherEntities] =
    useState({
      ano_de_conclusao: '',
      curso_compativel_id: '',
      observacao: '',
      centro_espirita_id: '',
      analise: '',
      selected: [],
    });

  const [files, setFiles] = useState([]);
  const [filesSelected, setFilesSelected] = useState([]);
  const [isEditingFile, setIsEditingFile] = useState(false);
  const [textFile, setTextFile] = useState(null);
  const [coursesCompatible, setCoursesCompatible] = useState([]);
  const [spiritualCenter, setSpiritualCenter] = useState([]);
  const [open, setOpen] = useState(false);
  const [spiritualCenterSelected, setSpiritualCenterSelected] = useState('');
  const [spiritualCenterNew, setSpiritualCenterNew] = useState(false);
  const [analise, setAnalise] = useState('');

  const handleCloseHistory = () => setOpen(false);

  const { hasPermission } = useContext(PermissionContext);

  const modalAlertDelete = (id, spiritualCenterSelectedId) => {
    Swal.fire({
      title: 'Tem certeza que deseja apagar?',
      text: 'Não será póssível reverter a ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteHistory(id, spiritualCenterSelectedId);
      }
    });
  };

  const modalAlertDeleteSpiritualCenter = (id) => {
    console.log(id);
    Swal.fire({
      title: 'Tem certeza que deseja apagar?',
      text: 'Não será póssível reverter a ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteSpiritualCenter(id);
      }
    });
  };

  const deleteSpiritualCenter = useCallback(async (spiritualCenterId) => {
    try {
      ApiService.delete(
        `/historico-cursos/user/${userId}?centroId=${spiritualCenterId}`
      )
        .then((response) => {
          toast('success', 'Removido com sucesso');
          getCourseHistoryFromSpiritualCenter();
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
      toast('error', 'Ocorreu um erro');
    }
  }, []);

  const deleteHistory = useCallback(async (id, spiritualCenterSelectedId) => {
    try {
      ApiService.delete(`/historico-cursos/${id}`)
        .then((response) => {
          toast('success', 'Removido com sucesso');
          getCourseHistoryFromSpiritualCenter(spiritualCenterSelectedId);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
      toast('error', 'Ocorreu um erro');
    }
  }, []);

  const optionsAllCourses = courses
    .filter((course) => course.tipo_curso === 'regular')
    .map((course) => {
      return {
        value: course.curso_id || course.value,
        label: course.nome || course.label,
      };
    });

  const uploadHandler = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    setFiles([...files, file]);
    setFilesSelected([...filesSelected, file]);
  };

  const removeFile = (index) => {
    setFiles(files.filter((file, i) => i !== index));
  };

  const handleSaveClick = (index) => {
    setIsEditingFile(false);
    setFiles(files.map((file, i) => {
      if (i === index) {
        const updatedFile = new File([file], textFile, { type: file.type });
        updatedFile.nome_label = textFile;
        return updatedFile;
      }
      return file;
    }));
    console.log('Texto editado:', textFile);
    setTextFile('');
  };

  const handleEditClick = (index) => {
    setIsEditingFile({ active: true, index: index });
    setTextFile(
      files
        .filter(
          (file, i) =>
            i === index &&
            (file.nome_label || file.nome || file instanceof File)
        )
        .map((file) => {
          if (file instanceof File && file.nome_label) {
            return file.nome_label;
          }
          return file.nome_label || file.nome || file.name;
        })
    );
  };

  const handleInputChangeFile = (e) => {
    setTextFile(e.target.value);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    let formData = new FormData();
    filesSelected.forEach((file, index) => {
      if (file instanceof File) {
        formData.append('docs', file);
        formData.append(`nome_label_${index}`, file.nome_label);
      }
    });

    const pessoa_que_autorizou_id = localStorage.getItem('storedUserId');
    const userCoursesFromOtherEntitiesWithSpiritualCenterId = {
      ...userCoursesFromOtherEntities,
      centro_espirita_id: spiritualCenterSelected,
    };
    try {
      ApiService.post(
        `/lancamento-curso-lote/aluno/${userId}/autorizou/${pessoa_que_autorizou_id}`,
        {
          userCourses,
          userCoursesCompatible, // alterar no backend
          userCoursesFromOtherEntities: spiritualCenterSelected
            ? userCoursesFromOtherEntitiesWithSpiritualCenterId
            : userCoursesFromOtherEntities,
          pessoa_que_autorizou_id,
        }
      )
        .then((response) => {
          const IdsCourseHistory = response.data?.IdsCourseHistory;
          console.log('IdsCourseHistory', IdsCourseHistory);

          toast('success', 'Adicionado com sucesso');

          if (files.length > 0) {
            formData.append(
              'newCoursesArray',
              JSON.stringify(IdsCourseHistory)
            );
            formData.append('pessoa_id', userId); // Adiciona o pessoa_id
            formData.append(
              'centro_espirita_id',
              spiritualCenterSelected
                ? userCoursesFromOtherEntitiesWithSpiritualCenterId
                : userCoursesFromOtherEntities.centro_espirita_id
            );

            ApiService.put(`/lancamento-curso-lote/atualizar-docs`, formData)
              .then(() => {
                setUserCoursesCombatible({
                  selected: null,
                  observacao: '',
                });
                setUserCoursesFromOtherEntities({
                  ano_de_conclusao: '',
                  curso_compativel_id: '',
                  observacao: '',
                  centro_espirita_id: '',
                  analise: '',
                  selected: [],
                });
                setFiles([]);
                setFilesSelected([]);
                setTextFile(null);
                getCourseHistoryFromSpiritualCenter();
              })
              .catch((error) => {
                toast('error', 'Ocorreu um erro ao atualizar imagem', true);

                if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
              });
          } else {
            setUserCoursesCombatible({
              selected: null,
              observacao: '',
            });

            setUserCoursesFromOtherEntities({
              ano_de_conclusao: '',
              curso_compativel_id: '',
              observacao: '',
              centro_espirita_id: userCoursesFromOtherEntities.centro_espirita_id,
              analise: '',
              selected: [],
            });
            setAnalise('')
            getCourseHistoryFromSpiritualCenter();
            
          }
        })
        .catch((error) => {
          if (error.response) {
            toast('error', error.response.data.error);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
      toast('error', 'Ocorreu um erro');
    }
  };

  const getCourse = useCallback(async () => {
    try {
      ApiService.get(`/cursos`)
        .then((response) => {
          setCourses(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getSpiritualCenter = useCallback(async () => {
    try {
      ApiService.get(`/centro-espirita`)
        .then((response) => {
          setSpiritualCenter(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getCourseHistoryFromSpiritualCenter = useCallback(
    async (spiritualCenterSelectedId = undefined) => {
      console.log({spiritualCenterSelectedId, userCoursesFromOtherEntities})
      try {
        ApiService.get(
          `/centro-espirita/historico-usuario/${userId}?centroId=${
            spiritualCenterSelectedId || spiritualCenterSelected || userCoursesFromOtherEntities.centro_espirita_id
          }`
        )
          .then((response) => {
            console.log(response.data);
            setCoursesCompatible(response.data.cursoHistoricoDoUsuario);
            if (spiritualCenterSelected || userCoursesFromOtherEntities.centro_espirita_id)
              setAnalise(
                response.data.cursoHistoricoDoUsuario[0]
                  ?.Analise_Centro_Espirita[0]?.analise
              );
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    [spiritualCenterSelected, userId, userCoursesFromOtherEntities]
  );

  useEffect(() => {
    getCourse();
    getSpiritualCenter();
    getCourseHistoryFromSpiritualCenter();
  }, [spiritualCenterSelected, userCoursesFromOtherEntities.centro_espirita_id]);

  useEffect(() => {
    Swal.close();
  }, [pathname]);

  const handleChange = (selectedOptions) => {
    setUserCoursesFromOtherEntities((prevState) => ({
      ...prevState,
      selected: selectedOptions || [], // Garante que selected seja um array vazio se nada for selecionado
    }));
  };

  return (
    <Paper sx={{ p: '40px', height: '550px' }}>
      <Box sx={{ height: '100%', overflowY: 'auto' }}>
        <Box
          display='flex'
          justifyContent='flex-end'
          alignItems='center'
          marginRight='1rem'
          gap={1}
        >
          {(spiritualCenterSelected || spiritualCenterNew) && (
            <Button
              size='small'
              variant='contained'
              color='success'
              onClick={() => {
                setSpiritualCenterSelected('');
                setSpiritualCenterNew(false);
                setAnalise('');
                setUserCoursesFromOtherEntities({
                  ano_de_conclusao: '',
                  curso_compativel_id: '',
                  observacao: '',
                  centro_espirita_id: '',
                  analise: '',
                  selected: [],
                })
                
              }}
            >
              Voltar
            </Button>
          )}
          <Button size='small' variant='contained' onClick={handleClose}>
            Fechar
          </Button>
        </Box>
        {spiritualCenterSelected || spiritualCenterNew ? (
          <Box>
            <Typography
              variant={'h1'}
              style={{ textAlign: 'center', marginBottom: 30 }}
            >
              Inclusão de Cursos Compatibilizados{' '}
              {/* limpar modal se inclusao for sucesso */}
            </Typography>
            <Fragment>
              <Formik
                initialValues={{}}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form style={{ width: '100%' }} noValidate autoComplete='off'>
                    <Grid container spacing={3} alignContent={'center'}>
                      <Grid item xs={12}>
                        <Box display={'flex'} gap={1} alignItems={'center'}>
                          <Typography variant='h4' fontWeight={'300'}>
                            Aluno selecionado:
                          </Typography>

                          <Typography
                            variant='h4'
                            textTransform={'capitalize'}
                            fontWeight={'600'}
                          >
                            {username}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth>
                          <InputLabel shrink id='Entidade de Origem'>
                            Entidade de Origem
                          </InputLabel>
                          <JumboSelectField
                            labelId='Entidade de Origem'
                            id='Entidade de Origem'
                            name='centro_espirita_id'
                            label='Entidade de Origem'
                            disabled={spiritualCenterSelected ? true : false}
                            value={
                              userCoursesFromOtherEntities.centro_espirita_id ||
                              spiritualCenterSelected
                            }
                            onChange={(e) =>
                              setUserCoursesFromOtherEntities({
                                ...userCoursesFromOtherEntities,
                                centro_espirita_id: e.target.value,
                              })
                            }
                            sx={{
                              background: '#ffffff',
                            }}
                            notched
                          >
                            <MenuItem value=''>
                              Selecione
                            </MenuItem>
                            {spiritualCenter.map((item) => (
                              <MenuItem
                                key={item.centro_espirita_id}
                                value={item.centro_espirita_id}
                              >
                                {item.nome_fantasia}
                              </MenuItem>
                            ))}
                          </JumboSelectField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={9}></Grid>
                      <Grid item xs={4}>
                        <div style={{ position: 'relative' }}>
                          <Select
                            name='selected'
                            placeholder='Selecione curso a ser compatibilizado'
                            /* isMulti */
                            options={optionsAllCourses}
                            formatGroupLabel={formatGroupLabel}
                            menuPortalTarget={document.body}
                            styles={customStyles}
                            value={userCoursesFromOtherEntities.selected}
                            onChange={handleChange}
                            components={animatedComponents}
                          />
                          <Typography
                            style={{
                              position: 'absolute',
                              top: '-9px',
                              left: '10px',
                              background: '#ffffff',
                              padding: '0 5px',
                              color: '#8595A6',
                              fontSize: '0.7rem',
                              fontFamily: 'NoirPro,Arial',
                            }}
                          >
                            Selecione curso a ser compatibilizado
                          </Typography>
                        </div>
                      </Grid>

                      <Grid item xs={2}>
                        <JumboTextField
                          fullWidth
                          label='Ano de Conclusão'
                          placeholder='Ano de Conclusão'
                          name='observacao'
                          value={userCoursesFromOtherEntities?.ano_de_conclusao}
                          onChange={(e) =>
                            setUserCoursesFromOtherEntities({
                              ...userCoursesFromOtherEntities,
                              ano_de_conclusao: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <JumboTextField
                          fullWidth
                          label='Observação'
                          placeholder='Observação'
                          name='observacao'
                          value={userCoursesFromOtherEntities?.observacao}
                          onChange={(e) =>
                            setUserCoursesFromOtherEntities({
                              ...userCoursesFromOtherEntities,
                              observacao: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3} sx={{ textAlign: 'center' }}>
                        <LoadingButton
                          color='success'
                          type='submit'
                          variant='contained'
                          size='large'
                          loading={isSubmitting}
                          disabled={!hasPermission('Turmas', 'editar')}
                        >
                          Adicionar Curso
                        </LoadingButton>
                      </Grid>

                      {(!spiritualCenterSelected && !userCoursesFromOtherEntities.centro_espirita_id) && (
                        <Grid item xs={12}>
                          <CompatibleCoursesList
                            coursesCompatible={coursesCompatible}
                          />
                        </Grid>
                      )}

                     {/*  {spiritualCenterNew && (
                        <Grid item xs={8}>
                          <JumboTextField
                            fullWidth
                            name='analise'
                            label='Análise realizada'
                            multiline
                            rows={4}
                            value={userCoursesFromOtherEntities?.analise}
                            onChange={(e) =>
                              setUserCoursesFromOtherEntities({
                                ...userCoursesFromOtherEntities,
                                analise: e.target.value,
                              })
                            }
                            sx={{ background: '#ffffff' }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      )} */}

                      {/* <Grid item xs={12}>
                        <Button
                          variant='contained'
                          component='label'
                          color='info'
                          sx={{
                            height: '53.13px',
                            fontSize: {
                              xs: '0.7rem',
                            },
                            textAlign: 'center',
                          }}
                        >
                          Adicionar declarações do Centro Espírita
                          <input
                            type='file'
                            hidden
                            name='imagem_area'
                            onChange={uploadHandler}
                          />
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <FileListSlim
                          files={files}
                          removeFile={removeFile}
                          isEditingFile={isEditingFile}
                          textFile={textFile}
                          handleInputChangeFile={handleInputChangeFile}
                          handleSaveClick={handleSaveClick}
                          handleEditClick={handleEditClick}
                          setIsEditingFile={setIsEditingFile}
                        />
                      </Grid> */}
                      
                      {(spiritualCenterSelected || userCoursesFromOtherEntities.centro_espirita_id) && (
                        <Grid item xs={12}>
                          <Box
                            display='flex'
                            flexDirection='column'
                            gap={1}
                            marginTop='2rem'
                            marginBottom='1rem'
                          >
                            <Typography
                              variant={'h4'}
                              margin={0}
                              textAlign={'center'}
                              fontWeight={'bold'}
                            >
                              Cursos Compatibilizados
                            </Typography>
                            <CompatibleCoursesInfosForEdit
                              coursesCompatible={coursesCompatible}
                              showButton={true}
                              modalAlertDelete={modalAlertDelete}
                              userId={userId}
                              spiritualCenterSelectedId={
                                spiritualCenterSelected
                              }
                              getCourseHistoryFromSpiritualCenter={
                                getCourseHistoryFromSpiritualCenter
                              }
                              analise={analise}
                              setAnalise={setAnalise}
                              spiritualCenterSelectedNewId={userCoursesFromOtherEntities.centro_espirita_id}
                            />
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Fragment>
          </Box>
        ) : (
          <Box>
            <Typography
              variant={'h1'}
              style={{ textAlign: 'center', marginBottom: 30 }}
            >
              Manutenção de Cursos Compatibilizados{' '}
              {/* limpar modal se inclusao for sucesso */}
            </Typography>
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <Typography variant='h4' fontWeight={'300'}>
                Aluno selecionado:
              </Typography>

              <Typography
                variant='h4'
                textTransform={'capitalize'}
                fontWeight={'600'}
              >
                {username}
              </Typography>
            </Box>
            <Typography
              variant={'h1'}
              style={{ textAlign: 'center', marginBottom: 30 }}
            >
              Entidades onde o aluno estudou (exceto FEESP)
            </Typography>
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              marginBottom={3}
            >
              <Button
                variant='contained'
                color='success'
                onClick={() => setSpiritualCenterNew(true)}
              >
                Nova Inclusão
              </Button>
            </Box>
            <SpiritualCenterForUserList
              coursesCompatible={coursesCompatible}
              showSelectedButton={true}
              showRemoveButton={true}
              modalAlertDeleteSpiritualCenter={modalAlertDeleteSpiritualCenter}
              spiritualCenterSelectedId={spiritualCenterSelected}
              setSpiritualCenterSelected={setSpiritualCenterSelected}
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default CoursesFromOtherEntitiesAuthorizeLaunch;
