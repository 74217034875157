import React, { useState, useCallback, useEffect, Fragment } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { Grid, Link, Paper, Stack, useMediaQuery, Typography, Button} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import { ApiService } from 'app/servicesTwo/ApiService';
import ClassStudentsListItem from "./ClassStudentsListItem";
import JumboSearch from "@jumbo/components/JumboSearch";
import { useParams } from "react-router-dom";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

const validationSchema = yup.object({
    email: yup
        .string('Insira seu email')
        .email('Insira um email válido'),
    senha: yup
        .string('Insira sua senha'),
    nome: yup
        .string('Insira seu nome')
        .required('Preenchimento obrigatório'),
    cpf: yup
        .string(),
    rg: yup
        .string()
        .max(11, 'Deve ter no máximo 11 digitos'),
    data_nascimento: yup
        .date()
        .max(new Date(), 'Não é possivel incluir uma data futura')
        .required('Preenchimento obrigatório'),
    celular: yup
        .string()
});

const ClassStudentsList = ({classStudents, setClassStudents, courses, handleInputFocus, handleInputBlur, coursesInClasses, classes}) => {  
    const {theme} = useJumboTheme();
    const { id } = useParams()
    const animatedComponents = makeAnimated();

    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });
    };

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    // const [classStudents, setClassStudents] = useState([]);
    const [allClassStudents, setAllClassStudents] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null)
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [getCourse, setGetCourse] = useState([]);
    const [select1Value, setSelect1Value] = useState(null);

    useEffect(() => {
        console.log('searchTerm', searchTerm)
        if(searchTerm == undefined || searchTerm == ''){
            setClassStudents(allClassStudents);
            
            return;
        } 

        const delayDebounceFn = setTimeout(() => {
            searchClass(searchTerm)
        }, 1500)

        
        return () => clearTimeout(delayDebounceFn)
      }, [searchTerm])

    const getClassStudents = useCallback(async (searchData) => {
        try {
            ApiService.get(`/turmas/${id}/alunos`, {params: {searchData}})
            .then((response) => {
                console.log('response.data', response.data)
                const students = (response.data.Curso_Historico).map((x) => ({...x.User, status: x.status, curso_historico_id: x.curso_historico_id, observacao: x.observacao, motivo_reprovacao: x.motivo_reprovacao, ano_de_conclusao: x.ano_de_conclusao}));
                setAllClassStudents(students);
                setClassStudents(students);
                setGetCourse(response.data)
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const searchClass = useCallback(async (e) => {
    // const found = allClassStudents.find(element => (element.nome).search(e) >= 0);
    // // console.log('found', found)
    // if(found){
    //   setClassStudents([found]);
    // } else {
    //   setClassStudents([]);
    // }


    try {
            ApiService.get(`/turmas/${id}/alunos`, {params: {search: e}})
        .then((response) => {
            console.log('retorno', response.data)
            const students = (response.data.Curso_Historico).map((x) => ({...x.User, status: x.status, curso_historico_id: x.curso_historico_id}));
            setClassStudents(students);
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('error', error.message);
            }
        })
    } catch (err) {
        console.log(err);
    }
    }, [classStudents]);

    const searchData = {
        select1Value,
    };

    useEffect(() => {
        getClassStudents(searchData);
    }, []);

    const options = ["Ativo", "Aprovado", "Desistente", "Excluído", "Outro", "Reprovado"];

    const optionsStatus = options.map((result) => {
        return {
            value: result,
            label: result
        }
    })

    const handleSelect1Change = (selected) => {
        setSelect1Value(selected.length == 0 ? null : selected);
    };

    function handleSubmit() {
        getClassStudents(searchData);
    }
    
  return (
    <JumboContentLayout
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '10px'}}>
            <Grid container spacing={3}>
                <Grid item xs={6} sx={{textAlign: 'center'}}> 
                    <JumboSearch
                        onChange={(e) => setSearchTerm(e)}
                        placeholder="Buscar por Nome, Email, CPF ou Status"
                        sx={{
                            width: '440px',
                            marginBottom: '1rem'
                        }}
                        onFocus={handleInputFocus} 
                        onBlur={handleInputBlur}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select 
                        id="select-one"
                        isMulti 
                        options={optionsStatus}
                        components={animatedComponents}
                        value={select1Value}
                        onChange={handleSelect1Change}
                        placeholder="Selecione status"
                        styles={{
                            menu: (list) => ({
                            ...list,
                            zIndex: 3,
                            }),
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                        <Button
                        fullWidth 
                        variant={'contained'} 
                        onClick={handleSubmit}
                        >
                            Filtrar
                        </Button>
                </Grid>

            </Grid>
            <Fragment>
                {
                    classStudents.length > 0 && classStudents.sort((a, b) => a.nome?.localeCompare(b.nome, 'en')).map((student, key) => (
                        <ClassStudentsListItem item={student} setClassStudents={(data) => setClassStudents([...data])} classStudents={classStudents} key={key} courses={courses} getCourse={getCourse} coursesInClasses={coursesInClasses} classes={classes}/>
                    ))
                }
                {
                    classStudents && classStudents.length == 0 && <Typography>Sem resultados correspondentes</Typography>
                }
            </Fragment>
        </Paper>
    </JumboContentLayout>
  )
}

export default ClassStudentsList