import { Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import React from 'react';

const columns = [
  {
    name: 'Turma.ano',
    label: 'Ano',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          <Typography variant={'body1'}>{value ?? '-'}</Typography>
        </>
      ),
    },
  },
  {
    name: 'Turma.Curso.nome',
    label: 'Nome',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        width: '40%',
      }),
      setCellProps: () => ({
        width: '40%',
      }),
    },
  },
  {
    name: 'Turma.dia_da_semana',
    label: 'Dia da semana',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          <Typography variant={'body1'}>{value ?? '-'}</Typography>
        </>
      ),
    },
  },
  {
    name: 'Turma.horario',
    label: 'Horário',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          <Typography variant={'body1'}>{value ?? '-'}</Typography>
        </>
      ),
    },
  },
  {
    name: 'Turma.local',
    label: 'Local',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          <Typography variant={'body1'}>{value ?? '-'}</Typography>
        </>
      ),
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          <Typography variant={'body1'}>{value ?? '-'}</Typography>
        </>
      ),
    },
  },
  /* {
      name: 'nome_que_autorizou',
      label: 'Autorizado Por',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({
          align: 'center',
        }),
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Typography variant={'body1'}>{value ?? '-'}</Typography>
          </>
        ),
      },
    }, */
];

const options = {
  filterType: 'checkbox',
  selectableRows: 'none',
  search: false,
  download: false,
  print: false,
  sortFilterList: false,
  viewColumns: false,
  filter: false,
  expandableRows: false,
  expandableRowsHeader: false,
  enableNestedDataAccess: '.',
};

const components = {
  ExpandButton: function (props) {
    return <div style={{ width: '24px' }} />;
  },
};
export const CourseWithAllStatusList = ({ courseHistoryOnlyApproval }) => {
  return (
    <>
      {courseHistoryOnlyApproval.length > 0 ? (
        <MUIDataTable
          data={courseHistoryOnlyApproval}
          columns={columns}
          options={options}
          components={components}
        />
      ) : (
        <Typography>Nenhum histórico encontrado</Typography>
      )}
    </>
  );
};
