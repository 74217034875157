import React, { useState, useCallback, useEffect, Fragment } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import PageHeader from '../../../layouts/shared/headers/PageHeader/PageHeader';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboAvatarField from '@jumbo/components/JumboFormik/JumboAvatarField';
import { CheckBox, CollectionsBookmarkOutlined } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams } from 'react-router-dom';
import { updateInputValue, disableInputValue } from '../../../utils/appHelpers';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import ModalTransferClassItem from './ModalTransferClassItem';

const ModalTransferClass = ({
  data,
  courses,
  handleClose,
  classStudents,
  setClassStudents,
  getCourse,
  coursesInClasses,
  classes,
}) => {
  console.log(data);
  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });
  };

  console.log(courses);

  const { id } = useParams();
  const [courseHistory, setCourseHistory] = useState([]);

  const [class_id, setClass_id] = useState(null);

  const getCourseHistory = useCallback(async () => {
    try {
      ApiService.get(`/historico-cursos/${id}`)
        .then((response) => {
          setCourseHistory(response.data.turma);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  function removeById(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.pessoa_id === id);

    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }

    return arr;
  }

  useEffect(() => {
    if (id) {
      getCourseHistory();
    }
  }, []);

  const handleSubmit = (turma_id) => {
    console.log(courseHistory);
    const values = {
      turma_id,
      turma_id_anterior: id,
      curso_historico_id: data?.curso_historico_id,
      pessoa_id: data?.pessoa_id,
    };

    ApiService.put(`/historico-cursos`, { values })
      .then(() => {
        const newArr = removeById(classStudents, data.pessoa_id);
        setClassStudents(newArr);

        toast('success', 'Transferência realizada com sucesso');
        handleClose();
      })
      .catch((error) => {
        toast('error', error?.response?.data?.error || 'Ocorreu um erro');

        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('error', error.message);
        }
      });
  };

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '40px', height: '500px' }}>
        <Box
          display='flex'
          justifyContent='flex-end'
          alignItems='center'
          marginRight='1rem'
        >
          <Button size='small' variant='contained' onClick={handleClose}>
            Fechar
          </Button>
        </Box>
        <Box sx={{ height: '100%', overflowY: 'auto' }}>
          <Typography
            variant={'h1'}
            style={{ textAlign: 'center', marginBottom: 30 }}
          >
            Escolha a turma que o aluno gostaria de ser transferido
          </Typography>
          <Fragment>
            <Grid container spacing={3} alignContent={'center'}>
              <Grid item xs={12}>
                <Box display={'flex'} gap={1} alignItems={'center'}>
                  <Typography variant='h4' fontWeight={'300'}>
                    Aluno selecionado:
                  </Typography>

                  <Typography
                    variant='h4'
                    textTransform={'capitalize'}
                    fontWeight={'bold'}
                  >
                    {data?.nome}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id='curso_id'>Turma</InputLabel>
                  <JumboSelectField
                    labelId='curso_id'
                    id='curso_id'
                    name='curso_id'
                    value={getCourse?.Curso?.curso_id ?? ''}
                    label='Turma'
                    /* onChange={(e) => {
                                            handleChange(e);
                                            setFilters({'curso_id': e.target.value})
                                        }} */
                    disabled={true}
                  >
                    {/*  {courses.length > 0 && courses.map((x) => 
                                            <MenuItem value={x.curso_id}>{x.nome}</MenuItem>
                                        )} */}
                    <MenuItem value={getCourse?.Curso?.curso_id}>
                      {getCourse?.Curso?.nome}
                    </MenuItem>
                  </JumboSelectField>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <InputLabel id='ano'>Ano</InputLabel>
                  <JumboSelectField
                    labelId='ano'
                    id='ano'
                    name='ano'
                    value={getCourse?.ano ?? ''}
                    label='Ano'
                    disabled={true}
                    /* onChange={(e) => {
                                            handleChange(e);
                                            setFilters({
                                                'curso_id': filters.curso_id,
                                                'ano': e.target.value
                                            })
                                        }} */
                  >
                    {/* {Object.keys(yearOptions).map((x) => 
                                            <MenuItem value={x}>{x}</MenuItem>
                                        )} */}
                    {/* {Object.keys(yearOptions).forEach(function(key, index) {
                                            <MenuItem value={key.ano}>{key.ano}</MenuItem>
                                        })}    */}
                    {/* {yearOptions.length > 0 && yearOptions.map((x, key) => 
                                            <MenuItem value={x}>{x}</MenuItem>
                                        )} */}
                    <MenuItem value={getCourse?.ano}>{getCourse?.ano}</MenuItem>
                  </JumboSelectField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id='periodicidade'>Periodicidade</InputLabel>
                  <JumboSelectField
                    labelId='periodicidade'
                    id='periodicidade'
                    name='periodicidade'
                    value={getCourse?.periodicidade ?? ''}
                    label='Periodicidade'
                    disabled={true}
                    /* onChange={(e) => {
                                            handleChange(e);
                                            setFilters({
                                                'curso_id': filters.curso_id,
                                                'ano': filters.ano,
                                                'periodicidade': e.target.value
                                            })
                                        }} */
                  >
                    {/* {Object.keys(frequencyOptions).map((x) => 
                                            <MenuItem value={x}>{x}</MenuItem>
                                        )} */}
                    <MenuItem value={getCourse?.periodicidade}>
                      {getCourse?.periodicidade}
                    </MenuItem>
                  </JumboSelectField>
                </FormControl>
              </Grid>
            </Grid>
            <Box marginTop='1rem'>
              {coursesInClasses?.map((item) => (
                <ModalTransferClassItem
                  item={item}
                  getCourse={getCourse}
                  handleSubmit={handleSubmit}
                />
              ))}
            </Box>
          </Fragment>
        </Box>
      </Paper>
    </JumboContentLayout>
  );
};

export default ModalTransferClass;
