import React, { useContext } from 'react';
import {
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Grid,
    Chip,
    Tooltip,
    Zoom,
} from '@mui/material';
import Button from '@mui/material/Button';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { TextService } from 'app/servicesTwo/TextService';
import { LinkStyle } from 'app/pages/app/users/UserItem';
import { useLocation } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { EllipsisTooltip } from '../EllipsisTooltip/EllipsisTooltip';

const ListItemVolunteerInterested = ({ item, includeStudentToClass }) => {
    const location = useLocation();
    const { hasPermission } = useContext(PermissionContext);
    const maxLengthText = 20;

    return (
        <ListItem sx={{ p: (theme) => theme.spacing(1, 3) }}>
            <Grid container spacing={2} alignContent={'center'}>
                <Grid item lg={4} md={5} xs={4}>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Nome
                    </Typography>
                    <EllipsisTooltip text={item?.nome}/>
                </Grid>
                <Grid item lg={2} md={5} xs={3}>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        CPF
                    </Typography>
                    <EllipsisTooltip text={item?.cpf}/>
                </Grid>
                <Grid
                    item
                    lg={2}
                    sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }}
                >
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Email
                    </Typography>
                    {/* {item.email?.length < maxLengthText 
                        ? 
                        (
                            <Typography variant={"body1"}>
                                {item.email}
                            </Typography>
                        )
                        :
                        (
                            <Tooltip title={item?.email} arrow TransitionComponent={Zoom}>
                                <Typography variant={"body1"}>
                                    {item.email ? TextService.limitText(item.email, maxLengthText) : ''}
                                </Typography>
                            </Tooltip>
                        )
                    } */}
                    <EllipsisTooltip text={item?.email} />
                </Grid>
                {/* <Grid item lg={3} md={1} xs={1}>
                    <Typography
                        fontSize={"12px"}
                        variant={"h6"}
                        color={"text.secondary"}
                        mb={.25}
                    >
                        Curso de voluntariado?
                    </Typography>
                    {item.curso_de_voluntario 
                        ? <Chip label="Sim" color="success" size='small'/>
                        : <Chip label="Não" color="error" size='small'/>
                    }
                    
                </Grid> */}
                <Grid item lg={2} md={5} xs={3}>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Celular
                    </Typography>
                    <EllipsisTooltip text={item?.celular} />
                </Grid>
                <Grid item lg={1} md={1} xs={2}>
                    <Tooltip
                        title='Visualizar'
                        TransitionComponent={Zoom}
                        arrow
                    >
                        <LinkStyle
                            to={`/app/editar-aluno/${item.pessoa_id}`}
                            state={{
                                backUrl: location.pathname,
                                disable: true,
                            }}
                        >
                            <VisibilityIcon />
                        </LinkStyle>
                    </Tooltip>
                </Grid>
                <Grid item lg={1} md={1} xs={1}>
                    <Button
                        size={'small'}
                        variant={'contained'}
                        color={'success'}
                        disableElevation
                        onClick={() => includeStudentToClass(item)}
                        // {...(item.follow) ? {color: "inherit"} : {}}
                        disabled={!hasPermission('Voluntariado', 'editar')}
                        sx={{
                            minWidth: 78,
                            textTransform: 'none',
                            p: (theme) => theme.spacing(0.5, 1.5),
                        }}
                    >
                        Incluir
                    </Button>
                </Grid>
            </Grid>
        </ListItem>
    );
};
/* Todo item, includeStudentToClass prop define */
export default ListItemVolunteerInterested;
