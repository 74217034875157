import React from 'react'
import { Box, Paper, Typography} from '@mui/material'

const InfosAboutCreateAndUpdate = ({data, quem_criou, data_quem_criou, data_quem_atualizou, quem_atualizou, paperBg = '#ffffff'}) => {
    const moment = require('moment-timezone');
    const dateTimeUpdatedAt = data && data?.updatedAt;
    const dateTimeCreatedAt = data && data?.createdAt;

    let dateLastUpdateBr;
    let timeLastUpdateBr;

    let dateCreatedAtBr;
    let timeCreatedAtBr;

    if(dateTimeUpdatedAt) {
        dateLastUpdateBr = moment(dateTimeUpdatedAt).tz('America/Sao_Paulo').format('DD/MM/YYYY');
        timeLastUpdateBr = moment(dateTimeUpdatedAt).tz('America/Sao_Paulo').format('HH:mm:ss')
    }
    if(dateTimeCreatedAt) {
        dateCreatedAtBr = moment(dateTimeCreatedAt).tz('America/Sao_Paulo').format('DD/MM/YYYY');
        timeCreatedAtBr = moment(dateTimeCreatedAt).tz('America/Sao_Paulo').format('HH:mm:ss')
    }
    console.log(dateCreatedAtBr, timeCreatedAtBr)

    return (
        <Box display='flex' justifyContent='center' width='100%' gap='1rem' marginBottom='1rem'>
            <Paper elevation={1} sx={{p: '10px', background: paperBg}}>
                <Box textAlign='center'>
                    <Typography variant='h6'>
                        Quem criou?
                    </Typography>
                    <Typography variant='h6'>
                    {/*  {users?.quem_aceitou?.nome} */}
                    {quem_criou}
                    </Typography>
                </Box>
            </Paper>
            <Paper elevation={1} sx={{p: '10px', background: paperBg}}>
                <Box textAlign='center'>
                    <Typography variant='h6'>
                        Data de criação
                    </Typography>
                    <Typography variant='h6'>
                    {/*  {users?.quem_aceitou?.nome} */}
                    {dateCreatedAtBr}
                    </Typography>
                </Box>
            </Paper>
            <Paper elevation={1} sx={{p: '10px', background: paperBg}}>
                <Box textAlign='center'>
                    <Typography variant='h6'>
                        Quem atualizou? 
                    </Typography>
                    <Typography variant='h6'>
                        {/* {users?.quem_atualizou?.nome} */}
                        {quem_atualizou}
                    </Typography>
                </Box>
            </Paper>
            <Paper elevation={1} sx={{p: '10px', background: paperBg}}>
                <Box textAlign='center'>
                    <Typography variant='h6'>
                        Data da ultima atualização 
                    </Typography>
                    <Typography variant='h6'>
                        {dateLastUpdateBr}
                    </Typography>
                </Box>
            </Paper>
            {/* <Paper elevation={1} sx={{p: '10px', background: paperBg}}>
                <Box textAlign='center'>
                    <Typography variant='h6'>
                        Horário da ultima atualização
                    </Typography>
                    <Typography variant='h6'>
                        {timeLastUpdateBr}
                    </Typography>
                </Box>
            </Paper> */}
        </Box>
    )
}

export default InfosAboutCreateAndUpdate