import React, {useCallback, useState} from 'react';
// import {connections} from "./data";
import {List} from "@mui/material";
import ListItemUser from "./ListItemUser";
import { useParams } from "react-router-dom";
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const ListRowUser = ({ data , redirectToCreate}) => {
    const { id } = useParams()
    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });
    };

    const [itemsList, setItemsList] = useState(data);


    return (
            <List disablePadding>
                {
                    itemsList.map((item, index) => (
                        <ListItemUser item={item} key={index} redirectToCreate={redirectToCreate}/>
                    ))
                }
            </List>
    );
};

export default ListRowUser;
