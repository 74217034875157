import React, { useContext } from 'react';
import {Avatar, ListItem, ListItemAvatar, ListItemText, Typography, Grid, Tooltip, Zoom, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from "@mui/material";
import Button from "@mui/material/Button";
import { PermissionContext } from 'app/contexts/PermissionContext';
import { TextService } from 'app/servicesTwo/TextService';
import { useParams } from 'react-router-dom';

const ListRowItemCourseIntro = ({item, includeStudentToClass, infosAboutCourse, setInfosAboutCourse,    selectedClass, setSelectedClass, usersRegistration, itemsList}) => {
    const { hasPermission } = useContext(PermissionContext);
    const maxLengthText = 20;
    const { id } = useParams()
    console.log(itemsList.length < 1)
   const handleClassSelection = (item, turmasId) => {
        setSelectedClass((prevSelectedClass) => {
            const updatedClass = prevSelectedClass.find((entry) => entry.pessoa_id === item.pessoa_id);
            
            if (updatedClass) {
              updatedClass.turmas_id = turmasId;
             
              return [...prevSelectedClass];
            } else {
              return [...prevSelectedClass, {...item, pessoa_id: item.pessoa_id, turmas_id: turmasId }];
            }
        });
    };

    return (
        <ListItem sx={{p: theme => theme.spacing(1, 3)}}>
            <Grid container spacing={2} alignContent={'center'}>
                <Grid item lg={1} md={1} xs={2} >
                    <Avatar alt={item.nome} src={`${process.env.REACT_APP_API_KEY}/images/${item.imagem_perfil}`}/>
                </Grid>


                <Grid item lg={4} md={5} xs={4} >
                    <Typography
                        fontSize={"12px"}
                        variant={"h6"}
                        color={"text.secondary"}
                        mb={.25}
                    >
                        Nome
                    </Typography>
                    <Typography variant={"body1"}>{item.nome}</Typography>
                </Grid>
                <Grid item lg={4} md={5} xs={4}>
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                            Escolha horário
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            
                            value={
                                selectedClass.some((entry) => entry.pessoa_id === item.pessoa_id) 
                                ? selectedClass.find((entry) => entry.pessoa_id === item.pessoa_id).turmas_id 
                                : item.Curso_Introdutorio_Historico?.find((curso) => curso.curso_indrodutorio_id === Number(id))?.turmas_id 
                                || ''
                              }

                            onChange={(e) => handleClassSelection(item, e.target.value)}
                        >
                            {
                                infosAboutCourse.map((classe, index) => (
                                    
                                    <FormControlLabel 
                                        key={index}
                                        value={classe.turmas_id} 
                                        control={
                                            <Radio 
                                                size='small'
                                                onChange={(e) => handleClassSelection(item, e.target.value)}
                                            />
                                        } 
                                        label={`${classe.dia_da_semana} - ${classe.horario}`} 
                                    />
                                    
                                ))
                            }
                            
                        </RadioGroup>
                    </FormControl> 
                </Grid>
                
                {
                    itemsList.length === 1 
                    ?
                        <Grid item lg={1} md={1} xs={1} >
                            <Button
                                size={"small"}
                                variant={"contained"}
                                color={"success"}
                                disableElevation
                                onClick={() => includeStudentToClass(selectedClass)}
                                // {...(item.follow) ? {color: "inherit"} : {}}
                                disabled={!hasPermission('Turmas', 'editar')}
                                sx={{
                                    minWidth: 78,
                                    textTransform: 'none',
                                    p: theme => theme.spacing(.5, 1.5)
                                }}
                            >
                                Incluir
                            </Button>
                        </Grid>
                    :
                        null
                }

            </Grid>
            
            
        </ListItem>

    )
};
/* Todo item, includeStudentToClass prop define */
export default ListRowItemCourseIntro;
