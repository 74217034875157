import styled from '@emotion/styled'
import { Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';

const FileCardContainer = styled.div`
    .file-card {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 3px dashed #cbd5e0;
        background-color: #edf2f7;

        .file-inputs {
            position: relative;
            margin-bottom: 1.5em;

            input {
                position: relative;
                text-align: right;
                opacity: 0;
                z-index: 2;
                cursor: pointer;
                height: 35px;
                max-width: 180px;
            }

            button {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                color: ${props => props.status == false ? 'rgba(0, 0, 0, 0.26)' : '#fff'};
                background-color: ${props => props.status == false ? 'rgba(0, 0, 0, 0.12)' : '#f55e30'};
                
                font-size: 1rem;
                cursor: pointer;
                border-radius: 4px;
                border: none;
                outline: none;
                transition: background-color 0.4s;
                box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);

                svg {
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }

            &:hover {
                button {
                    background-color: ${props => props.status == false ? 'rgba(0, 0, 0, 0.12)' : '#f15120'};
                    ${props => props.status == false ? 'pointer-events: none;' : ''}
                }
            }
        }
    }

    .main {
        font-weight: bold;
        margin-bottom: 0.4rem;
    }

    .info {
        font-size: 0.8rem;
    }
`
const FileUpload = ({ files, setFiles, removeFile, uploadHandler, status }) => {
    
  return (
    <FileCardContainer status={status}>
        <div className="file-card">

            <div className="file-inputs">
                <input type="file" onChange={uploadHandler} disabled={status == false}/>
                <Button
                    disabled={status == false}
                >
                    Upload
                    <UploadIcon />
                </Button>
            </div>

            <p className="main">Arquivos suportados</p>
            <p className="info">PDF, JPG, PNG</p>

        </div>
    </FileCardContainer>
  )
}

export default FileUpload