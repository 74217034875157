import React, { useState, useCallback, useEffect, Fragment } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { Paper, Stack, useMediaQuery, Pagination, Typography, Grid, InputLabel, Button} from "@mui/material";
import { ApiService } from 'app/servicesTwo/ApiService';
import LogItem from "./LogItem";
import CircularProgress from '@mui/material/CircularProgress';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const LogList = () => {  
    const {theme} = useJumboTheme();

    const animatedComponents = makeAnimated();
    
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const [logs, setLogs] = useState(null)
    const [page, setPage] = useState(1);
    const [per_page, setPerPage] = useState(10);
    const [count, setCount] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [distinctsAction, setDistinctsAction] = useState([]);
    const [distinctsModel, setDistinctsModel] = useState([]);
    const [distinctsPersonId, setDistinctsPersonId] = useState([]);
    const [select1Value, setSelect1Value] = useState(null);
    const [select2Value, setSelect2Value] = useState(null);
    const [select3Value, setSelect3Value] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);

    const handleSelect1Change = (selected) => {
      setSelect1Value(selected.length == 0 ? null : selected);
    };

    const handleSelect2Change = (selected) => {
      setSelect2Value(selected.length == 0 ? null : selected);
    };

    const handleSelect3Change = (selected) => {
      setSelect3Value(selected.length == 0 ? null : selected);
    };

    const handleDatesChange = ({ startDate, endDate }) => {
      setStartDate(startDate);
      setEndDate(endDate);
    };

    const handleFocusChange = (focusedInput) => {
      setFocusedInput(focusedInput);
    };

    const searchData = {
      select1Value,
      select2Value,
      select3Value,
      startDate: startDate?.format('YYYY-MM-DD'),
      endDate: endDate?.format('YYYY-MM-DD'),
      pagesSearch: page,
      per_pages_search: per_page
    };

    const getLogs = useCallback(async (searchData) => {
      setLogs(null);
      setIsLoading(true);

        let params = { page, per_page };
        if (searchData) {
          searchData = { ...params, ...searchData };
        }

        try {
            ApiService.get('/log-de-atividades', {params: {searchData}})
            .then((response) => {
              console.log(response.data);
              setDistinctsAction(response.data.optionsAcao)
              setDistinctsModel(response.data.optionsModelo)
              setDistinctsPersonId(response.data.optionsPessoaId)
              setLogs(response.data.logs);
              setCount(response.data.count)
              setIsLoading(false);
            })
            .catch((error) => {
              setIsLoading(false);

              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          setIsLoading(false);
          console.log(err);
        }
    }, [page, searchData]); 

   
    useEffect(() => {
      getLogs(searchData);
    }, [page]);

    
    function handleSubmit() {
      // if (!select1Value?.length && !select2Value?.length && !select3Value?.length && !startDate && !endDate) {
      //   return;
      // }

      const searchData = {
        select1Value,
        select2Value,
        select3Value,
        startDate: startDate?.format('YYYY-MM-DD'),
        endDate: endDate?.format('YYYY-MM-DD'),
        pagesSearch: page,
        per_pages_search: per_page
      };

      console.log('searchData', searchData)

      searchLog(searchData);
    }

    const searchLog = useCallback(async (searchData) => {
      setIsLoading(true);
      console.log('pesquisar', searchData)
      if(searchData) {
          try {
              ApiService.get('/log-de-atividades', { params: {searchData}})
              .then((response) => {
                setLogs(response.data.logs);
                setCount(response.data.count);
              })
              .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('error', error.message);
                  }
              })
          } catch (err) {
            console.log(err);
          }
      }
      setIsLoading(false);
    }, []);

    const actions = {
      findMany: 'Listar dados',
      findFirst: 'Visualizar dado',
      update: 'Atualizar dado',
      deleteMany: 'Deletar dado',
      create: 'Criar dado',
      updateMany: 'Atualizar vários dados',
      queryRaw: 'Pesquisar dado',
      findUnique: 'Visualizar dado unico',
      upsert: 'Atualizar ou criar'
    };

    const getActionLabel = (action) => {
      return actions[action] || '';
    }

    const optionLabels = {};

    distinctsAction.forEach((option) => {
      optionLabels[option.value] = actions[option.value];
    });

    const sortedOptions = distinctsAction.sort((a, b) => {
      const labelA = optionLabels[a.value];
      const labelB = optionLabels[b.value];
      return labelA?.localeCompare(labelB);
    });

  return (
    <JumboContentLayout
        header={
            <PageHeader
                title={"Logs"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px', textAlign: 'center'}}>
            <form /* onSubmit={handleSubmit}  */style={{display: 'flex', alignItems: 'center', width: '100%'}}>
        <Grid container spacing={3} alignContent={'center'} sx={{mb: '2rem', justifyContent: 'center'}}>
                <Grid item xs={4}>
                    {/* <InputLabel sx={{textAlign: 'left'}} htmlFor="select-one">Selecione uma opção</InputLabel> */}
                    <Select 
                        id="select-one"
                        isMulti 
                        options={distinctsPersonId}
                        components={animatedComponents}
                        value={select1Value}
                        onChange={handleSelect1Change}
                        placeholder="Selecione um usuário"
                    />
                </Grid>
                <Grid item xs={4}>
                    {/* <InputLabel sx={{textAlign: 'left'}} htmlFor="select-two">Selecione uma opção</InputLabel> */}
                    <Select
                        id="select-two"
                        isMulti 
                        options={distinctsModel}
                        components={animatedComponents}
                        value={select2Value}
                        onChange={handleSelect2Change}
                        placeholder="Selecione uma área"
                    />
                </Grid>
                <Grid item xs={4}>
                {/*  <InputLabel sx={{textAlign: 'left'}} htmlFor="select-three">Selecione uma opção</InputLabel> */}
                    <Select
                        id="select-three"
                        isMulti 
                        options={sortedOptions}
                        components={animatedComponents}
                        value={select3Value}
                        onChange={handleSelect3Change}
                        placeholder="Selecione uma ação"
                        formatOptionLabel={({ label, value }) => (
                          <div>
                            {console.log(value)}
                            <span>{getActionLabel(label)}</span>
                          </div>
                        )}
                    />
                </Grid>
                <Grid item xs={5} >
                {/* <InputLabel sx={{textAlign: 'left'}} htmlFor="select-four">Selecione uma opção</InputLabel> */}
                  <DateRangePicker
                    startDate={startDate}
                    startDateId="start_date_id"
                    endDate={endDate}
                    endDateId="end_date_id"
                    onDatesChange={handleDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={handleFocusChange}
                    isOutsideRange={() => false}
                    startDatePlaceholderText="Data inicial"
                    endDatePlaceholderText="Data final"
                  />
                </Grid>
                <Grid item xs={3}>
                    <Button variant={'contained'} onClick={handleSubmit}>Filtrar</Button>
                </Grid>
        </Grid>
            </form>
        
                <Fragment>
                    {
                        logs && logs.map((user, key) => (
                            <LogItem item={user} key={key} getActionLabel={getActionLabel} distinctsModel={distinctsModel}/>
                        ))
                    }
                    {isLoading && <CircularProgress/>}
                    {(logs != null && logs.length == 0) && <Typography>Sem resultados correspondentes</Typography>}
                </Fragment>
          <Stack sx={{ mt: 2 }}>
              <Pagination
                  color="primary"
                  count={count}
                  page={page}
                  onChange={(event, value) => setPage(value)}
              />
          </Stack>
        </Paper>
        
    </JumboContentLayout> 
  )
}

export default LogList