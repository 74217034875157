import React, { useEffect } from 'react';
import Div from "@jumbo/shared/Div";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';

const Thanks = () => {
    const navigate = useNavigate();
    const isRegisterSuccess = localStorage.getItem('register-success');
    console.log('isRegisterSuccess', isRegisterSuccess == true)

    useEffect(() => {
    if (!isRegisterSuccess) {
      navigate('/cadastro-inicial'); 
      return;
    }

   
  }, [isRegisterSuccess, navigate]);

  setTimeout(() => {
    localStorage.removeItem('register-success');
  }, 30 * 1000); // 30s em milissegundos

  const exit = () => {
    localStorage.removeItem('register-success');
    window.location.href = 'http://www.feesp.org.br';
  }

    return (
        <>
            <Div sx={{
                flex: 1,
                flexWrap: 'wrap',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            
            }}>
                <Typography
                    variant={"h1"}
                    fontWeight="500"
                    sx={{fontSize: 70, textShadow: '1rem 0.6rem 1rem rgba(0, 0, 0, 0.35)', mb: 1}}
                >
                    Obrigado!
                </Typography>
                <Typography
                    component={"h2"}
                    variant={"h1"}
                    color={"text.secondary"}
                    mb={4}
                    textAlign={"center"}
                >
                    Seu cadastro foi realizado com sucesso.
                </Typography>

                <Typography
                    component={"h4"}
                    variant={"h3"}
                    color={"text.secondary"}
                    mb={4}
                    textAlign={"center"}
                >
                    Em breve, você poderá consultar suas informações pessoais, históricos de cursos e trabalhos realizados na FEESP!
                </Typography>
                <Button variant="contained" onClick={exit}>Sair</Button>
            </Div>
        </>
    );
};

export default Thanks;
