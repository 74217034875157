import React from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { Paper, Stack,Typography,useMediaQuery} from "@mui/material";
import LogModalItem from './LogModalItem';

const ModalLogs = ({ item, getDadosGravados }) => {
    const {theme} = useJumboTheme();
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);


    
  return (
    <JumboContentLayout
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px'}}>
            <Typography variant={"h1"} style={{ textAlign: 'center', marginBottom: 30 }}>Detalhes do log</Typography>
            <React.Fragment>
                {console.log(item.dados_antigo !== null)}
                {item.dados_antigo !== null 
                    ?
                        <LogModalItem itemName={'Dados enviados'} itemType={item.dados_antigo}/> 
                    : <Typography variant={'h6'}>Nenhum histórico de dados enviados encontrado</Typography>}
                {item.dados_gravados !== null 
                    ?
                    <LogModalItem itemName={'Resposta recebida'}  itemType={getDadosGravados(item)} /* itemType={item.dados_gravados} *//> 
                    : <Typography variant={'h6'}>Nenhum histórico de resposta recebida encontrado</Typography>}
            </React.Fragment>
        </Paper>
    </JumboContentLayout>
  )
}

export default ModalLogs