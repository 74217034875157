
import React from 'react'
import FileItem from './FileItem.js'

const FileList = ({ files, removeFile, isEditingFile, textFile, handleInputChangeFile, handleSaveClick, handleEditClick, setIsEditingFile, status}) => {
    return (
        <ul style={{padding: '0 2rem'}}>
            {
                files &&
                files.map((f, index) => (
                    <FileItem
                        key={f.nome}
                        file={f}
                        files={files}
                        index={index}
                        removeFile={removeFile}
                        isEditingFile={isEditingFile}
                        textFile={textFile}
                        handleInputChangeFile={handleInputChangeFile}
                        handleSaveClick={handleSaveClick}
                        handleEditClick={handleEditClick}
                        setIsEditingFile={setIsEditingFile}
                        status={status}
                    />
                ))
            }
        </ul>
    )
}

export default FileList