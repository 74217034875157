import React, { useCallback, useState, useContext } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import { Accordion, Card, Tooltip, Typography, Zoom } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import Span from '@jumbo/shared/Span';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import capitalize from '../../../utils/capitalize';
import formatDate from '../../../utils/formatDate';
import { Link, useLocation } from 'react-router-dom';
import { TextService } from 'app/servicesTwo/TextService';
import { PermissionContext } from 'app/contexts/PermissionContext';

const Item = styled(Span)(({ theme }) => ({
    padding: theme.spacing(0, 1),
}));

const LinkStyle = styled(Link)`
    padding: 4px 10px;
    background: #7352c7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    svg {
        color: white;
    }
`;

const ActivitiesItem = ({ item, setActivities, activities }) => {
    const { hasPermission } = useContext(PermissionContext);
    const location = useLocation();

    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });
    };

    const modalAlert = (id) => {
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                deleteActivity(id);
            }
        });
    };

    function removeById(arr, id) {
        const objWithIdIndex = arr.findIndex(
            (obj) => obj.atividade_voluntario_id === id
        );

        if (objWithIdIndex > -1) {
            arr.splice(objWithIdIndex, 1);
        }

        return arr;
    }

    const deleteActivity = useCallback(async (id) => {
        const updatedActivitiesArray = removeById(activities, id);
        setActivities(updatedActivitiesArray);

        try {
            ApiService.delete(`/atividades-voluntarios/${id}`)
                .then((response) => {
                    toast('success', 'Removido com sucesso');
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
            toast('error', 'Ocorreu um erro');
        }
    }, []);

    const maxLengthText = 20;

    return (
        <Card sx={{ mb: 1 }}>
            <Accordion square sx={{ borderRadius: 2 }}>
                <AccordionSummary
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                    sx={{
                        px: 3,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',

                            '&.Mui-expanded': {
                                margin: '12px 0',
                            },
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            },
                        },
                    }}
                >
                    <Div sx={{ lineBreak: 'anywhere', flexShrink: 1, px: 1 }}>
                        <Typography
                            fontSize={'12px'}
                            variant={'h6'}
                            color={'text.secondary'}
                            mb={0.25}
                        >
                            Atividade
                        </Typography>
                        <Typography variant={'body1'}>{item.nome}</Typography>
                    </Div>

                    <Div
                        sx={{
                            ml: 'auto',
                            flexDirection: 'row',
                            display: 'flex',
                        }}
                    >
                        <Tooltip
                            title='Visualizar'
                            TransitionComponent={Zoom}
                            arrow
                        >
                            <Item sx={{ ml: 'auto', display: { sm: 'block' } }}>
                                <LinkStyle
                                    to={`/app/editar-atividade/${item.atividade_voluntario_id}`}
                                    state={{ backUrl: location.pathname }}
                                >
                                    <VisibilityIcon />
                                </LinkStyle>
                            </Item>
                        </Tooltip>

                        <Tooltip
                            title='Excluir'
                            TransitionComponent={Zoom}
                            arrow
                        >
                            <Item sx={{ ml: 'auto', display: { sm: 'block' } }}>
                                <Button
                                    sx={{ minWidth: 1 }}
                                    disableElevation
                                    variant={'contained'}
                                    size={'small'}
                                    color={'secondary'}
                                    disabled={
                                        !hasPermission('Atividades', 'deletar')
                                    }
                                    onClick={() =>
                                        modalAlert(item.atividade_voluntario_id)
                                    }
                                >
                                    <DeleteIcon />
                                </Button>
                            </Item>
                        </Tooltip>
                    </Div>
                </AccordionSummary>
            </Accordion>
        </Card>
    );
};
/* Todo item prop define */

export default ActivitiesItem;
