import React, { useState, useCallback, useEffect, Fragment } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Box, Chip, FormControl, Grid, InputLabel, MenuItem, Paper, Stack, Typography,useMediaQuery} from "@mui/material";
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams } from "react-router-dom";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import ModalTransferClassEvaluateItem from './ModalTransferClassEvaluateItem';
import { getChipColor } from './EvaluateDocumentsItem';

const ModalTransferClassEvaluate = ({ data, transferStudentFromCourseIntro }) => {
    const {theme} = useJumboTheme();
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });
    };

    const { id } = useParams()
    const [courseHistory, setCourseHistory] = useState([]);
    
    const [class_id, setClass_id] = useState(null);

    const getCourseHistory = useCallback(async () => {
        try {
            ApiService.get(`/historico-cursos/${id}`)
            .then((response) => {
                setCourseHistory(response.data.turma);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
      }, []);

      function removeById(arr, id) {
        const objWithIdIndex = arr.findIndex((obj) => obj.pessoa_id === id);
      
        if (objWithIdIndex > -1) {
          arr.splice(objWithIdIndex, 1);
        }
      
        return arr;
      }

      useEffect(() => {
        if(id) {
            getCourseHistory();
        }
      }, []);

    

    console.log(data)
  return (
    <JumboContentLayout
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px'}}>
            <Typography variant={"h1"} style={{ textAlign: 'center', marginBottom: 30 }}>
                Escolha a turma que o aluno gostaria de ser transferido
            </Typography>
                <Fragment>
               
                    <Grid container spacing={3} alignContent={'center'}>
                        <Grid item xs={12}>
                            <Typography variant={"h3"}>
                                Preferência de turma do inscrito:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Chip 
                                label={`${data.Turma.horario} - ${data.Turma.dia_da_semana} - ${data.Turma.modo}`} 
                                color={getChipColor(data.Turma.dia_da_semana)} 
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant={"h3"}>
                                Selecione abaixo para qual turma o inscrito será matriculado:
                            </Typography>
                        </Grid>
                        
                    </Grid>
                    <Box marginTop='1rem'>
                        {data.Curso_Introdutorio.Turma?.map((item) => (
                            <ModalTransferClassEvaluateItem 
                                item={item} 
                                data={data}
                                transferStudentFromCourseIntro={transferStudentFromCourseIntro}
                            />
                        ))}
                    </Box>
                                
                </Fragment>
        </Paper>
    </JumboContentLayout>
  )
}

export default ModalTransferClassEvaluate