import React, {useCallback, useState} from 'react';
import {List} from "@mui/material";
import { useParams } from "react-router-dom";
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ListItemCourseIntro from './ListItemCourseIntro';
//import ListItemVolunteerInterested from './ListItemVolunteerInterested';

const ListRowCoursesIntro = ({ data, classesSelected , setClassesSelected, handleSelectTurma }) => {
    
    return (
        <List disablePadding sx={{width: '100%'}}>
            {
                data.map((item, index) => (
                    <ListItemCourseIntro 
                        item={item} 
                        key={index} 
                        classesSelected={classesSelected}
                        setClassesSelected={setClassesSelected}
                        handleSelectTurma={handleSelectTurma}
                    />
                ))
            }
        </List>
    );
};

export default ListRowCoursesIntro;
