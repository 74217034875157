import React, { useCallback, useState, useContext } from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import {Accordion, Card, Tooltip, Typography, Zoom, Grid, LinearProgress, AccordionDetails, Chip} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import capitalize from "../../../utils/capitalize";
import formatDate from "../../../utils/formatDate";
import {DAYS_OF_WEEK} from "../../../utils/constants/daysOfWeek";
import { PermissionContext } from 'app/contexts/PermissionContext';
import { LinkStyle } from '../users/UserItem';
import { CSVLink } from 'react-csv';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DownloadIcon from '@mui/icons-material/Download';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

export const Item = styled(Span)(({theme}) => ({
    padding: theme.spacing(0, 1),
}));

export const CSVStyled = styled(CSVLink)`
    padding: 4px 10px;
    background: #0092D0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    
    svg {
        color: white;
    }
`

const CoursesIntroItem = ({ item ,setClasses, classes }) => {
    
    const { hasPermission } = useContext(PermissionContext);
    const [expanded, setExpanded] = React.useState(false);
    console.log(item)
    
    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });
    };

    const modalAlert = (id) => {
        
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: "Não será póssível reverter a ação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                deleteCoursesIntro(id);
            }
        })
        
    };

    function removeById(arr, id) {
        const objWithIdIndex = arr.findIndex((obj) => obj.curso_indrodutorio_id === id);
      
        if (objWithIdIndex > -1) {
          arr.splice(objWithIdIndex, 1);
        }
      
        return arr;
      }

    const deleteCoursesIntro = useCallback(async (id) => {

        try {
            ApiService.delete(`/cursos-introdutorios/${id}`)
            .then((response) => {
                toast('success', 'Removido com sucesso');
                const updatedClassesArray = removeById(classes, id)
                setClasses(updatedClassesArray);
            })
            .catch((error) => {
              if (error.response) {
                toast('error', error.response.data.error);
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
          toast('error', 'Ocorreu um erro');
        }
    }, []);

    const verifyCourse = () => {
        
        if(item.Curso.deletedAt !== null) {
            return 'Curso excluído'
        }
        return item.Curso?.nome
        
    }

    /* const data = [];
    const alunos = item.Curso_Historico?.map(i => ({
        nome_do_aluno: i.User.nome, 
        cpf: i.User.cpf,
        status: i.status,
        motivo_reprovacao: i.motivo_reprovacao,
        observacao: i.observacao
    })) || [];
    const addedNames = {}; // objeto para armazenar nomes já adicionados
    
    if (alunos.length === 0) {
        const newRow = {
            ...item,
            nome_do_curso: item.Curso.nome,
            nome_do_aluno: "",
            cpf: "",
            status: "",
            motivo_reprovacao: "",
            observacao: ""
        };
        data.push(newRow);
    }
    
    for (const aluno of alunos) {
        const newRow = {
            ...item,
            nome_do_curso: item.Curso.nome,
            nome_do_aluno: aluno.nome_do_aluno,
            cpf: aluno.cpf,
            status: aluno.status,
            motivo_reprovacao: aluno.motivo_reprovacao,
            observacao: aluno.observacao
        };
        if(!addedNames[aluno.nome_do_aluno]) { // verifica se o nome já foi adicionado antes
            data.push(newRow);
            addedNames[aluno.nome_do_aluno] = true; // marca o nome como adicionado
        }
    }
    

    const generateOptions = (key) => ({ label: key, key });

    const headers = [
        // para turmas
        generateOptions('ano'),
        generateOptions('nome_do_curso'),
        generateOptions('dia_da_semana'),
        generateOptions('horario'),
        generateOptions('modo'),
        generateOptions('local'),
        generateOptions('grupo'),

        // para alunos
        generateOptions('nome_do_aluno'),
        generateOptions('cpf'),
        generateOptions('status'),
        generateOptions('motivo_reprovacao'),
        generateOptions('observacao')
    ]; */
   /*  const handleClick = () => {
        if (classes.find((product) => product.curso_indrodutorio_id === item.curso_indrodutorio_id)) 
            setExpanded(!expanded);
    } */

    const getChipColor = (diaDaSemana) => {
        switch (diaDaSemana) {
            case 'Segunda':
                return 'primary';
            case 'Terça':
                return 'secondary';
            case 'Quarta':
                return 'success';
            case 'Quinta':
                return 'success';
            case 'Sexta':
                return 'info';
            case 'Sábado':
                return 'error';
          
          default:
            return 'default';
        }
    };
console.log(item)
    return (
        <Card sx={{mb: 1}}>
            <Accordion expanded={true}/*  onChange={handleClick} */ square sx={{borderRadius: 2}}>
                <AccordionSummary
                    //expandIcon={expanded ? <RemoveIcon/> : <AddIcon/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        px: 0.5,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',

                            '&.Mui-expanded': {
                               margin: '12px 0',
                            }
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            }
                        }
                    }}
                >
                    {/* <Grid container spacing={2} alignContent={'center'}> */}
                        {/* <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width: {xs: '7%', lg: '7%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Ano
                            </Typography>
                            <Typography variant={"body1"}>{item.ano}</Typography>
                        </Div> */}
                        <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width:{xs: '30%', xl: '60%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Título
                            </Typography>
                            <Typography variant={"body1"}>{item.titulo.replace(/<[^>]*>/g, '')}</Typography>
                        </Div>
                        <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width:{xs: '10%', xl: '20%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Ano
                            </Typography>
                            <Typography variant={"body1"}>{item.Turma[0]?.ano}</Typography>
                        </Div>
                        <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width:{xs: '10%', xl: '20%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Inscrições
                            </Typography>
                            <Typography variant={"body1"}>{item.Curso_Introdutorio_Historico.length}</Typography>
                        </Div> 
                        <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width:{xs: '10%', xl: '20%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Turmas
                            </Typography>
                            <Typography variant={"body1"}>{item.Turma.length}</Typography>
                        </Div> 
                        {/* <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width:{xs: '10%', xl: '10%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Dia
                            </Typography>
                            <Typography variant={"body1"}>{item.dia_da_semana}</Typography>
                        </Div> */}
                        {/* <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width:{xs: '7%', xl: '7%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Horário
                            </Typography>
                            <Typography variant={"body1"}>{item.horario}</Typography>
                        </Div> */}
                        {/* <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width:{xs: '12%', xl: '10%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Modo
                            </Typography>
                            <Typography variant={"body1"}>{item.modo}</Typography>
                        </Div> */}
                        {/* <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width:{xs: '16%', xl: '16%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Local
                            </Typography>
                            <Typography variant={"body1"}>{capitalize(item.local)}</Typography>
                        </Div> */}

                        {/* <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width:{xs: '7%', xl: '7%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Grupo
                            </Typography>
                            <Typography variant={"body1"}>{item.grupo}</Typography>
                        </Div> */}

                        <Div sx={{display: 'flex', lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                            <Tooltip 
                                title="Ir para o formulário deste curso"
                                TransitionComponent={Zoom}
                                arrow
                            > 
                                <Item>
                                    <LinkStyle to={`/inscricao-curso-introdutorio/${item.curso_indrodutorio_id}`} target='_blank'>
                                        <ArrowForwardIosIcon />
                                    </LinkStyle>
                                </Item>
                            </Tooltip> 
                            <Tooltip 
                                title="Visualizar"
                                TransitionComponent={Zoom}
                                arrow
                            > 
                                <Item>
                                    <LinkStyle to={`/app/editar-abrir-inscricoes/${item.curso_indrodutorio_id}`}>
                                        <VisibilityIcon />
                                    </LinkStyle>
                                </Item>
                            </Tooltip> 

                            <Tooltip 
                                title="Excluir"
                                TransitionComponent={Zoom}
                                arrow
                            > 
                                <Item>
                                    <Button 
                                        sx={{minWidth: 1}}
                                        disableElevation 
                                        variant={"contained"} 
                                        size={"small"} 
                                        color={"secondary"}
                                        disabled={!hasPermission('Inscrições nos Cursos', 'deletar')}
                                        onClick={() => modalAlert(item.curso_indrodutorio_id)}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </Item>
                            </Tooltip>
                        </Div>  
                   {/*  </Grid> */}  
                </AccordionSummary>
                <AccordionDetails sx={{borderTop: 1, borderColor: 'divider', /* p: theme => theme.spacing(2, 2, 2, 8.25) */}}>

                     <Typography variant={"h5"}>Horários</Typography>
                        <Div
                            sx={{
                                display: {xs: 'flex'},
                                minWidth: 0,
                                mb:2,
                                gap: 1,
                                flexWrap: 'wrap',
                            }}
                        >
                        
                        {item.Turma?.map((turma, index, array) => (
                            <Div 
                                key={index}
                            >
                               <Chip label={`${turma.horario} - ${turma.dia_da_semana} - ${turma.modo}`} color={getChipColor(turma.dia_da_semana)} />

                            </Div>
                        ))}
                        </Div>
                        
                            <Typography
                                variant={"h5"}
                                mb={.25}
                                
                            >
                                Curso
                            </Typography>
                            <Typography variant={"body1"}>{item.Turma[0]?.Curso?.nome}</Typography>
                        
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};
/* Todo item prop define */
export default CoursesIntroItem;
