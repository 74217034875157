import React, {useCallback, useState} from 'react';
import {List} from "@mui/material";
import { useParams } from "react-router-dom";
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ListItemVolunteerInterested from './ListItemVolunteerInterested';

const ListRowVolunteerInterested = ({ data , setAddStudents, updateStudentList, classStudents, status,setStatus, setUsername, setSearchTerm, setUsers, setPreviewFile, setSubmitClicked}) => {
    const { id } = useParams()
    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });
    };

    const [itemsList, setItemsList] = useState(data);
    const includeStudentToClass = useCallback((record) => {
        try {
            ApiService.get(`/pessoa-voluntariada/${record.pessoa_id}`)
            .then((response) => {
                console.log(response.data)
                const volunteerFile =  response.data.user[0].Voluntario;
                let volunteerInfos = null;

                /* Itera do último elemento ao primeiro */
                for(let i = volunteerFile.length - 1; i >= 0; i--) {
                    if(volunteerFile[i].status === 'ativo' /* && volunteerFile[i].ficha !== null */) {
                        volunteerInfos = volunteerFile[i];
                        break;
                    }
                }

                let [user] = response.data.user;
               /*  user = {...user, ficha: volunteerInfos?.ficha}; */

                console.log(user);
                setUsers((prevUsers) => ({
                    ...prevUsers,
                    teve_vivencia_pratica: response.data.hasCourse,
                    ficha: volunteerInfos?.ficha || null,
                    voluntario_desde: volunteerInfos?.voluntario_desde,
                    codigo_antigo_voluntariado: volunteerInfos?.codigo_antigo_voluntariado,
                    codigo_voluntario_feesp: volunteerInfos?.codigo_voluntario_feesp,
                    is_codigo_voluntario_feesp_exists: volunteerInfos?.codigo_voluntario_feesp ? true : false,
                    voluntario_id: volunteerInfos?.voluntario_id,
                    Ficha_Do_Voluntario: volunteerInfos?.Ficha_Do_Voluntario
                }));
                setStatus(true)
                setUsername(user)
                setSearchTerm('')
                setPreviewFile(null)
                setSubmitClicked(false)
                //toast('success', 'Aluno adicionado com sucesso');
               
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }

    }, [itemsList, status]);
    
    /* const usersWithFlag = data.map(user => {
        const hasCourseVolunteer = user.Curso_Historico?.some(curso => curso.Turma.Curso.curso_id === 13); //trocar para 13
        return { ...user, curso_de_voluntario: hasCourseVolunteer };
    }); */

    return (
            <List disablePadding>
                {
                    data.map((item, index) => (
                        <ListItemVolunteerInterested item={item} key={index} includeStudentToClass={includeStudentToClass} />
                    ))
                }
            </List>
    );
};

export default ListRowVolunteerInterested;
