import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, Paper, Stack,Switch,Tooltip,Typography,Zoom,useMediaQuery} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { updateInputValue, disableInputValue } from "../../../utils/appHelpers";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import TextField from '@mui/material/TextField';
import JumboSearch from "@jumbo/components/JumboSearch";
/* import ClassStudentsList from "./ClassStudentsList"; */
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';
import SendIcon from '@mui/icons-material/Send';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import ListRowVolunteerInterested from 'app/components/ListRowVolunteerInterested/ListRowVolunteerInterested';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import InfosAboutCreateAndUpdate from 'app/components/InfosAboutCreateAndUpdate/InfosAboutCreateAndUpdate';
import JumboSearchClasses from '@jumbo/components/JumboSearchClasses';
import { useDebouncedCallback } from 'beautiful-react-hooks';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
};

const VolunteerInterestedFormResume = () => {

    const {theme} = useJumboTheme();
    const {showDialog, hideDialog} = useJumboDialog();

    const navigate = useNavigate();
    const moment = require('moment-timezone');

    const Swal = useSwalWrapper();
    const toast = (variant, message, type = false) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });

        //  Redireciona se mensagem de retorno for de sucesso
        
        /* if(id) {
            if(variant == 'success' && (!userFile || (userFile && type))){
                setTimeout(function() {
                    navigate("/app/listar-interesse-voluntariado")
    
                }, 2000)
            }
        } */
        
    };

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const initialValues = {
        pessoa_id: "",
        voluntario_id: "",
        codigo_antigo_voluntariado: "",
        status: "",
        data_inicio: "",
        data_fim: "",
        departamento_id: "",
        area_id: "",
        local: "",
        ficha: null,
        atividade: "",
        observacao: "",
        teve_vivencia_pratica: false,
        quais_trabalhos: false,
        confirmar_voluntariado: false,
    }

    const validationSchema = yup.object().shape({
        departamento_id: yup.number().nullable(),
        area_id: yup.number().required('Preenchimento obrigatório'),
        local: yup.string().required('Preenchimento obrigatório'),
    });

    const { id } = useParams()
    
    const [searchTerm, setSearchTerm] = useState(null);
    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState(initialValues);
    const [addStudents, setAddStudents] = useState(null);
    const [classStudents, setClassStudents] = useState([]);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [previewFile, setPreviewFile] = useState(null);
    const [userFile, setUserFile] = useState(null);
    const [status, setStatus] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const [userVolunteerCourse, setUserVolunteerCourse] = useState(false);
    const [verifyVolunteerCourse, setVerifyVolunteerCourse] = useState({});
    const [username, setUsername] = useState('');
    const [departments, setDepartments] = useState([]);
    const [areas, setAreas] = useState([]);
    const [activities, setActivities] = useState([]);
    const [activitiesSelected, setActivitiesSelected] = useState(null);

    const [userPreferences, setUserPreferences] = useState([{ dia_da_semana: "", horario_inicio: "", horario_fim: "" }])
    const [deletedPreferences, setDeletedPreferences] = useState([]);

    const [userVolunteerOut, setUserVolunteerOut] = useState([{ nome_trabalho_voluntario_fora: "", local_fora: "", data_inicio_voluntario_fora: "", obs_fora: ""}])
    const [deletedVolunteerOut, setDeletedVolunteerOut] = useState([]);

    const { hasPermission } = useContext(PermissionContext);
    let { state, pathname } = useLocation();

    const canCreate = hasPermission('Voluntariado', 'criar');
    const canEdit = hasPermission('Voluntariado', 'editar');
    const isDisabled = id ? !canEdit : !canCreate
    
    const storedUserId = localStorage.getItem("storedUserId");

    const modalAlertInteresseEncerrar = (id) => {
        console.log(id)
        Swal.fire({
            title: 'Tem certeza que deseja encerrar interesse?',
            text: "Não será póssível reverter a ação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                inactiveInterest(id)
            }
        });
    };

    const modalAlertInteresseAtivar = (id) => {
        console.log(id)
        Swal.fire({
            title: 'Tem certeza que deseja ativar o interesse?',
            text: "Não será póssível reverter a ação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                activeInterest(id)
            }
        });
    };
    

    const modalAlertEmail = (id) => {
        console.log(id)
        Swal.fire({
            title: 'Tem certeza que deseja enviar email de confirmação?',
            text: "Não será póssível reverter a ação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                emailConfirmation(id)
            }
        });
    };

    const emailConfirmation = useCallback(async (id) => {
        setLoading(true)
        try {
            ApiService.post(`/email-voluntariado-interesse/${id}`)
            .then((response) => {
                toast('success', 'Email enviado com sucesso');
                setLoading(false)
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
                setLoading(false)
            })
        } catch (err) {
          console.log(err);
          toast('error', 'Ocorreu um erro');
        }
    }, []);

    const inactiveInterest = useCallback(async (id) => {
        
        try {
            ApiService.put(`/encerrar-interesse-voluntariado/${id}`)
            .then((response) => {
                toast('success', 'Encerrado com sucesso');
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
          toast('error', 'Ocorreu um erro');
        }
    }, []);

    const activeInterest = useCallback(async (id) => {
        
        try {
            ApiService.put(`/ativar-interesse-voluntariado/${id}`)
            .then((response) => {
                toast('success', 'Ativado com sucesso');
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
          toast('error', 'Ocorreu um erro');
        }
    }, []);


    const getVolunteerInterestedById = useCallback(async (selectedOption, userVolunteerCourse) => {
        try {
            ApiService.get(`/voluntarios-interesse/${id}`)
            .then((response) => {
                console.log('iii', response.data)
                setUsers({...response.data, quais_trabalhos: response.data.Trabalho_Voluntario_Fora_Feesp.length > 0 ? true : false});
                setSelectedOption(response.data.User)
                //setUserPreferences(response.data.Horario_voluntariado)
                //setUserVolunteerOut(response.data.Trabalho_Voluntario_Fora_Feesp)

                if(response.data.Horario_voluntariado.length > 0) {
                    setUserPreferences(response.data.Horario_voluntariado)
                }

                if(response.data.Trabalho_Voluntario_Fora_Feesp.length > 0) {
                    console.log('aqui')
                    setUserVolunteerOut(response.data.Trabalho_Voluntario_Fora_Feesp)
                }
                
                if(response.data.status == 'ativo') {
                    setStatus(true)
                }
                //setVerifyVolunteerCourse(response.data.verifyVolunteerCourse)
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    console.log(userVolunteerOut.length > 0)


    const getAreas = useCallback(async () => {
        try {
            ApiService.get(`/areas`)
            .then((response) => {
              setAreas(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const getAtividades = useCallback(async () => {
        try {
            ApiService.get(`/atividades`)
            .then((response) => {
                console.log(response.data)
              setActivities(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    useEffect(() => {
        if(id) getVolunteerInterestedById(selectedOption, userVolunteerCourse);
        getAreas();
        getAtividades();
    }, []);

    useEffect(() => {
        Swal.close();
    }, [pathname]);

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {

        if(id) {
            if(values.confirmar_voluntariado == 'on') {
                //confirmar voluntariado
                const result = await Swal.fire({
                    title: 'Tem certeza que deseja confirmar voluntariado?',
                    text: 'Não será possível reverter a ação!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim!',
                    cancelButtonText: 'Não!',
                    reverseButtons: true,
                });

                if(result.value) {
                    values = {...values, atividade: activitiesSelected}
                    await ApiService.post(`/voluntarios/`, { values })
                    .then((response) => {
                        toast('success', 'Voluntario confirmado com sucesso')
                    })
                    .catch((error) => {
                        toast('error', error.response.data.error);
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        } else if (error.request) {
                        console.log(error.request);
                        } else {
                        console.log('error', error.message);
                        }
                    })
                }
            }else {
                //atualiza form sem confirmar o voluntariado
                const allValuesEdit = {...values, horario_voluntariado: userPreferences, trabalho_voluntario_fora_feesp: userVolunteerOut }
               
                await ApiService.put(`/voluntarios-interesse/${id}`, { values: allValuesEdit })
                .then((response) => {
                    if (!userFile) toast('success', 'Voluntario atualizado com sucesso')
                })
                .catch((error) => {
                    toast('error', error.response.data.error);
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    console.log(error.request);
                    } else {
                    console.log('error', error.message);
                    }
                })
                if(userFile) {
                    let data = new FormData()
                    data.append("ficha", userFile)
                    console.log(data)
                    ApiService.put(`/voluntarios-interesse/atualizar-ficha/${id}`, data)
                      .then(() => {
                        toast('success', 'Voluntario atualizado com sucesso', true)
                      })
                      .catch((error) => {
                        console.log(error)
                        toast('error', 'Ocorreu um erro ao enviar a ficha', true)
        
                        if (error.response) {
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                          } else if (error.request) {
                            console.log(error.request);
                          } else {
                            console.log('error', error.message);
                          }
                    })
                    
                }
            }
        } else {
            const allValues = {...values, pessoa_id: username.pessoa_id, horario_voluntariado: userPreferences, trabalho_voluntario_fora_feesp: userVolunteerOut, id_quem_aceitou: storedUserId }
            await ApiService.post('/voluntarios-interesse', { values: allValues })
            .then((response) => {
                toast('success', 'Criado com sucesso')
                resetForm()
                setUsername('')
                setUserPreferences([{ dia_da_semana: "", horario_inicio: "", horario_fim: "" }])
                setUserVolunteerOut([{ nome_trabalho_voluntario_fora: "", local_fora: "", data_inicio_voluntario_fora: "", obs_fora: ""}])
            })
            .catch((error) => {
                toast('error', error.response.data.error);
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('error', error.message);
                }
            })
        }


        setSubmitting(false);
    } 

    const handleChangeFile = (e) => {
        setPreviewFile(URL.createObjectURL(e.target.files[0]));
        setUserFile(e.target.files[0])
    }

    
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...userPreferences];
        list[index][name] = value;
        setUserPreferences(list);
    };

    const handleRemoveClick = index => {
        const list = [...userPreferences];
    
        const preferences = list[index];
        setDeletedPreferences([...deletedPreferences, preferences])
    
        list.splice(index, 1);
        setUserPreferences(list);
    };
    
    const handleAddClick = () => {
        setUserPreferences([...userPreferences, { dia_da_semana: "", horario_inicio: "", horario_fim: "" }]);
    };


    const handleInputChangeVolunteerOut = (e, index) => {
        const { name, value } = e.target;
        const list = [...userVolunteerOut];
        list[index][name] = value;
        setUserVolunteerOut(list);
    };

    const handleRemoveClickVolunteerOut  = index => {
        const list = [...userVolunteerOut];
    
        const volunteerOut = list[index];
        setDeletedVolunteerOut([...deletedVolunteerOut, volunteerOut])
    
        list.splice(index, 1);
        setUserVolunteerOut(list);
    };
    const handleAddClickVolunteerOut = () => {
        setUserVolunteerOut([...userVolunteerOut, { nome_trabalho_voluntario_fora: "", local_fora: "", data_inicio_voluntario_fora: "", obs: "" }]);
    };

    useEffect(() => {
        if(searchTerm == undefined || searchTerm == ''){
            setAddStudents(null);
            return;
        } 

        const delayDebounceFn = setTimeout(() => {
          searchStudent(searchTerm)
        }, 1500)

        
        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    const searchStudent = useCallback(async (e) => {
        setLoading(true)
        try {
             ApiService.get(`/pessoa`, {params: {search: e, turma_id: id}})
            .then((response) => {
                setAddStudents(response.data)
                // toast('success', 'Aluno adicionado com sucesso');
                setLoading(false)
            })
            .catch((error) => {
                // toast('error', 'Ocorreu um erro');

              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
                setLoading(false)
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const handleChangeSearch = useDebouncedCallback((event) => {
        setSearchTerm(event.target.value);
    });

    
    React.useEffect(() => {
        setSearchTerm(searchTerm);
    }, [searchTerm]);

    React.useEffect(() => {
        return () => handleChangeSearch.cancel();
    });

    const updateStudentList = useCallback(async (data) => {
        setClassStudents([...data]);
        setAddStudents(null);
    }, []);

    const handleInputFocus = () => {
        setIsInputFocused(true);
      }
    
    const handleInputBlur = () => {
        setIsInputFocused(false);
    }


    const dateTime = users && users?.updatedAt;
    let dateBr;
    let timeBr;
    if(dateTime) {
        dateBr = moment(dateTime).tz('America/Sao_Paulo').format('DD/MM/YYYY');
        timeBr = moment(dateTime).tz('America/Sao_Paulo').format('HH:mm:ss')
    }

    const createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
    });

    const handleCreate = (inputValue) => {
        const newOption = createOption(inputValue);
        
        setActivities((prev) => [...prev, newOption]);
        setActivitiesSelected(newOption);
    };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'transparent' : 'white',
      border: state.isFocused ? '1px solid #7352C7' : '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      padding: '8px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'black',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#7352C7' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: 'lightblue',
        color: 'white',
      },
    }),
  };
   
  return (
    <JumboContentLayout
        header={
            <HeaderBreadcrumbs
                id={id}
                title={"Voluntariado Interesse"}
                subtitle={id ? "Editar" : "Cadastro de Voluntariado"}
                titleUrl={"/app/listar-interesse-voluntariado"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px'}}> 
            {state &&
                <Button
                    color="success"
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{maxWidth: {md: '200px'}, mb: '2rem'}}
                    onClick={() => navigate(state.backUrl)}
                >
                    Voltar
                </Button>
            }
            <Formik
                initialValues={users}
                validationSchema={validationSchema}
                enableReinitialize
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={handleSubmit}
            >
                {({values, isSubmitting, handleChange}) => {
                    const selectedArea = areas.find(area => area.area_id === values.area_id);
                    
                    return (
                        <Form style={{width: '100%'}} noValidate autoComplete='off'>
                            <Grid container spacing={3} alignContent={'center'}>
                                {id
                                    ?
                                    null
                                    :
                                    (
                                        <>
                                            <Grid item xs={12} >
                                                <JumboSearchClasses
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                    placeholder="Busque por Nome, CPF ou E-mail."
                                                    sx={{
                                                        width: '440px',
                                                        marginBottom: '1rem'
                                                    }}
                                                    onFocus={handleInputFocus} 
                                                    onBlur={handleInputBlur}
                                                    disabled={isDisabled}
                                                />
                                            </Grid> 
                                            <Grid item xs={12}>
                                                {loading 
                                                    && 
                                                    <Box display='flex' justifyContent='center'>
                                                        <CircularProgress  /> 
                                                    </Box>
                                                }
                                                {
                                                    (addStudents != null && addStudents.length > 0) && <ListRowVolunteerInterested data={addStudents} updateStudentList={(data) => updateStudentList(data)} classStudents={classStudents} status={status} setStatus={setStatus} setUsername={setUsername}setSearchTerm={setSearchTerm} setUsers={setUsers} />
                                                }
                                                {
                                                    (addStudents != null && addStudents.length == 0) && <Typography>Sem resultados correspondentes</Typography>
                                                }  

                                            </Grid>
                                        </>
                                    ) 
                                }
                                <Grid item xs={12}>
                                    <JumboTextField
                                        fullWidth
                                        name={id ? "voluntario_id" : "pessoa_id"}
                                        label="Nome"
                                        value={id ? selectedOption && selectedOption.nome : username && username.nome}
                                        disabled={true}
                                        InputLabelProps={{ 
                                            shrink: true 
                                        }}
                                    />
                                </Grid>
                                { !id &&
                                    <>
                                        <Grid item xs={4} >
                                        <FormControl fullWidth>
                                            <InputLabel shrink id="area_id">Área</InputLabel>
                                            <JumboSelectField
                                                labelId="area_id"
                                                id="area_id"
                                                name="area_id"
                                                value={updateInputValue(users, values, 'area_id')}
                                                label="Área"
                                                disabled={isDisabled || status == false}
                                                notched
                                            >
                                                <MenuItem value={''}>Sem seleção</MenuItem>
                                                {areas.length > 0 && areas.map((x) => 
                                                    <MenuItem value={x.area_id}>{x.nome}</MenuItem>
                                                )}
                                            </JumboSelectField>
                                           
                                        </FormControl>
                                        </Grid>
                                        <Grid item xs={4} >
                                            <FormControl fullWidth>
                                                <InputLabel shrink id="departamento_id">Departamento</InputLabel>
                                                <JumboSelectField
                                                    labelId="departamento_id"
                                                    id="departamento_id"
                                                    name="departamento_id"
                                                    value={updateInputValue(users, values, 'departamento_id')}
                                                    label="Departamento"
                                                    disabled={isDisabled || status == false}
                                                    sx={{ background: '#ffffff'}}
                                                    notched
                                                >
                                                    <MenuItem value={''}>Sem seleção</MenuItem>
                                                    {(selectedArea?.departamento || []).map((x) => 
                                                        <MenuItem value={x.departamento_id}>{x.nome}</MenuItem>
                                                    )}
                                                </JumboSelectField>
                                                
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} >
                                            <FormControl fullWidth>
                                                <InputLabel shrink id="local">Local</InputLabel>
                                                <JumboSelectField
                                                    labelId="local"
                                                    id="local"
                                                    name="local"
                                                    value={updateInputValue(users, values, 'local')}
                                                    label="Local"
                                                    disabled={isDisabled || status == false}
                                                    notched
                                                >
                                                    <MenuItem value={'Casa Transitória'}>Casa Transitória</MenuItem>
                                                    <MenuItem value={'Sede'}>Sede</MenuItem>
                                                    <MenuItem value={'Outro'}>Outro</MenuItem>
                                                </JumboSelectField>
                                            </FormControl>
                                        </Grid>
                                    </>
                                }
                                
                                {userPreferences.map((x, i) => (
                                    <>
                                        <Grid item xs={2} sx={{marginBottom: {xs: '0.5rem'}}}>
                                            <FormControl fullWidth>
                                                <InputLabel shrink id="dia_da_semana">Dia da Semana</InputLabel>
                                                <JumboSelectField
                                                    labelId="dia_da_semana"
                                                    id="dia_da_semana"
                                                    name="dia_da_semana"
                                                    label="dia_da_semana"
                                                    value={x.dia_da_semana}
                                                    onChange={e => handleInputChange(e, i)}
                                                    sx={{ background: '#ffffff'}}
                                                    disabled={isDisabled || status == false}
                                                    notched
                                                >
                                                    <MenuItem value={'Domingo'} style={{ display: userPreferences.some(item => item.dia_da_semana == 'Domingo') ? 'none' : 'block' }}>Domingo</MenuItem>
                                                    <MenuItem value={'Segunda'} style={{ display: userPreferences.some(item => item.dia_da_semana == 'Segunda') ? 'none' : 'block' }}>Segunda</MenuItem>
                                                    <MenuItem value={'Terça'} style={{ display: userPreferences.some(item => item.dia_da_semana == 'Terça') ? 'none' : 'block' }}>Terça</MenuItem>
                                                    <MenuItem value={'Quarta'} style={{ display: userPreferences.some(item => item.dia_da_semana == 'Quarta') ? 'none' : 'block' }}>Quarta</MenuItem>
                                                    <MenuItem value={'Quinta'} style={{ display: userPreferences.some(item => item.dia_da_semana == 'Quinta') ? 'none' : 'block' }}>Quinta</MenuItem>
                                                    <MenuItem value={'Sexta'} style={{ display: userPreferences.some(item => item.dia_da_semana == 'Sexta') ? 'none' : 'block' }}>Sexta</MenuItem>
                                                    <MenuItem value={'Sabado'} style={{ display: userPreferences.some(item => item.dia_da_semana == 'Sabado') ? 'none' : 'block' }}>Sábado</MenuItem>
                                                </JumboSelectField>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={2}>
                                            <FormControl fullWidth>
                                                <InputMask
                                                    mask="99:99"
                                                    value={x.horario_inicio}
                                                    onChange={e => handleInputChange(e, i)}
                                                    disabled={isDisabled || status == false}
                                                >
                                                    {() => 
                                                        <JumboTextField
                                                            fullWidth
                                                            name="horario_inicio"
                                                            label="Horário Inicial"
                                                            disabled={isDisabled || status == false}
                                                            InputLabelProps={{ 
                                                                shrink: true 
                                                            }}
                                                        />
                                                    }
                                                </InputMask>
                                    
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl fullWidth>
                                                <InputMask
                                                    mask="99:99"
                                                    value={x.horario_fim}
                                                    onChange={e => handleInputChange(e, i)}
                                                    disabled={isDisabled || status == false}
                                                >
                                                    {() => 
                                                    <JumboTextField
                                                        fullWidth
                                                        name="horario_fim"
                                                        label="Horário Final"
                                                        disabled={isDisabled || status == false}
                                                        InputLabelProps={{ 
                                                            shrink: true 
                                                        }}
                                                    />
                                                    }
                                                </InputMask>
                                    
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                                {x.dia_da_semana == '' || x.horario_inicio == '' || x.horario_fim == '' ? null
                                                :
                                                userPreferences.length !== 1 && 
                                                
                                                    <Button
                                                        color="error"
                                                        variant="contained"
                                                        size="large"
                                                        component="label"
                                                        onClick={() => handleRemoveClick(i)}
                                                        sx={{height:'60px', 
                                                            borderRadius:'50%', 
                                                            marginRight: {xs: '0.5rem'},
                                                            fontSize: 
                                                            {
                                                                xs: '0.7rem'
                                                            }
                                                        }}
                                                        disabled={isDisabled || status == false}
                                                    >
                                                        <DeleteIcon />
                                                    </Button>

                                                }
                                            {userPreferences.length - 1 === i && 
                                                <Button
                                                    sx={{height:'53.13px',fontSize: {xs: '0.7rem'}, padding: {lg: '8px 22px', xs: '8px 5px'}}}
                                                    color="info"
                                                    variant="contained"
                                                    size="large" 
                                                    component="label"
                                                    disabled= {
                                                        x.dia_da_semana == '' 
                                                        || x.horario_inicio == ''
                                                        || x.horario_fim == ''
                                                        || isDisabled 
                                                        || status == false
                                                    } 
                                                    onClick={handleAddClick}
                                                    
                                                >
                                                    Adicionar outra periodicidade
                                                </Button>
                                            }
                                        </Grid>
                                    </>
                                ))}

                                <Grid item xs={12}>
                                
                                    <FormControlLabel
                                        name={updateInputValue(users, values, 'teve_vivencia_pratica')}
                                        control={
                                            <Checkbox
                                                name="teve_vivencia_pratica"
                                                checked={updateInputValue(users, values, 'teve_vivencia_pratica')} disabled={isDisabled || status == false}
                                            />
                                        }
                                        label="Realizou curso de Capacitação de Voluntários?"
                                        labelPlacement="end"
                                        onChange={handleChange}
                                    />
                                </Grid>

                                {id && 
                                    <Grid item lg={6} xs={2} >
                                        <Div sx={{ display: 'flex', flexDirection: 'row', }}>
                                            { previewFile && 
                                                <embed src={previewFile} type="application/pdf" style={{ width: 50, height: 50, borderRadius: 50, marginRight: 10, overflow: 'hidden' }}/>
                                            }
                                            {values.ficha && !previewFile && 
                                                <>
                                                    <Div sx={{width: '50px', height: '50px', borderRadius: 50, marginRight: '1rem', overflow: 'hidden'}}>
                                                    <embed src={`${process.env.REACT_APP_API_KEY}/images/${values.ficha}`} type="application/pdf"/>
                                                    </Div>
                                                    <Button sx={{marginRight: 3}} variant='contained' href={`${process.env.REACT_APP_API_KEY}/images/${values.ficha}`} target="_blank">
                                                        Abrir arquivo
                                                    </Button>
                                                </>
                                                
                                            }
                                            <Tooltip 
                                                title="Adicionar ficha com a assinatura do voluntariado"
                                                TransitionComponent={Zoom}
                                                arrow
                                            >
                                                <Button 
                                                    variant="contained" 
                                                    component="label" 
                                                    color="info"
                                                    disabled={isDisabled || users.status === 'inativo'}
                                                    endIcon={<UploadIcon />}
                                                >
                                                    Adicionar ficha
                                                    <input 
                                                        type="file" 
                                                        hidden 
                                                        onChange={handleChangeFile
                                                    }/>
                                                </Button>
                                            </Tooltip>
                                        </Div>
                                    </Grid>
                                }
                                
                                <Grid item xs={12}>
                                    <Divider variant="middle" />
                                    
                                    <Accordion 
                                        expanded={updateInputValue(users, values, 'quais_trabalhos') ? true : false}
                                        elevation={0}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                                <Stack
                                                    sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                    direction={{ xs: 'column', lg: 'row' }}
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography
                                                        sx={{fontSize: '1.05rem'}}
                                                    >
                                                        Realiza trabalho voluntário fora da FEESP?
                                                    </Typography>

                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        <Typography>Não</Typography>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch 
                                                                        checked={updateInputValue(users, values, 'quais_trabalhos')? true : false}
                                                                        onChange={handleChange}
                                                                        name="quais_trabalhos" 
                                                                        disabled={isDisabled || status == false}
                                                                    />
                                                                }
                                                                labelPlacement="start"
                                                            />
                                                        <Typography>Sim</Typography>
                                                    </Stack>

                                                </Stack>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {userVolunteerOut.map((x, i) => (
                                                <Grid container spacing={3} marginBottom={3.8}>
                                                    <Grid item xs={3} >
                                                        <JumboTextField
                                                            fullWidth
                                                            name="nome_trabalho_voluntario_fora"
                                                            label="Nome do trabalho voluntário"
                                                            value={x.nome_trabalho_voluntario_fora}
                                                            //disabled={isDisabled ?? disableInputValue(user, values, 'quais_cuidados')}
                                                            onChange={e => handleInputChangeVolunteerOut(e, i)}
                                                            disabled={isDisabled || status == false}
                                                            InputLabelProps={{ 
                                                                shrink: true 
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <JumboTextField
                                                                fullWidth
                                                                name="local_fora"
                                                                label="Local"
                                                                value={x.local_fora}
                                                                //disabled={isDisabled ?? disableInputValue(user, values, 'quais_cuidados')}
                                                                onChange={e => handleInputChangeVolunteerOut(e, i)}
                                                                disabled={isDisabled || status == false}
                                                                InputLabelProps={{ 
                                                                    shrink: true 
                                                                }}
                                                            />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <JumboTextField
                                                            fullWidth
                                                            name="data_inicio_voluntario_fora"
                                                            type="date"
                                                            label="Data de início"
                                                            value={x.data_inicio_voluntario_fora}
                                                            sx={{ background: '#ffffff'}}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={isDisabled || status == false}
                                                            onChange={e => handleInputChangeVolunteerOut(e, i)}
                                                        />
                                                    </Grid>
                                                    
                                                    <Grid item xs={3}>
                                                        {
                                                            x.nome_trabalho_voluntario_fora == '' || x.local_fora == '' || x.data_inicio_voluntario_fora == '' ? null
                                                            :
                                                            userVolunteerOut.length !== 1 && 
                                                            
                                                            <Button
                                                                color="error"
                                                                variant="contained"
                                                                size="large"
                                                                component="label"
                                                                onClick={() => handleRemoveClickVolunteerOut (i)}
                                                                sx={{height:'60px', 
                                                                    borderRadius:'50%', 
                                                                    marginRight: {xs: '0.5rem'},
                                                                    fontSize: 
                                                                    {
                                                                        xs: '0.7rem'
                                                                    }
                                                                }}
                                                                disabled={isDisabled || status == false}
                                                            >
                                                                <DeleteIcon />
                                                            </Button>

                                                        }
                                                        {userVolunteerOut.length - 1 === i && 
                                                            <Button
                                                                sx={{height:'53.13px',fontSize: {xs: '0.7rem'}, padding: {lg: '8px 22px', xs: '8px 5px'}}}
                                                                color="info"
                                                                variant="contained"
                                                                size="large" 
                                                                component="label"
                                                                disabled= {x.nome_trabalho_voluntario_fora == '' || x.local_fora == '' || x.data_inicio_voluntario_fora == '' || isDisabled || status == false} 
                                                                onClick={handleAddClickVolunteerOut}
                                                                
                                                            >
                                                                Adicionar
                                                            </Button>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            ))}                   
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>

                                {id ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Divider variant="middle" />
                                            <Accordion 
                                                expanded={updateInputValue(users, values, 'confirmar_voluntariado') == "on" ? true : false}
                                                elevation={0}
                                            >
                                                <AccordionSummary
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                        <Stack
                                                            sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                            direction={{ xs: 'column', lg: 'row' }}
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            spacing={2}
                                                        >
                                                            <Typography
                                                                sx={{fontSize: '1.05rem'}}
                                                            >
                                                                Confirmar voluntariado?
                                                            </Typography>

                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                spacing={1}
                                                            >
                                                                <Typography>Não</Typography>
                                                                    <FormControlLabel
                                                                        control={
                                                                        <Switch 
                                                                            checked={updateInputValue(users, values, 'confirmar_voluntariado') == "on" ? true : false}
                                                                            
                                                                            onChange={handleChange}
                                                                            name="confirmar_voluntariado" 
                                                                            disabled={isDisabled || status == false}
                                                                        />
                                                                        }
                                                                        labelPlacement="start"
                                                                    />
                                                                <Typography>Sim</Typography>
                                                            </Stack>

                                                        </Stack>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{display: 'flex', gap: '1.5rem'}}>
                                                    <Grid container spacing={3} alignContent={'center'}>
                                                        <Grid item xs={4} >
                                                            {/* <JumboTextField
                                                                fullWidth
                                                                name="area_id"
                                                                label="Área"
                                                                value={updateInputValue(users, values, 'area_id')}
                                                                disabled={true}
                                                            /> */}
                                                            <FormControl fullWidth>
                                                                <InputLabel id="area_id">Área</InputLabel>
                                                                <JumboSelectField
                                                                    labelId="area_id"
                                                                    id="area_id"
                                                                    name="area_id"
                                                                    value={updateInputValue(users, values, 'area_id')}
                                                                    label="Área"
                                                                    //disabled={true}
                                                                    InputLabelProps={{ 
                                                                        shrink: true 
                                                                    }}
                                                                >
                                                                    <MenuItem value={''}>Sem seleção</MenuItem>
                                                                    {areas.length > 0 && areas.map((x) => 
                                                                        <MenuItem value={x.area_id}>{x.nome}</MenuItem>
                                                                    )}
                                                                </JumboSelectField>
                                                                { id && values.Areas !== null && !areas.some(y => y.area_id === values.area_id) && values.area_id !== '' && (
                                                                    <FormHelperText error>
                                                                        A área selecionada foi excluída. Por favor, selecione outra área.
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={4} >
                                                        <FormControl fullWidth>
                                                            <InputLabel shrink id="departamento_id">Departamento</InputLabel>
                                                            <JumboSelectField
                                                                labelId="departamento_id"
                                                                id="departamento_id"
                                                                name="departamento_id"
                                                                value={updateInputValue(users, values, 'departamento_id')}
                                                                label="Departamento"
                                                                //disabled={true}
                                                                sx={{ background: '#ffffff'}}
                                                                notched
                                                            >
                                                                <MenuItem value={''}>Sem seleção</MenuItem>
                                                                {(selectedArea?.departamento || []).map((x) => 
                                                                    <MenuItem value={x.departamento_id}>{x.nome}</MenuItem>
                                                                )}
                                                                
                                                            </JumboSelectField>
                                                            { id && values.Departamento !== null && !departments.some(y => y.departamento_id === values.departamento_id) && values.departamento_id && (
                                                                <FormHelperText error>
                                                                O departamento selecionado foi excluído. Por favor, selecione outro departamento.
                                                                </FormHelperText>
                                                            )}
                                                        </FormControl>
                                                        </Grid>
                                                        <Grid item xs={4} >
                                                            <FormControl fullWidth>
                                                                <InputLabel shrink id="local">Local</InputLabel>
                                                                <JumboSelectField
                                                                    labelId="local"
                                                                    id="local"
                                                                    name="local"
                                                                    //value={updateInputValue(classes, values, 'local')}
                                                                    label="Local"
                                                                    //disabled={true}
                                                                    notched
                                                                >
                                                                    <MenuItem value={'Casa Transitória'}>Casa Transitória</MenuItem>
                                                                    <MenuItem value={'Sede'}>Sede</MenuItem>
                                                                    <MenuItem value={'Outro'}>Outro</MenuItem>
                                                                </JumboSelectField>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <JumboTextField
                                                                fullWidth
                                                                name="data_inicio"
                                                                type="date"
                                                                label="Data de início"
                                                                //value={}
                                                                sx={{ background: '#ffffff'}}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                disabled={isDisabled}
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <JumboTextField
                                                                fullWidth
                                                                name="data_fim"
                                                                type="date"
                                                                label="Data Final"
                                                                //value={}
                                                                sx={{ background: '#ffffff'}}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                disabled={isDisabled}
                                                            />
                                                        </Grid> 

                                                        <Grid item xs={6} >
                                                        <div style={{ position: 'relative' }}> 
                                                                <CreatableSelect 
                                                                    isClearable
                                                                    options={activities}
                                                                    onChange={(newValue) => setActivitiesSelected(newValue)}
                                                                    onCreateOption={handleCreate}
                                                                    styles={customStyles}
                                                                    placeholder=''
                                                                    maxMenuHeight={100}
                                                                    isDisabled={isDisabled}
                                                                    
                                                                />
                                                                <Typography 
                                                                    style={{ 
                                                                        position: 'absolute', 
                                                                        top: '-9px', 
                                                                        left: '10px', 
                                                                        background: '#ffffff', 
                                                                        padding: '0 5px',
                                                                        color: '#8595A6',
                                                                        fontSize: '0.7rem',
                                                                        fontFamily: 'NoirPro,Arial'
                                                                    }}>
                                                                    Selecione atividade
                                                                </Typography>
                                                        </div>
                                                        </Grid> 
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            { values.confirmar_voluntariado == 'on' ? null : <Divider variant="middle" />}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Accordion
                                                expanded={updateInputValue(users, values, 'infos_usuario') == 'on' ? true : false}
                                                elevation={0}
                                            >
                                                <AccordionSummary>
                                                    <Stack
                                                        sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                        direction={{ xs: 'column', lg: 'row' }}
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <Typography
                                                            sx={{fontSize: '1.05rem'}}
                                                        >
                                                            Informações sobre criação e atualização do usuário
                                                        </Typography>

                                                        <Stack 
                                                            direction="row" 
                                                            spacing={1} 
                                                            alignItems="center"
                                                        >
                                                            <Typography>Ocultar</Typography>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch 
                                                                            checked={updateInputValue(users, values, 'infos_usuario') == 'on' ? true : false}
                                                                            onChange={handleChange}
                                                                            name="infos_usuario" 
                                                                            disabled={isDisabled}
                                                                        />
                                                                    }
                                                                    labelPlacement="start"
                                                                />
                                                            <Typography>Visualizar</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                <Grid item xs={12}>
                                                <InfosAboutCreateAndUpdate 
                                                    data={users}
                                                    quem_criou={users?.quem_aceitou?.nome ?? '-'}
                                                    quem_atualizou={users?.quem_atualizou?.nome ?? '-'}
                                                />
                                                </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    </>

                                )
                                : null
                                }
                                
                                {!id ? (
                                    <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                        <LoadingButton
                                            color="success"
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                            loading={isSubmitting}
                                            disabled={!hasPermission('Voluntariado', 'criar') || isInputFocused || status == false}
                                        >
                                            Salvar
                                        </LoadingButton>
                                    </Grid>
                                ):(
                                    <>
                                        {values.confirmar_voluntariado == 'on' // verifica se o switch é true
                                            ?
                                            (
                                                <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                                    {console.log(values.data_inicio)}
                                                    <LoadingButton
                                                        color="success"
                                                        type="submit"
                                                        variant="contained"
                                                        size="large"
                                                        sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                                        loading={isSubmitting}
                                                        disabled={!hasPermission('Voluntariado', 'editar') || isInputFocused || values.data_inicio == '' || values.data_inicio == undefined}

                                                    >
                                                        Confirmar
                                                    </LoadingButton>
                                                </Grid>
                                            )
                                            :
                                            (
                                                <>
                                                    
                                                    <Grid item xs={4} sx={{textAlign: 'center'}}> 
                                                        <LoadingButton
                                                            color="success"
                                                            type="submit"
                                                            variant="contained"
                                                            size="large"
                                                            sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                                            loading={isSubmitting}
                                                            disabled={isDisabled || isInputFocused || status == false}
                                                        >
                                                            Atualizar
                                                        </LoadingButton>
                                                    </Grid>
                                                    <Grid item sx={4}>
                                                        <Tooltip 
                                                            title="Enviar email de confirmação de voluntariado"
                                                            TransitionComponent={Zoom}
                                                            arrow
                                                        >
                                                            <LoadingButton 
                                                                loading={loading}
                                                                variant="contained" 
                                                                endIcon={<SendIcon />} 
                                                                size="large"
                                                                onClick={() => modalAlertEmail(id)}
                                                                disabled={!hasPermission('Voluntariado', 'editar') || users.status === 'inativo'}
                                                            >
                                                                Enviar email
                                                            </LoadingButton>
                                                            
                                                        </Tooltip>

                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {status 
                                                            ?
                                                            (
                                                                <Button 
                                                                    color='error' 
                                                                    variant="contained"  
                                                                    size="large"
                                                                    onClick={() => modalAlertInteresseEncerrar(id)}
                                                                    disabled={!hasPermission('Voluntariado', 'editar')}
                                                                >
                                                                    Encerrar interesse
                                                                </Button>

                                                            )
                                                            :
                                                            (
                                                                <Button 
                                                                    color='success' 
                                                                    variant="contained"  
                                                                    size="large"
                                                                    onClick={() => modalAlertInteresseAtivar(id)}
                                                                    disabled={!hasPermission('Voluntariado', 'editar')}
                                                                >
                                                                    Ativar interesse
                                                                </Button>
                                                            )
                                                        }
                                                    </Grid> 
                                                </>
                                            )
                                        }
                                    </>
                                )}
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </Paper>
    </JumboContentLayout>
  )
}

export default VolunteerInterestedFormResume