
import React from 'react'
import FileItemSlim from './FileItemSlim.js'

const FileListSlim = ({ files, removeFile, isEditingFile, textFile, handleInputChangeFile, handleSaveClick, handleEditClick, setIsEditingFile}) => {
    return (
        <ul style={{padding: '0 2rem'}}>
            {
                files &&
                files.map((f, index) => (
                    <FileItemSlim
                        key={f.nome}
                        file={f}
                        files={files}
                        index={index}
                        removeFile={removeFile}
                        isEditingFile={isEditingFile}
                        textFile={textFile}
                        handleInputChangeFile={handleInputChangeFile}
                        handleSaveClick={handleSaveClick}
                        handleEditClick={handleEditClick}
                        setIsEditingFile={setIsEditingFile}
                    />
                ))
            }
        </ul>
    )
}

export default FileListSlim