import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TableCell,
  TableRow,
  Button,
  Tooltip,
  Zoom,
  Grid,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MUIDataTable from 'mui-datatables';
import FileListSlim from '../FileUploadSlim/FileListSlim';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import moment from 'moment';
import styled from '@emotion/styled';

const FileListItem = styled.li`
  list-style: none;
  /* margin: 1rem 0; */
  background-color: rgba(0,0,0,.07);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.9rem;
`;

const CompatibleCoursesList = ({
  coursesCompatible,
  showButton = false,
  modalAlertDelete = undefined,
  userId = undefined,
  spiritualCenterSelectedId = undefined,
  getCourseHistoryFromSpiritualCenter = undefined,
}) => {
  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      title: message,
      icon: variant,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      showCloseButton: true,
    });
  };

  const [files, setFiles] = useState([]);
  const [filesSelected, setFilesSelected] = useState([]);
  const [isEditingFile, setIsEditingFile] = useState(false);
  const [textFile, setTextFile] = useState(null);

  const uploadHandler = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    setFiles([...files, file]);
    setFilesSelected([...filesSelected, file]);
  };

  const removeFile = (index) => {
    setFiles(files.filter((file, i) => i !== index));
  };

  const handleSaveClick = (index) => {
    setIsEditingFile(false);
    files.map((file, i) =>
      i == index ? (file.nome_label = textFile) : file.nome_label
    );
    console.log('Texto editado:', textFile);
    setTextFile('');
  };

  const handleEditClick = (index) => {
    setIsEditingFile({ active: true, index: index });
    setTextFile(
      files
        .filter(
          (file, i) =>
            i === index &&
            (file.nome_label || file.nome || file instanceof File)
        )
        .map((file) => {
          if (file instanceof File && file.nome_label) {
            return file.nome_label;
          }
          return file.nome_label || file.nome || file.name;
        })
    );
  };

  const handleInputChangeFile = (e) => {
    setTextFile(e.target.value);
  };

  const uploadFile = async () => {
    let formData = new FormData();
    filesSelected.forEach((file, index) => {
      if (file instanceof File) {
        formData.append('docs', file);
        formData.append(`nome_label_${index}`, file.nome_label);
      }
    });

    if (files.length > 0) {
      formData.append('pessoa_id', userId); // Adiciona o pessoa_id
      formData.append('centro_espirita_id', spiritualCenterSelectedId);

      ApiService.post(`/documentos/upload`, formData)
        .then(() => {
          setFiles([]);
          setFilesSelected([]);
          setTextFile(null);
          getCourseHistoryFromSpiritualCenter();
          toast('success', 'Adicionado com sucesso');
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    }
  };

  const modalAlertDeleteFile = (id) => {
    console.log(id);
    Swal.fire({
      title: 'Tem certeza que deseja apagar?',
      text: 'Não será póssível reverter a ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        removeFileFromBd(id);
      }
    });
  };

  const removeFileFromBd = async (id) => {
    ApiService.delete(`/documentos/remove/${id}`)
      .then(() => {
        setFiles([]);
        setFilesSelected([]);
        setTextFile(null);
        getCourseHistoryFromSpiritualCenter();
        toast('success', 'Removido com sucesso');
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('error', error.message);
        }
      });
  };

  if (coursesCompatible?.length > 0) {
    const hasCompatibleCourses = coursesCompatible.some(
      (el) => el.curso_compativel_id !== null || el?.Turma?.local === 'Externo'
    );

    const coursesCompatibleFiltered = coursesCompatible.filter(
      (item) =>
        item.curso_compativel_id !== null || item?.Turma?.local === 'Externo'
    );

    const columns = [
      {
        name: 'Curso',
        label: 'Curso',
        options: {
          filter: false,
          sort: false,
          setCellHeaderProps: (value) => ({
            width: '40%',
          }),
          setCellProps: () => ({
            width: '40%',
          }),
          customBodyRender: (value) => (
            <Typography variant='body1'>{value ?? '-'}</Typography>
          ),
        },
      },
      {
        name: 'Ano',
        label: 'Conclusão',
        options: {
          filter: false,
          sort: false,
          setCellHeaderProps: () => ({
            align: 'center',
          }),
          setCellProps: () => ({
            align: 'center',
          }),
          customBodyRender: (value) => (
            <Typography variant='body1'>{value ?? '-'}</Typography>
          ),
        },
      },
      /* {
        name: 'Local',
        label: 'Local',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => (
            <Typography variant='body1'>{value ?? '-'}</Typography>
          ),
        },
      }, */
      {
        name: 'Observacao',
        label: 'Comentários',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => (
            <Typography variant='body1'>{value ?? '-'}</Typography>
          ),
        },
      },
      /* {
        name: 'data_analise',
        label: 'Data da Análise Realizada',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const createdData =
              coursesCompatibleFiltered[0].Analise_Centro_Espirita[0]
                ?.createdAt;
            const formattedDate = createdData
              ? moment(createdData).format('DD/MM/YYYY')
              : '-';
            return <Typography>{formattedDate}</Typography>;
          },
        },
      }, */
      {
        name: 'Curso_Historico_Id',
        label: 'Ação',
        options: {
          filter: false,
          sort: false,
          display: showButton ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            <>
              <Button
                disableElevation
                variant={'contained'}
                size={'small'}
                color={'secondary'}
                //disabled={!hasPermission('Pessoas', 'deletar') ? true : false}
                onClick={() => modalAlertDelete(value)}
              >
                <Tooltip title='Excluir' TransitionComponent={Zoom} arrow>
                  <DeleteIcon />
                </Tooltip>
              </Button>
            </>
          ),
        },
      },
    ];

    const transformData = (turmas) => {
      return turmas
        .filter((turma) => turma.Curso_Historico.length > 0)
        .map((turma) => ({
          Curso: turma.Curso.nome,
          Ano: turma.ano,
          Local: turma?.Centro_Espirita?.nome_fantasia,
          Observacao: turma.Curso_Historico[0]?.observacao,
          Curso_Historico_Id: turma.Curso_Historico[0]?.curso_historico_id,
        }));
    };

    const CustomTableWithFooter = ({ centro }) => {
      console.log(centro);
      const transformedData = transformData(centro.Turmas);
      const analiseData = centro?.Analise_Centro_Espirita[0]?.analise;
      const createdData = centro?.Analise_Centro_Espirita[0]?.createdAt;
      const formattedDate = createdData
        ? moment(createdData).format('DD/MM/YYYY')
        : '-';

      const renderFooterRow = () => {
        const docs = centro.Documento_De_Curso_Outra_Entidade;

        return (
          <>
            <TableRow>
              <TableCell>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography
                    /* textAlign={'center'} */
                    fontWeight={'bold'}
                    fontSize={'1.2rem'}
                  >
                    Análise realizada
                  </Typography>
                  {/* <Typography
                    fontWeight={'bold'}
                    fontSize={'1rem'}
                  >
                    {`Análise realizada em: ${formattedDate}`}
                  </Typography> */}
                </Box>
                <Typography>
                  {analiseData ?? 'Nenhuma análise encontrada'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow sx={{ background: 'rgba(0,0,0,.05)' }}>
              <TableCell colSpan={columns.length}>
                <Box
                  display={'flex'}
                  flexWrap={'wrap'}
                  gap={3}
                  flexDirection={'column'}
                >
                  <Typography>Declaração(ões) da Entidade de Origem</Typography>
                  {docs.map((item) => (
                    <FileListItem className='file-item' key={item.nome}>
                      <p>
                        <a
                          href={`${process.env.REACT_APP_API_KEY}/images/${item.nome}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          {item?.nome_label || item?.nome.replace(/^\d+_/, '')}
                        </a>
                      </p>

                      {showButton && (
                        <Button
                          variant='contained'
                          size='small'
                          color='secondary'
                          onClick={() =>
                            modalAlertDeleteFile(
                              item.documento_de_curso_outra_entidade_id
                            )
                          }
                        >
                          <DeleteIcon />
                        </Button>
                      )}
                    </FileListItem>
                  ))}
                </Box>
              </TableCell>
            </TableRow>
          </>
        );
      };

      return (
        <Box mb={4} px={2}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography
              variant='h6'
              fontSize={'1rem'}
              fontWeight={'bold'}
              gutterBottom
            >
              Entidade de Origem: {centro?.nome_fantasia}
            </Typography>
            {centro?.Analise_Centro_Espirita[0]?.diretor?.nome && (
              <Typography
                variant='h6'
                fontSize={'1rem'}
                fontWeight={'bold'}
                gutterBottom
              >
                {/* Analisado por:
                {centro?.Analise_Centro_Espirita[0]?.diretor?.nome} */}
                {` Analisado por: ${centro?.Analise_Centro_Espirita[0]?.diretor?.nome} \u00A0\u00A0\u00A0\u00A0 Em: ${formattedDate}`}
              </Typography>
            )}
          </Box>
          <MUIDataTable
            //title={centro.nome_fantasia || centro.nome}
            data={transformedData}
            columns={columns}
            options={{
              filterType: 'checkbox',
              selectableRows: 'none',
              search: false,
              download: false,
              print: false,
              sortFilterList: false,
              viewColumns: false,
              filter: false,
              footer: true,
              customFooter: () => renderFooterRow(),
              textLabels: {
                pagination: {
                  next: 'Próxima Página',
                  previous: 'Página Anterior',
                  rowsPerPage: 'Itens por Página:',
                  displayRows: 'de',
                },
              },
              setTableProps: () => {
                return {
                  size: 'small',
                };
              },
            }}
          />
        </Box>
      );
    };

    if (hasCompatibleCourses) {
      return (
        <Box>
          {coursesCompatibleFiltered.map((centro, index) => (
            <Box key={index} mb={4}>
              <CustomTableWithFooter key={index} centro={centro} />
            </Box>
          ))}
          {showButton && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Button
                  variant='contained'
                  component='label'
                  color='info'
                  sx={{
                    height: '53.13px',
                    fontSize: {
                      xs: '0.7rem',
                    },
                    textAlign: 'center',
                  }}
                >
                  Adicionar mais declarações
                  <input
                    type='file'
                    hidden
                    name='imagem_area'
                    onChange={uploadHandler}
                  />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FileListSlim
                  files={files}
                  removeFile={removeFile}
                  isEditingFile={isEditingFile}
                  textFile={textFile}
                  handleInputChangeFile={handleInputChangeFile}
                  handleSaveClick={handleSaveClick}
                  handleEditClick={handleEditClick}
                  setIsEditingFile={setIsEditingFile}
                />
              </Grid>
              {files.length > 0 && (
                <Grid item xs={12}>
                  <Button variant='contained' onClick={() => uploadFile()}>
                    Enviar declaração(ões)
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      );
    } else {
      return 'Nenhum curso compatibilizado encontrado';
    }
  } else {
    return 'Nenhum curso compatibilizado encontrado';
  }
};

export default CompatibleCoursesList;
