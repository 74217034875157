import React from 'react'
import { Box, Paper, Typography} from '@mui/material'

const InfosAboutCreate = ({data, quem_criou, paperBg = '#ffffff'}) => {
    const moment = require('moment-timezone');
    const dateTimeCreatedAt = data && data?.data_quem_autorizou;

    let dateCreatedAtBr;
    let timeCreatedAtBr;

    
    if(dateTimeCreatedAt) {
        dateCreatedAtBr = moment(dateTimeCreatedAt).tz('America/Sao_Paulo').format('DD/MM/YYYY');
        timeCreatedAtBr = moment(dateTimeCreatedAt).tz('America/Sao_Paulo').format('HH:mm:ss')
    }
    console.log(dateCreatedAtBr, timeCreatedAtBr)

    return (
        <Box display='flex' justifyContent='center' width='100%' gap='1rem' marginBottom='1rem'>
            <Paper elevation={1} sx={{p: '10px', background: paperBg}}>
                <Box textAlign='center'>
                    <Typography variant='h6'>
                        Quem autorizou?
                    </Typography>
                    <Typography variant='h6'>
                    {/*  {users?.quem_aceitou?.nome} */}
                    {quem_criou}
                    </Typography>
                </Box>
            </Paper>
            <Paper elevation={1} sx={{p: '10px', background: paperBg}}>
                <Box textAlign='center'>
                    <Typography variant='h6'>
                        Data de autorização
                    </Typography>
                    <Typography variant='h6'>
                    {/*  {users?.quem_aceitou?.nome} */}
                    {dateCreatedAtBr}
                    </Typography>
                </Box>
            </Paper>
        </Box>
    )
}

export default InfosAboutCreate