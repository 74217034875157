import React, { useEffect } from 'react';
import {Box, Card, CardContent, Checkbox, FormControlLabel, IconButton, InputAdornment, Typography} from "@mui/material";
/* import Link from "@mui/material/Link"; */
import Stack from "@mui/material/Stack";
import {Facebook, Google, Twitter, Visibility, VisibilityOff} from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import {alpha} from "@mui/material/styles";
import {ASSET_LOGOS} from "../../utils/constants/paths";
import {getAssetPath} from "../../utils/appHelpers";
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import authServices from "../../servicesTwo/auth-services";
import {Link, useNavigate} from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import InputMask from 'react-input-mask';
import { useState } from 'react';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { cpf } from 'cpf-cnpj-validator'; 

function formatCPF(cpf) {
    if (cpf.length <= 11) {
      const regex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
      return cpf.replace(regex, "$1.$2.$3-$4");
    } else {
      // Se o CPF tiver mais de 11 dígitos, você pode retornar o CPF formatado até os 11 dígitos e ignorar os extras.
      return cpf.substring(0, 14); // 14 caracteres para incluir os pontos e o traço
    }
  }

const validationSchema = yup.object({
    email: yup
        .string('Insira seu email')
        .email('Insira um email válido'),
    cpf: yup
        .string()
        .required('Preenchimento obrigatório')
        .test({
          message: 'CPF inválido',
          test: (value) => cpf.isValid(value),
        }),
    senha: yup
        .string()
        .required('Preenchimento obrigatório'),
    senhaConfirmacao: yup
        .string()
        .required('Preenchimento obrigatório')
        .oneOf([yup.ref('senha')], 'As senhas devem ser iguais')
    
});


const Signup = ({disableSmLogin}) => {
    const {setAuthToken} = useJumboAuth();

    const navigate = useNavigate();

    const Swal = useSwalWrapper();

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

    const toast = (variant, message, type = false) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });
      
    };

    const onRegisterUser = async (email, cpf, senha, celular, setSubmitting) => {
        await authServices.signUp({email, cpf, senha, celular })
        .then((data) => {
            
        })
        .catch((err) => {
            if(err.response.data.error) {
                alert(err.response.data.error);
            } else {
                alert(err.response.data);
            }
        })

        setSubmitting(false)
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPasswordConfirmation = () => setShowPasswordConfirmation((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseDownPasswordConfirmation = (event) => {
        event.preventDefault();
    };

    return (
        <Div sx={{
            width: 850,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: {xs: 'column', md: 'row'}
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 350px',
                        position: 'relative',
                        /* background: `#0267a0 url(/images/logos/logo.png) no-repeat center`, */
                        backgroundImage: 'url(/images/logos/logo.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: '100%',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                        
                        },


                    }}
                >
                </CardContent>
                <CardContent sx={{flex: 1, p: 4}}
                >
                    <Box marginBottom={2}>
                        <Typography variant='h3' color="#686868" sx={{fontWeight: 'bold'}}>
                            Cadastre-se
                        </Typography>
                        <Typography variant='h6'>
                            Para criar uma conta preencha o formulário abaixo.
                        </Typography>
                    </Box>
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            email: '',
                            cpf: '',
                            senha: '',
                            senhaConfirmacao: '',
                            celular: '05511'
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => {
                            setSubmitting(true);
                            onRegisterUser(data.email, data.cpf, data.senha, data.celular, setSubmitting);
                        }}
                    >
                        {({isSubmitting, handleChange, values, setFieldTouched, setFieldValue}) => (
                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                                <Div sx={{mt: 1, mb: 3}}>
                                    <JumboTextField
                                        fullWidth
                                        name="email"
                                        label="Email"
                                        value={values.email}
                                        onBlur={() => setFieldTouched('email', true)} 
                                    />
                                </Div>
                                <Div sx={{mt: 1, mb: 3}}>
                                    <JumboTextField
                                        fullWidth
                                        name="cpf"
                                        label="CPF"
                                        value={values.cpf}
                                        onChange={(event) => {
                                            const {name, value} = event.target;
                                            setFieldValue(name, formatCPF(value));
                                        }}
                                        onBlur={() => setFieldTouched('cpf', true)} 
                                    />
                                </Div>
                                <Div sx={{mt: 1, mb: 2}}>
                                    <JumboTextField
                                        fullWidth
                                        id="senha"
                                        type={showPassword ? 'text' : 'password'}
                                        name="senha"
                                        value={values.senha}
                                        InputProps={{ 
                                            endAdornment: 
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    >
                                            {showPassword ? <VisibilityOff /> : <Visibility/>}
                                            </IconButton>
                                            </InputAdornment>,
                                        }}
                                        sx={{ background: '#ffffff'}}
                                        label="Senha"
                                        onBlur={() => setFieldTouched('senha', true)} 
                                    />
                                </Div>
                                <Div sx={{mt: 1, mb: 2}}>
                                    <JumboTextField
                                        fullWidth
                                        id="senhaConfirmacao"
                                        type={showPasswordConfirmation ? 'text' : 'password'}
                                        name="senhaConfirmacao"
                                        InputProps={{
                                            endAdornment: 
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPasswordConfirmation}
                                                    onMouseDown={handleMouseDownPasswordConfirmation}
                                                    edge="end"
                                                    >
                                            {showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </InputAdornment>,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        sx={{ background: '#ffffff'}}
                                        label="Confirmar nova senha"
                                        onBlur={() => setFieldTouched('senhaConfirmacao', true)} 
                                    />
                                </Div>
                                <LoadingButton
                                    color="info"
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    loading={isSubmitting}
                                >
                                    Cadastrar
                                </LoadingButton>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </Div>
    );
};

export default Signup;
