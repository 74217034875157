import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, Paper, Radio, RadioGroup, Stack,Switch,Tooltip,Typography,Zoom,useMediaQuery} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { updateInputValue, disableInputValue } from "../../../utils/appHelpers";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import TextField from '@mui/material/TextField';
import JumboSearch from "@jumbo/components/JumboSearch";
/* import ClassStudentsList from "./ClassStudentsList"; */
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';
import SendIcon from '@mui/icons-material/Send';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import ListRowVolunteerInterested from 'app/components/ListRowVolunteerInterested/ListRowVolunteerInterested';
import CreatableSelect from 'react-select/creatable';
import ListRowAssociate from 'app/components/ListRowAssociates/ListRowAssociate';
import FileUpload from 'app/components/FileUpload/FileUpload';
import FileList from 'app/components/FileUpload/FileList';
import JumboSearchClasses from '@jumbo/components/JumboSearchClasses';
import { useDebouncedCallback } from 'beautiful-react-hooks';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
};

const AssociateFormResume = () => {

    const {theme} = useJumboTheme();
    const {showDialog, hideDialog} = useJumboDialog();

    const navigate = useNavigate();
    const moment = require('moment-timezone');

    const Swal = useSwalWrapper();
    const toast = (variant, message, type = false) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });

        //  Redireciona se mensagem de retorno for de sucesso
        
        /* if(id) {
            if(variant == 'success' && (!userFile || (userFile && type) || !files || (files && type))){
                setTimeout(function() {
                    navigate("/app/listar-associados")
                }, 2000)
            }
        } */
        
    };

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const initialValues = {
        associado_id: "",
        pessoa_id: "",
        status: "",
        data_inicio_associado: "",
        data_inicio_associado_efetivo: "",
        data_inicio_associado_contribuinte: "",
        data_fim_associado: "",
        ficha: "",
        tipo_associado: "",
        encerrar_associacao: false,
        seguir_associacao_efetiva: false
    }

    const validationSchema = yup.object().shape({
        //motivo_encerramento: yup.string().required('Preenchimento obrigatório'),
        departamento_id: yup.number().nullable(),
        area_id: yup.number().nullable(),
        local: yup.string().required('Preenchimento obrigatório'),
        }).test('departamento-ou-area', 'Escolha apenas departamento ou área', function (value) {
        if ((!value.departamento_id && !value.area_id) || (value.departamento_id && value.area_id)) {
            return this.createError({ message: 'Escolha apenas departamento ou área', path: 'departamento_id' });
        }
        return true;
    }, []);

    const { id } = useParams()
    
    const [searchTerm, setSearchTerm] = useState(null);
    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState(initialValues);
    const [addStudents, setAddStudents] = useState(null);
    const [classStudents, setClassStudents] = useState([]);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [previewFile, setPreviewFile] = useState(null);
    const [userFile, setUserFile] = useState(null);
    const [status, setStatus] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [userVolunteerCourse, setUserVolunteerCourse] = useState(false);
    const [verifyVolunteerCourse, setVerifyVolunteerCourse] = useState({});
    const [username, setUsername] = useState('');
    const [departments, setDepartments] = useState([]);
    const [areas, setAreas] = useState([]);
    const [activities, setActivities] = useState([]);
    const [activitiesSelected, setActivitiesSelected] = useState(null);
    const [files, setFiles] = useState([])
    const [filesSelected, setFilesSelected] = useState([])
    const [isEditingFile, setIsEditingFile] = useState(false);
    const [textFile, setTextFile] = useState(null);

    const [userPreferences, setUserPreferences] = useState([{ dia_da_semana: "", horario_inicio: "", horario_fim: "" }])
    const [deletedPreferences, setDeletedPreferences] = useState([]);

    const [userVolunteerOut, setUserVolunteerOut] = useState([{ nome_trabalho_voluntario_fora: "", local_fora: "", data_inicio_voluntario_fora: "", obs_fora: ""}])
    const [deletedVolunteerOut, setDeletedVolunteerOut] = useState([]);

    const { hasPermission } = useContext(PermissionContext);
    let { state, pathname } = useLocation();

    const canCreate = hasPermission('Associados', 'criar');
    const canEdit = hasPermission('Associados', 'editar');
    const isDisabled = id ? !canEdit : !canCreate
    
    const storedUserId = localStorage.getItem("storedUserId");

    const getAssociateById = useCallback(async () => {
        try {
            ApiService.get(`/associados/${id}`)
            .then((response) => {
                console.log('iii', response.data)
                setUsers(response.data);
                setSelectedOption(response.data.User)
                setFiles(response.data.Documentos_De_Associados)
                
                if(response.data.status == 'ativo') {
                    setStatus(true)
                }
    
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const getDepartments = useCallback(async () => {
        try {
            ApiService.get(`/departamentos`)
            .then((response) => {
              setDepartments(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const getAreas = useCallback(async () => {
        try {
            ApiService.get(`/areas`)
            .then((response) => {
              setAreas(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const getAtividades = useCallback(async () => {
        try {
            ApiService.get(`/atividades`)
            .then((response) => {
                console.log(response.data)
              setActivities(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    useEffect(() => {
        if(id) getAssociateById();
        getDepartments();
        getAreas();
        getAtividades();
    }, []);

    useEffect(() => {
        Swal.close();
    }, [pathname]);
    

    console.log(files)
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        //return console.log('Submit', teste)
        let associateID = id;
        let data = new FormData()
        data.append("ficha", userFile)
        
        let formData = new FormData();
        filesSelected.forEach((file, index) => {
            if (file instanceof File) {
                formData.append("docs", file);
                formData.append(`nome_label_${index}`, file.nome_label);
            }
        });
        
        if(id) {
            
            if(values.encerrar_associacao == 'on') {
                //encerrar voluntariado
                const result = await Swal.fire({
                    title: 'Tem certeza que deseja encerrar associação?',
                    text: 'Não será possível reverter a ação!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim!',
                    cancelButtonText: 'Não!',
                    reverseButtons: true,
                });

                /* const allValuesEdit = {...values, horario_voluntariado: userPreferences, trabalho_voluntario_fora_feesp: userVolunteerOut } */
               
                if(result.value) {
                    await ApiService.put(`/encerrar-associacao/${id}`, { values })
                    .then((response) => {
                    toast('success', 'Associação encerrada com sucesso')
                    })
                    .catch((error) => {
                    toast('error', 'Ocorreu um erro')
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        } else if (error.request) {
                        console.log(error.request);
                        } else {
                        console.log('error', error.message);
                        }
                    })
                }
            } else if(values.seguir_associacao_efetiva == 'on') {
                //encerrar voluntariado
                console.log('aqui')
                const result = await Swal.fire({
                    title: 'Tem certeza que deseja seguir com a associação?',
                    text: 'Não será possível reverter a ação!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim!',
                    cancelButtonText: 'Não!',
                    reverseButtons: true,
                });

                /* const allValuesEdit = {...values, horario_voluntariado: userPreferences, trabalho_voluntario_fora_feesp: userVolunteerOut } */
                const allValuesEdit = {...values, tipo_associado: 'Associado Efetivo'}
               
                if(result.value) {
                    await ApiService.post(`/seguir-associacao-efetiva/${id}`, { values: allValuesEdit, files })
                    .then((response) => {
                        associateID = response.data.associado_id
                        toast('success', 'Associação encerrada com sucesso')

                        if(files.length > 0) {
        
                            ApiService.put(`/associados/atualizar-docs/${associateID}`, formData,)
                            .then(() => {
                            })
                            .catch((error) => {
                            console.log(error)
                            
                            toast('error', 'Ocorreu um erro ao enviar os documentos', true)
            
                            if (error.response) {
                                console.log(error.response.data);
                                console.log(error.response.status);
                                console.log(error.response.headers);
                                } else if (error.request) {
                                console.log(error.request);
                                } else {
                                console.log('error', error.message);
                                }
                            })
                        }
                    })
                    .catch((error) => {
                    toast('error', error.response.data.error)
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        } else if (error.request) {
                        console.log(error.request);
                        } else {
                        console.log('error', error.message);
                        }
                    })
                }
            } else if(values.seguir_associacao_contribuinte == 'on') {
                //encerrar voluntariado
                console.log('aqui')
                const result = await Swal.fire({
                    title: 'Tem certeza que deseja seguir com a associação?',
                    text: 'Não será possível reverter a ação!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim!',
                    cancelButtonText: 'Não!',
                    reverseButtons: true,
                });

                /* const allValuesEdit = {...values, horario_voluntariado: userPreferences, trabalho_voluntario_fora_feesp: userVolunteerOut } */
                const allValuesEdit = {...values, tipo_associado: 'Associado Contribuinte'}
               
                if(result.value) {
                    await ApiService.post(`/seguir-associacao-contribuinte/${id}`, { values: allValuesEdit, files })
                    .then((response) => {
                        associateID = response.data.associado_id
                        toast('success', 'Associação encerrada com sucesso')
                        if(files.length > 0) {
        
                            ApiService.put(`/associados/atualizar-docs/${associateID}`, formData,)
                            .then(() => {
                            })
                            .catch((error) => {
                            console.log(error)
                            
                            toast('error', 'Ocorreu um erro ao enviar os documentos', true)
            
                            if (error.response) {
                                console.log(error.response.data);
                                console.log(error.response.status);
                                console.log(error.response.headers);
                                } else if (error.request) {
                                console.log(error.request);
                                } else {
                                console.log('error', error.message);
                            }
                            })
                        }
                    })
                    .catch((error) => {
                    toast('error', error.response.data.error)
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        } else if (error.request) {
                        console.log(error.request);
                        } else {
                        console.log('error', error.message);
                        }
                    })
                }
            }
            else {
                //atualiza form sem encerrar associação
                await ApiService.put(`/associados/${id}`, { values, files })
                .then((response) => {
                    if (!userFile) toast('success', 'Associado atualizado com sucesso')
                    setSubmitClicked(true)
                    if(files.length > 0) {
        
                        ApiService.put(`/associados/atualizar-docs/${associateID}`, formData,)
                        .then(() => {
                        })
                        .catch((error) => {
                        console.log(error)
                        
                        toast('error', 'Ocorreu um erro ao enviar os documentos', true)
        
                        if (error.response) {
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                            } else if (error.request) {
                            console.log(error.request);
                            } else {
                            console.log('error', error.message);
                            }
                        })
                    }
                })
                .catch((error) => {
                    toast('error', error.response.data.error);
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    console.log(error.request);
                    } else {
                    console.log('error', error.message);
                    }
                })
                
            }
        } else {
            //horario_voluntariado: userPreferences, trabalho_voluntario_fora_feesp: userVolunteerOut,
            const allValues = {...values, pessoa_id: username[0].pessoa_id,}
            //return console.log(allValues)
            await ApiService.post('/associados', { values: allValues })
            .then((response) => {
                console.log(response)
                associateID = response.data.associado_id
                toast('success', 'Criado com sucesso')
                if(files.length > 0) {
        
                    ApiService.put(`/associados/atualizar-docs/${associateID}`, formData,)
                    .then(() => {
                    })
                    .catch((error) => {
                    console.log(error)
                    
                    toast('error', 'Ocorreu um erro ao enviar os documentos', true)
    
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        } else if (error.request) {
                        console.log(error.request);
                        } else {
                        console.log('error', error.message);
                        }
                    })
                }

                resetForm()
                setUsername('')
                setSearchTerm('')
                setFiles([])
                setFilesSelected([])
            })
            .catch((error) => {
                toast('error', error.response.data.error);
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('error', error.message);
                }
            })
        }
        
        if(userFile) {
           
            console.log(data)
            ApiService.put(`/associados/atualizar-ficha/${associateID}`, data)
              .then(() => {
                toast('success', 'Voluntario atualizado com sucesso', true)
              })
              .catch((error) => {
                console.log(error)
                toast('error', 'Ocorreu um erro ao enviar a ficha', true)

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('error', error.message);
                  }
              })
            
        }

        setSubmitting(false);
    } 

    console.log(username)

    const handleChangeFile = (e) => {
        setPreviewFile(URL.createObjectURL(e.target.files[0]));
        setUserFile(e.target.files[0])
    }

    const uploadHandler = (event) => {
        const file = event.target.files[0];
       
        if(!file) return;

        setFiles([...files, file]);
        setFilesSelected([...filesSelected, file])
    }

    const removeFile = (index) => {
        setFiles(files.filter((file, i) => i !== index))
    }

    const handleEditClick = (index) => {
        setIsEditingFile({ active: true, index: index });
        setTextFile(
            files
              .filter((file, i) => i === index && (file.nome_label || file.nome || file instanceof File))
              .map((file) => {
                if (file instanceof File && file.nome_label) {
                  return file.nome_label;
                }
                return file.nome_label || file.nome || file.name;
              })
        );
    };

    const handleInputChangeFile = (e) => {
        setTextFile(e.target.value);
    };
    console.log(files)
    const handleSaveClick = (index) => {
        setIsEditingFile(false);
        files.map((file, i) => i == index ? file.nome_label = textFile : file.nome_label)
        console.log('Texto editado:', textFile);
        setTextFile('')
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...userPreferences];
        list[index][name] = value;
        setUserPreferences(list);
    };

    const handleRemoveClick = index => {
        const list = [...userPreferences];
    
        const preferences = list[index];
        setDeletedPreferences([...deletedPreferences, preferences])
    
        list.splice(index, 1);
        setUserPreferences(list);
    };
    
    const handleAddClick = () => {
        setUserPreferences([...userPreferences, { dia_da_semana: "", horario_inicio: "", horario_fim: "" }]);
    };
console.log(files)

    const handleInputChangeVolunteerOut = (e, index) => {
        const { name, value } = e.target;
        const list = [...userVolunteerOut];
        list[index][name] = value;
        setUserVolunteerOut(list);
    };

    const handleRemoveClickVolunteerOut  = index => {
        const list = [...userVolunteerOut];
    
        const volunteerOut = list[index];
        setDeletedVolunteerOut([...deletedVolunteerOut, volunteerOut])
    
        list.splice(index, 1);
        setUserVolunteerOut(list);
    };
    const handleAddClickVolunteerOut = () => {
        setUserVolunteerOut([...userVolunteerOut, { nome_trabalho_voluntario_fora: "", local_fora: "", data_inicio_voluntario_fora: "", obs: "" }]);
    };

    useEffect(() => {
        if(searchTerm == undefined || searchTerm == ''){
            setAddStudents(null);
            return;
        } 

        const delayDebounceFn = setTimeout(() => {
          searchStudent(searchTerm)
        }, 1500)

        
        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    const searchStudent = useCallback(async (e) => {
        setLoading(true)
        try {
             ApiService.get(`/pessoa`, {params: {search: e}})
            .then((response) => {
                setAddStudents(response.data)
                // toast('success', 'Aluno adicionado com sucesso');
                setLoading(false)
            })
            .catch((error) => {
                // toast('error', 'Ocorreu um erro');

              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
                setLoading(false)
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const handleChangeSearch = useDebouncedCallback((event) => {
        setSearchTerm(event.target.value);
    });

    
    React.useEffect(() => {
        setSearchTerm(searchTerm);
    }, [searchTerm]);

    React.useEffect(() => {
        return () => handleChangeSearch.cancel();
    });


    const updateStudentList = useCallback(async (data) => {
        setClassStudents([...data]);
        setAddStudents(null);
    }, []);

    const handleInputFocus = () => {
        setIsInputFocused(true);
      }
    
    const handleInputBlur = () => {
        setIsInputFocused(false);
    }


    const dateTime = users && users?.updatedAt;
    let dateBr;
    let timeBr;
    if(dateTime) {
        dateBr = moment(dateTime).tz('America/Sao_Paulo').format('DD/MM/YYYY');
        timeBr = moment(dateTime).tz('America/Sao_Paulo').format('HH:mm:ss')
    }

    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? 'transparent' : 'white',
          border: state.isFocused ? '1px solid #7352C7' : '1px solid rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',
          padding: '8px',
          boxShadow: 'none',
          '&:hover': {
            borderColor: 'black',
          },
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? '#7352C7' : 'white',
          color: state.isSelected ? 'white' : 'black',
          '&:hover': {
            backgroundColor: 'lightblue',
            color: 'white',
          },
        }),
    };

    const createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
    });

    const handleCreate = (inputValue) => {
        const newOption = createOption(inputValue);
        
        setActivities((prev) => [...prev, newOption]);
        setActivitiesSelected(newOption);
    };

  const [submitClicked, setSubmitClicked] = useState(false);

  return (
    <JumboContentLayout
        header={
            <HeaderBreadcrumbs
                id={id}
                title={"Associados"}
                subtitle={id ? "Editar" : "Cadastro de Associados"}
                titleUrl={"/app/listar-associados"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }

        <Paper sx={{p: '40px'}}> 
            {state &&
                <Button
                    color="success"
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{maxWidth: {md: '200px'}, mb: '2rem'}}
                    onClick={() => navigate(state.backUrl)}
                >
                    Voltar
                </Button>
            }
            <Formik
                initialValues={users}
                //validationSchema={validationSchema}
                enableReinitialize
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={handleSubmit}
            >
                {({values, isSubmitting, handleChange}) => (
                    <Form style={{width: '100%'}} noValidate autoComplete='off'>
                        <Grid container spacing={3} alignContent={'center'}>
                            {id
                                ?
                                null
                                :
                                (
                                    <>
                                        <Grid item xs={12} >
                                            <JumboSearchClasses
                                                value={searchTerm}
                                                onChange={(e) =>  setSearchTerm(e.target.value)}
                                                placeholder="Busque por Nome, CPF ou E-mail."
                                                sx={{
                                                    width: '440px',
                                                    marginBottom: '1rem'
                                                }}
                                                onFocus={handleInputFocus} 
                                                onBlur={handleInputBlur}
                                                disabled={isDisabled}
                                            />
                                        </Grid> 
                                        <Grid item xs={12}>
                                            {loading 
                                                && 
                                                <Box display='flex' justifyContent='center'>
                                                    <CircularProgress  /> 
                                                </Box>
                                            }
                                            {
                                                (addStudents != null && addStudents.length > 0) && <ListRowAssociate data={addStudents} updateStudentList={(data) => updateStudentList(data)} classStudents={classStudents} status={status} setStatus={setStatus} setUsername={setUsername}setSearchTerm={setSearchTerm} />
                                            }
                                            {
                                                (addStudents != null && addStudents.length == 0) && <Typography>Sem resultados correspondentes</Typography>
                                            }  

                                        </Grid>
                                    </>
                                ) 
                            }
                            
                            <Grid item xs={12}>
                                <JumboTextField
                                    fullWidth
                                    name={id ? "associado_id" : "pessoa_id"}
                                    label="Nome"
                                    value={id ? selectedOption && selectedOption.nome : username && username[0].nome}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true 
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">Tipo de associação</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="tipo_associado"
                                        value={updateInputValue(users, values, 'tipo_associado')}
                                        //onChange={(event) => setSize(event.target.value)}
                                        onChange={handleChange}
                                    >
                                        {
                                            !id && (
                                                <>
                                                    <FormControlLabel 
                                                        value="Associado Efetivo" 
                                                        control={<Radio />} 
                                                        label="Associado Efetivo"
                                                        disabled={isDisabled || status == false}
                                                    />
                                                     <FormControlLabel 
                                                        value="Associado Contribuinte" 
                                                        control={<Radio />} 
                                                        label="Associado Contribuinte" 
                                                        disabled={isDisabled || status == false}
                                                    />
                                                </>
                                            )
                                        }

                                        {
                                            id && values.tipo_associado === "Associado Efetivo"
                                            ?
                                            <FormControlLabel 
                                                value="Associado Efetivo" 
                                                control={<Radio />} 
                                                label="Associado Efetivo" 
                                                disabled={isDisabled || status == false}
                                            />
                                            :
                                            null
                                        }
                                       
                                        {
                                            id && values.tipo_associado === "Associado Contribuinte"
                                            ?
                                            <FormControlLabel 
                                                value="Associado Contribuinte" 
                                                control={<Radio />} 
                                                label="Associado Contribuinte" 
                                                disabled={isDisabled || status == false}
                                            />
                                            :
                                            null
                                        }
                                        

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {
                                values.seguir_associacao_efetiva == 'on' 
                                ?
                                null
                                :
                                <>
                                    <Grid item xs={6}>
                                        <JumboTextField
                                            fullWidth
                                            name="data_inicio_associado"
                                            type="date"
                                            label="Data de início"
                                            sx={{ background: '#ffffff'}}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={isDisabled || status == false}
                                        />
                                    </Grid>  
                                   {/*  <Grid item lg={6} xs={2} >
                                        <Div sx={{ display: 'flex', flexDirection: 'row', }}>
                                            { previewFile && 
                                                <Div sx={{width: '50px', height: '50px', borderRadius: 50, marginRight: '1rem', overflow: 'hidden'}}>
                                                <embed src={previewFile} type="application/pdf"/>
                                                </Div>
                                                
                                            }
                                            {values.ficha && !previewFile && 
                                                <>
                                                    <Div sx={{width: '50px', height: '50px', borderRadius: 50, marginRight: '1rem', overflow: 'hidden'}}>
                                                        <embed src={`${process.env.REACT_APP_API_KEY}/images/${values.ficha}`} type="application/pdf"/>
                                                    </Div>
                                                    <Button 
                                                        sx={{marginRight: 3}} 
                                                        variant='contained' 
                                                        href={`${process.env.REACT_APP_API_KEY}/images/${values.ficha}`} 
                                                        target="_blank"
                                                    >
                                                        Abrir arquivo
                                                    </Button>
                                                </>
                                                
                                            }
                                            <Tooltip 
                                                title="Adicionar ficha com a assinatura do voluntariado"
                                                TransitionComponent={Zoom}
                                                arrow
                                            >
                                                <Button 
                                                    variant="contained" 
                                                    component="label" 
                                                    color="info"
                                                    disabled={isDisabled || status == false}
                                                    endIcon={<UploadIcon />}
                                                >
                                                    Adicionar ficha
                                                    <input 
                                                        type="file" 
                                                        hidden 
                                                        onChange={handleChangeFile}
                                                    />
                                                </Button>
                                            </Tooltip>
                                        </Div>
                                    </Grid> */}
                                </>
                            }
                            {
                                values.seguir_associacao_efetiva || values.seguir_associacao_contribuinte == 'on' 
                                    ?
                                    null
                                    :
                                <Grid item lg={12} xs={2} >
                                    <Div sx={{ display: 'flex', flexDirection: 'column', }}>
                                        <FileUpload 
                                            files={files} 
                                            setFiles={setFiles} 
                                            uploadHandler={uploadHandler}
                                            removeFile={removeFile}
                                            status={status}
                                        />
                                        <FileList 
                                            files={files} 
                                            removeFile={removeFile}
                                            isEditingFile={isEditingFile}
                                            textFile={textFile}
                                            handleInputChangeFile={handleInputChangeFile}
                                            handleSaveClick={handleSaveClick}
                                            handleEditClick={handleEditClick}
                                            setIsEditingFile={setIsEditingFile}
                                            status={status}
                                        />
                                    </Div>
                                </Grid> 
                            } 
                            <Grid item xs={12}>
                                {id && values.tipo_associado == 'Associado Contribuinte'
                                    ?
                                        <Grid item xs={12}>
                                            <Accordion 
                                                expanded={updateInputValue(users, values, 'seguir_associacao_efetiva') == "on" ? true : false}
                                                elevation={0}
                                            >
                                                <AccordionSummary
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                        <Stack
                                                            sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                            direction={{ xs: 'column', lg: 'row' }}
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            spacing={2}
                                                        >
                                                            <Typography
                                                                sx={{fontSize: '1.05rem'}}
                                                            >
                                                                Seguir com associação efetiva?
                                                            </Typography>

                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                spacing={1}
                                                            >
                                                                <Typography>Não</Typography>
                                                                    <FormControlLabel
                                                                        control={
                                                                        <Switch 
                                                                            checked={updateInputValue(users, values, 'seguir_associacao_efetiva') == "on" ? true : false}
                                                                            
                                                                            onChange={handleChange}
                                                                            name="seguir_associacao_efetiva" 
                                                                            disabled={isDisabled}
                                                                        />
                                                                        }
                                                                        labelPlacement="start"
                                                                    />
                                                                <Typography>Sim</Typography>
                                                            </Stack>

                                                        </Stack>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{display: 'flex', gap: '1.5rem'}}>
                                                    <Grid container spacing={3} alignContent={'center'}>
                                                        <Grid item xs={4}>
                                                            <JumboTextField
                                                                fullWidth
                                                                name="data_fim_associado"
                                                                type="date"
                                                                label="Insira a data final do associado contribuinte"
                                                                value={updateInputValue(users, values, 'data_fim_associado')}
                                                                sx={{ background: '#ffffff'}}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                disabled={isDisabled || status == false}
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={4}>
                                                            <JumboTextField
                                                                fullWidth
                                                                name="data_inicio_associado_efetivo"
                                                                type="date"
                                                                label="Insira a data inicial do associado efetivo"
                                                                value={updateInputValue(users, values, 'data_inicio_associado_efetivo')}
                                                                sx={{ background: '#ffffff'}}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                disabled={isDisabled || status == false}
                                                            />
                                                        </Grid>
                                                        
                                                        <Grid item lg={12} xs={2} >
                                                            <Div sx={{ display: 'flex', flexDirection: 'column', }}>
                                                                <FileUpload 
                                                                    files={files} 
                                                                    setFiles={setFiles} 
                                                                    uploadHandler={uploadHandler}
                                                                    removeFile={removeFile}
                                                                />
                                                                <FileList 
                                                                    files={files} 
                                                                    removeFile={removeFile}
                                                                    isEditingFile={isEditingFile}
                                                                    textFile={textFile}
                                                                    handleInputChangeFile={handleInputChangeFile}
                                                                    handleSaveClick={handleSaveClick}
                                                                    handleEditClick={handleEditClick}
                                                                    setIsEditingFile={setIsEditingFile}
                                                                />
                                                            </Div>
                                                        </Grid> 
                                                        {values.seguir_associacao_efetiva == 'on' // verifica se o switch é true
                                                            ?
                                                            (
                                                                <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                                                    <LoadingButton 
                                                                        color='error' 
                                                                        type="submit"
                                                                        variant="contained"  
                                                                        size="large"
                                                                        loading={isSubmitting}
                                                                        disabled={isDisabled || isInputFocused || status == false || values.data_fim_associado == '' || values.data_fim_associado == null}
                                                                    >
                                                                        Confirmar
                                                                    </LoadingButton> 
                                                                </Grid>
                                                            )
                                                            :
                                                            null
                                                        } 
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            {values.encerrar_associacao == 'on' ? null : <Divider variant="middle" />}
                                        </Grid>
                                    :
                                    null
                                }
                                {values.data_fim_associado == 'on' // verifica se o switch é true
                                    ?
                                    (
                                        <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                            <LoadingButton 
                                                color='error' 
                                                type="submit"
                                                variant="contained"  
                                                size="large"
                                                loading={isSubmitting}
                                                disabled={isDisabled || isInputFocused || status == false || values.data_fim_associado == '' || values.data_fim_associado == null}
                                            >
                                                Encerrar associação
                                            </LoadingButton> 
                                        </Grid>
                                    )
                                    :
                                    null
                                } 
                                    
                            </Grid>
                            <Grid item xs={12}>
                                {id && values.tipo_associado == 'Associado Efetivo'
                                    ?
                                        <Grid item xs={12}>
                                            <Accordion 
                                                expanded={updateInputValue(users, values, 'seguir_associacao_contribuinte') == "on" ? true : false}
                                                elevation={0}
                                            >
                                                <AccordionSummary
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                        <Stack
                                                            sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                            direction={{ xs: 'column', lg: 'row' }}
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            spacing={2}
                                                        >
                                                            <Typography
                                                                sx={{fontSize: '1.05rem'}}
                                                            >
                                                                Seguir com associação contribuinte?
                                                            </Typography>

                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                spacing={1}
                                                            >
                                                                <Typography>Não</Typography>
                                                                    <FormControlLabel
                                                                        control={
                                                                        <Switch 
                                                                            checked={updateInputValue(users, values, 'seguir_associacao_contribuinte') == "on" ? true : false}
                                                                            
                                                                            onChange={handleChange}
                                                                            name="seguir_associacao_contribuinte" 
                                                                            disabled={isDisabled}
                                                                        />
                                                                        }
                                                                        labelPlacement="start"
                                                                    />
                                                                <Typography>Sim</Typography>
                                                            </Stack>

                                                        </Stack>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{display: 'flex', gap: '1.5rem'}}>
                                                    <Grid container spacing={3} alignContent={'center'}>
                                                        <Grid item xs={4}>
                                                            <JumboTextField
                                                                fullWidth
                                                                name="data_fim_associado"
                                                                type="date"
                                                                label="Insira a data final do associado efetivo"
                                                                value={updateInputValue(users, values, 'data_fim_associado')}
                                                                sx={{ background: '#ffffff'}}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                disabled={isDisabled || status == false}
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={4}>
                                                            <JumboTextField
                                                                fullWidth
                                                                name="data_inicio_associado_contribuinte"
                                                                type="date"
                                                                label="Insira a data inicial do associado contribuinte"
                                                                value={updateInputValue(users, values, 'data_inicio_associado_contribuinte')}
                                                                sx={{ background: '#ffffff'}}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                disabled={isDisabled || status == false}
                                                            />
                                                        </Grid>
                                                        
                                                        <Grid item lg={12} xs={2} >
                                                            <Div sx={{ display: 'flex', flexDirection: 'column', }}>
                                                                <FileUpload 
                                                                    files={files} 
                                                                    setFiles={setFiles} 
                                                                    uploadHandler={uploadHandler}
                                                                    removeFile={removeFile}
                                                                />
                                                                <FileList 
                                                                    files={files} 
                                                                    removeFile={removeFile}
                                                                    isEditingFile={isEditingFile}
                                                                    textFile={textFile}
                                                                    handleInputChangeFile={handleInputChangeFile}
                                                                    handleSaveClick={handleSaveClick}
                                                                    handleEditClick={handleEditClick}
                                                                    setIsEditingFile={setIsEditingFile}
                                                                />
                                                            </Div>
                                                        </Grid> 
                                                        {values.seguir_associacao_contribuinte == 'on' // verifica se o switch é true
                                                            ?
                                                            (
                                                                <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                                                    <LoadingButton 
                                                                        color='error' 
                                                                        type="submit"
                                                                        variant="contained"  
                                                                        size="large"
                                                                        loading={isSubmitting}
                                                                        disabled={isDisabled || isInputFocused || status == false || values.data_fim_associado == '' || values.data_fim_associado == null}
                                                                    >
                                                                        Confirmar
                                                                    </LoadingButton> 
                                                                </Grid>
                                                            )
                                                            :
                                                            null
                                                        } 
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            {values.encerrar_associacao == 'on' ? null : <Divider variant="middle" />}
                                        </Grid>
                                    :
                                    null
                                }
                                {values.data_fim_associado == 'on' // verifica se o switch é true
                                    ?
                                    (
                                        <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                            <LoadingButton 
                                                color='error' 
                                                type="submit"
                                                variant="contained"  
                                                size="large"
                                                loading={isSubmitting}
                                                disabled={isDisabled || isInputFocused || status == false || values.data_fim_associado == '' || values.data_fim_associado == null}
                                            >
                                                Encerrar associação
                                            </LoadingButton> 
                                        </Grid>
                                    )
                                    :
                                    null
                                } 
                                    
                            </Grid>         
                            <Grid item xs={12}>
                                {id
                                    ?
                                        <Grid item xs={12}>
                                            <Accordion 
                                                expanded={updateInputValue(users, values, 'encerrar_associacao') == "on" ? true : false}
                                                elevation={0}
                                            >
                                                <AccordionSummary
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                        <Stack
                                                            sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                            direction={{ xs: 'column', lg: 'row' }}
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            spacing={2}
                                                        >
                                                            <Typography
                                                                sx={{fontSize: '1.05rem'}}
                                                            >
                                                                Encerrar associação?
                                                            </Typography>

                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                spacing={1}
                                                            >
                                                                <Typography>Não</Typography>
                                                                    <FormControlLabel
                                                                        control={
                                                                        <Switch 
                                                                            checked={updateInputValue(users, values, 'encerrar_associacao') == "on" ? true : false}
                                                                            
                                                                            onChange={handleChange}
                                                                            name="encerrar_associacao" 
                                                                            disabled={isDisabled}
                                                                        />
                                                                        }
                                                                        labelPlacement="start"
                                                                    />
                                                                <Typography>Sim</Typography>
                                                            </Stack>

                                                        </Stack>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{display: 'flex', gap: '1.5rem'}}>
                                                    <Grid container spacing={3} alignContent={'center'}>
                                                        <Grid item xs={6}>
                                                            <JumboTextField
                                                                fullWidth
                                                                name="data_fim_associado"
                                                                type="date"
                                                                label="Data Final"
                                                                value={updateInputValue(users, values, 'data_fim_associado')}
                                                                sx={{ background: '#ffffff'}}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                disabled={isDisabled || status == false}
                                                            />
                                                        </Grid> 
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            {values.encerrar_associacao == 'on' ? null : <Divider variant="middle" />}
                                        </Grid>
                                    :
                                    null
                                }
                                {values.encerrar_associacao == 'on' // verifica se o switch é true
                                    ?
                                    (
                                        <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                            <LoadingButton 
                                                color='error' 
                                                type="submit"
                                                variant="contained"  
                                                size="large"
                                                loading={isSubmitting}
                                                disabled={isDisabled || isInputFocused || status == false || values.data_fim_associado == '' || values.data_fim_associado == null}
                                            >
                                                Encerrar associação
                                            </LoadingButton> 
                                        </Grid>
                                    )
                                    :
                                    (
                                        values.seguir_associacao_efetiva == 'on' 
                                        ?
                                        null
                                        :
                                        <Grid item xs={12} sx={{textAlign: 'center', mt: 3}}>
                                            <LoadingButton
                                            color="success"
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                            loading={isSubmitting}
                                            disabled={isDisabled || isInputFocused || status == false || values.data_inicio_associado == '' || values.data_inicio_associado == null}
                                        >
                                            {id ? 'Atualizar' : 'Cadastrar'}
                                            </LoadingButton>
                                        </Grid>
                                    )
                                } 
                                    
                            </Grid> 
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Paper>
    </JumboContentLayout>
  )
}

export default AssociateFormResume