import { ApiService } from 'app/servicesTwo/ApiService';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const useTokenCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await ApiService.get('/auth-check'); // Endpoint para validar o token
        if (response.status !== 200) {
          throw new Error('Token inválido');
        }
      } catch (error) {
        localStorage.removeItem('token');
        localStorage.removeItem('storedUserImage');
        localStorage.removeItem('storedUserId');
        localStorage.removeItem('storedUsername');
        localStorage.removeItem('storedUserEmail');
        localStorage.removeItem('storedPermissions');
        navigate('/login');
      }
    };

    checkTokenValidity();
  }, [navigate]);
};

export default useTokenCheck;
