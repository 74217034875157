import {USE_IMAGE_PLACEHOLDERS} from "./constants/paths";

export const getAssetPath = (url, size) => {
    if(USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`;
    }

    return url;
};

export const updateInputValue = (user, values, data) => {
    return user && (user[data] == values[data]) ? user[data] || '' : values[data] || '';
};

export const disableInputValue = (user, values, data) => {
    return user && (user[data] == values[data]) ? !user[data] : !values[data];
};