import React from 'react'
import styled from '@emotion/styled'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button } from '@mui/material';

const FileListItem = styled.li`
    list-style: none;
    margin: 1rem 0;
    background-color: #f55e3038;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.9rem;

    .inputFile {
        border-radius: 5px;
        border: none;
        outline: none;
        padding: 0.5rem 0.9rem;
    }

`

const FileItem = ({ file,removeFile, index, isEditingFile, textFile, handleInputChangeFile, handleSaveClick, handleEditClick, setIsEditingFile, status }) => {
    return (
        <>
            <FileListItem
                className="file-item"
                key={file.nome}
            >
                {isEditingFile.active && isEditingFile.index === index
                    ?
                        <Box display="flex" gap='1rem'>
                            <input className="inputFile" type="text" value={textFile} onChange={handleInputChangeFile} />
                            <Button 
                                variant="contained"
                                color="success"
                                size="small"
                                onClick={() => handleSaveClick(index)}
                            >
                                Salvar
                            </Button>
                        </Box>
                    :
                    <p>
                        {
                            file.nome_label 
                            ?
                                <a href={`${process.env.REACT_APP_API_KEY}/images/${file.nome}`} target="_blank" rel="noreferrer">
                                    {file.nome_label}
                                </a>
                            :
                            file.nome 
                            ?
                                <a href={`${process.env.REACT_APP_API_KEY}/images/${file.nome}`} target="_blank" rel="noreferrer">
                                {file.nome}
                                </a>
                            :
                                <a href={URL.createObjectURL(file)} target="_blank" rel="noreferrer">
                                    {file.name}
                                </a>
                        }
                    </p>
                    
                }    
                
                <Box display='flex' gap='1rem'>
                    {
                        isEditingFile.active && isEditingFile.index === index
                        ?
                            <Button
                                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                disableElevation 
                                variant={"contained"} 
                                size={"small"} 
                                color={"error"}
                                disabled={status === false}
                                onClick={() => setIsEditingFile(false)}
                            >
                               Cancelar
                            </Button> 
                        :
                            <Button
                                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                disableElevation 
                                variant={"contained"} 
                                size={"small"} 
                                color={"primary"}
                                disabled={status === false}
                                onClick={() => handleEditClick(index)}
                            >
                                <EditIcon />
                            </Button> 

                    }

                    <Button
                        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                        disableElevation 
                        variant={"contained"} 
                        size={"small"} 
                        color={"secondary"}
                        disabled={status === false}
                        onClick={() => removeFile(index)}
                    >
                        <DeleteIcon />
                    </Button> 
                </Box>
               
                
            </FileListItem>
        </>
    )
}

export default FileItem