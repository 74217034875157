import React, { useState, useCallback, useEffect, Fragment } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { Grid, Link, Paper, Stack, useMediaQuery, Typography, Button} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import { ApiService } from 'app/servicesTwo/ApiService';
import JumboSearch from "@jumbo/components/JumboSearch";
import { useParams } from "react-router-dom";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import CoursesIntroRegistrationListItem from './CoursesIntroRegistrationListItem';


const CoursesIntroRegistrationList = ({usersRegistration, setUsersRegistration, handleInputFocus, handleInputBlur}) => {  

    const {theme} = useJumboTheme();
    const { id } = useParams()

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

   
    const [searchTerm, setSearchTerm] = useState(null)
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [getCourse, setGetCourse] = useState([]);
   
    useEffect(() => {
        console.log('searchTerm', searchTerm)
        if(searchTerm == undefined || searchTerm == ''){
            searchClass(searchTerm);
            return;
        } 

        const delayDebounceFn = setTimeout(() => {
            searchClass(searchTerm)
        }, 1500)

        
        return () => clearTimeout(delayDebounceFn)
      }, [searchTerm])

    const searchClass = useCallback(async (e) => {

        try {
            ApiService.get(`/cursos-introdutorios/${id}/pessoas`, {params: {search: e}})
            .then((response) => {
                console.log('retorno', response.data)
                const students = (response.data.Curso_Introdutorio_Historico).map((x) => (
                    {
                        ...x.User, 
                        Turma: x.Turma,
                        turmas_id: x.Turma.turmas_id,
                        curso_introdutorio_historico_id: x.curso_introdutorio_historico_id,
                        status_curso_introdutorio_historico: x.status,
                        ficha_compativel: x.ficha_compativel,
                        observacao: x.observacao,
                    }));
                setUsersRegistration(students);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('error', error.message);
                }
            })
        } catch (err) {
            console.log(err);
        }
    }, [usersRegistration]);

    console.log('list', usersRegistration)
  return (
    <JumboContentLayout
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px'}}>
            <Grid container spacing={3}>
                <Grid item xs={6} sx={{textAlign: 'center'}}> 
                    <JumboSearch
                        onChange={(e) => setSearchTerm(e)}
                        placeholder="Buscar por Nome, Email ou CPF"
                        sx={{
                            width: '440px',
                            marginBottom: '1rem'
                        }}
                        onFocus={handleInputFocus} 
                        onBlur={handleInputBlur}
                    />
                </Grid>

            </Grid>
            <Fragment>
                {
                    usersRegistration.length > 0 && usersRegistration.map((student, key) => (
                        <CoursesIntroRegistrationListItem 
                            item={student} 
                            setUsersRegistration={(data) => setUsersRegistration([...data])} usersRegistration={usersRegistration} 
                            key={key} 
                            
                        /> 
                    ))
                }
                {
                    usersRegistration && usersRegistration.length == 0 && <Typography>Sem resultados correspondentes</Typography>
                }
            </Fragment>
        </Paper>
    </JumboContentLayout>
  )
}

export default CoursesIntroRegistrationList