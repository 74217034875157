import React, { useContext, useState } from 'react';
import { ListItem, Typography, Grid, Tooltip, Zoom } from '@mui/material';
import Button from '@mui/material/Button';
import { PermissionContext } from 'app/contexts/PermissionContext';

import { LinkStyle } from 'app/pages/app/users/UserItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useLocation } from 'react-router-dom';
import { EllipsisTooltip } from '../EllipsisTooltip/EllipsisTooltip';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const ListRowBookTeamItem = ({
  item,
  includeVolunteerToTeam,
  isButtonDisabled,
  activities,
  onActivitySelect,
}) => {
  const { hasPermission } = useContext(PermissionContext);
  const location = useLocation();
  const animatedComponents = makeAnimated();

  const [selectedActivity, setSelectedActivity] = useState(null);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'transparent' : 'white',
      border: state.isFocused
        ? '1px solid #7352C7'
        : '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      padding: '8px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'black',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#7352C7' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: 'lightblue',
        color: 'white',
      },
    }),
    menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
  };

  const optionsVolunteerActivities = activities?.map((result) => {
    return {
      value: result.atividade_voluntario_id,
      label: result.nome,
    };
  });

  const handleSelectChange = (newValue) => {
    const activityId = newValue.value;
    setSelectedActivity(activityId);
    onActivitySelect(item.voluntario_id, activityId);
  };

  return (
    <ListItem sx={{ p: (theme) => theme.spacing(1, 3) }}>
      <Grid container spacing={2} alignContent={'center'}>
        <Grid item lg={3} md={5} xs={4}>
          <Typography
            fontSize={'12px'}
            variant={'h6'}
            color={'text.secondary'}
            mb={0.25}
          >
            Nome
          </Typography>
          <EllipsisTooltip text={item?.User?.nome} />
        </Grid>

        <Grid item lg={2} md={5} xs={3}>
          <Typography
            fontSize={'12px'}
            variant={'h6'}
            color={'text.secondary'}
            mb={0.25}
          >
            Celular
          </Typography>
          <EllipsisTooltip text={item?.User?.celular} />
          {/*  <Typography variant={'body1'}>{item.celular}</Typography> */}
        </Grid>
        <Grid item xs={5}>
          <div style={{ position: 'relative' }}>
            <Select
              id='select-two'
              menuPortalTarget={document.body}
              styles={customStyles}
              options={optionsVolunteerActivities}
              components={animatedComponents}
              onChange={handleSelectChange}
              value={optionsVolunteerActivities.filter(
                (option) => option.value === selectedActivity
              )}
              placeholder='Selecione Atividade'
              isDisabled={
                !hasPermission('Inclusão de Alunos na Turma', 'editar')
              }
            />

            <Typography
              style={{
                position: 'absolute',
                top: '-9px',
                left: '10px',
                background: '#ffffff',
                padding: '0 5px',
                color: '#8595A6',
                fontSize: '0.7rem',
                fontFamily: 'NoirPro,Arial',
              }}
            >
              Selecione atividade
            </Typography>
          </div>
        </Grid>
        <Grid item lg={1} md={1} xs={2}>
          <Tooltip title='Visualizar' TransitionComponent={Zoom} arrow>
            <LinkStyle
              to={`/app/editar-aluno/${item.pessoa_id}`}
              state={{
                backUrl: location.pathname,
                disable: true,
              }}
            >
              <VisibilityIcon />
            </LinkStyle>
          </Tooltip>
        </Grid>
        <Grid item lg={1} md={1} xs={1}>
          <Button
            size={'small'}
            variant={'contained'}
            color={'success'}
            disableElevation
            onClick={() => includeVolunteerToTeam(item)}
            // {...(item.follow) ? {color: "inherit"} : {}}
            disabled={
              !hasPermission('Inclusão de Alunos na Turma', 'editar') ||
              isButtonDisabled
            }
            sx={{
              minWidth: 78,
              textTransform: 'none',
              p: (theme) => theme.spacing(0.5, 1.5),
            }}
          >
            Incluir
          </Button>
        </Grid>
      </Grid>
    </ListItem>
  );
};
/* Todo item, includeStudentToClass prop define */
export default ListRowBookTeamItem;
