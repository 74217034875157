import React, { useCallback, useState, useContext} from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import {Accordion, Card, Tooltip, Typography, Zoom, Grid, Chip, Box, Modal, Backdrop, Fade, Checkbox, CardMedia, CardContent, IconButton} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import { Link } from 'react-router-dom';


export const LinkStyle = styled(Link)`
    padding: 4px 10px;
    background: #7352C7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    
    svg {
        color: white;
    }
`

export const ButtonStyle = styled(Button)`
    padding: 4px 10px;
    min-width: 30px;
    background: #2EB5C9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    
    svg {
        color: white;
    }
`


const None = styled(Div) `
    @media(max-width: 1280px) {
        display: none;
    }
    
`
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
};

const VolunteerPrintingItem = ({ item ,setUsers, users, selectedUsers, setSelectedUsers }) => {
   

    const handleCheckboxChange = () => {
        setSelectedUsers((prevSelectedUsers) => {
          if (prevSelectedUsers.some((user) => user.pessoa_id === item.pessoa_id)) {
            // Remove o usuário se já estiver na lista
            return prevSelectedUsers.filter((user) => user.pessoa_id !== item.pessoa_id);
          } else {
            // Adiciona o usuário se não estiver na lista
            return [...prevSelectedUsers, item];
          }
        });
    };
    
    return (
        <>
            <Card sx={{mb: 1}}>
                <Accordion square sx={{borderRadius: 2}}>
                    <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{
                            px: 3,
                            flexDirection: 'row-reverse',

                            '& .MuiAccordionSummary-content': {
                                alignItems: 'center',

                                '&.Mui-expanded': {
                                margin: '12px 0',
                                }
                            },
                            '.MuiAccordionSummary-expandIconWrapper': {
                                borderRadius: 1,
                                border: 1,
                                color: 'text.secondary',
                                borderColor: 'divider',
                                transform: 'none',
                                height: 28,
                                width: 28,
                                alignItems: 'center',
                                justifyContent: 'center',
                                mr: 1,

                                '&.Mui-expanded': {
                                    transform: 'none',
                                    color: 'primary.main',
                                    borderColor: 'primary.main',
                                },

                                '& svg': {
                                    fontSize: '1.25rem',
                                }
                            }
                        }}
                    >
                        <Grid container spacing={2} alignContent={'center'}>
                            {/* <Grid item lg={1} md={2} xs={12} sx={{flexShrink: 0, px: 1}}>
                                <Avatar sx={{width: 52, height: 52}} alt={item.nome} src={item.imagem_perfil ? `${process.env.REACT_APP_API_KEY}/images/${item.imagem_perfil}` : '#'} variant="rounded"/>
                            </Grid> */}
                            <Grid item lg={6} md={4} xs={8} sx={{flexShrink: 0, px: 1}}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    Nome
                                </Typography>
                                <Typography variant={"body1"}>{item.User?.nome}</Typography>
                            </Grid>
                           
                            <Grid item lg={6} md={2} xs={4}>
                                <Box display='flex' justifyContent='flex-end' gap='1rem'  width='100%'>
                                    <Tooltip 
                                        title={'Selecione para gerar o crachá deste usuário'} 
                                        arrow 
                                        TransitionComponent={Zoom}
                                    >
                                        <Checkbox
                                            checked={selectedUsers.some((user) => user.pessoa_id === item.pessoa_id)}
                                            onChange={handleCheckboxChange}
                                        />
                                    </Tooltip>

                                </Box>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                </Accordion>
            </Card>
            
        </>
        
    );
};
/* Todo item prop define */




export default VolunteerPrintingItem;
