import React, {
    useState,
    useCallback,
    useEffect,
    Fragment,
    useContext,
} from "react";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {
    Grid,
    Paper,
    Stack,
    useMediaQuery,
    Typography,
    Box,
    Pagination,
    CircularProgress,
    Modal,
    Fade,
    Backdrop,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ApiService } from "app/servicesTwo/ApiService";
import JumboSearch from "@jumbo/components/JumboSearch";
import { Link, useLocation } from "react-router-dom";
import { PermissionContext } from "app/contexts/PermissionContext";
import BatchCourseLaunchItem from "./BatchCourseLaunchItem";
import Div from "@jumbo/shared/Div";
import BatchAuthorizeLaunch from "./BatchAuthorizeLaunch";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import useTokenCheck from "@jumbo/hooks/useTokenCheck";

const BatchCourseLaunchList = () => {
    useTokenCheck();
    const { theme } = useJumboTheme();
    const location = useLocation();
    const Swal = useSwalWrapper();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80em',
       
    };

    const lg = useMediaQuery(theme.breakpoints.down("lg"));
    const layoutOptions = React.useMemo(
        () => ({
            sidebar: {
                sx: {
                    [theme.breakpoints.up("lg")]: {
                        position: "sticky",
                        zIndex: 5,
                        top: 96,
                        minHeight: "auto",
                    },
                    [theme.breakpoints.down("lg")]: {
                        display: "none",
                    },
                },
            },
            wrapper: {
                sx: {
                    alignItems: "flex-start",
                },
            },
        }),
        [theme]
    );

    const [users, setUsers] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const { hasPermission } = useContext(PermissionContext);

    const [searchTerm, setSearchTerm] = useState(null);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (searchTerm == undefined) return;

        if (searchTerm == "") {
            setLoading(true);
            searchUser(searchTerm);
            setLoading(false);
        }

        const delayDebounceFn = setTimeout(() => {
            setLoading(true);
            searchUser(searchTerm);
            setLoading(false);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const searchUser = useCallback(async (e) => {
        try {
            ApiService.get("/pessoa", { params: { search: e } })
                .then((response) => {
                    setUsers(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("error", error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, []);

    const handleOpen = (user) => {
        setOpen(true)
        setSelectedUsers(user)
    };
    const handleClose = () => {
        setOpen(false)

    } ;

    useEffect(() => {
        Swal.close();
    }, [location.pathname, Swal]);

    return (
        <JumboContentLayout
            header={<PageHeader title={"Lançamento de Curso em Lote"} />}
            layoutOptions={layoutOptions}
        >
            {lg && (
                <Stack
                    spacing={2}
                    direction={"row"}
                    sx={{ mb: 3, mt: -2 }}
                ></Stack>
            )}
            <Paper sx={{ p: "40px" }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: "500",
                        marginBottom: "1rem",
                        marginLeft: "1rem",
                    }}
                >
                    Busque por nome, email, celular e cpf.
                </Typography>

                <Div
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <JumboSearch
                        onChange={(e) => setSearchTerm(e)}
                        placeholder="Digite o nome, email, celular ou cpf aqui..."
                        sx={{
                            width: "440px",
                            marginBottom: "1rem",
                        }}
                    />
                </Div>
                <>
                    {loading && (
                        <Box display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    )}
                    {users != null && users.length == 0 && (
                        <Typography>Sem resultados correspondentes</Typography>
                    )}

                    {users &&
                        users.map((user, key) => (
                            <BatchCourseLaunchItem
                                item={user}
                                selectedUsers={selectedUsers}
                                setSelectedUsers={setSelectedUsers}
                                handleOpen={handleOpen}
                                handleClose={handleClose}
                                open={open}
                                key={key}
                            />
                        ))}
                </>
            </Paper>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
            >
                <Fade in={open}>
                    <Div sx={style}>
                        <BatchAuthorizeLaunch username={selectedUsers?.nome} userId={selectedUsers?.pessoa_id} handleClose={handleClose}/>
                    </Div>
                </Fade>
            </Modal>
        </JumboContentLayout>
    );
};

export default BatchCourseLaunchList;
