import React, { useCallback, useState, useContext } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import {
    Accordion,
    Card,
    Tooltip,
    Typography,
    Grid,
    Chip,
    Modal,
    Fade,
    Backdrop,
    Box,
    Checkbox,
    Button,
} from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { TextService } from 'app/servicesTwo/TextService';
import { PermissionContext } from 'app/contexts/PermissionContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { EllipsisTooltip } from 'app/components/EllipsisTooltip/EllipsisTooltip';
import { LinkStyle } from '../users/UserItem';
import { useLocation } from 'react-router-dom';

export const getChipColor = (diaDaSemana) => {
    switch (diaDaSemana) {
        case 'Segunda':
            return 'primary';
        case 'Terça':
            return 'secondary';
        case 'Quarta':
            return 'success';
        case 'Quinta':
            return 'success';
        case 'Sexta':
            return 'info';
        case 'Sábado':
            return 'error';

        default:
            return 'default';
    }
};

const CoursesFromOtherEntitiesLaunchItem = ({
    item,
    selectedUsers,
    setSelectedUsers,
    open,
    handleClose,
    handleOpen,
}) => {
    const { hasPermission } = useContext(PermissionContext);
    const location = useLocation();

    const handleCheckboxChange = () => {
        // logica para marcar multiplos usuário - não apagar
        /* setSelectedUsers((prevSelectedUsers) => {
          if (prevSelectedUsers.some((user) => user.pessoa_id === item.pessoa_id)) {
            // Remove o usuário se já estiver na lista
            return prevSelectedUsers.filter((user) => user.pessoa_id !== item.pessoa_id);
          } else {
            // Adiciona o usuário se não estiver na lista
            return [...prevSelectedUsers, item];
          }
        }); */

        // logica para marcar somente um usuário
        setSelectedUsers((prevSelectedUsers) => {
            // Verifica se o usuário já está na lista
            const userAlreadySelected = prevSelectedUsers.some(
                (user) => user.pessoa_id === item.pessoa_id
            );

            if (userAlreadySelected) {
                // Se o usuário já estiver na lista, desmarca todos
                return [];
            } else {
                // Se o usuário não estiver na lista, desmarca todos e marca o atual
                return [item];
            }
        });
    };

    const maxLengthText = 20;

    return (
        <>
            <Card sx={{ mb: 1 }}>
                <Accordion square sx={{ borderRadius: 2 }}>
                    <AccordionSummary
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'
                        sx={{
                            px: 3,
                            flexDirection: 'row-reverse',

                            '& .MuiAccordionSummary-content': {
                                alignItems: 'center',

                                '&.Mui-expanded': {
                                    margin: '12px 0',
                                },
                            },
                            '.MuiAccordionSummary-expandIconWrapper': {
                                borderRadius: 1,
                                border: 1,
                                color: 'text.secondary',
                                borderColor: 'divider',
                                transform: 'none',
                                height: 28,
                                width: 28,
                                alignItems: 'center',
                                justifyContent: 'center',
                                mr: 1,

                                '&.Mui-expanded': {
                                    transform: 'none',
                                    color: 'primary.main',
                                    borderColor: 'primary.main',
                                },

                                '& svg': {
                                    fontSize: '1.25rem',
                                },
                            },
                        }}
                    >
                        <Grid container spacing={2} alignContent={'center'}>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={8}
                                sx={{ flexShrink: 0, px: 1 }}
                            >
                                <Typography
                                    fontSize={'12px'}
                                    variant={'h6'}
                                    color={'text.secondary'}
                                    mb={0.25}
                                >
                                    Nome
                                </Typography>
                                <Typography variant={'body1'}>
                                    {item?.nome}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                lg={2}
                                md={3}
                                xs={4}
                                sx={{ flexShrink: 0, px: 1 }}
                            >
                                <Typography
                                    fontSize={'12px'}
                                    variant={'h6'}
                                    color={'text.secondary'}
                                    mb={0.25}
                                >
                                    CPF
                                </Typography>
                                <EllipsisTooltip text={item.cpf} /> {/* trocar para cpf aqui */}
                            </Grid>

                            <Grid
                                item
                                lg={2}
                                md={3}
                                xs={4}
                                sx={{ flexShrink: 0, px: 1 }}
                            >
                                <Typography
                                    fontSize={'12px'}
                                    variant={'h6'}
                                    color={'text.secondary'}
                                    mb={0.25}
                                >
                                    Email
                                </Typography>
                                <EllipsisTooltip text={item.email} />
                            </Grid>

                            <Grid
                                item
                                lg={2}
                                md={3}
                                xs={4}
                                sx={{ flexShrink: 0, px: 1 }}
                            >
                                <Typography
                                    fontSize={'12px'}
                                    variant={'h6'}
                                    color={'text.secondary'}
                                    mb={0.25}
                                >
                                    Celular
                                </Typography>

                                <EllipsisTooltip text={item.celular} />
                            </Grid>
                            <Grid item lg={1} md={2} xs={4}>
                                <Tooltip
                                    title={
                                        'Clique para acessar o registro dessa pessoa'
                                    }
                                    arrow
                                    TransitionComponent={Zoom}
                                >
                                    <LinkStyle
                                        to={`/app/editar-aluno/${item.pessoa_id}`}
                                        state={{ backUrl: location.pathname }}
                                        onClick={() =>
                                            window.scrollTo({
                                                top: 0,
                                                behavior: 'smooth',
                                            })
                                        }
                                    >
                                        <VisibilityIcon />
                                    </LinkStyle>
                                </Tooltip>
                            </Grid>
                            <Grid item lg={1} md={2} xs={4}>
                                <Tooltip
                                    title={'Clique para lançar cursos de outras entidades'}
                                    arrow
                                    TransitionComponent={Zoom}
                                >
                                    {/* <Checkbox
                                        checked={selectedUsers.some((user) => user.pessoa_id === item.pessoa_id)}
                                        
                                    /> */}
                                    <Button
                                        size='small'
                                        variant='contained'
                                        onChange={handleCheckboxChange}
                                        onClick={() => handleOpen(item)}
                                    >
                                        <PostAddIcon />
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                </Accordion>
            </Card>
        </>
    );
};
/* Todo item prop define */
export default CoursesFromOtherEntitiesLaunchItem;
