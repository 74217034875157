import React, { useState, useCallback, useEffect, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { Box, Button, CircularProgress, Grid, Paper, Stack, Tooltip, Typography, useMediaQuery, Zoom} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { ApiService } from 'app/servicesTwo/ApiService';
import { Link, useLocation } from "react-router-dom";
import Div from '@jumbo/shared/Div';
import JumboSearch from '@jumbo/components/JumboSearch';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVStyled, Item } from '../classes/ClassForm';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import html2pdf from 'html2pdf.js';
import defaultAvatar from '../../../../defaultAvatar.jpg';
import { makeStyles } from "@mui/styles";
import VolunteerAlocationItem from './VolunteerAlocationItem';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

const useStyles = makeStyles((theme) => ({
    card: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "16px",
        width: "320px",
        maxHeight: "490px",
        overflow: "hidden",
        border: "2px solid #000"
    },
    media: {
        width: "140px",
        height: "140px",
        borderRadius: "16px",
        objectFit: "cover",
    },
    mediaLogo: {
        width: "150px",
        height: "150px",
        borderRadius: "16px",
        objectFit: "cover",
    },
    content: { 
        display: "flex",
        flexDirection: "column",
    },
}));


const VolunteerAlocationList = () => {  
    const {theme} = useJumboTheme();
    const location = useLocation();
    const classes = useStyles();
    const Swal = useSwalWrapper();
    
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const [users, setUsers] = useState(null);
    const [usersToExcel, setUsersToExcel] = useState(null);
    const [select1Value, setSelect1Value] = useState(null);
    const [select2Value, setSelect2Value] = useState(null);
    const [select3Value, setSelect3Value] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedArea, setSelectedArea] = useState(null);
    const [filteredDepartments, setFilteredDepartments] = useState([]);

    const animatedComponents = makeAnimated();

    const { hasPermission } = useContext(PermissionContext);

    const handleSelect1Change = (selected) => {
        setSelect1Value(selected.length == 0 ? null : selected);
    };

    const handleSelect2Change = (selected) => {
        setSelectedArea(selected.length == 0 ? null : selected);
    };

    const handleSelect3Change = (selected) => {
    setSelect3Value(selected.length == 0 ? null : selected);
    };
    const [areas, setAreas] = useState(null)
    const [departments, setDepartments] = useState(null)
    const [selectedUsers, setSelectedUsers] = useState([])

    const getAreas = useCallback(async () => {
        try {
            ApiService.get('/areas')
            .then((response) => {
              setAreas(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const getDepartments = useCallback(async () => {
        try {
            ApiService.get('/departamentos')
            .then((response) => {
              setDepartments(response.data);
              console.log('response.data', response.data)
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    useEffect(() => {
    getAreas();
    getDepartments();
    }, []);

    useEffect(() => {
    console.log('atualizou')
    }, [setAreas, areas]);

    useEffect(() => {
        if (selectedArea && selectedArea.length > 0) {
            const areaIds = selectedArea.map(area => area.value);
            const filtered = departments.filter(department => areaIds.includes(department.area_id));
            setFilteredDepartments(filtered);
        } else {
            setFilteredDepartments(departments);
        }
    }, [selectedArea, departments]);


    const optionsAreas = areas?.filter(result => result?.area_id != null).map((result) => {
        return {
          value: result.area_id,
          label: result.nome
        };
    });

    const optionsDepartments = filteredDepartments?.filter(result => result?.departamento_id != null).map((result) => {
        return {
          value: result.departamento_id,
          label: result.nome
        };
    });

    const optionsStatus = ['ativo', 'inativo'].map((result) => {
        return {
            value: result,
            label: result
        }
    })
    
    const searchData = {
        select1Value,
        select2Value,
        select3Value,
    };

    const getUsers = useCallback(async (searchData) => {
        try {
            ApiService.get('/voluntarios', {params: {searchData}})
            .then((response) => {
                console.log(response.data)
              setUsers(response.data);
              setIsLoading(false)
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
      }, []);

    const getUsersToExcel = useCallback(async (searchData) => {
    try {
        ApiService.get('/voluntarios-excel',)
        .then((response) => {
            console.log(response.data)
            setUsersToExcel(response.data);
            setIsLoading(false)
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('error', error.message);
            }
        })
    } catch (err) {
        console.log(err);
    }
    }, []);

    const extractFirstAndLastName = (fullName) => {
        const regex = /[A-Z]{2,}(?=[A-Z][a-z]|\b)|[A-Z][a-z]*/gm
        const match = regex.exec(fullName);

        if (match) {
            const firstName = match[0];
            return { firstName };
        } else {
            return { firstName: fullName };
        }
    };

    const handleGenerateBatchCrachas = () => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.flexWrap = 'wrap';
        container.style.gap = '1rem';
        container.style.width = '100%';
        container.style.boxSizing = 'border-box';
        
        const createCard = (user) => {
            const { firstName } = extractFirstAndLastName(user.User.nome);
            console.log('createCard', user)
            const card = document.createElement('div');
            const foto = `${process.env.REACT_APP_API_KEY}/images/${user.User.imagem_perfil}`;
            card.innerHTML = `
                <div class="${classes.card}">
                    <img
                        component="img"
                        alt="Logo da FEESP"
                        class="${classes.mediaLogo}"
                        src="/images/logos/logo.png"
                    />
                    <img
                        alt="Foto do voluntário"
                        class="${classes.media}"
                        src=${user.User.imagem_perfil !== null && user.User.imagem_perfil !== '' ? foto : defaultAvatar}
                    />
                    <div">
                        <p style="font-size: 1.2rem;">
                            ${firstName}
                        </p>
                        <p>
                           ${user.User.nome_social || '-'}
                        </p>
                        <p>
                            Número: ${user.codigo_voluntario_feesp || '-'}
                        </p>
                        <p>
                            Voluntário desde: ${user.voluntario_desde || '-'}
                        </p>
                    </div>
                </div>
            `;
        
            container.appendChild(card);
        };
    
        // Iterar sobre os usuários e criar crachás
        for (let i = 0; i < selectedUsers.length; i++) {
            createCard(selectedUsers[i]);
        }
    
        // Configurações para o PDF
        const opt = {
            margin: 0.5,
            filename: 'crachas.pdf', // Nome do arquivo PDF
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };
    
        // Gerar o PDF a partir do conteúdo HTML
        html2pdf().from(container).set(opt).save();
    };
    
    function handleSubmit() {
    // if (!select1Value?.length && !select2Value?.length && !select3Value?.length && !startDate && !endDate) {
    //   return;
    // }

    const searchData = {
        select1Value,
        select2Value: selectedArea,
        select3Value,
    };

    console.log('searchData', searchData)

    getUsers(searchData);
    }

    useEffect(() => {
        getUsers(searchData);
        getUsersToExcel()
    }, []);

    useEffect(() => {
    console.log('atualizou')
    }, [setUsers, users]);

    const [searchTerm, setSearchTerm] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(searchTerm == undefined) return;

        if(searchTerm == '') {
            setLoading(true)
            searchUser(searchTerm)
            setLoading(false)
        }

        const delayDebounceFn = setTimeout(() => {
        setLoading(true)
        searchUser(searchTerm)
        setLoading(false)
        }, 1500)

        
        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    useEffect(() => {
        Swal.close();
    }, [location.pathname, Swal]);

    const searchUser = useCallback(async (e) => {
        console.log('pesquisar', e)
        try {
            ApiService.get('/voluntarios', {params: {search: e}})
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('error', error.message);
                }
            })
        } catch (err) {
            console.log(err);
        }
    }, []);

    const small = window.matchMedia('(max-width:1330px)');

    const data = [];

    if(usersToExcel) {
        for (const item of usersToExcel) {
    
            const areas = item.Areas || [];
            const departamentos = item.Departamento || [];

            const cleanClasses = {
                nome: item.User.nome,
                cpf: item.User.cpf,
                email: item.User.email,
                data_nascimento: item.User.data_nascimento,
                genero: item.User.genero,
                nacionalidade: item.User.nacionalidade,
                rg: item.User.rg,
                telefone: item.User.telefone,
                celular: item.User.celular,
                atividade: item.atividade,
                teve_vivencia_pratica: item.teve_vivencia_pratica ? 'Sim' : 'Não',
                ficha: item.ficha,
                codigo_antigo_voluntariado: item.codigo_antigo_voluntariado,
                nome_area: areas.nome,
                nome_departamento: departamentos.nome,

               /*  aceita_contato_whatsapp: item.aceita_contato_whatsapp,
                documento_internacional: item.documento_internacional,
                estado_civil: item.estado_civil,
                tem_filhos: item.tem_filhos,
                qtde_filhos: item.qtde_filhos,
                quais_cuidados: item.quais_cuidados,
                escolaridade: item.escolaridade,
                formacao_principal: item.formacao_principal,
                ocupacao: item.ocupacao,
                ocupacao_natureza: item.ocupacao_natureza ,
                profissao: item.profissao,
                habilidade_especifica: item.habilidade_especifica,
                religiao: item.religiao,
                conhece_espiristimo: item.conhece_espiristimo,
                cursos_fora_feesp: item.cursos_fora_feesp,
                obs_voluntariado: item.obs_voluntariado,
                obs_gerais: item.obs_gerais,
                bairro: item.bairro,
                cidade: item.cidade,
                estado: item.estado,
                CEP: item.CEP,
                codigo_postal: item.codigo_postal,
                complemento: item.complemento,
                endereco: item.endereco,
                numero: item.numer,
                pais: item.pais,
                nome_emergencia: item.nome_emergencia ,
                celular_emergencia: item.celular_emergencia, */
            }
            data.push(cleanClasses);
        }
    }

    const generateOptions = (key) => ({ label: key, key });

    const headers = [
        
        // para alunos
        generateOptions('nome'),
        generateOptions('cpf'),
        generateOptions('email'),
        generateOptions('data_nascimento'),
        generateOptions('genero'),
        generateOptions('nacionalidade'),
        generateOptions('rg'),
        generateOptions('telefone'),
        generateOptions('celular'),
        generateOptions('atividade'),
        generateOptions('teve_vivencia_pratica'),
        generateOptions('ficha'),
        generateOptions('codigo_antigo_voluntariado'),


        /* generateOptions('aceita_contato_whatsapp'),
        generateOptions('documento_internacional'),
        generateOptions('estado_civil'),
        generateOptions('tem_filhos'),
        generateOptions('qtde_filhos'),
        generateOptions('quais_cuidados'),
        generateOptions('escolaridade'),
        generateOptions('formacao_principal'),
        generateOptions('ocupacao'),
        generateOptions('ocupacao_natureza'),
        generateOptions('profissao'),
        generateOptions('habilidade_especifica'),
        generateOptions('religiao'),
        generateOptions('conhece_espiristimo'),
        generateOptions('cursos_fora_feesp'),
        generateOptions('obs_voluntariado'),
        generateOptions('obs_gerais'),
        generateOptions('bairro'),
        generateOptions('cidade'),
        generateOptions('estado'),
        generateOptions('CEP'),
        generateOptions('codigo_postal'),
        generateOptions('complemento'),
        generateOptions('endereco'),
        generateOptions('numero'),
        generateOptions('pais'),
        generateOptions('nome_emergencia'),
        generateOptions('celular_emergencia'), */


        // para area e depart
        generateOptions('nome_area'),
        generateOptions('nome_departamento')
        /* generateOptions('nome_do_curso'),
        generateOptions('dia_da_semana'),
        generateOptions('horario'),
        generateOptions('local'),
        generateOptions('status'),
        generateOptions('motivo_reprovacao'), */
    ];

    const headerLabels = headers.map(header => header.label);

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
    const fileExtension = '.xlsx';
    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(data, { header: headerLabels });
        const wb = { Sheets: { 'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const nData = new Blob([excelBuffer], { type: fileType})
        FileSaver.saveAs(nData, 'voluntarios' + fileExtension)
    }

  return (
    <JumboContentLayout
        header={
            <PageHeader
                title={"Alocação de Voluntários"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px'}}>
            {/* <Grid item xs={1} sx={{textAlign: 'center'}}> 
                <Link to="/app/novo-voluntariado" style={{ textDecoration:'none', pointerEvents: !hasPermission('Voluntariado', 'criar') ? 'none' : ''  }} state={{ backUrl: location.pathname }}>
                    <LoadingButton
                        color="success"
                        type="submit"
                        variant="contained"
                        size="large"
                        disabled={!hasPermission('Voluntariado', 'criar') ? true : false}
                        sx={{maxWidth: {md: '200px', mt: '1rem'}, marginBottom: '2rem'}}
                    >
                        Novo Cadastro
                    </LoadingButton>
                </Link>
            </Grid> */}
            <Grid container spacing={3} justifyContent={'center'} alignContent={'center'} sx={{mb: '2rem', justifyContent: 'center'}}>
                <Grid item xs={3}>
                    <Select 
                        id="select-one"
                        isMulti 
                        options={optionsStatus}
                        components={animatedComponents}
                        value={select1Value}
                        onChange={handleSelect1Change}
                        placeholder="Selecione status"
                        styles={{
                            menu: (list) => ({
                              ...list,
                              zIndex: 3,
                            }),
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Select
                        id="select-two"
                        isMulti 
                        options={optionsAreas}
                        components={animatedComponents}
                        value={selectedArea}
                        onChange={handleSelect2Change}
                        placeholder="Selecione área"
                    />
                </Grid>
                <Grid item xs={small.matches ? 4 : 3}>
                    <Select
                        id="select-three"
                        isMulti 
                        options={optionsDepartments}
                        components={animatedComponents}
                        value={select3Value}
                        onChange={handleSelect3Change}
                        placeholder="Selecione departamento"
                    />
                </Grid>
                    
                    <Grid item xs={small.matches ? 2 : 3}>
                        <Button 
                        fullWidth 
                        variant={'contained'} 
                        onClick={handleSubmit}
                        >
                            Filtrar
                        </Button>
                    </Grid>
            </Grid>
            <Typography 
                variant="h6" 
                sx={{fontWeight: '500', marginBottom: '1rem', marginLeft: '1rem'}}
            >
                Busque por nome, email, celular e cpf.
            </Typography>
            <Div sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                
                <JumboSearch
                    onChange={(e) => setSearchTerm(e)}
                    placeholder="Digite o nome, email, celular ou cpf aqui..."
                    sx={{
                        width: '440px',
                        marginBottom: '1rem'
                    }}
                />

                <Tooltip
                    title="Exportar dados"
                    TransitionComponent={Zoom}
                    arrow
                >
                    <CSVStyled 
                        onClick={(e) => exportToExcel()}
                        variant='container'
                        size='small'
                        color='info'
                    >
                        
                        <Typography
                            variant={"h5"}
                            color="#ffffff"
                            mb={.25}
                        >
                            Download
                        </Typography>
                        <DownloadIcon/>
                    </CSVStyled>
                </Tooltip>
            </Div>
            <>
                {
                    (users != null && users.length == 0) && <Typography>Sem resultados correspondentes</Typography>
                }

                {/* <Box display="flex" justifyContent="flex-end" width="100%" marginBottom="1rem">
                    <Tooltip
                        title="Gerar crachás selecionados"
                        TransitionComponent={Zoom}
                        arrow
                    >
                        <Button 
                            onClick={handleGenerateBatchCrachas}
                            variant='contained'
                            size='small'
                            color='info'
                            disabled={selectedUsers.length === 0}
                        >
                            Gerar Crachás
                        </Button>
                    </Tooltip>
                </Box> */}
                {
                    users && users.map((user, key) => (
                        <VolunteerAlocationItem 
                            item={user} 
                            setUsers={(data) => setUsers([...data])} 
                            users={users} 
                            key={key}
                            selectedUsers={selectedUsers}
                            setSelectedUsers={setSelectedUsers}
                            handleGenerateBatchCrachas={handleGenerateBatchCrachas}
                        />
                    ))
                }

                {isLoading && <CircularProgress/>}
            </>
        </Paper>  
    </JumboContentLayout>
  )
}

export default VolunteerAlocationList