import React, { useState, useContext} from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import {Accordion, Card, Tooltip, Typography, Zoom, Grid, Chip, Box, Modal, Backdrop, Fade} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import VisibilityIcon from '@mui/icons-material/Visibility';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { Link, useNavigate } from 'react-router-dom';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { useLocation } from 'react-router-dom'
import HistoryIcon from '@mui/icons-material/History';
import { Form, Formik } from 'formik';
import ModalVolunteerInterestedHistory from 'app/components/VolunteersInterested/Modals/ModalVolunteerInterestedHistory';
import { EllipsisTooltip } from 'app/components/EllipsisTooltip/EllipsisTooltip';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';

export const LinkStyle = styled(Link)`
    padding: 4px 10px;
    background: #7352C7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    
    svg {
        color: white;
    }
`

export const ButtonStyle = styled(Button, props => props)`
    padding: 4px 10px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    
    svg {
        color: white;
    }
`


const None = styled(Div) `
    @media(max-width: 1280px) {
        display: none;
    }
    
`
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
};

const VolunteerHistoryItem = ({ item ,setUsers, users, selectedUsers, setSelectedUsers }) => {
    const location = useLocation();
    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });
    };

    function removeById(arr, id) {
        const objWithIdIndex = arr.findIndex((obj) => obj.pessoa_id === id);
      
        if (objWithIdIndex > -1) {
          arr.splice(objWithIdIndex, 1);
        }
      
        return arr;
    }

    const maxLengthText = 15;

    const { hasPermission } = useContext(PermissionContext);
    const navigate = useNavigate();
    const [openVolunteer, setOpenVolunteer] = useState(false);
    const [openVolunteerConsult, setOpenVolunteerConsult] = useState(false);
    
    const verifyAreasAndDepartments = () => {
        /* {item.Areas !== null ? item.Areas?.nome : item.Departamento?.nome} */

        if(item.Areas !== null) {

            if(item.Areas?.deletedAt !== null) {
                return 'Área excluída'
            }
            return item.Areas?.nome
        } else {
            if(item.Departamento?.deletedAt !== null) {
                return 'Departamento excluído'
            }
           return item.Departamento?.nome
        }

    }

    const handleOpenVolunteer = () => setOpenVolunteer(true);
    const handleCloseVolunteer = () => {
        setOpenVolunteer(false)
        setOpenVolunteerConsult(false)
    };

    const handleOpenVolunteerConsult = () => setOpenVolunteerConsult(true);
    const handleCloseVolunteerConsult = () => setOpenVolunteerConsult(false);

    const handleCheckboxChange = () => {
        setSelectedUsers((prevSelectedUsers) => {
          if (prevSelectedUsers.some((user) => user.pessoa_id === item.pessoa_id)) {
            // Remove o usuário se já estiver na lista
            return prevSelectedUsers.filter((user) => user.pessoa_id !== item.pessoa_id);
          } else {
            // Adiciona o usuário se não estiver na lista
            return [...prevSelectedUsers, item];
          }
        });
    };
    
    return (
        <>
            <Card sx={{mb: 1}}>
                <Accordion square sx={{borderRadius: 2}}>
                    <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{
                            px: 3,
                            flexDirection: 'row-reverse',

                            '& .MuiAccordionSummary-content': {
                                alignItems: 'center',

                                '&.Mui-expanded': {
                                margin: '12px 0',
                                }
                            },
                            '.MuiAccordionSummary-expandIconWrapper': {
                                borderRadius: 1,
                                border: 1,
                                color: 'text.secondary',
                                borderColor: 'divider',
                                transform: 'none',
                                height: 28,
                                width: 28,
                                alignItems: 'center',
                                justifyContent: 'center',
                                mr: 1,

                                '&.Mui-expanded': {
                                    transform: 'none',
                                    color: 'primary.main',
                                    borderColor: 'primary.main',
                                },

                                '& svg': {
                                    fontSize: '1.25rem',
                                }
                            }
                        }}
                    >
                        <Grid container spacing={2} alignContent={'center'}>
                            {/* <Grid item lg={1} md={2} xs={12} sx={{flexShrink: 0, px: 1}}>
                                <Avatar sx={{width: 52, height: 52}} alt={item.nome} src={item.imagem_perfil ? `${process.env.REACT_APP_API_KEY}/images/${item.imagem_perfil}` : '#'} variant="rounded"/>
                            </Grid> */}
                            <Grid item lg={3} md={4} xs={8}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    Nome
                                </Typography>
                                <EllipsisTooltip text={item?.User?.nome}/>
                            </Grid>
                            <Grid item lg={2} md={3} xs={4}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    CPF
                                </Typography>
                                <EllipsisTooltip text={item?.User?.cpf}/>
                            </Grid>
                            <Grid item lg={3} md={3} xs={4}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    Email
                                </Typography>
                               {/*  {item.User?.email &&
                                    <Tooltip title={item.User?.email} arrow TransitionComponent={Zoom}>
                                        <Typography variant={"body1"}>
                                            {TextService.limitText(item.User?.email, maxLengthText)}
                                        </Typography>
                                    </Tooltip>
                                } */}
                                <EllipsisTooltip text={item?.User?.email}/>
                            </Grid>
                            <Grid item lg={2} md={3} xs={4}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    Celular
                                </Typography>
                                <EllipsisTooltip text={item?.User?.celular}/>
                            </Grid>
                            
                            <Grid item lg={3} md={3} xs={4} sx={{flexShrink: 0, px: 1, display: 'none'}}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    Voluntário
                                </Typography>
                                <Typography variant={"body1"}>
                                    {verifyAreasAndDepartments()}
                                </Typography>
                            </Grid>
                            
                            <Grid item lg={1} md={3} xs={4} sx={{flexShrink: 0, px: 1, display: 'none'}}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    Status
                                </Typography>
                                {item.status === 'ativo' 
                                    ? <Chip label="Ativo" color="success" size='small'/>
                                    : <Chip label="Inativo" color="warning" size='small'/>
                                }
                                
                            </Grid>

                            <Grid item lg={1} md={3} xs={4} sx={{flexShrink: 0, px: 1, display: 'none'}}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    Ficha
                                </Typography>
                                {item.ficha !== null
                                    ? <Chip label="Sim" color="success" size='small'/>
                                    : <Chip label="Não" color="error" size='small'/>
                                }
                                
                            </Grid>
                
                            <Grid item lg={2} md={2} xs={4}>
                                <Box display='flex' justifyContent='flex-end' gap='1rem'  width='100%'>
                                    {/* <Tooltip 
                                        title={'Selecione para gerar o crachá deste usuário'} 
                                        arrow 
                                        TransitionComponent={Zoom}
                                    >
                                        <Checkbox
                                            checked={selectedUsers.some((user) => user.pessoa_id === item.pessoa_id)}
                                            onChange={handleCheckboxChange}
                                        />
                                    </Tooltip> */}
                                    <Tooltip title={'Acessar histórico do voluntário'} arrow TransitionComponent={Zoom}>
                                        <ButtonStyle
                                            size="small"
                                            color="info"
                                            variant="contained"
                                            /* sx={{maxWidth: {md: '400px', mt: '1rem'}}} */
                                            onClick={() => {
                                                handleOpenVolunteer()
                                                setOpenVolunteerConsult(true)
                                            }}
                                        >
                                            <HistoryIcon />
                                        </ButtonStyle>
                                    </Tooltip>

                                    <Tooltip 
                                        title="Visualizar"
                                        TransitionComponent={Zoom}
                                        arrow
                                    >
                                        <LinkStyle 
                                            to={`/app/editar-aluno/${item.User.pessoa_id}`} 
                                            state={{ backUrl: location.pathname }}
                                            style={{ 
                                                background: !hasPermission('Pessoas', 'acessar') ? 'rgba(0, 0, 0, 0.12)' : '#7352C7',
                                                pointerEvents: !hasPermission('Pessoas', 'acessar') ? 'none': ''
                                            }}
                                        >
                                            <VisibilityIcon 
                                                style={{ 
                                                    color: !hasPermission('Pessoas', 'acessar') ? 'rgba(0, 0, 0, 0.26)' : '#ffffff'
                                                }}
                                            />
                                        </LinkStyle>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                </Accordion>
            </Card>
            <Formik key={item.pessoa_id}>
                <Form>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openVolunteer}
                        onClose={handleCloseVolunteer}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openVolunteer}>
                            
                            <Div sx={style}>
                                <ModalVolunteerInterestedHistory 
                                    key={item.pessoa_id} 
                                    item={item}
                                    openVolunteerConsult={openVolunteerConsult} 
                                    handleCloseVolunteer={handleCloseVolunteer} 
                                />
                            </Div>
                        
                        </Fade>
                    </Modal>
                </Form>
            </Formik> 
        </>
        
    );
};
/* Todo item prop define */




export default VolunteerHistoryItem;
