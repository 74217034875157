import React, { useState, useCallback, useEffect } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useLocation } from 'react-router-dom';
import CourseApprovalList from 'app/components/CourseApprovalList/CourseApprovalList';
import ContinuingCoursesList from 'app/components/ContinuingCoursesList/ContinuingCoursesList';
import CurrentCoursesAccordion from 'app/components/CurrentCoursesAccordion/CurrentCoursesAccordion';
import { CourseWithAllStatusList } from 'app/components/CourseWithAllStatusList/CourseWithAllStatusList';
import CompatibleCoursesList from 'app/components/CompatibleCoursesList/CompatibleCoursesList';

const ModalCourseHistory = ({ username, userId = null, handleClose }) => {
  const { theme } = useJumboTheme();

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const { id } = useParams();
  const location = useLocation();

  const [courseHistory, setCourseHistory] = useState([]);
  const [courseHistoryOnlyApproval, setCourseHistoryOnlyApproval] = useState(
    []
  );
  const [courseHistoryOnlyActive, setCourseHistoryOnlyActive] = useState([]);
  const [coursesCompatible, setCoursesCompatible] = useState([]);
  const [coursesContinued, setCoursesContinued] = useState([]);
  const [modalTitle, setModalTitle] = useState('Histórico de Cursos');
  const [modalSubtitle, setModalSubtitle] = useState();
  const [activeComponent, setActiveComponent] = useState('initial');

  const currentPath = location.pathname;
  const inClassesURL =
    currentPath.startsWith('/app/editar-turma') ||
    currentPath.startsWith('/app/listar-cursos-outras-entidades') ||
    currentPath.startsWith('/app/listar-curso-lote') ||
    currentPath.startsWith('/app/listar-consultar-historico');

  const idSelected = inClassesURL ? userId : id;

  const getCourseHistory = useCallback(async () => {
    try {
      ApiService.get(`/historico-cursos-feesp/${idSelected}`)
        .then((response) => {
          console.log(response.data);
          setCourseHistory(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getCourseHistoryWithApproval = useCallback(async () => {
    try {
      console.log('id selecionado', idSelected);
      ApiService.get(`/cursos-regulares-ativos/${idSelected}`)
        .then((response) => {
          console.log('sdsds', response);
          setCourseHistoryOnlyActive(response.data.cursosRegularesAtivos);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getCoursesRegular = useCallback(async () => {
    try {
      ApiService.get(`/cursos-regulares-continuados/${idSelected}`)
        .then((response) => {
          console.log(
            'redlkfjd',
            response.data?.historicoCursosRegulares?.historicoCursos
          );
          /* setCoursesCompatible(
            response.data?.historicoCursosRegulares?.historicoCursos
          ); */
          setCourseHistoryOnlyApproval(
            response.data?.historicoCursosRegulares?.historicoCursos
          );
          setCoursesContinued(
            response.data?.historicoCursosContinuados?.historicoCursos
          );
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getCourseHistoryFromSpiritualCenter = useCallback(async () => {
    try {
      ApiService.get(`/centro-espirita/historico-usuario/${idSelected}`)
        .then((response) => {
          console.log('cursos centro espirita compativeis', response.data);
          setCoursesCompatible(response.data.cursoHistoricoDoUsuario);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleBackClick = () => {
    setActiveComponent('initial');
    setModalTitle('Histórico de Cursos');
    setModalSubtitle('');
  };

  const handleCompatibleCoursesClick = () => {
    setActiveComponent('compatible');
    setModalTitle('Histórico de Cursos Compatibilizados');
    setModalSubtitle('');
  };

  const handleDetailedHistoryClick = () => {
    setActiveComponent('detailedHistory');
    setModalTitle('Histórico Detalhado de Cursos FEESP');
    setModalSubtitle(
      'Somente constam dados de cursos realizados a partir de 2024'
    );
  };

  const renderHistoryButton = () => {
    return (
      <>
        {activeComponent === 'initial' && (
          <>
            <Button
              size='small'
              variant='contained'
              color='primary'
              style={{ marginRight: 10 }}
              onClick={handleCompatibleCoursesClick}
            >
              Cursos Compatibilizados
            </Button>
            <Button
              size='small'
              variant='contained'
              color='primary'
              onClick={handleDetailedHistoryClick}
            >
              Histórico Detalhado
            </Button>
          </>
        )}
      </>
    );
  };

  const renderContent = () => {
    switch (activeComponent) {
      case 'compatible':
        return <CompatibleCoursesList coursesCompatible={coursesCompatible} />;
      case 'detailedHistory':
        return (
          <CourseWithAllStatusList courseHistoryOnlyApproval={courseHistory} />
        );
      default:
        return (
          <>
            <CurrentCoursesAccordion
              courseHistoryOnlyActive={courseHistoryOnlyActive}
            />
            <CourseApprovalList
              courseHistoryOnlyApproval={courseHistoryOnlyApproval}
            />
            <ContinuingCoursesList coursesContinued={coursesContinued} />
          </>
        );
    }
  };

  useEffect(() => {
    if (id || userId) {
      getCourseHistory();
      getCoursesRegular();
      getCourseHistoryWithApproval();
      getCourseHistoryFromSpiritualCenter();
    }
  }, []);

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '40px', height: '500px' }}>
        <Box sx={{ height: '100%', overflowY: 'auto' }}>
          <Box
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
            marginRight='1rem'
            gap={1}
          >
            {activeComponent !== 'initial' && (
              <Button
                size='small'
                variant='contained'
                color='success'
                onClick={handleBackClick}
              >
                Voltar
              </Button>
            )}
            <Button size='small' variant='contained' onClick={handleClose}>
              Fechar
            </Button>
          </Box>
          <Typography
            variant={'h1'}
            style={{ textAlign: 'center', marginBottom: 30 }}
          >
            {modalTitle}
          </Typography>
          <Typography variant={'h5'} fontWeight={'bold'}>
            {modalSubtitle}
          </Typography>
          <Box display={'flex'} gap={1} alignItems={'center'}>
            <Typography variant='h4' fontWeight={'300'}>
              Aluno selecionado:
            </Typography>

            <Typography
              variant='h4'
              textTransform={'capitalize'}
              fontWeight={'600'}
            >
              {username}
            </Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='end'
            alignItems='center'
            marginBottom='1rem'
          >
            {renderHistoryButton()}
          </Box>
          <React.Fragment>{renderContent()}</React.Fragment>
        </Box>
      </Paper>
    </JumboContentLayout>
  );
};

export default ModalCourseHistory;
