import React, { useState, useCallback, useEffect, useContext, useRef } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, Paper, Stack,Switch,Typography,useMediaQuery, Accordion, AccordionSummary, AccordionDetails, Box, Input} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { updateInputValue, disableInputValue } from "../../utils/appHelpers";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import axios from 'axios';
import { COUNTRIES } from '../../utils/constants/countries'
import Select from 'react-select'
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import { cpf } from 'cpf-cnpj-validator'; 
import { PermissionContext } from 'app/contexts/PermissionContext';
import styled from "@emotion/styled";
import makeAnimated from 'react-select/animated';


export const InputWithouDatePicker = styled(JumboTextField)`
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
`
const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      border: state.isFocused ? '1px solid #7352C7' : '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      padding: '8px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'black',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#7352C7' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: 'lightblue',
        color: 'white',
      },
    }),
    menu: provided => ({ ...provided, zIndex: 9999 })
};

const RegisterUserFormPublic = () => {

    const animatedComponents = makeAnimated();
    
    const { hasPermission } = useContext(PermissionContext);
    const celularInputRef = useRef(null);
    const tellInputRef = useRef(null);

    const {theme} = useJumboTheme();
    
    const small = window.matchMedia('(max-width:1000px)');

    const Swal = useSwalWrapper();

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const initialValues = {
        nome: null,
        nome_social: null,
        data_nascimento: null,
        genero: "",
        nacionalidade: "",
        cpf: null,
        rg: null,
        telefone: "05511",
        celular: "05511",
        aceita_contato_whatsapp: "sim",
        documento_internacional: "",
        estado_civil: "",
        tem_filhos: "",
        qtde_filhos: "",
        email: null,
        escolaridade: "",
        religiao: "",
        formacao_principal: "",
        ocupacao: "",
        ocupacao_natureza: "",
        profissao: "",
        habilidade_especifica: "",
        obs_saude: "",
        conhece_espiristimo: false,
        cursos_fora_feesp: "",
        obs_voluntariado: "",
        obs_gerais: "",
        imagem_perfil: "",
        pais: "BR",
        bairro: "",
        cidade: "",
        estado: "",
        uf: "",
        numero: "",
        complemento: "",
        CEP: "",
        codigo_postal: "",
        reset_password_token: "teste_token",
        trabalhos_voluntarios: false,
        infos_usuario: false,
        quais_cuidados: false,
        celular_emergencia: "05511",
        celular_confirmacao: "",
        email_confirmacao: "",
        tipo_sanguineo: "",
        profissao_selecionada_id: null,
        formacao_principal_selecionada_id: null
    }

    const { id } = useParams()
    const [user, setUser] = useState(initialValues);
    const [whoCreated, setWhoCreated] = useState([]);
    const [whoUpdated, setWhoUpdated] = useState([]);
    const [userImage, setUserImage] = useState(null);
    const [userLanguages, setUserLanguages] = useState([{ idiomas_id: "", nivel_idioma: "" }]);
    const [deleteLanguages, setDeleteLanguages] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [dataNascimentoObrigatorio, setDataNascimentoObrigatorio] = useState(false);
    const [emailObrigatorio, setEmailObrigatorio] = useState(false);
    const [celularObrigatorio, setCelularObrigatorio] = useState(false);
    const [cpfObrigatorio, setCpfObrigatorio] = useState(false);
    const [nomeSocialObrigatorio, setNomeSocialObrigatorio] = useState(true)
    const [activities, setActivities] = useState([]);

    const [mask, setMask] = useState('');
    const [maskOnlyNumbers, setMaskOnlyNumbers] = useState('')

    const [verifyMask, setVerifyMask] = useState(true)
    const [verifyMaskTell, setVerifyMaskTell] = useState(true)

    const [errorsFromApi, setErrorsFromApi] = useState('');

    const canCreate = hasPermission('Pessoas', 'criar');
    const canEdit = hasPermission('Pessoas', 'editar');
    let { state } = useLocation();
    const navigate = useNavigate();
    const isDisabled = state && state.disable ? true : (id ? !canEdit : !canCreate)
    const [hasScrolled, setHasScrolled] = useState(false);
   
    useEffect(() => {
      if (errorsFromApi.length > 0 && !hasScrolled) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setHasScrolled(true);
      }
    }, [errorsFromApi, hasScrolled]);

    useEffect(() => {
        // Se verifyMask for false, focalize o campo de entrada do celular
        if (!verifyMask && celularInputRef.current) {
          celularInputRef.current.focus();
        }
    }, [verifyMask]);

    useEffect(() => {
        // Se verifyMaskTell for false, focalize o campo de entrada do celular
        if (!verifyMaskTell && tellInputRef.current) {
            tellInputRef.current.focus();
        }
    }, [verifyMaskTell]);
    
    
    const validationSchema = yup.object().shape({
        email:yup.string()
            .email('Endereço de email inválido')
            .nullable()
            .required('Preenchimento obrigatório') ,
        email_confirmacao: yup.string()
            .oneOf([yup.ref('email'), null], 'Os emails devem ser iguais')
            .required('Preenchimento obrigatório')
            .nullable(),
        cpf: yup
            .string()
            .test({ 
                message: 'CPF inválido',
                test: (value) => cpf.isValid(value)
            })
            .when(['email', 'rg'], {
                is: (email, rg) => !email && !rg,
                then: yup.string().required('Preenchimento obrigatório').nullable()
            }) 
            ,
        nome:yup.string('Insira seu nome')
            .required('Preenchimento obrigatório')
            .nullable(),

        data_nascimento:yup
            .date()
            .max(new Date(), 'Não é possivel incluir uma data futura')
            .required('Preenchimento obrigatório').nullable(),
        celular: 
             verifyMask ? yup
            .string()
            .required('Preenchimento obrigatório')
            .nullable()
            .transform(value => value?.replace(/[^\d]/g, ''))
            .min(14, "Preencha o número completo")
            .max(14, "Preencha o número completo") : yup.string().required('Preenchimento obrigatório').nullable().transform(value => value?.replace(/[^\d]/g, '')),
        celular_confirmacao: 
       verifyMask ? yup
            .string()
            .required('Preenchimento obrigatório')
            .nullable()
            .transform(value => value?.replace(/[^\d]/g, ''))
            .test('cell-match', 'Os números do celular devem ser iguais', function(value){
                return this.parent.celular === value
            }) : yup.string().nullable(),
        }, [['email', 'cpf'], ['email', 'rg'], ['cpf','rg']]);

    const toast = (variant, message, type = false) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });

        //  Redireciona se mensagem de retorno for de sucesso
        if(variant == 'success' && (!userImage || (userImage && type))){
            setTimeout(function(){
                navigate("/obrigado")
            }, 2000)
        }
    };

    const getUser = useCallback(async () => {
        try {
            await ApiService.get(`/pessoa/${id}`)
            .then((response) => {
              setUser(response.data.user[0]);

              if(response.data.user[0].pais == null || response.data.user[0].pais == '' || response.data.user[0].pais == 'brasil') {
                setUser({...response.data.user[0], pais: 'BR'})
              }

              if(response.data.user[0].nome_social == null || response.data.user[0].nome_social == '') {
                setNomeSocialObrigatorio(true)
              } else {
                setNomeSocialObrigatorio(false)
              }

              if(response.data.user[0].data_nascimento == null || response.data.user[0].data_nascimento == '') {
                setDataNascimentoObrigatorio(true)
              }
              if(response.data.user[0].email == null || response.data.user[0].email == '') {
                setEmailObrigatorio(true)
              }
              if(response.data.user[0].celular == null || response.data.user[0].celular == '') {
                setCelularObrigatorio(true)
              }
              if(response.data.user[0].cpf == null || response.data.user[0].cpf == '') {
                setCpfObrigatorio(true)
              }

              if(response.data.userLanguages.length > 0){
                setUserLanguages(response.data.userLanguages)
              }
              if(response.data.quemCriou !== null) {
                setWhoCreated(response.data.whoCreated?.nome)
              }
              if(response.data.quemAtualizou !== null) {
                setWhoUpdated(response.data.whoUpdated?.nome)
              }
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const getActivities = useCallback(async () => {
        try {
            ApiService.get(`/atividades`)
            .then((response) => {
                console.log(response.data)
                setActivities(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    useEffect(() => {
        if(id) {
            getUser()
            getActivities()
        };
        getActivities()
    }, []);

    const handleChangeImage = (e) => {
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
        setUserImage(e.target.files[0])
    }

    const handleRemoveImage = () => {
        setUser((prevValues) => ({
            ...prevValues,
            imagem_perfil: null
        }))
        setPreviewImage(null); 
        setUserImage(null); 
    };

    const removeNonNumericCharacters = (str) => {
        return str.replace(/\D/g, ''); //remove todos os caracteres não numéricos
    };
      
    // função responsavel por verificar quais campos do formulario estão vazios
    const isObjectFilled = (obj) => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key];
           
            if (obj.pais === 'BR') {
              if (
                (key !== 'telefone' && key !== 'celular_emergencia' && key !== 'nome_emergencia' && key !== 'tipo_sanguineo' && key !== 'obs_saude' && key !== 'nome_social' && key !== 'documento_internacional' && key !== 'obs_gerais' && key !== 'codigo_postal' && key !== 'estado' && key !== 'imagem_perfil' && key !== 'cursos_fora_feesp' && key !== 'obs_voluntariado')
                // exclui essas propriedades acima da verificação de preenchimento obrigatório
                && (value === null || value === undefined || value === '' ||
                  (key === 'celular') &&
                  (removeNonNumericCharacters(value).length < 13))
              ) {
                return false;
              }
            } else {
    
              if (
                key !== 'telefone' && key !== 'celular_emergencia' && key !== 'nome_emergencia' && key !== 'tipo_sanguineo' && key !== 'obs_saude' && key !== 'nome_social' && key !== 'documento_internacional' && key !== 'obs_gerais' && key !== 'codigo_postal' && key !== 'estado' && key !== 'imagem_perfil' && key !== 'cursos_fora_feesp' && key !== 'obs_voluntariado' &&
                (key !== 'bairro' && key !== 'cidade' && key !== 'numero' && key !== 'complemento' && key !== 'CEP')
                // exclui essas propriedades acima da verificação de preenchimento obrigatório
              ) {
                if (value === null || value === undefined || value === '' ||
                  (key === 'celular') &&
                  (removeNonNumericCharacters(value).length < 13))
                {
                  return false;
                }
              }
            }
          }
        }
        return true;
    };

    // função responsavel por mostrar quais campos do formulario estão vazios (não esta em uso)
    const getUnfilledFields = (obj) => {
        const unfilledFields = [];

        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key];
        
            if (obj.pais === 'BR') {
              // Se o país for "BR", verifica se os campos abaixo foram preenchidos
              if (
                (key !== 'nome_social' && key !== 'documento_internacional' && key !== 'obs_gerais' && key !== 'codigo_postal' && key !== 'estado' && key !== 'imagem_perfil' && key !== 'cursos_fora_feesp' && key !== 'obs_voluntariado') &&
                (value === null || value === undefined || value === '' ||
                  (key === 'celular' || key === 'celular_emergencia' || key === 'telefone') &&
                  (removeNonNumericCharacters(value).length < 13))
              ) {
                unfilledFields.push(key);
              }
            } else {
              // Se o país não for "BR", pule a validação para certos campos.
              if (
                key !== 'nome_social' && key !== 'documento_internacional' && key !== 'obs_gerais' && key !== 'codigo_postal' && key !== 'estado' && key !== 'imagem_perfil' && key !== 'cursos_fora_feesp' && key !== 'obs_voluntariado' &&
                (key !== 'bairro' && key !== 'cidade' && key !== 'numero' && key !== 'complemento' && key !== 'CEP')
              ) {
                if (value === null || value === undefined || value === '' ||
                  (key === 'celular' || key === 'celular_emergencia' || key === 'telefone') &&
                  (removeNonNumericCharacters(value).length < 13))
                {
                  unfilledFields.push(key);
                }
              }
            }
          }
        }
      
        return unfilledFields;
    };

    const optionsPrincipalFormations = activities?.filter(result => result?.tipo_formacao === 'formacao_principal').map((result) => {
        return {
          value: result.atividade_id,
          label: result.descricao
        };
    });

    const optionsJobs = activities?.filter(result => result?.tipo_formacao === 'profissao').map((result) => {
        return {
          value: result.atividade_id,
          label: result.descricao
        };
    });
      
    const handleSubmit = async (values, { setSubmitting, setFieldError, setStatus }) => {

        const isFormFilled = isObjectFilled(values);

        //const unfilledFields = getUnfilledFields(values);
        
        let userLanguagesFiltered = userLanguages.filter((x) => !x.idiomas_id == "" && !x.nivel_idioma == "")
        
        const result = await Swal.fire({
            title: isFormFilled ? 'Você confirma a inclusão no cadastro?': 'Existem campos que ainda não foram preenchidos. Você confirma a inclusão no cadastro?',
            /* text: 'Não será possível reverter a ação!', */
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        });
        var personID = id;
        let data = new FormData()
        data.append("imagem_perfil", userImage)
        

        if(id){
           await ApiService.put(`/pessoa/${id}`, {
                values,
                userLanguages: userLanguagesFiltered,
                deleteLanguages
               })
               .then(() => {
                    toast('success', 'Atualizado com sucesso')
                })
               .catch((error) => {
                    const target = error.response.data.error;
                    console.log(error.response.data)
                    setErrorsFromApi(target)
                    //toast('error', target)
                    //let message = 'Ocorreu um erro'
                    
                    if(target.toLowerCase().includes('cpf')){
                        toast('error', 'CPF já existente')
                        setFieldError('cpf', 'CPF já existente');
                    } else if(target.toLowerCase().includes('rg')){
                        toast('error', 'RG já existente')
                        setFieldError('rg', 'RG já existente');
                    } else if(target.toLowerCase().includes('email')){
                        toast('error', 'E-mail já cadastrado')
                        setFieldError('email', 'E-mail já cadastrado');
                    }
                    //toast('error', message)

                   if (error.response.data) {
                        console.log(error.response.data.error);
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
               })
        } else {
            if(result.value) {
                await ApiService.post('/cadastro-inicial', {
                    values,
                    userLanguages: userLanguagesFiltered
                })
                .then((response) => {
                    personID = response.data.pessoa_id
                    toast('success', 'Criado com sucesso')
                    localStorage.setItem('register-success', true)
                })
                .catch((error) => {
                    const target = error.response.data.error;
                    console.log(error.response.data)
                    setErrorsFromApi(target)
                    //toast('error', target)
                    //let message = 'Ocorreu um erro'
                    
                    if(target.toLowerCase().includes('cpf')){
                        toast('error', 'CPF já existente')
                        setFieldError('cpf', 'CPF já existente');
                    } else if(target.toLowerCase().includes('rg')){
                        toast('error', 'RG já existente')
                        setFieldError('rg', 'RG já existente');
                    } else if(target.toLowerCase().includes('email')){
                        toast('error', 'E-mail já cadastrado')
                        setFieldError('email', 'E-mail já cadastrado');
                    }else if(target.toLowerCase().includes('celular')){
                        toast('error', 'Celular já cadastrado')
                        setFieldError('celular', 'Celular já cadastrado')
                    }
                   
    
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                    console.log(error.request);
                    } else {
                    console.log('error', error.message);
                    }
                })
            }
        }

        if(userImage) {
            ApiService.put(`/pessoa/atualizar-imagem/${personID}`, data)
              .then(() => {
                toast('success', 'Imagem atualizada com sucesso', true)
              })
              .catch((error) => {
                toast('error', 'Ocorreu um erro ao atualizar imagem', true)

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('error', error.message);
                  }
              })
            
        }
        setSubmitting(false);
    } 
    
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...userLanguages];
        list[index][name] = value;
        setUserLanguages(list);
    };

    const handleRemoveClick = index => {
        const list = [...userLanguages];

        const language = list[index];
        setDeleteLanguages([...deleteLanguages, language])

        list.splice(index, 1);
        setUserLanguages(list);
    };

    const handleAddClick = () => {
        setUserLanguages([...userLanguages, { idiomas_id: "", nivel_idioma: "" }]);
    };
    
    const checkCep = async (e, setFieldValue) => {
        setIsLoading(true);
        const {value} = e.target;
        console.log(value)
        const cep = value.replace(/\D/g, '');
        await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => {
            console.log(res.data)
            setFieldValue('endereco', res.data.logradouro)
            // setFieldValue('complemento', res.data.complemento)
            setFieldValue('bairro', res.data.bairro)
            setFieldValue('cidade', res.data.localidade)
            setFieldValue('uf', res.data.uf)
            setIsLoading(false);
        })
        .catch((err) => {
            setIsLoading(false);
            console.log(err)
        })
    }

    const errorsHandler = (errors) => {
        
        if(Object.keys(errors).length != 0) {
            window.scrollTo({ top: 250, behavior: 'smooth' })
        
            if(errors.nome) {
                setTimeout(() => {
                    document.getElementsByName('nome')[0].focus()
                }, 1000)  // 1000 milissegundos (1 segundo)
                //toast('error', errors.cpf)
            } else if(errors.cpf) {
                setTimeout(() => {
                    document.getElementsByName('cpf')[0].focus()
                }, 1000)  // 1000 milissegundos (1 segundo))
                //toast('error', errors.data_nascimento)
            }
            else if(errors.celular) {
                setTimeout(() => {
                    document.getElementsByName('celular')[0].focus()
                }, 1000)  
                //toast('error', errors.data_nascimento)
            } else if(errors.celular_confirmacao) {
                setTimeout(() => {
                    document.getElementsByName('celular_confirmacao')[0].focus()
                }, 1000)  
               // toast('error', errors.celular_confirmacao)
            } else if(errors.email) {
                setTimeout(() => {
                    document.getElementsByName('email')[0].focus()
                }, 1000)  
               // toast('error', errors.email)
            } else if(errors.email_confirmacao) {
                setTimeout(() => {
                    document.getElementsByName('email_confirmacao')[0].focus()
                }, 1000)  
               // toast('error', errors.email_confirmacao)
            } else {
                //toast('error', errors.nome)
            }
        } else {
            return null
        }
    }
    
    const verifyCellMask = (values) => {
        const brazilDDI = '055';
        const cellFormatted = values.celular?.replace(/[^\d]/g, '')
        const tellFormatted = values.telefone?.replace(/[^\d]/g, '')
        console.log(cellFormatted.length > 5)

        if (cellFormatted.length > 5) {
            setCelularObrigatorio(true);
            if (cellFormatted.startsWith(brazilDDI)) {
                setVerifyMask(true)
            } else {
                setVerifyMask(false)
            }
        }

        if (tellFormatted.length > 5) {
            if (tellFormatted.startsWith(brazilDDI)) {
                setVerifyMaskTell(true)
            } else {
                setVerifyMaskTell(false)
            }
        }

        if (values.cpf?.replace(/[^\d]/g, '').length > 0 ) {
            setCpfObrigatorio(true);
        } else {
            setCpfObrigatorio(false);
        }

        if (values.cpf === '') {
            values.cpf = null;
        }
    }

    /* const verifyCellMask = (values) => {
        const cellFormatted = values.celular?.replace(/[^\d]/g, '');
    
        if (cellFormatted.length > 5) {
            setCelularObrigatorio(true);
    
            const country = COUNTRIES.find((c) => cellFormatted.startsWith(c.DDI));
            console.log(country);
            if (country) {
                
                setMask(country.mask);
                setMaskOnlyNumbers(country.mask?.replace(/[^\d]/g, ''))
            } else {
                setMask('');
            }
        }
    }; */
    
    
  return (
    <JumboContentLayout
        header={
            <Box display='flex' justifyContent='center' alignItems='center' gap='1rem' margin='1rem 0'>
                <img width='80' height='80' src="../../../images/logos/logo.png" alt="Logo FEESP"/>
                <Typography variant='h1' textAlign='center'>
                    Sistema de Informação FEESP
                </Typography>
            </Box>
            
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }

        <Typography variant='h2' textAlign='center'>
            Cadastro de Pessoas
        </Typography>
        
        <Typography variant='h4' margin='1rem 0'>
            A FEESP, visando aprimorar seu sistema de informação, está fazendo um cadastramento geral de seus alunos e colaboradores. Favor preencher os dados abaixo, com a máxima exatidão possível.
        </Typography>

        <Typography variant='h4' marginBottom='1rem'>
            A FEESP se compromete a não utilizar os dados para nenhum outro fim, a não ser uso interno.
        </Typography>
        
        <Paper sx={{p: '15px', background: '#f9f9f9'}}>
            {state &&
                <Button
                    color="success"
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                    onClick={() => navigate(state.backUrl)}
                >
                    Voltar
                </Button>
            }
            <Formik
                initialValues={user}
                // validationSchema={null}
                validationSchema={validationSchema}
                enableReinitialize
                validateOnChange={false}
                //validateOnBlur={true}
                onSubmit={handleSubmit}
            >
                {({values, isSubmitting, handleChange, setFieldValue, touched, errors, setTouched, setFieldTouched}) => (

                    <Form style={{width: '100%'}} noValidate autoComplete='off'>
                        <Grid container alignContent={'center'}>
                            <Grid container spacing={3} alignContent={'center'} 
                                sx={
                                        {   mt: '0.8rem',
                                            background: '#EFF0F2',
                                            marginLeft: 0,
                                            padding: '0 24px 24px 0'
                                        }
                                    }
                            >
                                <Grid item lg={8} xs={12} >
                                    <JumboTextField
                                        fullWidth
                                        name="nome"
                                        label="Nome"
                                        value={updateInputValue(user, values, 'nome')}
                                        sx={{ background: '#ffffff'}}
                                        /* disabled={isDisabled} */
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                
                                {/* <Grid item lg={3} xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: {xs: 'center', lg: 'flex-end'}}}>
                                    {previewImage && (
                                        <img src={previewImage} alt="img" style={{ width: 50, height: 50, borderRadius: 50, marginRight: 10 }}/>
                                    )}
                                    {values.imagem_perfil && !previewImage && (
                                        <img src={`${process.env.REACT_APP_API_KEY}/images/${values.imagem_perfil}`} alt="img" style={{ width: 50, height: 50, borderRadius: 50, marginRight: 10 }}/>
                                    )}
                                    {previewImage || values.imagem_perfil ? (
                                        <div>
                                        <Button 
                                            variant="contained" 
                                            color="error" 
                                            sx={{ height: '53.13px', fontSize: { xs: '0.7rem' }, marginLeft: 2 }}
                                            onClick={handleRemoveImage}
                                        >
                                            Remover foto
                                        </Button>
                                        </div>
                                    ) : (
                                        <Button 
                                        variant="contained" 
                                        component="label" 
                                        color="info" 
                                        sx={{ height: '53.13px', fontSize: { xs: '0.7rem' } }}
                                        disabled={isDisabled}
                                        >
                                        Adicionar foto
                                        <input type="file" hidden onChange={handleChangeImage}/>
                                        </Button>
                                    )}
                                </Grid> */}

                                <Grid item lg={8} sm={6} xs={12} >
                                    <JumboTextField
                                        fullWidth
                                        name="nome_social"
                                        label="Nome Social"
                                        value={  updateInputValue(user, values, 'nome_social')}
                                        sx={{ background: '#ffffff'}}
                                       /*  disabled={isDisabled} */
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={4} sm={6} xs={12} >
                                    <FormControl fullWidth>
                                        <InputLabel shrink id="genero">Gênero</InputLabel>
                                        <JumboSelectField
                                            labelId="genero"
                                            id="genero"
                                            name="genero"
                                            value={updateInputValue(user, values, 'genero')}
                                            label="Gênero"
                                            sx={{ background: '#ffffff'}}
                                            /* disabled={isDisabled} */
                                            notched
                                        >
                                            <MenuItem value={'masculino'}>Masculino</MenuItem>
                                            <MenuItem value={'feminino'}>Feminino</MenuItem>
                                            <MenuItem value={'outro'}>Outro</MenuItem>
                                        
                                        </JumboSelectField>
                                    </FormControl>
                                </Grid> 
                                <Grid item lg={3} xs={12}>
                                    <InputWithouDatePicker
                                        fullWidth
                                        name="data_nascimento"
                                        type="date"
                                        label="Data de Nascimento"
                                        value={updateInputValue(user, values, 'data_nascimento')}
                                        sx={{ background: '#ffffff',}}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        /* disabled={isDisabled} */
                                    />
                                </Grid>
                                
                                <Grid item lg={5} xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink id="estado_civil">Estado Civil</InputLabel>
                                        <JumboSelectField
                                            labelId="estado_civil"
                                            id="estado_civil"
                                            name="estado_civil"
                                            value={updateInputValue(user, values, 'estado_civil')}
                                            label="Estado Civil"
                                            sx={{ background: '#ffffff'}}
                                            /* disabled={isDisabled} */
                                            notched
                                        >
                                            <MenuItem value={'casado'}>Casado(a)</MenuItem>
                                            <MenuItem value={'solteiro'}>Solteiro(a)</MenuItem>
                                            <MenuItem value={'divorciado'}>Divorciado(a)</MenuItem>
                                            <MenuItem value={'uniao_estavel'}>União Estável</MenuItem>
                                            <MenuItem value={'viuvo'}>Viúvo(a)</MenuItem>
                                            <MenuItem value={'outro'}>Outro</MenuItem>
                                        
                                        </JumboSelectField>
                                    </FormControl>
                                </Grid>
                               
                                <Grid item lg={2} xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink id="tem_filhos">Tem filhos?</InputLabel>
                                        <JumboSelectField
                                            labelId="tem_filhos"
                                            id="tem_filhos"
                                            name="tem_filhos"
                                            value={updateInputValue(user, values, 'tem_filhos')}
                                            label="Tem filhos?"
                                            sx={{ background: '#ffffff'}}
                                            /* disabled={isDisabled} */
                                            notched
                                        >
                                            <MenuItem value={'semFilhos'}>Não</MenuItem>
                                            <MenuItem value={'comFilhos'}>Sim</MenuItem>
                                        
                                        </JumboSelectField>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={2} xs={6} >
                                    <JumboTextField
                                        fullWidth
                                        name="qtde_filhos"
                                        label="Quantos?"
                                        value={
                                            values.tem_filhos == 'comFilhos' 
                                            ? updateInputValue(user, values, 'qtde_filhos') 
                                            : values.qtde_filhos = "0"}
                                        /* disabled={
                                            user &&(
                                            user.tem_filhos == values.tem_filhos)
                                            ? ['semFilhos', ''].includes(user.tem_filhos) 
                                            : ['semFilhos', ''].includes(values.tem_filhos) 
                                            isDisabled
                                        } */
                                        sx={{ background: '#ffffff'}}
                                        
                                    />
                                </Grid>  
                                
                                <Grid item lg={3} xs={6}>
                                    <JumboTextField
                                        fullWidth
                                        name="nacionalidade"
                                        label="Nacionalidade"
                                        value={updateInputValue(user, values, 'nacionalidade')}
                                        sx={{ background: '#ffffff'}}
                                       /*  disabled={isDisabled} */
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={3} xs={6}>
                                    <InputMask
                                        mask="999.999.999-99"
                                        value={updateInputValue(user, values, 'cpf')}
                                        onChange={handleChange}
                                       /*  disabled={isDisabled} */
                                        disableUnderline={isDisabled}
                                    >
                                        {(inputProps) => 
                                            <JumboTextField
                                                {...inputProps}
                                                id='cpf'
                                                fullWidth
                                                name="cpf"
                                                label="CPF"
                                                type="text"
                                                sx={{ background: '#ffffff'}}
                                               /*  disabled={inputProps.disableUnderline} */
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                    </InputMask>
                                </Grid>
                                <Grid item lg={3} xs={6}>
                                
                                    <JumboTextField
                                        fullWidth
                                        name="rg"
                                        label="RG"
                                        value={updateInputValue(user, values, 'rg')}
                                        sx={{ background: '#ffffff'}}
                                      /*   disabled={isDisabled} */
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                            
                                </Grid>
                                <Grid item lg={3} xs={6} >
                                    <JumboTextField
                                        fullWidth
                                        name="obs_gerais"
                                        label="Outros"
                                        value={updateInputValue(user, values, 'obs_gerais')}
                                        sx={{ background: '#ffffff'}}
                                      /*   disabled={isDisabled} */
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                               
                               
                                <Grid item lg={3} xs={12} >
                                    {
                                        verifyMask
                                        ?
                                            <InputMask
                                                mask={ verifyMask ? "+999(99)99999-9999" : undefined}
                                                maskPlaceholder={null}
                                                error={touched.celular ? errors.celular : undefined}
                                                touched={touched.celular}
                                                value={updateInputValue(user, values, 'celular')}
                                                onChange={handleChange}
                                                /* disabled={isDisabled} */
                                                /* disabledUnderline={isDisabled} */
                                            >
                                                {(inputProps) =>
                                                    <JumboTextField
                                                        {...inputProps}
                                                        fullWidth
                                                        name="celular"
                                                        label="Celular (DDI, DDD e número)"
                                                        sx={{ background: '#ffffff'}}
                                                        /* disabled={inputProps.disabledUnderline} */
                                                        InputLabelProps={{ shrink: true }}
                                                        inputRef={celularInputRef}
                                                    /> 
                                                }
                                            </InputMask>
                                        :
                                            <JumboTextField
                                                fullWidth
                                                name="celular"
                                                label="Celular (DDI, DDD e número)"
                                                sx={{ background: '#ffffff'}}
                                                InputLabelProps={{ shrink: true }}
                                                value={updateInputValue(user, values, 'celular').replace(/[^\d]/g, '')}
                                                onChange={handleChange}
                                                onBlur={() => setFieldTouched('celular', true)}
                                                inputRef={celularInputRef}
                                            /> 
                                    } 
                                        
                                    

                                </Grid>
                                <Grid item lg={3} xs={12} >
                                    {
                                        verifyMask
                                        ?
                                            <InputMask              
                                                mask={"+999(99)99999-9999"}
                                                maskPlaceholder={null}
                                                value={updateInputValue(user, values, 'celular_confirmacao')}
                                                onChange={handleChange}
                                                onPaste={(e) => e.preventDefault()}
                                                error={touched.celular_confirmacao ? errors.celular_confirmacao : undefined}
                                                touched={touched.celular_confirmacao}
                                                // onBlur={() => setTouched({'celular_confirmacao': true})}
                                                onBlur={() => setFieldTouched('celular_confirmacao', true)}
                                            /*  disabled={isDisabled} */
                                                /* disabledUnderline={isDisabled} */
                                            >
                                                {(inputProps) =>
                                                    <JumboTextField
                                                        {...inputProps}
                                                        fullWidth
                                                        name="celular_confirmacao"
                                                        label="Confirmar celular (DDI, DDD e número)"
                                                        sx={{ background: '#ffffff'}}
                                                        /* disabled={inputProps.disabledUnderline} */
                                                        InputLabelProps={{ shrink: true }}
                                                        
                                                    /> 
                                                }
                                            </InputMask>
                                        :

                                        <JumboTextField
    
                                            fullWidth
                                            name="celular_confirmacao"
                                            label="Confirmar celular (DDI, DDD e número)"
                                            sx={{ background: '#ffffff'}}
                                            InputLabelProps={{ shrink: true }}
                                            value={updateInputValue(user, values, 'celular_confirmacao').replace(/[^\d]/g, '')}
                                            onChange={handleChange}
                                            onBlur={() => setFieldTouched('celular_confirmacao', true)}
                                        /> 
                                    }
                                    
                                </Grid>
                                <Grid item lg={3} xs={12} >
                                    {
                                        verifyMaskTell 
                                        ?
                                            <InputMask
                                                mask="+999(99)9999-9999"
                                                value={updateInputValue(user, values, 'telefone')}
                                                onChange={handleChange}
                                            /*  disabled={isDisabled} */
                                                /* disabledUnderline={isDisabled} */
                                            >
                                                {(inputProps) =>
                                                    <JumboTextField
                                                        {...inputProps}
                                                        fullWidth
                                                        name="telefone"
                                                        label="Telefone residencial (DDI, DDD e número)"
                                                        sx={{ background: '#ffffff'}}
                                                    /*  disabled={inputProps.disabledUnderline} */
                                                        InputLabelProps={{ shrink: true }}
                                                        inputRef={tellInputRef}
                                                    />
                                                }
                                            </InputMask>
                                        :
                                            <JumboTextField
                                                fullWidth
                                                name="telefone"
                                                label="Telefone residencial (DDI, DDD e número)"
                                                sx={{ background: '#ffffff'}}
                                                InputLabelProps={{ shrink: true }}
                                                value={updateInputValue(user, values, 'telefone').replace(/[^\d]/g, '')}
                                                onChange={handleChange}
                                                inputRef={tellInputRef}
                                            />

                                    }
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <JumboTextField
                                        fullWidth
                                        name="email"
                                        label="Email"
                                        type="email"
                                        value={updateInputValue(user, values, 'email')}
                                        sx={{ background: '#ffffff'}}
                                        error={touched.email ? errors.email : undefined}
                                        touched={+touched.email}
                                       /*  disabled={isDisabled} */
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} xs={12} >
                                    <JumboTextField
                                        fullWidth
                                        name="email_confirmacao"
                                        label="Confirmar email"
                                        type="email"
                                        value={updateInputValue(user, values, 'email_confirmacao')}
                                        sx={{ background: '#ffffff'}}
                                        onPaste={e => e.preventDefault()}
                                        onChange={handleChange}
                                        error={touched.email_confirmacao ? errors.email_confirmacao : undefined}
                                        touched={+touched.email_confirmacao}
                                       /*  disabled={isDisabled} */
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={4} xs={6} >
                                    
                                    <FormControl fullWidth>
                                        <Select 
                                            options={COUNTRIES} 
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            onChange={(pais) =>
                                                setFieldValue('pais', pais.value)
                                            }
                                            value = {
                                                COUNTRIES.filter(option => 
                                                    option.value === values.pais)
                                            }
                                            placeholder="País"
                                            /* isDisabled={isDisabled} */
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item lg={8} xs={6}>
                                    <Typography variant="h6" component="h6">
                                        Escolha no campo de seleção ao lado o País em que você mora atualmente
                                    </Typography>
                                </Grid>
                                {/* <div style={{ marginTop: 20 }}>
                                    {JSON.stringify(values.pais)}
                                </div> */}

                                {values.pais === 'BR' 
                                    ? (
                                        <>
                                            <Grid item lg={2} xs={6} >
                                                <InputMask
                                                    mask="99999-999"
                                                    value={updateInputValue(user, values, 'CEP')}
                                                    onChange={handleChange}
                                                    onBlur={(e) => checkCep(e, setFieldValue)}
                                                   /*  disabled={isDisabled} */
                                                    /* disabledUnderline={isDisabled} */
                                                    >
                                                    {(inputProps) => 
                                                        <JumboTextField
                                                            {...inputProps}
                                                            fullWidth
                                                            name="CEP"
                                                            label="CEP"
                                                            sx={{ background: '#ffffff'}}
                                                           /*  disabled={inputProps.disabledUnderline} */
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    }
                                                </InputMask>
                                            </Grid>
                                            <Grid item lg={1} xs={1}>
                                                {isLoading && <CircularProgress style={{ alignSelf: 'center' }}/>}
                                            </Grid>
                                            <Grid item lg={7} xs={12}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="endereco"
                                                    label="Endereço"
                                                    value={updateInputValue(user, values, 'endereco')}
                                                    sx={{ background: '#ffffff'}}
                                                   /*  disabled={isDisabled} */
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={2} xs={4}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="numero"
                                                    label="Número"
                                                    value={updateInputValue(user, values, 'numero')}
                                                    sx={{ background: '#ffffff'}}
                                                   /*  disabled={isDisabled} */
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={3} xs={8}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="complemento"
                                                    label="Complemento"
                                                    value={updateInputValue(user, values, 'complemento')}
                                                    sx={{ background: '#ffffff'}}
                                                    /* disabled={isDisabled} */
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={3} xs={6}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="bairro"
                                                    label="Bairro"
                                                    value={updateInputValue(user, values, 'bairro')}
                                                    sx={{ background: '#ffffff'}}
                                                   /*  disabled={isDisabled} */
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={4} xs={6}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="cidade"
                                                    label="Cidade"
                                                    value={updateInputValue(user, values, 'cidade')}
                                                    sx={{ background: '#ffffff'}}
                                                    /* disabled={isDisabled} */
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={2} xs={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel shrink id="uf">UF</InputLabel>
                                                    <JumboSelectField
                                                        labelId="uf"
                                                        id="uf"
                                                        name="uf"
                                                        value={updateInputValue(user, values, 'uf')}
                                                        label="Uf"
                                                        sx={{ background: '#ffffff'}}
                                                        /* disabled={isDisabled} */
                                                        notched
                                                    >
                                                        <MenuItem value={'AC'}>Acre</MenuItem>
                                                        <MenuItem value={'AL'}>Alagoas</MenuItem>
                                                        <MenuItem value={'AP'}>Amapá</MenuItem>
                                                        <MenuItem value={'AM'}>Amazonas</MenuItem>
                                                        <MenuItem value={'BA'}>Bahia</MenuItem>
                                                        <MenuItem value={'CE'}>Ceará</MenuItem>
                                                        <MenuItem value={'DF'}>Distrito Federal</MenuItem>
                                                        <MenuItem value={'ES'}>Espírito Santo</MenuItem>
                                                        <MenuItem value={'GO'}>Goiás</MenuItem>
                                                        <MenuItem value={'MA'}>Maranhão</MenuItem>
                                                        <MenuItem value={'MT'}>Mato Grosso</MenuItem>
                                                        <MenuItem value={'MS'}>Mato Grosso do Sul</MenuItem>
                                                        <MenuItem value={'MG'}>Minas Gerais</MenuItem>
                                                        <MenuItem value={'PA'}>Pará</MenuItem>
                                                        <MenuItem value={'PB'}>Paraíba</MenuItem>
                                                        <MenuItem value={'PR'}>Paraná</MenuItem>
                                                        <MenuItem value={'PE'}>Pernambuco</MenuItem>
                                                        <MenuItem value={'PI'}>Piauí</MenuItem>
                                                        <MenuItem value={'RJ'}>Rio de Janeiro</MenuItem>
                                                        <MenuItem value={'RN'}>Rio Grande do Norte</MenuItem>
                                                        <MenuItem value={'RS'}>Rio Grande do Sul</MenuItem>
                                                        <MenuItem value={'RO'}>Rondônia</MenuItem>
                                                        <MenuItem value={'RR'}>Roraima</MenuItem>
                                                        <MenuItem value={'SC'}>Santa Catarina</MenuItem>
                                                        <MenuItem value={'SP'}>São Paulo</MenuItem>
                                                        <MenuItem value={'SE'}>Sergipe</MenuItem>
                                                        <MenuItem value={'TO'}>Tocantins</MenuItem>
                                                    
                                                    </JumboSelectField>
                                                </FormControl>
                                            </Grid>
                                            </>
                                    ) 
                                    : 
                                    (
                                        <> 
                                            <Grid item xs={12} >
                                                <JumboTextField
                                                    fullWidth
                                                    name="endereco"
                                                    label="Endereço"
                                                    value={updateInputValue(user, values, 'endereco')}
                                                    sx={{ background: '#ffffff'}}
                                                    /* disabled={isDisabled} */
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid> 
                                            <Grid item lg={4} xs={6} >  
                                                <JumboTextField
                                                    fullWidth
                                                    name="codigo_postal"
                                                    label="Código postal"
                                                    sx={{ background: '#ffffff'}}
                                                    value={updateInputValue(user, values, 'codigo_postal')}
                                                    /* disabled={isDisabled} */
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            
                                            <Grid item lg={4} xs={6}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="estado"
                                                    label="Estado"
                                                    value={updateInputValue(user, values, 'estado')}
                                                    sx={{ background: '#ffffff'}}
                                                    /* disabled={isDisabled} */
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                
                                            </Grid>
                                        </>
                                    )
                                }

                            </Grid>

                            <Grid container spacing={3} alignContent={'center'} 
                                sx={
                                        {   mt: '0.8rem',
                                            background: '#EFF0F2',
                                            marginLeft: 0,
                                            padding: '0 24px 24px 0'
                                        }
                                    }
                            >
                                <Grid item lg={4} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink id="ocupacao_natureza">Natureza da ocupação</InputLabel>
                                        <JumboSelectField
                                            labelId="ocupacao_natureza"
                                            id="ocupacao_natureza"
                                            name="ocupacao_natureza"
                                            value={updateInputValue(user, values, 'ocupacao_natureza')}
                                            label="Natureza da ocupação"
                                            sx={{ background: '#ffffff'}}
                                            /* disabled={isDisabled} */
                                            notched
                                        >
                                            <MenuItem value={'aposentado'}>Aposentado</MenuItem>
                                            <MenuItem value={'autônomo'}>Autônomo</MenuItem>
                                            <MenuItem value={'do_lar'}>"Do lar"</MenuItem>
                                            <MenuItem value={'empresario'}>Empresário</MenuItem>
                                            <MenuItem value={'funcionario_empresa_privada'}>Funcionário de empresa privada</MenuItem>
                                            <MenuItem value={'funcionario_publico'}>Funcionário público</MenuItem>
                                            <MenuItem value={'micro_empresario'}>Micro-empresário</MenuItem>
                                            <MenuItem value={'militar'}>Militar</MenuItem>
                                            <MenuItem value={'outro'}>Outro</MenuItem>
                                        
                                        </JumboSelectField>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={8} xs={12}>
                                    <JumboTextField
                                        fullWidth
                                        name="ocupacao"
                                        label="Ocupação"
                                        multiline
                                        rows={4}
                                        value={updateInputValue(user, values, 'ocupacao')}
                                        sx={{ background: '#ffffff'}}
                                        /* disabled={isDisabled} */
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid item lg={4} xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink id="escolaridade">Nível de escolaridade</InputLabel>
                                        <JumboSelectField
                                            labelId="escolaridade"
                                            id="escolaridade"
                                            name="escolaridade"
                                            value={updateInputValue(user, values, 'escolaridade')}
                                            label="Nível de escolaridade"
                                            sx={{ background: '#ffffff'}}
                                            /* disabled={isDisabled} */
                                            notched
                                        >
                                            <MenuItem value={'nao_estudei'}>Não estudei</MenuItem>
                                            <MenuItem value={'primeiro_grau'}>Primeiro grau</MenuItem>
                                            <MenuItem value={'segundo_grau'}>Segundo grau</MenuItem>
                                            <MenuItem value={'superior'}>Superior</MenuItem>
                                            <MenuItem value={'pos_graduado'}>Pós graduado</MenuItem>
                                            <MenuItem value={'mestrado'}>Mestrado</MenuItem>
                                            <MenuItem value={'doutorado'}>Doutorado</MenuItem>
                                            <MenuItem value={'pos_doutorado'}>Pós doutorado</MenuItem>
                                        
                                        </JumboSelectField>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={4} xs={6}>
                                    <div style={{ position: 'relative' }}>
                                        <Select
                                            id="select-one"
                                            styles={customStyles}
                                            options={optionsPrincipalFormations}
                                            components={animatedComponents}
                                            onChange={(formacao_principal_selecionada_id) =>
                                                setFieldValue('formacao_principal_selecionada_id', formacao_principal_selecionada_id.value)
                                            }
                                            value = {
                                                optionsPrincipalFormations.filter(option => 
                                                    option.value === values.formacao_principal_selecionada_id)
                                            }
                                            placeholder="Selecione formação principal"

                                        />
                                    </div>
                                </Grid>
                                <Grid item lg={4} xs={12}>
                                    <div style={{ position: 'relative' }}>
                                        <Select
                                            id="select-two"
                                            styles={customStyles}
                                            options={optionsJobs}
                                            components={animatedComponents}
                                            onChange={(profissao_selecionada_id) =>
                                                setFieldValue('profissao_selecionada_id', profissao_selecionada_id.value)
                                            }
                                            value={optionsJobs.filter(option => option.value === values.profissao_selecionada_id)}
                                            placeholder="Selecione profissão"
                                        />

                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <JumboTextField
                                        fullWidth
                                        name="habilidade_especifica"
                                        label="Alguma habilidade específica?"
                                        multiline
                                        rows={4}
                                        value={updateInputValue(user, values, 'habilidade_especifica')}
                                        sx={{ background: '#ffffff'}}
                                        /* disabled={isDisabled} */
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                            </Grid>
                            
                            <Grid container spacing={3} alignContent={'center'} 
                                sx={
                                        {   mt: '0.8rem',
                                            background: '#EFF0F2',
                                            marginLeft: 0,
                                            padding: '0 24px 24px 0',
                                            width: '100%'
                                        }
                                    }
                            >

                                {userLanguages.map((x, i) => (
                                    small.matches 
                                    ? 
                                    (
                                        <>
                                        <Grid item xs={6}>

                                            <Grid item xs={12} sx={{marginBottom: {xs: '0.5rem'}}}>
                                                <FormControl fullWidth>
                                                    <InputLabel shrink id="idioma">Idiomas</InputLabel>
                                                    <JumboSelectField
                                                        labelId="idiomas_id"
                                                        id="idiomas_id"
                                                        name="idiomas_id"
                                                        label="Idiomas"
                                                        value={x.idiomas_id}
                                                        onChange={e => handleInputChange(e, i)}
                                                        sx={{ background: '#ffffff'}}
                                                       /*  disabled={isDisabled} */
                                                        notched
                                                    >
                                                    <MenuItem value={1} style={{ display: userLanguages.some(item => item.idiomas_id == 1) ? 'none' : 'block' }}>Alemão</MenuItem>
                                                    <MenuItem value={2} style={{ display: userLanguages.some(item => item.idiomas_id == 2) ? 'none' : 'block' }}>Espanhol</MenuItem>
                                                    <MenuItem value={3} style={{ display: userLanguages.some(item => item.idiomas_id == 3) ? 'none' : 'block' }}>Francês</MenuItem>
                                                    <MenuItem value={4} style={{ display: userLanguages.some(item => item.idiomas_id == 4) ? 'none' : 'block' }}>Inglês</MenuItem>
                                                    <MenuItem value={5} style={{ display: userLanguages.some(item => item.idiomas_id == 5) ? 'none' : 'block' }}>Italiano</MenuItem>
                                                    <MenuItem value={6} style={{ display: userLanguages.some(item => item.idiomas_id == 6) ? 'none' : 'block' }}>Outro</MenuItem>
                                                    </JumboSelectField>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel shrink id="nivel_idioma">Nível</InputLabel>
                                                    <JumboSelectField
                                                        labelId="nivel_idioma"
                                                        id="nivel_idioma"
                                                        name="nivel_idioma"
                                                        label="Nível"
                                                        value={x.nivel_idioma}
                                                        onChange={e => handleInputChange(e, i)}
                                                        sx={{ background: '#ffffff'}}
                                                       /*  disabled={isDisabled} */
                                                        notched
                                                    >
                                                        <MenuItem value={'basico'}>Básico</MenuItem>
                                                        <MenuItem value={'intermediario'}>Intermediário</MenuItem>
                                                        <MenuItem value={'avancado'}>Avançado</MenuItem>
                                                        <MenuItem value={'fluente'}>Fluente</MenuItem>
                                                    
                                                    </JumboSelectField>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={6} sx={{display: 'flex', alignItems: 'center'}}>
                                            
                                            <Grid item xs={12}>
                                                {
                                                x.idiomas_id == '' || x.nivel_idioma == '' 
                                                ? 
                                                    null 
                                                : 
                                                userLanguages.length !== 1 &&
                                                    
                                                    <Button
                                                        color="error"
                                                        variant="contained"
                                                        size="large"
                                                        component="label"
                                                        onClick={() => handleRemoveClick(i)}
                                                        sx={{height:'53.13px', fontSize: {xs: '0.7rem'}}}
                                                        /* disabled={isDisabled} */
                                                    >
                                                        <DeleteIcon />
                                                    </Button>
                                                
                                                }
                                            {userLanguages.length - 1 === i && 
                                                <Button
                                                    sx={{height:'53.13px', fontSize: {xs: '0.6rem'}, padding: {lg: '8px 22px', xs: '8px 5px'}}}
                                                    color="info"
                                                    variant="contained"
                                                    size="large" 
                                                    component="label"                 /* disabled= {x.idiomas_id == '' || x.nivel_idioma == '' || isDisabled}  */
                                                    onClick={handleAddClick}
                                                >
                                                    Adicionar outro idioma
                                                </Button>
                                            }
                                            </Grid>

                                        </Grid>
                                        </>
                                    )
                                    : 
                                    (
                                        <>
                                    
                                        <Grid item xs={4} sx={{marginBottom: {xs: '0.5rem'}}}>
                                            <FormControl fullWidth>
                                                <InputLabel shrink id="idioma">Idiomas</InputLabel>
                                                <JumboSelectField
                                                    labelId="idiomas_id"
                                                    id="idiomas_id"
                                                    name="idiomas_id"
                                                    label="Idiomas"
                                                    value={x.idiomas_id}
                                                    onChange={e => handleInputChange(e, i)}
                                                    sx={{ background: '#ffffff'}}
                                                   /*  disabled={isDisabled} */
                                                    notched
                                                >
                                                    <MenuItem value={1} style={{ display: userLanguages.some(item => item.idiomas_id == 1) ? 'none' : 'block' }}>Alemão</MenuItem>
                                                    <MenuItem value={2} style={{ display: userLanguages.some(item => item.idiomas_id == 2) ? 'none' : 'block' }}>Espanhol</MenuItem>
                                                    <MenuItem value={3} style={{ display: userLanguages.some(item => item.idiomas_id == 3) ? 'none' : 'block' }}>Francês</MenuItem>
                                                    <MenuItem value={4} style={{ display: userLanguages.some(item => item.idiomas_id == 4) ? 'none' : 'block' }}>Inglês</MenuItem>
                                                    <MenuItem value={5} style={{ display: userLanguages.some(item => item.idiomas_id == 5) ? 'none' : 'block' }}>Italiano</MenuItem>
                                                    <MenuItem value={6} style={{ display: userLanguages.some(item => item.idiomas_id == 6) ? 'none' : 'block' }}>Outro</MenuItem>
                                                </JumboSelectField>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <InputLabel shrink id="nivel_idioma">Nível</InputLabel>
                                                <JumboSelectField
                                                    labelId="nivel_idioma"
                                                    id="nivel_idioma"
                                                    name="nivel_idioma"
                                                    label="Nível"
                                                    value={x.nivel_idioma}
                                                    onChange={e => handleInputChange(e, i)}
                                                    sx={{ background: '#ffffff'}}
                                                   /*  disabled={isDisabled} */
                                                    notched
                                                >
                                                    <MenuItem value={'basico'}>Básico</MenuItem>
                                                    <MenuItem value={'intermediario'}>Intermediário</MenuItem>
                                                    <MenuItem value={'avancado'}>Avançado</MenuItem>
                                                    <MenuItem value={'fluente'}>Fluente</MenuItem>
                                                
                                                </JumboSelectField>
                                            </FormControl>
                                        </Grid>
                                
                                        <Grid item xs={4}>
                                            {x.idiomas_id == '' || x.nivel_idioma == '' ? null
                                            :
                                            userLanguages.length !== 1 && 
                                            
                                                <Button
                                                    color="error"
                                                    variant="contained"
                                                    size="large"
                                                    component="label"
                                                    onClick={() => handleRemoveClick(i)}
                                                    sx={{height:'60px', 
                                                        borderRadius:'50%', 
                                                        marginRight: {xs: '0.5rem'},
                                                        fontSize: 
                                                        {
                                                            xs: '0.7rem'
                                                        }
                                                    }}
                                                    /* disabled={isDisabled} */
                                                >
                                                    <DeleteIcon />
                                                </Button>

                                            }
                                        {userLanguages.length - 1 === i && 
                                            <Button
                                                sx={{height:'53.13px',fontSize: {xs: '0.7rem'}, padding: {lg: '8px 22px', xs: '8px 5px'}}}
                                                color="info"
                                                variant="contained"
                                                size="large" 
                                                component="label"
                                               /*  disabled= {x.idiomas_id == '' || x.nivel_idioma == ''  || isDisabled}  */
                                                onClick={handleAddClick}
                                                
                                            >
                                                Adicionar outro idioma
                                            </Button>
                                        }
                                        </Grid>

                                        </> 
                                    )
                                    
                                ))}
                            </Grid>

                            <Grid container spacing={3} alignContent={'center'} 
                                sx={
                                        {   mt: '0.8rem',
                                            background: '#EFF0F2',
                                            marginLeft: 0,
                                            padding: '0 24px 24px 0'
                                        }
                                    }
                            >
                                <Grid item xs={12}>
                                    <Accordion 
                                        expanded={!disableInputValue(user, values, 'quais_cuidados')}
                                        elevation={0}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                                <Stack
                                                    sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                    direction={{ xs: 'column', lg: 'row' }}
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography
                                                        sx={{fontSize: '1.05rem'}}
                                                    >
                                                        Algum cuidado relacionado à sua saúde que gostaria que soubéssemos?
                                                    </Typography>

                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        <Typography>Não</Typography>
                                                            <FormControlLabel
                                                                control={
                                                                <Switch 
                                                                    checked={updateInputValue(user, values, 'quais_cuidados')}
                                                                    onChange={handleChange}
                                                                    name="quais_cuidados" 
                                                                    /* disabled={isDisabled} */
                                                                />
                                                                }
                                                                labelPlacement="start"
                                                            />
                                                        <Typography>Sim</Typography>
                                                    </Stack>

                                                </Stack>
                                        </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid item xs={12}>
                                                    <JumboTextField
                                                        fullWidth
                                                        name="obs_saude"
                                                        label="Quais?"
                                                        multiline
                                                        rows={4}
                                                        value={updateInputValue(user, values, 'obs_saude')}
                                                        /* disabled={isDisabled ?? disableInputValue(user, values, 'quais_cuidados')} */
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                            </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <Typography variant='h5'>
                                        Pessoa de contato para casos de emergência
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} lg={6}>
                                    <JumboTextField
                                        fullWidth
                                        name="nome_emergencia"
                                        label="Nome"
                                        sx={{ background: '#ffffff'}}
                                        value={updateInputValue(user, values, 'nome_emergencia')}
                                       /*  disabled={isDisabled} */
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={3}>
                                    <InputMask
                                        mask="+999(99)99999-9999"
                                        value={updateInputValue(user, values, 'celular_emergencia')}
                                        onChange={handleChange}
                                        /* disabled={isDisabled} */
                                        /* disabledUnderline={isDisabled} */
                                    >
                                        {(inputProps) => 
                                            <JumboTextField
                                                {...inputProps}
                                                fullWidth
                                                name="celular_emergencia"
                                                label="Celular (DDI, DDD e número)"
                                               /*  disabled={inputProps.disabledUnderline} */
                                                sx={{ background: '#ffffff'}}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                        
                                    </InputMask>
                                </Grid>
                                <Grid item lg={3} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink id="tipo_sanguineo">Tipo sanguíneo</InputLabel>
                                        <JumboSelectField
                                            labelId="tipo_sanguineo"
                                            id="tipo_sanguineo"
                                            name="tipo_sanguineo"
                                            label="Tipo sanguíneo"
                                            value={updateInputValue(user, values, 'tipo_sanguineo')}
                                            sx={{ background: '#ffffff'}}
                                           /*  disabled={isDisabled} */
                                            notched
                                        >
                                             <MenuItem value={''}>Sem seleção</MenuItem>
                                            <MenuItem value={'A+'}>A+</MenuItem>
                                            <MenuItem value={'B+'}>B+</MenuItem>
                                            <MenuItem value={'AB+'}>AB+</MenuItem>
                                            <MenuItem value={'O+'}>O+</MenuItem>
                                            <MenuItem value={'A-'}>A-</MenuItem>
                                            <MenuItem value={'B-'}>B-</MenuItem>
                                            <MenuItem value={'AB-'}>AB-</MenuItem>
                                            <MenuItem value={'O-'}>O-</MenuItem>
                                        
                                        </JumboSelectField>
                                    </FormControl>
                                </Grid>

                            </Grid>

                            <Grid container spacing={3} alignContent={'center'} 
                                sx={
                                        {   mt: '0.8rem',
                                            background: '#EFF0F2',
                                            marginLeft: 0,
                                            padding: '0 24px 24px 0'
                                        }
                                    }
                            >
                                <Grid item lg={6} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink id="religiao">Religião de origem</InputLabel>
                                        <JumboSelectField
                                            labelId="religiao"
                                            id="religiao"
                                            name="religiao"
                                            label="Religião de origem"
                                            value={updateInputValue(user, values, 'religiao')}
                                            sx={{ background: '#ffffff'}}
                                            /* disabled={isDisabled} */
                                            notched
                                        >
                                            <MenuItem value={'budismo'}>Budismo</MenuItem>
                                            <MenuItem value={'catolica'}>Católica</MenuItem>
                                            <MenuItem value={'espiritismo'}>Espiritismo</MenuItem>
                                            <MenuItem value={'matriz_africana'}>Matriz africana</MenuItem>
                                            <MenuItem value={'protestante'}>Protestante</MenuItem>
                                            <MenuItem value={'umbanda'}>Umbanda</MenuItem>
                                            <MenuItem value={'outra'}>Outra</MenuItem>
                                        
                                        </JumboSelectField>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <Accordion
                                        expanded={!disableInputValue(user, values, 'conhece_espiristimo')}
                                        elevation={0}
                                    >
                                        <AccordionSummary>
                                            <Stack
                                                sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                direction={{ xs: 'column', lg: 'row' }}
                                                justifyContent="space-between"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Typography
                                                    sx={{fontSize: '1.05rem'}}
                                                >
                                                   Relacionamento com o Espiritismo em outros Centros Espíritas?
                                                </Typography>
                                                
                                                <Stack 
                                                    direction="row" 
                                                    spacing={1} 
                                                    alignItems="center"
                                                >
                                                    <Typography>Não</Typography>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={updateInputValue(user, values, 'conhece_espiristimo')}
                                                                    onChange={handleChange}
                                                                    name="conhece_espiristimo" 
                                                                    /* disabled={isDisabled} */
                                                                />
                                                            }
                                                            labelPlacement="start"
                                                        />
                                                    <Typography>Sim</Typography>
                                                </Stack>
                                            </Stack>

                                        </AccordionSummary>                        
                                        <AccordionDetails>
                                            <Grid item xs={12}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="cursos_fora_feesp"
                                                    label="Descreva basicamente sua experiência no Espiritismo fora da FEESP: Centros Espíritas que frequenta ou frequentou, tipo de trabalho e cursos realizados e em que época.
                                                    "
                                                    multiline
                                                    rows={4}
                                                    value={updateInputValue(user, values, 'cursos_fora_feesp')}
                                                    /* disabled={isDisabled ?? disableInputValue(user, values, 'conhece_espiristimo')} */
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} alignContent={'center'} 
                                sx={
                                        {   mt: '0.8rem',
                                            background: '#EFF0F2',
                                            marginLeft: 0,
                                            padding: '0 24px 24px 0'
                                        }
                                    }
                            >
                                <Grid item xs={12}>
                                    <Accordion
                                        expanded={!disableInputValue(user, values, 'trabalhos_voluntarios')}
                                        elevation={0}
                                    >
                                        <AccordionSummary>
                                            <Stack
                                                sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                direction={{ xs: 'column', lg: 'row' }}
                                                justifyContent="space-between"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Typography
                                                    sx={{fontSize: '1.05rem'}}
                                                >
                                                    Realiza ou realizou trabalho voluntário fora da FEESP?
                                                </Typography>

                                                <Stack 
                                                    direction="row" 
                                                    spacing={1} 
                                                    alignItems="center"
                                                >
                                                    <Typography>Não</Typography>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch 
                                                                    checked={updateInputValue(user, values, 'trabalhos_voluntarios')}
                                                                    onChange={handleChange}
                                                                    name="trabalhos_voluntarios" 
                                                                    /* disabled={isDisabled} */
                                                                />
                                                            }
                                                            labelPlacement="start"
                                                        />
                                                    <Typography>Sim</Typography>
                                                </Stack>
                                            </Stack>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Grid item xs={12}>
                                            <JumboTextField
                                                fullWidth
                                                name="obs_voluntariado"
                                                label="Descreva basicamente os três últimos informando também o nome da entidade ou o local de atuação;
                                                "
                                                multiline
                                                rows={4}
                                                value={updateInputValue(user, values, 'obs_voluntariado')}
                                                /* disabled={isDisabled ?? disableInputValue(user, values, 'trabalhos_voluntarios')} */
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{textAlign: 'center', mt: '1rem'}}> 
                                <LoadingButton
                                    color="success"
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                    loading={isSubmitting}
                                   /*  disabled={isDisabled} */
                                >
                                    Cadastrar
                                </LoadingButton>

                            </Grid>
                        </Grid>

                        {  isSubmitting /* && Object.keys(errors).length !== 0 */
                        ? errorsHandler(errors) 
                        : null
                        }
    
                        {verifyCellMask(values)}
                        
                    </Form>
                    
                    )}
            </Formik>
        </Paper>
    </JumboContentLayout>
  )
}


export default RegisterUserFormPublic