import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import PageHeader from '../../../layouts/shared/headers/PageHeader/PageHeader';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { Grid, Paper, Stack, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { ApiService } from 'app/servicesTwo/ApiService';
import AreaItem from './AreaItem';
import { Link, useLocation } from 'react-router-dom';
import { PermissionContext } from 'app/contexts/PermissionContext';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

const validationSchema = yup.object({
    email: yup.string('Insira seu email').email('Insira um email válido'),
    senha: yup.string('Insira sua senha'),
    nome: yup.string('Insira seu nome').required('Preenchimento obrigatório'),
    cpf: yup.string(),
    rg: yup.string().max(11, 'Deve ter no máximo 11 digitos'),
    data_nascimento: yup
        .date()
        .max(new Date(), 'Não é possivel incluir uma data futura')
        .required('Preenchimento obrigatório'),
    celular: yup.string(),
});

const AreaList = () => {
    const { theme } = useJumboTheme();
    const location = useLocation();
    const Swal = useSwalWrapper();

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(
        () => ({
            sidebar: {
                sx: {
                    [theme.breakpoints.up('lg')]: {
                        position: 'sticky',
                        zIndex: 5,
                        top: 96,
                        minHeight: 'auto',
                    },
                    [theme.breakpoints.down('lg')]: {
                        display: 'none',
                    },
                },
            },
            wrapper: {
                sx: {
                    alignItems: 'flex-start',
                },
            },
        }),
        [theme]
    );

    const [areas, setAreas] = useState(null);
    const { hasPermission } = useContext(PermissionContext);

    const getAreas = useCallback(async () => {
        try {
            ApiService.get('/areas')
                .then((response) => {
                    setAreas(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        getAreas();
    }, []);

    useEffect(() => {
        Swal.close();
    }, [location.pathname, Swal]);

    useEffect(() => {
        console.log('atualizou');
    }, [setAreas, areas]);

    const areasWithVolunteers = areas?.map((area) => {
        return {
            ...area,
            numeroVoluntariosInteresse: area.Interesse_Voluntario?.length,
            numeroVoluntarios: area.Voluntario?.length,
        };
    });

    console.log('Areas', areasWithVolunteers);

    return (
        <JumboContentLayout
            header={<PageHeader title={'Áreas'} />}
            layoutOptions={layoutOptions}
        >
            {lg && (
                <Stack
                    spacing={2}
                    direction={'row'}
                    sx={{ mb: 3, mt: -2 }}
                ></Stack>
            )}
            <Paper sx={{ p: '40px' }}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Link
                        to='/app/nova-area'
                        style={{
                            textDecoration: 'none',
                            pointerEvents: !hasPermission('Pessoas', 'criar')
                                ? 'none'
                                : '',
                        }}
                        state={{ backUrl: location.pathname }}
                    >
                        <LoadingButton
                            color='success'
                            type='submit'
                            variant='contained'
                            size='large'
                            disabled={!hasPermission('Áreas', 'criar')}
                            sx={{
                                maxWidth: { md: '200px', mt: '1rem' },
                                marginBottom: '2rem',
                            }}
                        >
                            Nova Área
                        </LoadingButton>
                    </Link>
                </Grid>

                <React.Fragment>
                    {areasWithVolunteers &&
                        areasWithVolunteers.map((user, key) => (
                            <AreaItem
                                item={user}
                                setAreas={(data) => setAreas([...data])}
                                areas={areas}
                                key={key}
                            />
                        ))}
                </React.Fragment>
            </Paper>
        </JumboContentLayout>
    );
};

export default AreaList;
