import React, { useState, useCallback, useEffect } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, OutlinedInput, Paper, Select, Stack,Switch,TextField,Typography,useMediaQuery} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import { CheckBox } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams } from "react-router-dom";
import { updateInputValue, disableInputValue } from "../../../utils/appHelpers";
import CouncilHistoryItem from './CouncilHistoryItem';


const ModalCouncilHistory = ({ handleCloseCouncil, item }) => {
    const {theme} = useJumboTheme();
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const { id } = useParams()
   
    const [councilHistory, setCouncilHistory] = useState([]);
    const getUserId = item?.Associado?.User?.pessoa_id;
    const getUsername = item?.Associado?.User?.nome;
    
    const getHistory = useCallback(async () => {
       if(id) {
           try {
               ApiService.get(`/pessoa/${id}`)
               .then((response) => {
                   console.log('historico', response.data.user[0])
                   setCouncilHistory(response.data.user[0].Associado)
               })
               .catch((error) => {
                 if (error.response) {
                     console.log(error.response.data);
                     console.log(error.response.status);
                     console.log(error.response.headers);
                   } else if (error.request) {
                     console.log(error.request);
                   } else {
                     console.log('error', error.message);
                   }
               })
           } catch (err) {
             console.log(err);
           }
       } else {
        try {
            ApiService.get(`/pessoa/${getUserId}`)
            .then((response) => {
                console.log('historico', response.data.user[0])
                setCouncilHistory(response.data.user[0].Associado)
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
       }
      }, []);

      useEffect(() => {
        if(id || getUserId) getHistory();
      }, []);  

  return (
    <JumboContentLayout
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px', height: '500px'}}>
            <Box sx={{ height: '100%', overflowY: 'auto' }}>
                <Box display="flex" justifyContent="flex-end" alignItems="center" marginRight={'1rem'}>
                    <Button size="small" variant="contained" onClick={handleCloseCouncil}>
                        Fechar
                    </Button>
                </Box>
                <Typography variant={"h1"} style={{ textAlign: 'center', marginBottom: 30 }}>
                    Histórico de Conselheiro
                </Typography>
                <React.Fragment>
                    <Typography variant={"h4"} style={{ textAlign: 'left', marginBottom: 30 }}>
                        Histórico como Conselheiro: {getUsername || item.nome}
                    </Typography>
                        {councilHistory.length > 0 ?
                            councilHistory.map((data, key) => (
                                data.Conselho?.map((conselho) => (
                                    <CouncilHistoryItem item={conselho} handleCloseCouncil={handleCloseCouncil}/>
                                ))
                            ))
                    : 'Nenhum histórico encontrado'}
                </React.Fragment>
            </Box>
        </Paper>
    </JumboContentLayout>
  )
}

export default ModalCouncilHistory