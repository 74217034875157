/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { updateInputValue } from '../../../utils/appHelpers';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';
import styled from '@emotion/styled';
import Span from '@jumbo/shared/Span';
import { useDebouncedCallback } from 'beautiful-react-hooks';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80em',
  p: 4,
};

export const Item = styled(Span)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

export const CSVStyled = styled(Item)`
  padding: 4px 10px;
  background: #0092d0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 0.5rem;
  cursor: pointer;

  svg {
    color: white;
  }
`;

export const generateYearOptions = () => {
  const arr = [];

  const startYear = 1980;
  const endYear = new Date().getFullYear();

  for (let i = endYear + 2; i >= startYear; i--) {
    arr.push(
      <MenuItem key={i} value={i.toString()}>
        {i}
      </MenuItem>
    );
  }

  return arr;
};

const RoomForm = () => {
  const { theme } = useJumboTheme();
  const { showDialog, hideDialog } = useJumboDialog();

  const navigate = useNavigate();
  let { state, pathname } = useLocation();

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });

    //  Redireciona se mensagem de retorno for de sucesso
    /* if(!id) {
            if(variant == 'success'){
                setTimeout(function() {
                    navigate("/app/listar-turmas")

                }, 2000)
            }
        } */
  };

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const initialValues = {
    nome: '',
    local: '',
    andar: '',
  };

  const validationSchema = yup.object().shape(
    {
      nome: yup.string().required('Preenchimento obrigatório'),
    },
    []
  );

  const { id } = useParams();
 
  const [classes, setClasses] = useState(initialValues);
  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const { hasPermission } = useContext(PermissionContext);

  const canCreate = hasPermission('Salas', 'criar');
  const canEdit = hasPermission('Salas', 'editar');
  const isDisabled = id ? !canEdit : !canCreate;



  const getRoomById = useCallback(async () => {
    try {
      ApiService.get(`/salas/${id}`)
        .then((response) => {
          console.log(response.data);

          setClasses(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (id) getRoomById();
  }, []);

  useEffect(() => {
    Swal.close();
  }, [pathname]);

 
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (id) {
      await ApiService.put(`/salas/${id}`, { values })
        .then((response) => {
          toast('success', 'Sala atualizada com sucesso');
        })
        .catch((error) => {
          toast('error', error.response.data.error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } else {
      await ApiService.post('/salas', { values })
        .then((response) => {
          toast('success', 'Criado com sucesso');
          resetForm();
        })
        .catch((error) => {
          toast('error', error.response.data.error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    }

    setSubmitting(false);
  };


  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={'Salas'}
          subtitle={id ? 'Editar Sala' : 'Cadastro de Sala'}
          titleUrl={'/app/listar-salas'}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '40px' }}>
        {state && (
          <Button
            color='success'
            type='submit'
            variant='contained'
            size='large'
            sx={{ maxWidth: { md: '200px', mt: '1rem' }, mb: '2rem' }}
            onClick={() => navigate(state.backUrl)}
          >
            Voltar
          </Button>
        )}
        <Formik
          initialValues={classes}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting }) => {
            return (
              <Form style={{ width: '100%' }} noValidate autoComplete='off'>
                <Grid container spacing={3} alignContent={'center'}>
                  <Grid item xs={4}>
                    <JumboTextField
                      fullWidth
                      name='nome'
                      label='Nome da Sala'
                      value={updateInputValue(classes, values, 'nome')}
                      sx={{ background: '#ffffff' }}
                      disabled={isDisabled}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='local'>
                        Local
                      </InputLabel>
                      <JumboSelectField
                        labelId='local'
                        id='local'
                        name='local'
                        value={updateInputValue(classes, values, 'local')}
                        label='Local'
                        disabled={isDisabled}
                        notched
                      >
                        <MenuItem value={'Casa Transitória'}>
                          Casa Transitória
                        </MenuItem>
                        <MenuItem value={'Sede'}>Sede</MenuItem>
                        <MenuItem value={'Outro'}>Outro</MenuItem>
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <JumboTextField
                      fullWidth
                      name='andar'
                      label='Andar'
                      value={updateInputValue(classes, values, 'andar')}
                      sx={{ background: '#ffffff' }}
                      disabled={isDisabled}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  {!id ? (
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                      <LoadingButton
                        color='success'
                        type='submit'
                        variant='contained'
                        size='large'
                        sx={{ maxWidth: { md: '200px', mt: '1rem' } }}
                        loading={isSubmitting}
                        disabled={!hasPermission('Turmas', 'criar')}
                      >
                        Salvar
                      </LoadingButton>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <LoadingButton
                          color='success'
                          type='submit'
                          variant='contained'
                          size='large'
                          sx={{ maxWidth: { md: '200px', mt: '1rem' } }}
                          loading={isSubmitting}
                          disabled={isDisabled || isInputFocused}
                        >
                          Atualizar
                        </LoadingButton>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default RoomForm;
