/* eslint-disable no-unused-vars */
import React, { useCallback, useContext } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import {
  Accordion,
  Card,
  Tooltip,
  Typography,
  Zoom,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import Span from '@jumbo/shared/Span';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import capitalize from '../../../utils/capitalize';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { LinkStyle } from '../users/UserItem';
import { CSVLink } from 'react-csv';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useLocation } from 'react-router-dom';
import { useRef } from 'react';
import { EllipsisTooltip } from 'app/components/EllipsisTooltip/EllipsisTooltip';

export const Item = styled(Span)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

export const CSVStyled = styled(CSVLink)`
  padding: 4px 10px;
  background: #0092d0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  svg {
    color: white;
  }
`;

const BookTeamItem = ({ item, setBooks, books }) => {
  const { hasPermission } = useContext(PermissionContext);
  const location = useLocation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xl'));
  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });
  };

  const modalAlert = (id) => {
    Swal.fire({
      title: 'Tem certeza que deseja apagar?',
      text: 'Não será póssível reverter a ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteBook(id);
      }
    });
  };

  const removeById = (arr, id) => {
    const objWithIdIndex = arr.findIndex((obj) => obj.livro_id === id);
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  };

  const deleteBook = useCallback(async (id) => {
    try {
      ApiService.delete(`/livros/${id}`)
        .then((response) => {
          toast('success', 'Removido com sucesso');
          const updatedList = removeById(books, id);
          setBooks(updatedList);
        })
        .catch((error) => {
          console.log('error', error.message);
        });
    } catch (err) {
      console.log(err);
      toast('error', 'Ocorreu um erro');
    }
  }, []);

  return (
    <Card sx={{ mb: 1 }}>
      <Accordion square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{
            px: 0.5,
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',

              '&.Mui-expanded': {
                margin: '12px 0',
              },
            },
          }}
        >
          <Div sx={{pl: 1, width: '40%', flexShrink: 1 }}>
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              Departamento/Trabalho
            </Typography>
            <Typography variant={'body1'}>
              {item?.Departamento?.nome ?? '-'}
            </Typography>
          </Div>
          <Div sx={{ pl: 1, width: '10%', flexShrink: 1 }}>
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              Dia
            </Typography>
            <EllipsisTooltip text={item?.dia_da_semana} />
          </Div>
          <Div sx={{ width: '10%', flexShrink: 1 }}>
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              Início
            </Typography>
            <Typography variant={'body1'}>{item?.horario_inicio}</Typography>
          </Div>
          <Div sx={{ width: '10%', flexShrink: 1 }}>
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              Término
            </Typography>
            <Typography variant={'body1'}>{item?.horario_termino}</Typography>
          </Div>

          <Div sx={{ width: '20%', flexShrink: 1 }}>
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              Local
            </Typography>
            <EllipsisTooltip text={item?.Sala?.local} />
          </Div>

          <Div sx={{ width: '30%', flexShrink: 1 }}>
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              Sala
            </Typography>
            <EllipsisTooltip text={item?.Sala?.nome} />
          </Div>

          <Div sx={{ display: 'flex', alignItems: 'center', width: '15%' }}>
            <>
              <Tooltip
                title={'Clique para adicionar voluntários no livro'}
                TransitionComponent={Zoom}
                arrow
              >
                <Item>
                  <LinkStyle
                    to={`/app/inserir-voluntario-livro/${item.livro_id}`}
                    state={{
                      backUrl: location.pathname,
                    }}
                  >
                    <PersonAddIcon />
                  </LinkStyle>
                </Item>
              </Tooltip>

              <Tooltip title='Excluir' TransitionComponent={Zoom} arrow>
                <Item>
                  <Button
                    sx={{
                      minWidth: 1,
                    }}
                    disableElevation
                    variant={'contained'}
                    size={'small'}
                    color={'secondary'}
                    disabled={!hasPermission('Voluntariado', 'deletar')}
                    onClick={() => modalAlert(item.livro_id)}
                  >
                    <DeleteIcon />
                  </Button>
                </Item>
              </Tooltip>
            </>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  );
};
/* Todo item prop define */
export default BookTeamItem;
