/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
  Zoom,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { updateInputValue } from '../../../utils/appHelpers';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';
import styled from '@emotion/styled';
import Span from '@jumbo/shared/Span';
import DownloadIcon from '@mui/icons-material/Download';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { useDebouncedCallback } from 'beautiful-react-hooks';
import { ClassNames } from '@emotion/react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80em',
  p: 4,
};

export const Item = styled(Span)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

export const CSVStyled = styled(Item)`
  padding: 4px 10px;
  background: #0092d0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 0.5rem;
  cursor: pointer;

  svg {
    color: white;
  }
`;

export const generateYearOptions = () => {
  const arr = [];

  const startYear = 1980;
  const endYear = new Date().getFullYear();

  for (let i = endYear + 2; i >= startYear; i--) {
    arr.push(
      <MenuItem key={i} value={i.toString()}>
        {i}
      </MenuItem>
    );
  }

  return arr;
};

const BookForm = () => {
  const { theme } = useJumboTheme();
  const { showDialog, hideDialog } = useJumboDialog();

  const navigate = useNavigate();
  let { state, pathname } = useLocation();

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });

    //  Redireciona se mensagem de retorno for de sucesso
    /* if(!id) {
            if(variant == 'success'){
                setTimeout(function() {
                    navigate("/app/listar-turmas")

                }, 2000)
            }
        } */
  };

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const initialValues = {
    area_id: '',
    departamento_id: '',
    codigo_do_livro: '',
    dia_da_semana: '',
    horario_inicio: '',
    horario_termino: '',
    sala_id: '',
  };

  const validationSchema = yup.object().shape(
    {
      area_id: yup.string().required('Preenchimento obrigatório'),
    },
    []
  );

  const { id } = useParams();
  const location = useLocation();
  const [areas, setAreas] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [classes, setClasses] = useState(initialValues);
  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(false);

  const [addStudents, setAddStudents] = useState(null);
  const [classStudents, setClassStudents] = useState([]);

  const [isInputFocused, setIsInputFocused] = useState(false);

  const { hasPermission } = useContext(PermissionContext);

  const canCreate = hasPermission('Voluntariado', 'criar');
  const canEdit = hasPermission('Voluntariado', 'editar');
  const isDisabled = id ? !canEdit : !canCreate;

  const canCreateInclude = hasPermission(
    'Inclusão de Alunos na Turma',
    'criar'
  );
  const canEditInclude = hasPermission('Inclusão de Alunos na Turma', 'editar');
  const canView = hasPermission('Inclusão de Alunos na Turma', 'acessar');
  const isDisabledInclude = id ? !canEditInclude : !canCreateInclude;

  useEffect(() => {
    if (searchTerm == undefined || searchTerm == '') {
      setAddStudents(null);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      searchStudent(searchTerm);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const searchStudent = useCallback(async (e) => {
    try {
      ApiService.get(`/pessoa`, { params: { search: e, turma_id: id } })
        .then((response) => {
          setAddStudents(response.data);
          // toast('success', 'Aluno adicionado com sucesso');
        })
        .catch((error) => {
          // toast('error', 'Ocorreu um erro');

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getDepartments = useCallback(async () => {
    try {
      ApiService.get(`/departamentos`)
        .then((response) => {
          setDepartments(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getAreas = useCallback(async () => {
    try {
      ApiService.get(`/areas`)
        .then((response) => {
          setAreas(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getRooms = useCallback(async () => {
    try {
      ApiService.get(`/salas`)
        .then((response) => {
          console.log(response.data);
          setRooms(response.data.rooms);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getCourseById = useCallback(async () => {
    try {
      ApiService.get(`/livros/${id}`)
        .then((response) => {
          console.log(response.data);

          setClasses(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (id) getCourseById();
    getAreas();
    getDepartments();
    getRooms();
  }, []);

  useEffect(() => {
    Swal.close();
  }, [pathname]);

  console.log(classes);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log('enviou', values);
    if (id) {
      await ApiService.put(`/livros/${id}`, { values })
        .then((response) => {
          toast('success', 'Livro atualizado com sucesso');
        })
        .catch((error) => {
          toast('error', error.response.data.error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } else {
      await ApiService.post('/livros', { values })
        .then((response) => {
          toast('success', 'Criado com sucesso');
          resetForm();
        })
        .catch((error) => {
          toast('error', error.response.data.error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    }

    setSubmitting(false);
  };

  const handleChangeSearch = useDebouncedCallback((event) => {
    setSearchTerm(event.target.value);
  });

  React.useEffect(() => {
    setSearchTerm(searchTerm);
  }, [searchTerm]);

  React.useEffect(() => {
    return () => handleChangeSearch.cancel();
  });

  console.log(searchTerm);

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={'Livros'}
          subtitle={id ? 'Editar Livro' : 'Cadastro de Livros'}
          titleUrl={'/app/listar-livros'}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '40px' }}>
        {state && (
          <Button
            color='success'
            type='submit'
            variant='contained'
            size='large'
            sx={{ maxWidth: { md: '200px', mt: '1rem' }, mb: '2rem' }}
            onClick={() => navigate(state.backUrl)}
          >
            Voltar
          </Button>
        )}
        <Formik
          initialValues={classes}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange, setFieldValue }) => {
            const selectedArea = areas.find(
              (area) => area.area_id === values.area_id
            );
            const selectedRoom = rooms.find(
              (room) => room.sala_id === values.sala_id
            );
            
            return (
              <Form style={{ width: '100%' }} noValidate autoComplete='off'>
                <Grid container spacing={3} alignContent={'center'}>
                  <Grid item xs={3}>
                    <JumboTextField
                      fullWidth
                      name='codigo_do_livro'
                      label='Código do Livro'
                      value={updateInputValue(
                        classes,
                        values,
                        'codigo_do_livro'
                      )}
                      sx={{ background: '#ffffff' }}
                      disabled={isDisabled}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='area_id'>
                        Área
                      </InputLabel>
                      <JumboSelectField
                        labelId='area_id'
                        id='area_id'
                        name='area_id'
                        value={updateInputValue(classes, values, 'area_id')}
                        label='Área'
                        disabled={isDisabled}
                        notched
                      >
                        <MenuItem value={''}>Sem seleção</MenuItem>
                        {areas.length > 0 &&
                          areas.map((x) => (
                            <MenuItem value={x.area_id}>{x.nome}</MenuItem>
                          ))}
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='departamento_id'>
                        Departamento
                      </InputLabel>
                      <JumboSelectField
                        labelId='departamento_id'
                        id='departamento_id'
                        name='departamento_id'
                        value={updateInputValue(
                          classes,
                          values,
                          'departamento_id'
                        )}
                        label='Departamento'
                        disabled={isDisabled}
                        sx={{ background: '#ffffff' }}
                        notched
                      >
                        <MenuItem value={''}>Sem seleção</MenuItem>
                        {(selectedArea?.departamento || []).map((x) => (
                          <MenuItem value={x.departamento_id}>
                            {x.nome}
                          </MenuItem>
                        ))}
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='sala_id'>
                        Sala
                      </InputLabel>
                      <JumboSelectField
                        labelId='sala_id'
                        id='sala_id'
                        name='sala_id'
                        value={updateInputValue(classes, values, 'sala_id')}
                        label='Sala'
                        disabled={isDisabled}
                        notched
                      >
                        <MenuItem value={''}>Sem seleção</MenuItem>
                        {rooms.length > 0 &&
                          rooms.map((x) => (
                            <MenuItem value={x.sala_id}>{x.nome}</MenuItem>
                          ))}
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='local'>
                        Local
                      </InputLabel>
                      <JumboSelectField
                        labelId='local'
                        id='local'
                        name='local'
                        value={selectedRoom?.local || ''}
                        label='Local'
                        disabled={true}
                        sx={{ background: '#ffffff' }}
                        notched
                      >
                        <MenuItem value={''}>Sem seleção</MenuItem>

                        <MenuItem value={selectedRoom?.local}>
                          {selectedRoom?.local}
                        </MenuItem>
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='andar'>
                        Andar
                      </InputLabel>
                      <JumboSelectField
                        labelId='andar'
                        id='andar'
                        name='andar'
                        value={selectedRoom?.andar || ''}
                        label='andar'
                        disabled={true}
                        sx={{ background: '#ffffff' }}
                        notched
                      >
                        <MenuItem value={''}>Sem seleção</MenuItem>

                        <MenuItem value={selectedRoom?.andar}>
                          {selectedRoom?.andar}
                        </MenuItem>
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='dia_da_semana'>
                        Dia da semana
                      </InputLabel>
                      <JumboSelectField
                        labelId='dia_da_semana'
                        id='dia_da_semana'
                        name='dia_da_semana'
                        value={updateInputValue(
                          classes,
                          values,
                          'dia_da_semana'
                        )}
                        label='Dia da semana'
                        disabled={isDisabled}
                        notched
                      >
                        <MenuItem value={'Domingo'}>Domingo</MenuItem>
                        <MenuItem value={'Segunda'}>Segunda</MenuItem>
                        <MenuItem value={'Terça'}>Terça</MenuItem>
                        <MenuItem value={'Quarta'}>Quarta</MenuItem>
                        <MenuItem value={'Quinta'}>Quinta</MenuItem>
                        <MenuItem value={'Sexta'}>Sexta</MenuItem>
                        <MenuItem value={'Sábado'}>Sábado</MenuItem>
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputMask
                        mask='99:99'
                        value={updateInputValue(
                          classes,
                          values,
                          'horario_inicio'
                        )}
                        onChange={handleChange}
                        disabled={isDisabled}
                      >
                        {() => (
                          <JumboTextField
                            fullWidth
                            name='horario_inicio'
                            label='Início'
                            disabled={isDisabled}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      </InputMask>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputMask
                        mask='99:99'
                        value={updateInputValue(
                          classes,
                          values,
                          'horario_termino'
                        )}
                        onChange={handleChange}
                        disabled={isDisabled}
                      >
                        {() => (
                          <JumboTextField
                            fullWidth
                            name='horario_termino'
                            label='Término'
                            disabled={isDisabled}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      </InputMask>
                    </FormControl>
                  </Grid>

                  {!id ? (
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                      <LoadingButton
                        color='success'
                        type='submit'
                        variant='contained'
                        size='large'
                        sx={{ maxWidth: { md: '200px', mt: '1rem' } }}
                        loading={isSubmitting}
                        disabled={!hasPermission('Turmas', 'criar')}
                      >
                        Salvar
                      </LoadingButton>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <LoadingButton
                          color='success'
                          type='submit'
                          variant='contained'
                          size='large'
                          sx={{ maxWidth: { md: '200px', mt: '1rem' } }}
                          loading={isSubmitting}
                          disabled={isDisabled || isInputFocused}
                        >
                          Atualizar
                        </LoadingButton>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default BookForm;
