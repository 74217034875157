import React, { useEffect } from 'react';
import Div from "@jumbo/shared/Div";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';

const Unauthorized = () => {
    const navigate = useNavigate();
    const getTokenFromLocalStorage = localStorage.getItem("token")
    useEffect(() => {
    if (!getTokenFromLocalStorage) {
        navigate('/login'); 
        return;
    }

    }, [getTokenFromLocalStorage, navigate]);

    return (
        <>
            <Div sx={{
                flex: 1,
                flexWrap: 'wrap',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            
            }}>
               <Typography
                    variant={"h1"}
                    fontWeight="500"
                    sx={{ fontSize: 70, textShadow: '1rem 0.6rem 1rem rgba(0, 0, 0, 0.35)', mb: 1 }}
                >
                    Acesso Negado
                </Typography>
                <Typography
                    component={"h2"}
                    variant={"h1"}
                    color={"text.secondary"}
                    mb={4}
                    textAlign={"center"}
                >
                    Você já está logado.
                </Typography>

                <Typography
                    component={"h4"}
                    variant={"h3"}
                    color={"text.secondary"}
                    mb={4}
                    textAlign={"center"}
                >
                    A página que você tentou acessar é destinada a usuários não autenticados. Se você deseja acessar as rotas de "login" ou "esqueci-senha", por favor, faça logout primeiro.
                </Typography>

            </Div>
        </>
    );
};

export default Unauthorized;
