import React, {
    useState,
    useCallback,
    useEffect,
    Fragment,
    useContext,
} from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    Paper,
    Stack,
    Switch,
    Typography,
    useMediaQuery,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { ApiService } from 'app/servicesTwo/ApiService';
import { updateInputValue } from '../../../utils/appHelpers';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { PermissionContext } from 'app/contexts/PermissionContext';
import InfosAboutCreate from 'app/components/InfosAboutCreate/InfosAboutCreate';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const ModalAuthorizeFile = ({
    data,
    handleClose,
    classStudents,
    setClassStudents,
    getCourseById,
}) => {
    const { theme } = useJumboTheme();
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const animatedComponents = makeAnimated();
    const layoutOptions = React.useMemo(
        () => ({
            sidebar: {
                sx: {
                    [theme.breakpoints.up('lg')]: {
                        position: 'sticky',
                        zIndex: 5,
                        top: 96,
                        minHeight: 'auto',
                    },
                    [theme.breakpoints.down('lg')]: {
                        display: 'none',
                    },
                },
            },
            wrapper: {
                sx: {
                    alignItems: 'flex-start',
                },
            },
        }),
        [theme]
    );

    const initialValues = {
        observacao: data.observacao ?? null,
        infos_usuario: false,
    };

    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });
    };

    const [classData, setClassData] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [selectRequirement, setSelectRequirement] = useState(null);
    const [showModal, setShowModal] = useState(true);

    const { hasPermission } = useContext(PermissionContext);

    const isResgisterOnClass =
        data.status_curso_introdutorio_historico === 'Matriculado' ||
        data.status === 'Matriculado';

    function updateById(arr, id, status) {
        const updatedClass = arr.find(
            (entry) => entry.curso_introdutorio_historico_id === id
        );
        if (updatedClass) {
            updatedClass.status = status;
        }

        return arr;
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        const turmaId = data.Turma.turmas_id;
        const pessoa_id = data.pessoa_id;
        const introHistoricoId = data.curso_introdutorio_historico_id;
        const pessoa_que_autorizou_id = localStorage.getItem('storedUserId');
        const file = data.ficha_compativel;

        if (!selectRequirement) {
            toast('error', 'Selecione um curso compatível');
            return;
        }

        if (showModal) {
            Swal.fire({
                title: 'Tem certeza que deseja incluir o aluno na turma?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não!',
                reverseButtons: true,
            }).then((result) => {
                console.log(result);
                if (result.value) {
                    setShowModal(false);
                    try {
                        ApiService.post(
                            `/turmas-inserir/${turmaId}/aluno/${pessoa_id}/intro/${introHistoricoId}/autorizou/${pessoa_que_autorizou_id}`,
                            { values, selectRequirement, file }
                        )
                            .then((response) => {
                                toast('success', 'Adicionado com sucesso');
                                const status = response.data.status;
                                const updatedStudentsArray = updateById(
                                    classStudents,
                                    introHistoricoId,
                                    status
                                );
                                handleClose();
                                setClassStudents(updatedStudentsArray);
                                getCourseById(1, 2);
                            })
                            .catch((error) => {
                                if (error.response) {
                                    toast('error', error.response.data.error);
                                    console.log(error.response.data);
                                    console.log(error.response.status);
                                    console.log(error.response.headers);
                                } else if (error.request) {
                                    console.log(error.request);
                                } else {
                                    console.log('error', error.message);
                                }
                            });
                    } catch (err) {
                        console.log(err);
                        toast('error', 'Ocorreu um erro');
                    }
                }
            });
        }
    };

    const getRequirements = useCallback(async () => {
        const cursoId = data.Turma.curso_id;
        const introHistoricoId = data.curso_introdutorio_historico_id;

        try {
            ApiService.get(`/requisitos/${cursoId}/intro/${introHistoricoId}`)
                .then((response) => {
                    console.log(response.data);
                    setRequirements(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        getRequirements();
    }, []);

    const optionsRegularCourses = requirements.regularCourses?.map((result) => {
        return {
            value: result.curso_id,
            label: result.nome,
        };
    });

    const optionsContinuingCourses = requirements.continuingCourses?.map(
        (result) => {
            return {
                value: result.curso_id,
                label: result.nome,
            };
        }
    );

    const groupedOptions = [
        {
            label: 'Regulares',
            options: optionsRegularCourses,
        },
        {
            label: 'Educação Continuada',
            options: optionsContinuingCourses,
        },
    ];

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };

    const formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    const handleSelectRequirements = (selected) => {
        setSelectRequirement(selected.length == 0 ? null : selected);
    };

    const selectedOptions = requirements.historyCourseCompatible?.map(
        (item) => {
            const option = groupedOptions
                .flatMap((group) => group.options)
                .find((opt) => opt.value === item.curso_compativel_id);
            return option;
        }
    );

    return (
        <JumboContentLayout layoutOptions={layoutOptions}>
            {lg && (
                <Stack
                    spacing={2}
                    direction={'row'}
                    sx={{ mb: 3, mt: -2 }}
                ></Stack>
            )}
            <Paper sx={{ p: '40px' }}>
                <Typography
                    variant={'h1'}
                    style={{ textAlign: 'center', marginBottom: 30 }}
                >
                    Compatibilização de Curso
                </Typography>
                <Fragment>
                    <Formik
                        initialValues={initialValues}
                        // validationSchema={validationSchema}
                        enableReinitialize
                        validateOnChange={false}
                        validateOnBlur={false}
                        onSubmit={handleSubmit}
                    >
                        {({ values, isSubmitting, handleChange }) => (
                            <Form
                                style={{ width: '100%' }}
                                noValidate
                                autoComplete='off'
                            >
                                <Grid
                                    container
                                    spacing={3}
                                    alignContent={'center'}
                                >
                                    <Grid item xs={12}>
                                        <Typography
                                            variant='h5'
                                            marginBottom='1rem'
                                        >
                                            Antes de incluir um aluno na turma,
                                            verifique o arquivo de
                                            compatibilidade de curso para
                                            garantir que ele atenda aos
                                            requisitos necessários.
                                        </Typography>
                                        <Div
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '1rem',
                                            }}
                                        >
                                            {data.ficha_compativel && (
                                                <>
                                                    <Div
                                                        sx={{
                                                            width: '50px',
                                                            height: '50px',
                                                            borderRadius: 50,
                                                            marginRight: '1rem',
                                                            overflow: 'hidden',
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                        }}
                                                    >
                                                        <embed
                                                            src={`${process.env.REACT_APP_API_KEY}/images/${data.ficha_compativel}`}
                                                        />
                                                    </Div>
                                                    <Button
                                                        variant='contained'
                                                        href={`${process.env.REACT_APP_API_KEY}/images/${data.ficha_compativel}`}
                                                        target='_blank'
                                                    >
                                                        Abrir arquivo
                                                    </Button>
                                                </>
                                            )}
                                        </Div>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                    <Typography  variant="h6" component="div">
                                        Requisitos para este curso
                                    </Typography>
                                    <List>
                                        {
                                            requirements?.requirementsCourse?.map((requirement) => (
                                                <ListItem>
                                                    <ListItemText

                                                        secondary={requirement.pre_requisito.nome}

                                                    />
                                                </ListItem>
                                            ))
                                        }
                                        
                                    </List>
                                    
                                </Grid> */}
                                    <Grid item xs={12}>
                                        <Typography
                                            variant='h5'
                                            marginBottom='1rem'
                                        >
                                            {isResgisterOnClass
                                                ? 'Cursos marcados como compatíveis:'
                                                : 'Selecione abaixo os cursos compatíveis:'}
                                        </Typography>
                                        <Select
                                            name='selecione'
                                            placeholder='Selecione'
                                            isMulti
                                            options={groupedOptions}
                                            formatGroupLabel={formatGroupLabel}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                            onChange={handleSelectRequirements}
                                            value={
                                                isResgisterOnClass
                                                    ? selectedOptions
                                                    : undefined
                                            }
                                            isDisabled={isResgisterOnClass}
                                            components={animatedComponents}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            {/* <InputLabel id="observacao">Observação</InputLabel> */}
                                            <JumboTextField
                                                fullWidth
                                                name='observacao'
                                                label='Observação'
                                                multiline
                                                rows={4}
                                                value={updateInputValue(
                                                    classData,
                                                    values,
                                                    'observacao'
                                                )}
                                                disabled={
                                                    !hasPermission(
                                                        'Turmas',
                                                        'editar'
                                                    ) || isResgisterOnClass
                                                }
                                                sx={{ background: '#ffffff' }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ textAlign: 'center' }}
                                    >
                                        <LoadingButton
                                            color='success'
                                            type='submit'
                                            variant='contained'
                                            size='large'
                                            sx={{
                                                maxWidth: {
                                                    md: '200px',
                                                    mt: '1rem',
                                                },
                                            }}
                                            loading={isSubmitting}
                                            disabled={
                                                !hasPermission(
                                                    'Turmas',
                                                    'editar'
                                                ) || isResgisterOnClass
                                            }
                                        >
                                            Confirmar
                                        </LoadingButton>
                                    </Grid>
                                    {isResgisterOnClass ? (
                                        <Grid item xs={12}>
                                            {console.log('values', values)}
                                            <Accordion
                                                expanded={
                                                    updateInputValue(
                                                        classData,
                                                        values,
                                                        'infos_usuario'
                                                    )
                                                        ? true
                                                        : false
                                                }
                                                elevation={0}
                                            >
                                                <AccordionSummary>
                                                    <Stack
                                                        sx={{
                                                            width: '100%',
                                                            textAlign: {
                                                                xs: 'center',
                                                                lg: 'left',
                                                            },
                                                        }}
                                                        direction={{
                                                            xs: 'column',
                                                            lg: 'row',
                                                        }}
                                                        justifyContent='space-between'
                                                        alignItems='center'
                                                        spacing={2}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize:
                                                                    '1.05rem',
                                                            }}
                                                        >
                                                            Informações sobre
                                                            quem autorizou
                                                            compatibilização
                                                        </Typography>

                                                        <Stack
                                                            direction='row'
                                                            spacing={1}
                                                            alignItems='center'
                                                        >
                                                            <Typography>
                                                                Ocultar
                                                            </Typography>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={
                                                                            updateInputValue(
                                                                                classData,
                                                                                values,
                                                                                'infos_usuario'
                                                                            )
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        onChange={
                                                                            handleChange
                                                                        }
                                                                        name='infos_usuario'
                                                                    />
                                                                }
                                                                labelPlacement='start'
                                                            />
                                                            <Typography>
                                                                Visualizar
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid item xs={12}>
                                                        <InfosAboutCreate
                                                            data={
                                                                requirements?.introHistorico
                                                            }
                                                            quem_criou={
                                                                requirements
                                                                    ?.pessoaQueAutorizou
                                                                    ?.nome ??
                                                                '-'
                                                            }
                                                            paperBg={'#EFF0F2'}
                                                        />
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Fragment>
            </Paper>
        </JumboContentLayout>
    );
};

export default ModalAuthorizeFile;
