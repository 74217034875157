import React, {useCallback, useEffect, useState} from 'react';

import {Box, Button, Grid, List} from "@mui/material";
import { useParams } from "react-router-dom";
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ListRowItemCourseIntro from './ListRowItemCourseIntro';

const ListRowCourseIntro = ({ data , infosAboutCourse, setInfosAboutCourse, updateStudentList, classStudents, setSearchTerm, usersRegistration, setUsersRegistration, getCourseById}) => {
    const { id } = useParams()
    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });
    };
   
    const maxLengthText = 20;
    const [itemsList, setItemsList] = useState(data);
    const [selectedClass, setSelectedClass] = useState([])


    const includeStudentToClass = useCallback((record) => {
       
        try {
            ApiService.post(`/cursos-introdutorios-interno/${id}/pessoa/`, 
            {turmaEPessoaId: selectedClass})
            .then((response) => {
                
                toast('success', 'Aluno adicionado com sucesso');
                setSearchTerm('')
                getCourseById()
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                  toast('error', error.response.data.error)
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }

    }, [selectedClass, itemsList, usersRegistration]);

    return (
            <List disablePadding>
                {
                    data.length > 1 
                    ?
                    <Box display={"flex"} justifyContent={"end"} marginRight={'9.7rem'}>
                        <Button
                            size={"small"}
                            variant={"contained"}
                            color={"success"}
                            disableElevation
                            onClick={() => includeStudentToClass(selectedClass)}
                            // {...(item.follow) ? {color: "inherit"} : {}}
                            //disabled={!hasPermission('Turmas', 'editar')}
                            sx={{
                                minWidth: 78,
                                textTransform: 'none',
                                p: theme => theme.spacing(.5, 1.5)
                            }}
                        >
                            Incluir
                        </Button>
                    </Box>
                    : 
                    null
                }
                {
                    data.map((item, index) => (
                        <ListRowItemCourseIntro 
                            item={item} 
                            key={index}
                            itemsList={data}
                            selectedClass={selectedClass}
                            setSelectedClass={setSelectedClass} 
                            includeStudentToClass={includeStudentToClass} 
                            infosAboutCourse={infosAboutCourse}
                            setInfosAboutCourse={setInfosAboutCourse}
                            usersRegistration={usersRegistration}
                        />
                    ))
                }
            </List>
    );
};

export default ListRowCourseIntro;
