import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Checkbox,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Stack,
  Switch,
  Tooltip,
  Typography,
  Zoom,
  useMediaQuery,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useLocation } from 'react-router-dom';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
/* import ClassStudentsList from "./ClassStudentsList"; */
import { PermissionContext } from 'app/contexts/PermissionContext';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import InfosAboutCreateAndUpdate from 'app/components/InfosAboutCreateAndUpdate/InfosAboutCreateAndUpdate';
import { updateInputValue } from 'app/utils/appHelpers';
import MUIDataTable from 'mui-datatables';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import styled from '@emotion/styled';
import VolunteerAlocationFormResumeEdit from './VolunteerAlocationFormResumeEdit';

const FileListItem = styled.li`
  list-style: none;
  /* margin: 1rem 0; */
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.9rem;
  min-width: 15%;
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80em',
  p: 4,
};

const VolunteerAlocationFormResume = ({
  volunteerId,
  areaId,
  newActivityVolunteerArea,
  volunteerAreasHistory,
  openVolunteerConsult,
}) => {
  const { theme } = useJumboTheme();
  const animatedComponents = makeAnimated();
  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: 'Fechar',
    });
  };

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const initialValues = {
    pessoa_id: '',
    voluntario_id: '',
    codigo_antigo_voluntariado: '',
    status: '',
    data_inicio: '',
    data_fim: '',
    departamento_id: '',
    area_id: '',
    local: '',
    ficha: '',
    atividade: '',
    observacao: '',
    voluntario_desde: '',
    motivo_encerramento: null,
    obs_encerramento: null,
    teve_vivencia_pratica: false,
    quais_trabalhos: false,
    confirmar_voluntariado: false,
    encerrar_voluntariado: false,
  };

  const { id } = useParams();

  const [users, setUsers] = useState(initialValues);

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [userFile, setUserFile] = useState(null);
  const [status, setStatus] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userVolunteerCourse, setUserVolunteerCourse] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [areas, setAreas] = useState([]);
  const [activities, setActivities] = useState([]);
  const [activitiesSelected, setActivitiesSelected] = useState(null);
  const [editInput, setEditInput] = useState(true);

  const [submitClicked, setSubmitClicked] = useState(false);

  const [openActivityEdit, setOpenActivityEdit] = useState(false);
  const [activitySelected, setActivitySelected] = useState('');

  const [userPreferences, setUserPreferences] = useState([
    { dia_da_semana: '', horario_inicio: '', horario_fim: '' },
  ]);

  const [userVolunteerOut, setUserVolunteerOut] = useState([
    {
      nome_trabalho_voluntario_fora: '',
      local_fora: '',
      data_inicio_voluntario_fora: '',
      obs_fora: '',
    },
  ]);

  const [open, setOpen] = useState(false);
  const [volunteerAreaHistory, setVolunteerAreaHistory] = useState([]);
  const [editMode, setEditMode] = useState({
    edit: false,
    activitySelected: '',
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { hasPermission } = useContext(PermissionContext);
  let { state } = useLocation();

  const canCreate = hasPermission('Voluntariado', 'criar');
  const canEdit = hasPermission('Voluntariado', 'editar');
  const isDisabled = id ? !canEdit : !canCreate;

  const isInputEditable = editInput === true;
  const isStatusDisabled = status === false;

  const storedUserId = localStorage.getItem('storedUserId');

  const modalAlertDelete = (id) => {
    Swal.fire({
      title: 'Tem certeza que deseja apagar?',
      text: 'Não será póssível reverter a ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteHistory(id);
      }
    });
  };

  const getCourseById = useCallback(async () => {
    try {
      ApiService.get(`/voluntarios/${id || volunteerId}`)
        .then((response) => {
          console.log('iii', response.data);
          setUsers({
            ...response.data,
            quais_trabalhos: false,
            area_id: areaId,
          });
          setSelectedOption(response.data.User);
          setUserPreferences(response.data.Horario_voluntariado);
          setUserVolunteerOut(response.data.Trabalho_Voluntario_Fora_Feesp);
          setActivitiesSelected(response.data.atividade_selecionada_id);

          if (response.data.status == 'ativo') {
            setStatus(true);
          }
          //setVerifyVolunteerCourse(response.data.verifyVolunteerCourse)
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getDepartments = useCallback(async () => {
    try {
      ApiService.get(`/departamentos`)
        .then((response) => {
          setDepartments(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getAreas = useCallback(async () => {
    try {
      ApiService.get(`/areas`)
        .then((response) => {
          setAreas(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getAtividades = useCallback(async () => {
    try {
      ApiService.get(`/atividades-voluntarios`)
        .then((response) => {
          console.log(response.data);
          setActivities(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getVolunteerAreaHistory = useCallback(
    async (id = undefined) => {
      try {
        console.log({ areaId });
        ApiService.get(
          `/atividades-do-voluntario/voluntario/${volunteerId}?areaId=${
            areaId || id
          }`
        )
          .then((response) => {
            console.log('historico de areas com voluntarios', response.data);
            setVolunteerAreaHistory(response.data);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    [volunteerId, areaId]
  );

  const deleteHistory = useCallback(async (id) => {
    try {
      ApiService.delete(`/atividades-do-voluntario/${id}`)
        .then((response) => {
          toast('success', 'Removido com sucesso');
          getVolunteerAreaHistory();
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
      toast('error', 'Ocorreu um erro');
    }
  }, []);

  useEffect(() => {
    if (id || volunteerId) {
      getCourseById();
      getAtividades();
      getVolunteerAreaHistory();
    }
    getDepartments();
    getAreas();
    getAtividades();
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log(values);
    console.log(editMode);

    if (volunteerId) {
      if (values.encerrar_voluntariado == 'on') {
        //encerrar voluntariado
        const result = await Swal.fire({
          title: 'Tem certeza que deseja encerrar voluntariado?',
          text: 'Não será possível reverter a ação!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          reverseButtons: true,
        });

        /* const allValuesEdit = {...values, horario_voluntariado: userPreferences, trabalho_voluntario_fora_feesp: userVolunteerOut } */

        if (result.value) {
          await ApiService.put(
            `/atividades-do-voluntario/encerrar/${editMode.activitySelected}`,
            { values }
          )
            .then((response) => {
              toast('success', 'Atuação na atividade encerrada');
            })
            .catch((error) => {
              toast('error', 'Ocorreu um erro');
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('error', error.message);
              }
            });
        }
      } else {
        //atualiza form sem encerrar o voluntariado
        if (editMode.edit) {
          const allValuesEdit = {
            ...values,
            horario_voluntariado: userPreferences,
            trabalho_voluntario_fora_feesp: userVolunteerOut,
            atividade_selecionada_id: activitiesSelected,
          };

          await ApiService.put(
            `/atividades-do-voluntario/${editMode.activitySelected}`,
            { values: allValuesEdit }
          )
            .then((response) => {
              toast('success', 'Atividade atualizada com sucesso');
              setSubmitClicked(true);

              getVolunteerAreaHistory(users.area_id);
              setEditMode({
                edit: false,
                activitySelected: '',
              });
              setUsers({
                ...users,
                /*  area_id: '', */
                departamento_id: '',
                local: '',
                data_inicio: '',
              });
              setActivitiesSelected(null);
              setEditInput(true);
            })
            .catch((error) => {
              toast('error', error.response.data.error);
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('error', error.message);
              }
            });
        } else {
          const allValuesEdit = {
            ...values,
            horario_voluntariado: userPreferences,
            trabalho_voluntario_fora_feesp: userVolunteerOut,
            atividade_selecionada_id: activitiesSelected,
          };

          await ApiService.post(
            `/atividades-do-voluntario/voluntario/${volunteerId}`,
            { values: allValuesEdit }
          )
            .then((response) => {
              toast('success', 'Atividade cadastrada com sucesso');
              setSubmitClicked(true);

              getVolunteerAreaHistory(users.area_id);
              /* values = {
                ...values,
                departamento_id: '',
                local: '',
                data_inicio: '',
              }
              const resetValues = {
                ...values,
                departamento_id: '',
                local: '',
                data_inicio: '',
              };
              setUsers(resetValues); */
              resetForm();
              setActivitiesSelected(null);
            })
            .catch((error) => {
              toast('error', error.response.data.error);
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('error', error.message);
              }
            });
        }
      }
    }

    setSubmitting(false);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'transparent' : 'white',
      border: state.isFocused
        ? '1px solid #7352C7'
        : '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      padding: '8px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'black',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#7352C7' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: 'lightblue',
        color: 'white',
      },
    }),
  };

  const optionsVolunteerActivities = activities?.map((result) => {
    return {
      value: result.atividade_voluntario_id,
      label: result.nome,
    };
  });

  const handleOpenActivityEdit = () => setOpenActivityEdit(true);
  const handleCloseActivityEdit = () => setOpenActivityEdit(false);

  const columns = [
    {
      name: 'Departamento.nome',
      label: 'Departamento',
      options: {
        filter: false,
        sort: false,
        /* setCellProps: () => ({
          width: '80%',
        }), */
        customBodyRender: (value) => (
          <Typography variant={'body1'}>{value ?? '-'}</Typography>
        ),
      },
    },
    {
      name: 'Atividades_Voluntario.nome',
      label: 'Atividades',
      options: {
        filter: false,
        sort: false,
        /* setCellProps: () => ({
          width: '80%',
        }), */
        customBodyRender: (value) => (
          <Typography variant={'body1'}>{value ?? '-'}</Typography>
        ),
      },
    },
    {
      name: 'local',
      label: 'Local',
      options: {
        filter: false,
        sort: false,
        /* setCellProps: () => ({
          width: '80%',
        }), */
        customBodyRender: (value) => (
          <Typography variant={'body1'}>{value ? value : '-'}</Typography>
        ),
      },
    },
    {
      name: 'data_inicio',
      label: 'Início',
      options: {
        filter: false,
        sort: false,
        /* setCellProps: () => ({
          width: '80%',
        }), */
        customBodyRender: (value) => (
          <Typography variant={'body1'}>
            {value ? moment(value).format('DD/MM/YYYY') : '-'}
          </Typography>
        ),
      },
    },
    {
      name: 'data_fim',
      label: 'Encerramento',
      options: {
        filter: false,
        sort: false,
        /* setCellProps: () => ({
          width: '80%',
        }), */
        customBodyRender: (value) => (
          <Typography variant={'body1'}>
            {value ? moment(value).format('DD/MM/YYYY') : '-'}
          </Typography>
        ),
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
        /* setCellProps: () => ({
          width: '80%',
        }), */
        customBodyRender: (value) => (
          <Typography variant={'body1'}>
            {value === 'ativo' ? 'Ativo' : 'Inativo'}
          </Typography>
        ),
      },
    },
    {
      name: 'atividade_do_voluntario_id',
      label: 'Editar',
      options: {
        filter: false,
        sort: false,
        display: `${openVolunteerConsult ? false : true}`,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Tooltip title='Editar Atividade' TransitionComponent={Zoom} arrow>
              <Button
                disableElevation
                variant={'contained'}
                size={'small'}
                color={'primary'}
                //disabled={!hasPermission('Pessoas', 'deletar') ? true : false}
                onClick={() => {
                  /* setEditMode({
                  edit: true,
                  activitySelected: value,
                });
                getAreaActivity(value); */
                  setOpenActivityEdit(true);
                  setActivitySelected(value);
                }}
              >
                <EditIcon />
              </Button>
            </Tooltip>

            <Tooltip title='Excluir Atividade' TransitionComponent={Zoom} arrow>
              <Button
                disableElevation
                variant={'contained'}
                size={'small'}
                color={'secondary'}
                //disabled={!hasPermission('Pessoas', 'deletar') ? true : false}
                onClick={() => modalAlertDelete(value)}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Box>
        ),
      },
    },
  ];

  const columnsData = [
    {
      name: 'Departamento.nome',
      label: 'Nome do Departamento',
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'Atividades_Voluntario.nome',
      label: 'Atividades',
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'local',
      label: 'Local',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'data_inicio',
      label: 'Data de Início',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <Typography variant={'body1'}>
            {value ? moment(value).format('DD/MM/YYYY') : '-'}
          </Typography>
        ),
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    search: false,
    download: false,
    print: false,
    sortFilterList: false,
    viewColumns: false, //
    filter: false,
    enableNestedDataAccess: '.',
    textLabels: {
      body: {
        noMatch: 'Nenhum histórico encontrado',
      },
      toolbar: {
        filterTable: 'Filtrar por Área',
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Itens por Página:',
        displayRows: 'de',
      },
      filter: {
        all: 'Todos',
        title: 'Filtrar por Área',
        reset: 'Resetar',
      },
    },
    setTableProps: () => {
      return {
        size: 'small',
      };
    },
  };

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}

      <Paper sx={{ mt: 2 }}>
        <Formik
          initialValues={users}
          //validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange }) => {
            const selectedArea = areas.find(
              (area) => area.area_id === values.area_id
            );
            return (
              <Form style={{ width: '100%' }} noValidate autoComplete='off'>
                <Grid container spacing={3} alignContent={'center'}>
                  {/* <Grid item xs={3}>
                    <InputMask
                      mask='99/9999'
                      value={updateInputValue(
                        users,
                        values,
                        'voluntario_desde'
                      )}
                      onChange={handleChange}
                      disabled={true}
                      disableUnderline={true}
                      placeholder='mm/aaaa'
                    >
                      {(inputProps) => (
                        <JumboTextField
                          {...inputProps}
                          id='voluntario_desde'
                          fullWidth
                          name='voluntario_desde'
                          label='Voluntário desde'
                          type='text'
                          sx={{ background: '#ffffff' }}
                          disabled={true}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    </InputMask>
                  </Grid> */}
                  {/*  <Grid item xs={3}>
                    <JumboTextField
                      fullWidth
                      name='codigo_antigo_voluntariado'
                      label='Código do Voluntário (Sistema Antigo)'
                      value={updateInputValue(
                        users,
                        values,
                        'codigo_antigo_voluntariado'
                      )}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <JumboTextField
                      fullWidth
                      name='codigo_voluntario_feesp'
                      label='Código do Voluntário (Sistema Novo)'
                      value={updateInputValue(
                        users,
                        values,
                        'codigo_voluntario_feesp'
                      )}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControlLabel
                      name={updateInputValue(
                        users,
                        values,
                        'teve_vivencia_pratica'
                      )}
                      control={
                        <Checkbox
                          name='teve_vivencia_pratica'
                          checked={updateInputValue(
                            users,
                            values,
                            'teve_vivencia_pratica'
                          )}
                          disabled={isDisabled || status == false}
                        />
                      }
                      label='Realizou curso de Capacitação de Voluntários?'
                      labelPlacement='end'
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>

                  <Grid item lg={12} xs={2}>
                    <Div
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1rem',
                      }}
                    >
                      {previewFile && (
                        <Avatar
                          sx={{ width: 46, height: 46 }}
                          src={previewFile}
                        />
                      )}
                      {values.ficha && !previewFile && (
                        <>
                          <FileListItem
                            className='file-item'
                            key={values.ficha}
                          >
                            <p>
                              <a
                                href={`${process.env.REACT_APP_API_KEY}/images/${values.ficha}`}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {values?.ficha?.replace(/^\d+_/, '')}
                              </a>
                            </p>
                          </FileListItem>
                        </>
                      )}
                    </Div>
                  </Grid> */}

                  <>
                    {!openVolunteerConsult && (
                      <>
                        <Grid item xs={4}>
                          <FormControl fullWidth>
                            <InputLabel shrink id='area_id'>
                              Área
                            </InputLabel>
                            <JumboSelectField
                              labelId='area_id'
                              id='area_id'
                              name='area_id'
                              value={updateInputValue(users, values, 'area_id')}
                              label='Área'
                              disabled={isDisabled || status == false || areaId} //fdg
                              notched
                              onChange={(e) => {
                                setUsers({
                                  ...users,
                                  area_id: e.target.value,
                                });
                                getVolunteerAreaHistory(e.target.value);
                              }}
                            >
                              <MenuItem value={''}>Sem seleção</MenuItem>
                              {areas.length > 0 &&
                                areas.map((x) => (
                                  <MenuItem value={x.area_id}>
                                    {x.nome}
                                  </MenuItem>
                                ))}
                            </JumboSelectField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth>
                            <InputLabel shrink id='departamento_id'>
                              Departamento
                            </InputLabel>
                            <JumboSelectField
                              labelId='departamento_id'
                              id='departamento_id'
                              name='departamento_id'
                              value={updateInputValue(
                                users,
                                values,
                                'departamento_id'
                              )}
                              label='Departamento'
                              disabled={isDisabled || status == false}
                              sx={{ background: '#ffffff' }}
                              notched
                            >
                              <MenuItem value={''}>Sem seleção</MenuItem>
                              {(selectedArea?.departamento || []).map((x) => (
                                <MenuItem value={x.departamento_id}>
                                  {x.nome}
                                </MenuItem>
                              ))}
                            </JumboSelectField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl fullWidth>
                            <InputLabel shrink id='local'>
                              Local
                            </InputLabel>
                            <JumboSelectField
                              labelId='local'
                              id='local'
                              name='local'
                              value={updateInputValue(users, values, 'local')}
                              label='Local'
                              disabled={isDisabled || status == false}
                              notched
                            >
                              <MenuItem value={'Casa Transitória'}>
                                Casa Transitória
                              </MenuItem>
                              <MenuItem value={'Sede'}>Sede</MenuItem>
                              <MenuItem value={'Outro'}>Outro</MenuItem>
                            </JumboSelectField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <JumboTextField
                            fullWidth
                            name='data_inicio'
                            type='date'
                            label='Data de início'
                            value={updateInputValue(
                              users,
                              values,
                              'data_inicio'
                            )}
                            sx={{ background: '#ffffff' }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={isDisabled || status == false}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <div style={{ position: 'relative' }}>
                            <Select
                              id='select-two'
                              styles={customStyles}
                              options={optionsVolunteerActivities}
                              components={animatedComponents}
                              onChange={(newValue) =>
                                setActivitiesSelected(newValue.value)
                              }
                              value={optionsVolunteerActivities.filter(
                                (option) => option.value === activitiesSelected
                              )}
                              placeholder='Selecione Atividade'
                              isDisabled={isDisabled || status == false}
                            />

                            <Typography
                              style={{
                                position: 'absolute',
                                top: '-9px',
                                left: '10px',
                                background: '#ffffff',
                                padding: '0 5px',
                                color: '#8595A6',
                                fontSize: '0.7rem',
                                fontFamily: 'NoirPro,Arial',
                              }}
                            >
                              Selecione atividade
                            </Typography>
                          </div>
                        </Grid>

                        <Grid item xs={12}>
                          {volunteerId && users?.data_inicio ? (
                            <>
                              <Grid item xs={12}>
                                <Accordion
                                  expanded={
                                    users.status === 'ativo'
                                      ? updateInputValue(
                                          users,
                                          values,
                                          'encerrar_voluntariado'
                                        ) == 'on'
                                        ? true
                                        : false
                                      : true
                                  }
                                  elevation={0}
                                >
                                  <AccordionSummary
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'
                                  >
                                    <Stack
                                      sx={{
                                        width: '100%',
                                        textAlign: { xs: 'center', lg: 'left' },
                                      }}
                                      direction={{ xs: 'column', lg: 'row' }}
                                      justifyContent='space-between'
                                      alignItems='center'
                                      spacing={2}
                                    >
                                      <Typography sx={{ fontSize: '1.05rem' }}>
                                        {users.status === 'ativo'
                                          ? 'Encerrar voluntariado?'
                                          : 'Voluntariado Encerrado'}
                                      </Typography>
                                      {users.status === 'inativo' ? (
                                        <Tooltip
                                          title={
                                            'Editar data final e/ou motivo'
                                          }
                                          arrow
                                          TransitionComponent={Zoom}
                                        >
                                          <EditIcon
                                            onClick={() =>
                                              setEditInput(!editInput)
                                            }
                                          />
                                        </Tooltip>
                                      ) : undefined}

                                      {users.status === 'ativo' ? (
                                        <Stack
                                          direction='row'
                                          alignItems='center'
                                          spacing={1}
                                        >
                                          <Typography>Não</Typography>
                                          <FormControlLabel
                                            control={
                                              <Switch
                                                checked={
                                                  updateInputValue(
                                                    users,
                                                    values,
                                                    'encerrar_voluntariado'
                                                  ) == 'on'
                                                    ? true
                                                    : false
                                                }
                                                onChange={handleChange}
                                                name='encerrar_voluntariado'
                                                disabled={isDisabled}
                                              />
                                            }
                                            labelPlacement='start'
                                          />
                                          <Typography>Sim</Typography>
                                        </Stack>
                                      ) : undefined}
                                    </Stack>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{ display: 'flex', gap: '1.5rem' }}
                                  >
                                    <Grid
                                      container
                                      spacing={3}
                                      alignContent={'center'}
                                    >
                                      <Grid item xs={6}>
                                        <JumboTextField
                                          fullWidth
                                          name='data_fim'
                                          type='date'
                                          label='Data Final'
                                          value={updateInputValue(
                                            users,
                                            values,
                                            'data_fim'
                                          )}
                                          sx={{ background: '#ffffff' }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          disabled={
                                            isDisabled ||
                                            (isInputEditable
                                              ? isStatusDisabled
                                              : false)
                                          }
                                        />
                                      </Grid>

                                      <Grid item xs={6}>
                                        <JumboTextField
                                          fullWidth
                                          name='motivo_encerramento'
                                          label='Motivo'
                                          value={updateInputValue(
                                            users,
                                            values,
                                            'motivo_encerramento'
                                          )}
                                          disabled={
                                            isDisabled ||
                                            (isInputEditable
                                              ? isStatusDisabled
                                              : false)
                                          }
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                                {values.encerrar_voluntariado == 'on' ? null : (
                                  <Divider variant='middle' />
                                )}
                              </Grid>
                              <Grid item xs={12}>
                                <Accordion
                                  expanded={
                                    updateInputValue(
                                      users,
                                      values,
                                      'infos_usuario'
                                    ) == 'on'
                                      ? true
                                      : false
                                  }
                                  elevation={0}
                                >
                                  <AccordionSummary>
                                    <Stack
                                      sx={{
                                        width: '100%',
                                        textAlign: { xs: 'center', lg: 'left' },
                                      }}
                                      direction={{ xs: 'column', lg: 'row' }}
                                      justifyContent='space-between'
                                      alignItems='center'
                                      spacing={2}
                                    >
                                      <Typography sx={{ fontSize: '1.05rem' }}>
                                        Informações sobre criação e atualização
                                        do usuário
                                      </Typography>

                                      <Stack
                                        direction='row'
                                        spacing={1}
                                        alignItems='center'
                                      >
                                        <Typography>Ocultar</Typography>
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={
                                                updateInputValue(
                                                  users,
                                                  values,
                                                  'infos_usuario'
                                                ) == 'on'
                                                  ? true
                                                  : false
                                              }
                                              onChange={handleChange}
                                              name='infos_usuario'
                                              disabled={isDisabled}
                                            />
                                          }
                                          labelPlacement='start'
                                        />
                                        <Typography>Visualizar</Typography>
                                      </Stack>
                                    </Stack>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid item xs={12}>
                                      <InfosAboutCreateAndUpdate
                                        data={users}
                                        quem_criou={
                                          users?.quem_aceitou?.nome ?? '-'
                                        }
                                        quem_atualizou={
                                          users?.quem_atualizou?.nome ?? '-'
                                        }
                                      />
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              </Grid>
                            </>
                          ) : null}

                          {values.encerrar_voluntariado == 'on' ? ( // verifica se o switch é true
                            <Grid
                              item
                              xs={12}
                              sx={{ textAlign: 'center', m: 3 }}
                            >
                              <LoadingButton
                                color='error'
                                type='submit'
                                variant='contained'
                                size='small'
                                loading={isSubmitting}
                                disabled={
                                  isDisabled ||
                                  isInputFocused ||
                                  status == false ||
                                  values.data_fim == '' ||
                                  values.data_fim == null ||
                                  values.motivo_encerramento == null ||
                                  values.motivo_encerramento == ''
                                }
                              >
                                Encerrar voluntariado
                              </LoadingButton>
                            </Grid>
                          ) : (
                            <Grid
                              item
                              xs={12}
                              sx={{ textAlign: 'center', m: 3 }}
                            >
                              <Box
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                gap={1}
                              >
                                <LoadingButton
                                  color='success'
                                  type='submit'
                                  variant='contained'
                                  size='small'
                                  sx={{ maxWidth: { md: '200px', mt: '1rem' } }}
                                  loading={isSubmitting}
                                  disabled={
                                    isDisabled ||
                                    isInputFocused ||
                                    values.data_inicio == '' ||
                                    values.data_inicio == null ||
                                    (isInputEditable ? isStatusDisabled : false)
                                  }
                                >
                                  Cadastrar
                                </LoadingButton>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                </Grid>
              </Form>
            );
          }}
        </Formik>
        <Box>
          {newActivityVolunteerArea && !users.area_id ? (
            volunteerAreasHistory.map((area) => (
              <Box key={area.area_id} mb={4}>
                <Typography
                  variant='h6'
                  fontSize={'1rem'}
                  fontWeight={'bold'}
                  gutterBottom
                >
                  {`Atividades para a área: ${area.nome}`}
                </Typography>
                <MUIDataTable
                  data={area.Atividade_Do_Voluntario}
                  columns={columnsData}
                  options={options}
                />
              </Box>
            ))
          ) : (
            <>
              <Typography
                variant='h6'
                fontSize={'1rem'}
                fontWeight={'bold'}
                gutterBottom
              >
                {`Área: ${
                  volunteerAreaHistory.length > 0
                    ? volunteerAreaHistory[0].Areas?.nome
                    : '-'
                }`}
              </Typography>
              <MUIDataTable
                data={volunteerAreaHistory}
                columns={columns}
                options={options}
              />
            </>
          )}
        </Box>
      </Paper>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={openActivityEdit}
        onClose={handleCloseActivityEdit}
        closeAfterTransition
      >
        <Fade in={openActivityEdit}>
          <Div sx={style}>
            <VolunteerAlocationFormResumeEdit
              volunteerId={volunteerId}
              areaId={areaId}
              activitySelected={activitySelected}
              getVolunteerAreaHistory={() => getVolunteerAreaHistory()}
              handleCloseActivityEdit={() => handleCloseActivityEdit()}
              isButtonDisabled={isButtonDisabled}
              setIsButtonDisabled={setIsButtonDisabled}
            />
          </Div>
        </Fade>
      </Modal>
    </JumboContentLayout>
  );
};

export default VolunteerAlocationFormResume;
