import React from "react";
import PeopleIcon from '@mui/icons-material/People';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import ClassIcon from '@mui/icons-material/Class';
import CategoryIcon from '@mui/icons-material/Category';
import HistoryIcon from '@mui/icons-material/History';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupsIcon from '@mui/icons-material/Groups';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

const menus = [
    {
        label: 'sidebar.menu.home',
        type: "section",
    },
    {
       
        label: 'sidebar.menu.admin',
        type: "collapsible",
        icon: <ManageAccountsIcon sx={{fontSize: 20}}/>,
        children: [
            {
                uri: "/app/listar-salas",
                label: 'Salas',
                type: "nav-item",
                //icon: <CategoryIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/app/listar-centros-espiritas",
                label: 'Centros Espíritas',
                type: "nav-item",
                //icon: <CategoryIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/app/listar-areas",
                label: 'Áreas',
                type: "nav-item",
                //icon: <CategoryIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/app/listar-departamentos",
                label: 'Departamentos',
                type: "nav-item",
                //icon: <HolidayVillageIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/app/log-de-atividades",
                label: 'Log de Atividades',
                type: "nav-item",
                //icon: <HistoryIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/app/listar-perfis-de-acesso",
                label: 'Perfis',
                type: "nav-item",
                //icon: <ManageAccountsIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/app/listar-usuarios-registrados",
                label: 'Usuários',
                type: "nav-item",
                //icon: <GroupsIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/app/listar-introdutorios-cursos",
                label: 'Cursos Introdutórios',
                type: "nav-item",
                //icon: <GroupsIcon sx={{fontSize: 20}}/>
            },
        
        ]
    },
    {
        label: 'sidebar.menu.pessoas',
        type: "collapsible",
        icon: <PeopleIcon sx={{fontSize: 20}}/>,
        children: [
            {
                uri: "/app/listar-usuarios",
                label: 'Cadastro Único',
                type: "nav-item",
            },
            {
                uri: "/app/listar-associados",
                label: 'Associado',
                type: "nav-item",
            },
            {
                uri: "/app/listar-conselhos",
                label: 'Conselho Deliberativo',
                type: "nav-item",
            },
        
        ]
    },
    {
       
        label: 'sidebar.menu.ensino',
        type: "collapsible",
        icon: <ClassIcon sx={{fontSize: 20}}/>,
        children: [
            {
                uri: "/app/listar-consultar-historico",
                label: 'Consultar Histórico de Cursos',
                type: "nav-item",
            },
            {
                uri: "/app/listar-cursos",
                label: 'Cursos',
                type: "nav-item",
            },
            {
                uri: "/app/listar-turmas",
                label: 'Turmas',
                type: "nav-item",
            },
            {
                uri: "/app/listar-turmas-inserir-aluno",
                label: "Inclusão de Alunos na Turma",
                type: "nav-item",
            },
            {
                uri: "/app/listar-inscricoes-abertas",
                label: 'Inscrições nos Cursos',
                type: "nav-item",
                //icon: <GroupsIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/app/listar-avaliacao-de-documentos",
                label: "Avaliação de Documentos",
                type: "nav-item",
            },
            {
                uri: "/app/listar-curso-lote",
                label: "Lançamento de Curso em Lote",
                type: "nav-item",
            },
            {
                uri: "/app/listar-cursos-outras-entidades",
                label: "Inclusão de Cursos de Outras Entidades",
                type: "nav-item",
            }
        
        ]
    },
    {
       
        label: 'sidebar.menu.voluntariado',
        type: "collapsible",
        icon: <VolunteerActivismIcon sx={{fontSize: 20}}/>,
        children: [
            {
                uri: "/app/listar-consultar-historico-voluntariado",
                label: 'Consultar Histórico de Voluntário',
                type: "nav-item",
            },
            {
                uri: "/app/listar-voluntariado",
                label: 'Voluntários',
                type: "nav-item"
            },

            {
                uri: "/app/listar-alocar-voluntariado",
                label: 'Voluntários',
                type: "nav-item"
            },

            {
                uri: "/app/listar-livros",
                label: 'Voluntários',
                type: "nav-item"
            },

            {
                uri: "/app/listar-livros-inserir-voluntario",
                label: 'Voluntários',
                type: "nav-item"
            },
            
            {
                uri: "/app/listar-interesse-voluntariado",
                label: 'Interesse Voluntariado',
                type: "nav-item"
            },

            {
                uri: "/app/listar-atividades",
                label: 'Atividades',
                type: "nav-item",
                //icon: <CategoryIcon sx={{fontSize: 20}}/>
            },

            {
                uri: "/app/impressao-de-crachas",
                label: 'Voluntários',
                type: "nav-item"
            },
            
        
        ]
    },
];

export default menus;
