import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import PageHeader from '../../../layouts/shared/headers/PageHeader/PageHeader';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { Button, Grid, Paper, Stack, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { updateInputValue } from '../../../utils/appHelpers';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';

const validationSchema = yup.object().shape(
    {
        nome: yup
            .string('Insira o nome da área')
            .required('Preenchimento obrigatório'),
    },
    []
);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
};

const ActivitiesForm = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const navigate = useNavigate();
    let { state, pathname } = useLocation();


    

    const { hasPermission } = useContext(PermissionContext);

    const { theme } = useJumboTheme();

    const Swal = useSwalWrapper();

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(
        () => ({
            sidebar: {
                sx: {
                    [theme.breakpoints.up('lg')]: {
                        position: 'sticky',
                        zIndex: 5,
                        top: 96,
                        minHeight: 'auto',
                    },
                    [theme.breakpoints.down('lg')]: {
                        display: 'none',
                    },
                },
            },
            wrapper: {
                sx: {
                    alignItems: 'flex-start',
                },
            },
        }),
        [theme]
    );
   
    const initialValues = {
        nome: '',
    };

    const { id } = useParams();
    const [activities, setActivities] = useState(initialValues);

    const canCreate = hasPermission('Áreas', 'criar');
    const canEdit = hasPermission('Áreas', 'editar');
    const isDisabled = id ? !canEdit : !canCreate;

    const toast = (variant, message, type = false) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });
    };

    const getArea = useCallback(async () => {
        try {
            ApiService.get(`/atividades-voluntarios/${id}`)
                .then((response) => {
                    setActivities(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        if (id) getArea();
    }, []);

    useEffect(() => {
        Swal.close();
    }, [pathname, Swal]);

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        if (id) {
            ApiService.put(`/atividades-voluntarios/${id}`, {
                values,
            })
                .then(() => {
                    toast('success', 'Atualizado com sucesso');
                })
                .catch((error) => {
                    let message = 'Ocorreu um erro';

                    toast('error', message);

                    if (error.response.data) {
                        console.log(error.response.data.error.meta.target);
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } else {
            await ApiService.post('/atividades-voluntarios', {
                values,
            })
                .then((response) => {
                    toast('success', 'Criado com sucesso');
                    resetForm();
                })
                .catch((error) => {
                    const target = error.response.data.error.meta.target;
                    let message = 'Ocorreu um erro';

                    toast('error', message);

                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        }

        setSubmitting(false);
    };

    return (
        <JumboContentLayout
            header={
                <HeaderBreadcrumbs
                    id={id}
                    title={'Atividades'}
                    subtitle={id ? 'Editar' : 'Cadastro de Atividades'}
                    titleUrl={'/app/listar-atividades'}
                />
            }
            layoutOptions={layoutOptions}
        >
            {lg && (
                <Stack
                    spacing={2}
                    direction={'row'}
                    sx={{ mb: 3, mt: -2 }}
                ></Stack>
            )}
            <Paper sx={{ p: '15px', background: '#f9f9f9' }}>
                {state && (
                    <Button
                        color='success'
                        type='submit'
                        variant='contained'
                        size='large'
                        sx={{ maxWidth: { md: '200px', mt: '1rem' } }}
                        onClick={() => navigate(state.backUrl)}
                    >
                        Voltar
                    </Button>
                )}
                <Formik
                    initialValues={activities}
                    // validationSchema={null}
                    validationSchema={validationSchema}
                    enableReinitialize
                    validateOnChange={false}
                    //validateOnBlur={true}
                    onSubmit={handleSubmit}
                >
                    {({ values, isSubmitting }) => (
                        <Form
                            style={{ width: '100%' }}
                            noValidate
                            autoComplete='off'
                        >
                            <Grid container alignContent={'center'}>
                                <Grid
                                    container
                                    spacing={3}
                                    alignContent={'center'}
                                    sx={{
                                        mt: '0.8rem',
                                        background: '#EFF0F2',
                                        marginLeft: 0,
                                        padding: '0 24px 24px 0',
                                    }}
                                >
                                    <Grid item lg={9} xs={12}>
                                        <JumboTextField
                                            fullWidth
                                            name='nome'
                                            label='Título do atividade'
                                            value={updateInputValue(
                                                activities,
                                                values,
                                                'nome'
                                            )}
                                            sx={{ background: '#ffffff' }}
                                            disabled={isDisabled}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ textAlign: 'center', mt: '1rem' }}
                                    >
                                        <LoadingButton
                                            color='success'
                                            type='submit'
                                            variant='contained'
                                            size='large'
                                            sx={{
                                                maxWidth: {
                                                    md: '200px',
                                                    mt: '1rem',
                                                },
                                            }}
                                            loading={isSubmitting}
                                            disabled={isDisabled}
                                        >
                                            {id ? 'Atualizar' : 'Cadastrar'}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Paper>
        </JumboContentLayout>
    );
};

export default ActivitiesForm;
