import React from 'react';
import { Box, Typography, TableCell } from '@mui/material';
import MUIDataTable from 'mui-datatables';

const columnsContinuing = [
  {
    name: 'Turma.Curso.nome',
    label: 'Nome',
    options: {
      filter: false,
      sort: false,
      setCellProps: () => ({
        width: '40%',
      }),
      customHeadRender: (
        columnMeta,
        handleToggleColumn,
        sortOrder,
        sortColumn
      ) => <TableCell width={'40%'}>Nome</TableCell>,
    },
  },
  {
    name: 'ano_de_conclusao',
    label: 'Conclusão',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customBodyRender: (value) => (
        <Typography variant={'body1'}>{value ?? '-'}</Typography>
      ),
    },
  },
  /* {
    name: 'Turma.local',
    label: 'Local (FEESP ou Outro centro)',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customHeadRender: (
        columnMeta,
        handleToggleColumn,
        sortOrder,
        sortColumn
      ) => (
        <TableCell rowSpan={2} align='center'>
          Local de Realização
          <br />
          (FEESP ou Outro centro)
        </TableCell>
      ),
      customBodyRender: (value) => (
        <Typography variant={'body1'}>{value ?? '-'}</Typography>
      ),
    },
  }, */

  {
    name: 'Turma',
    label: 'Local',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        align: 'center',
        width: '25%'
      }),
      setCellProps: () => ({
        align: 'center',
        width: '25%'
      }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          <Typography variant={'body1'}>
            {value?.Centro_Espirita?.nome_fantasia || value?.local}
          </Typography>
        </>
      ),
    },
  },
];

const optionsContinuing = {
  filterType: 'checkbox',
  selectableRows: 'none',
  search: false,
  download: false,
  print: false,
  sortFilterList: false,
  viewColumns: false,
  filter: false,
  enableNestedDataAccess: '.',
  textLabels: {
    pagination: {
      next: 'Próxima Página',
      previous: 'Página Anterior',
      rowsPerPage: 'Itens por Página:',
      displayRows: 'de',
    },
  },
  setTableProps: () => {
    return {
      size: 'small',
    };
  },
};

const componentsContinuing = {
  ExpandButton: function (props) {
    return <div style={{ width: '24px' }} />;
  },
};

const ContinuingCoursesList = ({ coursesContinued }) => {
  const coursesContinuingFiltered = coursesContinued.filter(
    (item) => !item.curso_compativel_id
  );

  const sortedCoursesContinuingFiltered = coursesContinuingFiltered.sort(
    (a, b) => {
      const anoA = parseInt(a.Turma?.ano);
      const anoB = parseInt(b.Turma?.ano);

      if (anoA < anoB) {
        return -1;
      }
      if (anoA > anoB) {
        return 1;
      }
      return 0;
    }
  );

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={1}
      marginTop='2rem'
      marginBottom='1rem'
    >
      <Typography variant={'h4'} margin={0}>
        Cursos de Educação Continuada
      </Typography>
      {sortedCoursesContinuingFiltered.length > 0 ? (
        <MUIDataTable
          data={sortedCoursesContinuingFiltered}
          columns={columnsContinuing}
          options={optionsContinuing}
          components={componentsContinuing}
        />
      ) : (
        <Typography>Nenhum curso encontrado</Typography>
      )}
    </Box>
  );
};

export default ContinuingCoursesList;
