import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { Button, Grid, Paper, Stack, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { updateInputValue } from '../../../utils/appHelpers';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
// import ClassStudentsList from "./ClassStudentsList";
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';
import InputMask from 'react-input-mask';

const SpiritualCenterForm = () => {
  const { theme } = useJumboTheme();
  const { showDialog, hideDialog } = useJumboDialog();

  const navigate = useNavigate();
  let { state, pathname } = useLocation();

  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });

    //  Redireciona se mensagem de retorno for de sucesso
    /* if(id) {
            if(variant == 'success' && (!departmentImage || (departmentImage && type))){
                setTimeout(function(){
                    navigate("/app/listar-departamentos")
                }, 2000)
            }
        } */
  };

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const initialValues = {
    nome: '',
    nome_fantasia: '',
    endereco: '',
    telefone: '05511',
  };

  const validationSchema = yup.object().shape(
    {
      nome: yup.string().required('Preenchimento obrigatório'),
    },
    []
  );

  const { id } = useParams();

  const [data, setData] = useState(initialValues);
  const { hasPermission } = useContext(PermissionContext);

  const canCreate = hasPermission('Centros Espíritas', 'criar');
  const canEdit = hasPermission('Centros Espíritas', 'editar');
  const isDisabled = id ? !canEdit : !canCreate;

  const getDataById = useCallback(async () => {
    try {
      ApiService.get(`/centro-espirita/${id}`)
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  useEffect(() => {
    if (id) getDataById();
  }, [id, getDataById]);

  useEffect(() => {
    Swal.close();
  }, [pathname, Swal]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (id) {
      ApiService.put(`/centro-espirita/${id}`, { values })
        .then(() => {
          toast('success', 'Atualizado com sucesso');
        })
        .catch((error) => {
          toast('error', 'Ocorreu um erro');
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } else {
      await ApiService.post('/centro-espirita', { values })
        .then((response) => {
          toast('success', 'Criado com sucesso');
          resetForm();
        })
        .catch((error) => {
          toast('error', 'Ocorreu um erro');
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    }

    setSubmitting(false);
  };

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={'Centros Espíritas'}
          subtitle={
            id ? 'Editar Centro Espírita' : 'Cadastro de Centro Espírita'
          }
          titleUrl={'/app/listar-centros-espiritas'}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '40px' }}>
        {state && (
          <Button
            color='success'
            type='submit'
            variant='contained'
            size='large'
            sx={{ maxWidth: { md: '200px', mt: '1rem' }, mb: '2rem' }}
            onClick={() => navigate(state.backUrl)}
          >
            Voltar
          </Button>
        )}
        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange }) => (
            <Form style={{ width: '100%' }} noValidate autoComplete='off'>
              <Grid container spacing={3} alignContent={'center'}>
                <Grid item xs={3}>
                  <JumboTextField
                    fullWidth
                    name='nome'
                    label='Nome'
                    value={updateInputValue(data, values, 'nome')}
                    disabled={isDisabled}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <JumboTextField
                    fullWidth
                    name='nome_fantasia'
                    label='Nome Fantasia'
                    value={updateInputValue(data, values, 'nome_fantasia')}
                    disabled={isDisabled}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <JumboTextField
                    fullWidth
                    name='endereco'
                    label='Endereço'
                    value={updateInputValue(data, values, 'endereco')}
                    disabled={isDisabled}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputMask
                    mask='+999(99)99999-9999'
                    value={updateInputValue(data, values, 'telefone')}
                    onChange={handleChange}
                    disabled={isDisabled}
                    disabledUnderline={isDisabled}
                  >
                    {(inputProps) => (
                      <JumboTextField
                        {...inputProps}
                        fullWidth
                        name='telefone'
                        label='Telefone (DDI, DDD e número)'
                        sx={{ background: '#ffffff' }}
                        disabled={inputProps.disabledUnderline}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </InputMask>
                </Grid>

                <Grid item xs={12} sx={{ textAlign: 'center', mt: '1rem' }}>
                  <LoadingButton
                    color='success'
                    type='submit'
                    variant='contained'
                    size='large'
                    sx={{ maxWidth: { md: '200px', mt: '1rem' } }}
                    loading={isSubmitting}
                    disabled={isDisabled}
                  >
                    {id ? 'Atualizar' : 'Cadastrar'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default SpiritualCenterForm;
