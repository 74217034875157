import React from 'react';
import {useField} from "formik";
import TextField from "@mui/material/TextField";
import { styled } from '@mui/material';

//todo: to see how to define prop-types for this component

const JumboTextField = (props) => {
    const [field, meta] = useField(props);
    /* {console.log({props}, {field, meta})} */
    
    const errorText = meta.error && meta.touched ? meta.error : '';
    return (
        <TextField
            {...field}
            {...props}
            helperText={errorText}
            error={!!errorText}
            FormHelperTextProps={{
                sx:{ background: '#EFF0F2', margin: 0, padding: '3px 14px 0 14px'}
            }}
        />
    );
};

const TextFieldStyle = styled(TextField)`
  
    /* label {
        font-size: 1rem;
    } */
`
export default JumboTextField;